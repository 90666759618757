import React, {useContext, useEffect} from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row, Container } from 'react-bootstrap';
import Phone from '../../components/Phone';
import styled from 'styled-components';
import seal_head from '../../assets/characters/fish-head.svg';
import tiktok_1 from '../../assets/misc/fish-tiktok-1.jpg';
import tiktok_2 from '../../assets/misc/fish-tiktok-2.jpg';
import tiktok_3 from '../../assets/misc/fish-tiktok-3.jpg';
import tiktok_4 from '../../assets/misc/fish-tiktok-4.jpg';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const TikTokFollow: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        playVoiceover('fish-follow');
    }, [])
    return (
        <Slide header={defaultHeader} backgroundColor={'rgba(0,0,0,0.5)'} footerHidden={true}>
            <Phone backgroundColor={'#E1DEF9'}>
                <StyledContainer>
                    <PhoneHeader>
                        <PhoneHeaderTitle>NikNak</PhoneHeaderTitle>
                    </PhoneHeader>
                    <PhoneContent>
                        <Row className={'align-items-center'}>
                            <Col xs={'4'} className={'pr-0'}>
                                <SealCircle src={seal_head} alt="Fish's Profile Picture" />
                            </Col>
                            <Col xs={'8'}>
                                <h3>{t`Skillful Fish`}</h3>
                                <Button onClick={() => nextSlide()}>{t`Follow`}</Button>
                            </Col>
                        </Row>
                        <Row className={'mt-3 mt-sm-3 mt-md-4 mt-lg-5'}>
                            <TikTok>
                                <img src={tiktok_1} alt="Fish NikNak Video" width={'100'} />
                            </TikTok>
                            <TikTok>
                                <img src={tiktok_2} alt="Fish NikNak Video" width={'100'} />
                            </TikTok>
                            <TikTok>
                                <img src={tiktok_3} alt="Fish NikNak Video" width={'100'} />
                            </TikTok>
                            <TikTok>
                                <img src={tiktok_4} alt="Fish NikNak Video" width={'100'} />
                            </TikTok>
                        </Row>
                    </PhoneContent>
                </StyledContainer>
            </Phone>
        </Slide>
    );
};
const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
`;

const Message = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    color: #6400aa;
    padding: 4%;
    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
    }
`;

const MessageBtn = styled(Button)`
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 100%;
    margin-bottom: 15px;
    color: #6400aa;
`;
const SealCircle = styled.img`
    width: 100%;
`;

const PhoneHeaderTitle = styled.p`
    align-self: flex-end;
    margin: 0;
`;
const App = styled.img`
    width: 100%;
    padding-left: 10%;
    display: block;
`;

const PhoneContent = styled.div`
    flex: 1;
    padding: 5% 5% 3% 5%;
    display: flex;
    flex-flow: column;
`;

const TikTok = styled.div`
    display: inline-block;
    margin: 2%;
    width: 28%;
    img {
        width: 100%;
    }
`;

const PhoneHeader = styled.div`
    width: 100%;
    background-color: #6400aa;
    padding: 10% 5% 3% 5%;
    flex-shrink: 0;
`;

const PhoneFooter = styled.div`
    height: 13%;
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    @media screen and (min-width: 1000px) {
        display: block;
    }
    overflow: hidden;
`;

export default TikTokFollow;
