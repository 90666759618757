import { TCharacter } from '../../../general/Character';
import image from '../../../../assets/characters/thief_girl.svg';
import image_vault from '../../../../assets/characters/thief_girl-safe.svg';
import { t } from 'ttag';

const AgentTokyo: TCharacter = {
    characterName: t`Agent Anushka`,
    characterImage: image,
    characterWidth: 150,
    characterHeight: 180,
};

export const AgentTokyoVault: TCharacter = {
    characterName: t`Agent Anushka`,
    characterImage: image_vault,
    characterWidth: 200,
    characterHeight: 180,
};

export default AgentTokyo;
