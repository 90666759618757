import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import ModuleRouter from './modules/ModuleRouter';
import { Button, Col, Container, Row } from 'react-bootstrap';
import VolumeContext from './misc/volume-context';

import barefoot_zoo from '../assets/thumbs/barefoot_zoo.jpg';
import code_cracking from '../assets/thumbs/code_cracking.jpg';
import sorting_it_out from '../assets/thumbs/sorting_it_out.jpg';
import asteroid_blaster from '../assets/thumbs/asteroid_blasters.jpg';
import colourful_kits from '../assets/thumbs/colourful_kits.jpg';
import the_diamond from '../assets/thumbs/the_diamond.jpg';
import festive from '../assets/thumbs/festive.jpg';
import phisherman from '../assets/thumbs/phisherman.jpg';
import tricky_traffic from '../assets/thumbs/tricky_traffic.jpg';
import { volume as v } from '../services/volume';

import styled from 'styled-components';

const App: React.FC = () => {
    const [volume, setVolume] = useState(v);
    const [voiceoverMuted, setVoiceoverMuted] = useState(false)
    const [musicMuted, setMusicMuted] = useState(false)
    const value = { volume, setVolume, voiceoverMuted, setVoiceoverMuted, musicMuted, setMusicMuted };

    return (
        <VolumeContext.Provider value={value}>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <h1 className={'my-4 text-center text-md-left'}>Barefoot Games</h1>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={barefoot_zoo}
                                            alt={'Barefoot Zoo'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>Barefoot Zoo</h3>
                                        <Ages>5-11 YEARS</Ages>
                                        <Link to="barefoot-zoo">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={code_cracking}
                                            alt={'Code Cracking'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>Code Cracking</h3>
                                        <Ages>7-11 YEARS</Ages>
                                        <Link to="code-cracking">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={colourful_kits}
                                            alt={'Colourful Kits'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>Colourful Kits</h3>
                                        <Ages>5-11 YEARS</Ages>
                                        <Link to="colourful-kits">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={the_diamond}
                                            alt={'The Diamond'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>The Diamond</h3>
                                        <Ages>9-11 YEARS</Ages>
                                        <Link to="the-diamond">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={sorting_it_out}
                                            alt={'Sorting It Out'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>Sorting it Out!</h3>
                                        <Ages>5-7 YEARS</Ages>
                                        <Link to="sorting" className="text-white">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={asteroid_blaster}
                                            alt={'Asteroid Blasters'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />
                                        <h3 className={'text-center mb-0 px-2'}>Asteroid Blasters</h3>
                                        <Ages>7-11 YEARS</Ages>
                                        <Link to="variables">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>

                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={festive}
                                            alt={'Gift Wrapped'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />

                                        <h3 className={'text-center mb-0 px-2'}>Gift Wrapped</h3>
                                        <Ages>4-7 YEARS</Ages>
                                        <Link to="gift-wrapped">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={phisherman}
                                            alt={'The Phisherman'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />

                                        <h3 className={'text-center mb-0 px-2'}>The Phisherman</h3>
                                        <Ages>8-11 YEARS</Ages>
                                        <Link to="the-phisherman">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <div
                                        className={
                                            'island island--rounded island--index bg-white text-center mb-3 pb-3'
                                        }
                                    >
                                        <img
                                            src={tricky_traffic}
                                            alt={'Tricky Traffic'}
                                            width={'200px'}
                                            className={'d-block w-100 mb-3'}
                                        />

                                        <h3 className={'text-center mb-0 px-2'}>Tricky Traffic</h3>
                                        <Ages>7-11 YEARS</Ages>
                                        <Link to="tricky-traffic">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col>
                                {/* <Col xs={6} md={4}>
                                    <div className={'island island--rounded island--index bg-white text-center mb-3 pb-3'}>

                                        <h3 className={'text-center mb-3 px-2'}>Cyber Security</h3>
                                        <Link to="cybersecurity">
                                            <Button className="btn-3d btn-3d-dark">Start</Button>
                                        </Link>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </Route>
                    <ModuleRouter></ModuleRouter>
                </Switch>
            </Router>
        </VolumeContext.Provider>
    );
};

const Ages = styled.p`
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #777777;
    font-size: 16px;
`;

export default App;
