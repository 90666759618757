import React from 'react';
import { FeatureMatchQuestion } from './GroupFlags';
import ModalOverlay from '../../general/ModalOverlay';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const CorrectModal: React.FC<{ question: FeatureMatchQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <React.Fragment>
            <motion.div
                className={'w-100 text-center'}
                animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
                transition={{ duration: 0.2 }}
            >
                <h2 className="text-white text-center">
                    <i className="fas fa-thumbs-up mr-2"></i> {t`CORRECT!`}
                </h2>
                <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
            </motion.div>
        </React.Fragment>
    );

    const footer = <Button onClick={() => onClose()}>{t`Continue`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#00C3A5">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <div className="character__wrapper">
                        <img
                            className={'ck-flags mb-3'}
                            width="150px"
                            src={question.subject.image}
                            alt={question.subject.name}
                        />
                    </div>
                    <div className="island island--guide island--rounded p-4 px-md-5 mx-auto">
                        <p className={'mb-3 text-secondary'}>{question.subject.answer}</p>
                        {question.features
                            .filter((feature) => feature.correct)
                            .map((feature) => (
                                <div className={`btn--feature__wrapper`}>
                                    <div className={'btn btn-3d-dark btn--feature text-center'}>{feature.name}</div>
                                </div>
                            ))}
                    </div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default CorrectModal;
