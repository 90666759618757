import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const Outro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`Thank you for your help, I hope you learned a thing or two about tinkering. You have done such an amazing job, the elves will be delighted!`,
            buttonText: t`Woohoo!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Outro;
