import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import WordDiscovery from '../../../../general/WordDiscovery';

const GiftboxIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [show, setShow] = useState(false);
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`Now that we have the gifts, we need to make sure that we have the perfect gift boxes to go with them.`,
            buttonText: t`Next`,
        },
        {
            character: Penguin,
            text: t`Let's see if you can make more of our beautiful gift boxes by tinkering with our gift box machine...`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
        // {
        //     character: Penguin,
        //     text: t`Packing the gifts requires trial and error. Too many gifts and box will break, too few and our friends will be unhappy.`,
        //     buttonText: t`Next`,
        //     buttonAction: nextSlide,
        // },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Tinkering`}
                wordDefinition={t`Playing or exploring to discover what something is and how it works.`}
            ></WordDiscovery>
            {/* <WordDiscovery show={show} onClose={nextSlide} word={ t`Tinkering` } wordDefinition={ t`Tinkering is when you try something new to discover what it does and how it works.` }></WordDiscovery> */}
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default GiftboxIntro;
