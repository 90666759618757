import Intro from './Intro';
import ShapeGroupingIntro from './ShapeGroupingIntro';
import AttributesGame from './AttributesGame';
import ShapeGroupingOutro from './ShapeGroupingOutro';
import AttributesQuizIntro from './AttributesQuizIntro';
import AttributesQuiz from './AttributesQuiz';
import AttributesQuizOutro from './AttributesQuizOutro';
import AttributesGame2Intro from './AttributesGame2Intro';
import AttributesGame2 from './AttributesGame2';
import AttributesGame2Outro from './AttributesGame2Outro';
import AttributesGame2Outro2 from './AttributesGame2Outro2';

export default [
    Intro,
    ShapeGroupingIntro,
    AttributesGame,
    ShapeGroupingOutro,

    AttributesQuizIntro,
    AttributesQuiz,
    AttributesQuizOutro,

    AttributesGame2Intro,
    AttributesGame2,
    AttributesGame2Outro,
    AttributesGame2Outro2,
];
