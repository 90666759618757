import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import useLanguage from '../../hooks/language';
import { actions, sendAnalytics } from '../../services/analytics';
import VoiceoverContext from '../misc/voiceover-context';
import VolumeContext from '../misc/volume-context';
import Abstraction from './abstraction';
import CodeCracking from './codecracking';
import ColourfulKits from './colourfulkits';
import CyberSecurity from './cybersecurity';
import Festive from './festive';
import Sorting from './sorting';
import TheDiamond from './theheist';
import Traffic from './traffic';
import Variables from './variables';

const queryString = require('query-string');

const ModuleRouter: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [lang, setLang] = useLanguage(Cookies.get('bfg_lang') ?? 'en');

    const { volume, voiceoverMuted } = useContext(VolumeContext);
    const [voiceover, setVoiceover] = useState<Howl | undefined>();
    const [currentVoiceover, setCurrentVoiceover] = useState<number | undefined>();

    const playVoiceover = (spriteName: string) => {
        if (voiceover && spriteName) {
            let vo: number;

            stopVoiceover();

            if (voiceoverMuted !== undefined && !voiceoverMuted) {
                vo = voiceover.play(spriteName);
                setCurrentVoiceover(vo);
            }
        }
    }

    const stopVoiceover = () => {
        if (voiceover && currentVoiceover) {
            voiceover.stop(currentVoiceover);
        }
    }

    // console.log(location);

    const onComplete = (name: string) => {
        // console.log('Completed!', name);
        sendAnalytics(name, actions.completeModule, {});

        // let queryObj = queryString.parse(location.search);

        // if (
        //     queryObj.ref &&
        //     validURL(queryObj.ref) &&
        //     (queryObj.ref.includes('https://www.bt.com') || queryObj.ref.includes('https://www.barefootcomputing.org'))
        // ) {
        //     console.log('redirect to ', queryObj.ref);
        //     window.location.replace(queryObj.ref);
        // } else {
        //     console.log('redirect to sft');
        //     window.location.replace('https://www.bt.com/skillsfortomorrow/parents/learning-at-home.html');
        // }

        // console.log();
        history.push('/');
    };

    const onCompleteBarefoot = (name: string) => {
        sendAnalytics(name, actions.completeModule, {});

        history.push('/');
    };

    return (
        <Switch>
            <LocaleRoute path="/cy/barefoot-zoo" exact>
                <Abstraction onComplete={onComplete}></Abstraction>
            </LocaleRoute>
            <LocaleRoute path="/barefoot-zoo">
                <Abstraction onComplete={onComplete}></Abstraction>
            </LocaleRoute>

            <LocaleRoute path="/cy/codecracking" exact>
                <CodeCracking onComplete={onComplete}></CodeCracking>
            </LocaleRoute>
            <LocaleRoute path="/codecracking">
                <CodeCracking onComplete={onComplete}></CodeCracking>
            </LocaleRoute>
            <LocaleRoute path="/cy/code-cracking" exact>
                <CodeCracking onComplete={onComplete}></CodeCracking>
            </LocaleRoute>
            <LocaleRoute path="/code-cracking">
                <CodeCracking onComplete={onComplete}></CodeCracking>
            </LocaleRoute>
            <LocaleRoute path="/cy/colourful-kits" exact>
                <ColourfulKits onComplete={onComplete}></ColourfulKits>
            </LocaleRoute>
            <LocaleRoute path="/colourful-kits">
                <ColourfulKits onComplete={onComplete}></ColourfulKits>
            </LocaleRoute>
            <LocaleRoute path="/cy/the-diamond" exact>
                <TheDiamond onComplete={onComplete}></TheDiamond>
            </LocaleRoute>
            <LocaleRoute path="/the-diamond">
                <TheDiamond onComplete={onComplete}></TheDiamond>
            </LocaleRoute>
            <LocaleRoute path="/cy/sorting" exact>
                <Sorting onComplete={onComplete}></Sorting>
            </LocaleRoute>
            <LocaleRoute path="/sorting">
                <Sorting onComplete={onComplete}></Sorting>
            </LocaleRoute>
            <LocaleRoute path="/cy/variables" exact>
                <Variables onComplete={onComplete}></Variables>
            </LocaleRoute>
            <LocaleRoute path="/variables">
                <Variables onComplete={onComplete}></Variables>
            </LocaleRoute>
            <LocaleRoute path="/cy/asteroid-blaster" exact>
                <Variables onComplete={onComplete}></Variables>
            </LocaleRoute>
            <LocaleRoute path="/asteroid-blaster">
                <Variables onComplete={onComplete}></Variables>
            </LocaleRoute>

            <LocaleRoute path="/cybersecurity">
                <Redirect to={'/the-phisherman'}></Redirect>
            </LocaleRoute>

            <LocaleRoute path="/cy/the-phisherman" exact>
                <VoiceoverContext.Provider value={{ voiceover, setVoiceover, playVoiceover, stopVoiceover }}>
                    <CyberSecurity onComplete={onCompleteBarefoot}></CyberSecurity>
                </VoiceoverContext.Provider>
            </LocaleRoute>

            <LocaleRoute path="/the-phisherman">
                <VoiceoverContext.Provider value={{ voiceover, setVoiceover, playVoiceover, stopVoiceover }}>
                    <CyberSecurity onComplete={onCompleteBarefoot}></CyberSecurity>
                </VoiceoverContext.Provider>
            </LocaleRoute>

            <LocaleRoute path="/festive">
                <Redirect to={'/gift-wrapped'}></Redirect>
            </LocaleRoute>

            <LocaleRoute path="/gift-wrapped">
                <Festive onComplete={onCompleteBarefoot}></Festive>
            </LocaleRoute>

            <LocaleRoute path="/traffic">
                <Redirect to={'/tricky-traffic'}></Redirect>
            </LocaleRoute>

            <LocaleRoute path="/tricky-traffic">
                <VoiceoverContext.Provider value={{ voiceover, setVoiceover, playVoiceover, stopVoiceover }}>
                    <Traffic onComplete={onCompleteBarefoot}></Traffic>
                </VoiceoverContext.Provider>
            </LocaleRoute>
            <LocaleRoute path="/cy/tricky-traffic" exact>
                <VoiceoverContext.Provider value={{ voiceover, setVoiceover, playVoiceover, stopVoiceover }}>
                    <Traffic onComplete={onCompleteBarefoot}></Traffic>
                </VoiceoverContext.Provider>
            </LocaleRoute>

            <Route path="*">
                <p>Page not found!</p>
            </Route>
        </Switch>
    );
};

const LocaleRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const [lang, setLang] = useLanguage(Cookies.get('bfg_lang') ?? 'en');

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (location.pathname.indexOf('/cy/') !== -1 && lang !== 'cy') {
                    setLang('cy');
                    window.location.reload();
                } else if (location.pathname.indexOf('/cy/') === -1 && lang === 'cy') {
                    setLang('en');
                    window.location.reload();
                }

                return children;
            }}
        />
    );
};

function validURL(str: string) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
}

export default ModuleRouter;
