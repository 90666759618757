import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import diamond from '../../../../../assets/misc/th-diamond.svg';
import { t } from 'ttag';

const Diamond: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
                    <div className="th-diamond">
                        <h3 className={'text-center'}>{t`DIAMOND RETRIEVED`}</h3>
                        <motion.img
                            src={diamond}
                            alt={t`The Worlds Biggest Diamond`}
                            className="th-part__diamond--alone"
                            animate={{
                                rotate: [0, 380, 360],
                                scale: [0, 1.1, 1],
                            }}
                            transition={{
                                delay: 0.5,
                                duration: 2,
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Slide>
    );
};

export default Diamond;
