import React, { useEffect } from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import sfx from '../../../../../services/sounds';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Whale from '../../characters/Whale';

const WhaleIntro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    useEffect(() => {
        data.playMusic(sfx.themeCybersecurityWhale);
    }, []);

    const dialogue: DialogueData[] = [
        {
            character: Whale,
            text: t`Hey ${data.name}. Welcome to the village. I'm Whale! Turtle has been telling me that you are learning about phishing.`,
            textSpriteName: 'whale-1-1',
            buttonText: t`Next`,
        },
        {
            character: Whale,
            text: t`Now, I have the perfect game to help you learn about how you can spot a phishing scam. Would you like to play?`,
            textSpriteName: 'whale-2-1',
            buttonText: t`Of course!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default WhaleIntro;
