import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const LANGUAGE_COOKIE = 'bfg_lang';

const useLanguage: (language: string) => [string, (l: string) => void] = (language) => {
    const [lang, setLang] = useState(language ?? 'en');

    useEffect(() => {
        Cookies.set(LANGUAGE_COOKIE, lang);
    }, [lang]);

    return [lang, setLang];
};

export default useLanguage;
