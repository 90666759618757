import React from 'react';
import island from '../assets/island.svg';
import styled from 'styled-components';
import turtle from '../assets/characters/2-turtle.svg';
import shark from '../assets/characters/2-whale-bowie.svg';
import pufferfish from '../assets/characters/2-pufferfish-1.svg';
import squid from '../assets/characters/2-octopus.svg';
import seal from '../assets/characters/2-fish.svg';
import shadow from '../assets/characters/shadow.svg';
import sign from '../assets/characters/notification.svg';
import { t } from 'ttag';

interface IslandProps {
    nextSlide(): void;
    notification?: TIslandArea[];
    onSelect?: (location: TIslandArea) => void;
    infoText: string;
}
export type TIslandLocation = 'turtle' | 'squid' | 'pufferfish' | 'seal' | 'shark' | 'house';

export type TIslandArea = {
    name: TIslandLocation;
    onSelect?: () => void; // data.gotoSlide(X)
};

const Island: React.FC<IslandProps> = ({
    notification,
    nextSlide,
    onSelect,
    infoText = t`Select a villager to help them`,
}) => {
    const handleClick = (location?: TIslandArea) => {
        // take off incomplete list
        // nextSlide();
        if (onSelect && location) {
            if (location.onSelect) location.onSelect();
            onSelect(location);
        } else if (location) {
            nextSlide();
        }
    };
    return (
        <StyledContainer>
            <IslandWrapper>
                <IslandImg src={island} width="700" />
                {notification?.find((n) => n.name === 'house') ? (
                    <HouseNotification
                        src={sign}
                        onClick={() => handleClick(notification?.find((n) => n.name === 'house'))}
                    />
                ) : null}
                <TurtleTile
                    onClick={() =>
                        notification?.find((n) => n.name === 'turtle')
                            ? handleClick(notification?.find((n) => n.name === 'turtle'))
                            : null
                    }
                    className={notification?.find((n) => n.name === 'turtle') ? 'notification' : ''}
                >
                    <Notification src={sign} />
                    <Shadow>
                        <img src={shadow} />
                    </Shadow>
                    <Char src={turtle} width="40" />
                </TurtleTile>
                <PufferfishTile
                    onClick={() =>
                        notification?.find((n) => n.name === 'pufferfish')
                            ? handleClick(notification?.find((n) => n.name === 'pufferfish'))
                            : null
                    }
                    className={notification?.find((n) => n.name === 'pufferfish') ? 'notification' : ''}
                >
                    <Notification src={sign} />
                    <Shadow>
                        <img src={shadow} />
                    </Shadow>
                    <Char src={pufferfish} width="40" />
                </PufferfishTile>
                <SharkTile
                    onClick={() =>
                        notification?.find((n) => n.name === 'shark')
                            ? handleClick(notification?.find((n) => n.name === 'shark'))
                            : null
                    }
                    className={notification?.find((n) => n.name === 'shark') ? 'notification' : ''}
                >
                    <Notification src={sign} />
                    <Shadow>
                        <img src={shadow} />
                    </Shadow>
                    <Char src={shark} width="40" />
                </SharkTile>
                <SquidTile
                    onClick={() =>
                        notification?.find((n) => n.name === 'squid')
                            ? handleClick(notification?.find((n) => n.name === 'squid'))
                            : null
                    }
                    className={notification?.find((n) => n.name === 'squid') ? 'notification' : ''}
                >
                    <Notification src={sign} />
                    <Shadow>
                        <img src={shadow} />
                    </Shadow>
                    <Char src={squid} width="40" />
                </SquidTile>
                <SealTile
                    onClick={() =>
                        notification?.find((n) => n.name === 'seal')
                            ? handleClick(notification?.find((n) => n.name === 'seal'))
                            : null
                    }
                    className={notification?.find((n) => n.name === 'seal') ? 'notification' : ''}
                >
                    <Notification src={sign} />
                    <Shadow>
                        <img src={shadow} />
                    </Shadow>
                    <Char src={seal} width="40" />
                </SealTile>
            </IslandWrapper>
            <Explanation>
                <h3>{t`Kelpy Deeps`}</h3>
                <p>{infoText}</p>
            </Explanation>
        </StyledContainer>
    );
};

const Explanation = styled.div`
    margin-top: 2rem;
    opacity: 0.75;
    text-align: center;
`;

const HouseNotification = styled.img`
    position: absolute;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    cursor: pointer;
    bottom: 60%;
    width: 46px;
    left: 42%;
    z-index: 999;
    @media screen and (min-width: 768px) {
        width: 60px;
    }
    &:hover {
        transform: translateY(-5px);
    }
`;

const Shadow = styled.div`
    width: 100%;
    opacity: 0.2;
    position: absolute;
    bottom: -5%;
    transition: transform 0.3s ease-in-out, opacity 0.3s linear;
    img {
        width: 100%;
        margin: 0 auto;
        display: block;
        transition: width 0.3s linear;
    }
`;
const Notification = styled.img`
    display: none;
    position: absolute;
    top: -100%;
    width: 30px;
    left: calc(50% - 15px);
    z-index: 999;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    @media screen and (min-width: 420px) {
        width: 46px;
        top: -120%;
        left: calc(50% - 23px);
    }
    @media screen and (min-width: 768px) {
        width: 60px;
        top: -100%;
        left: calc(50% - 30px);
    }
`;

let TileStyles = `
    line-height: 0;
    position:absolute;
    transition: transform 0.2s ease-in-out;

    &:hover{
        transform: translateY(-5px);
        ${Shadow} {
            transform: translateY(5px);
            opacity: 0.1;
            img { width: 50%; }
        }
    }
    &.notification{
        cursor: pointer;
        ${Notification} {
            display: block
        }
    }
`;

const Char = styled.img`
    position: relative;
    width: 100%;
    z-index: 2;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
`;

const IslandImg = styled.img`
    width: 100%;
    max-height: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
`;

const IslandWrapper = styled.div`
    width: 130%;
    margin-left: -15%;
    display: block;
    position: relative;
    @media screen and (min-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`;

const TurtleTile = styled.div`
    ${TileStyles}
    width: 7%;
    left: 41%;
    bottom: 21%;
    ${Notification} {
        top: -105%;
    }
`;

const PufferfishTile = styled.div`
    ${TileStyles}
    width: 8%;
    left: 26%;
    top: 34%;
    ${Notification} {
        top: -215%;
        @media screen and (min-width: 768px) {
            top: -200%;
        }
    }
`;
const SharkTile = styled.div`
    ${TileStyles}
    width: 9%;
    right: 31.5%;
    bottom: 34%;
    ${Notification} {
        top: -175%;
        @media screen and (min-width: 768px) {
            top: -125%;
        }
    }
`;
const SealTile = styled.div`
    ${TileStyles}
    width: 8%;
    right: 26.5%;
    top: 37%;
    ${Notification} {
        top: -175%;
        @media screen and (min-width: 768px) {
            top: -150%;
        }
    }
`;
const SquidTile = styled.div`
    ${TileStyles}
    width: 9%;
    left: 18%;
    bottom: 37.5%;
    ${Notification} {
        top: -175%;
        @media screen and (min-width: 768px) {
            top: -150%;
        }
    }
`;

const StyledContainer = styled.div`
    max-width: 700px;
    width: 80%;
    margin: 0 auto;
`;

export default Island;
