import React from 'react';

interface IContent {
    type: ContentTypeAttr;
    styles: string;
}

type ContentTypeAttr = 'island' | 'island_dark' | null;

export const Content: React.FC<IContent> = ({ type, styles, children }) => {
    if (type === 'island') {
        return (
            <div className={`island island--rounded bg-white ${styles}`}>
                <div className="text-secondary">{children}</div>
            </div>
        );
    }
    if (type === 'island_dark') {
        return (
            <div className={`island island--rounded bg-secondary-700 ${styles}`}>
                <div className="text-white">{children}</div>
            </div>
        );
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default Content;
