import React, { Dispatch, SetStateAction, createContext } from 'react';

type VoiceoverContextProps = {
    voiceover: Howl | undefined;
    setVoiceover: Dispatch<SetStateAction<Howl | undefined>> | undefined;
    playVoiceover: (spriteName: string) => void;
    stopVoiceover: () => void;
};

export const VoiceoverContext = createContext<VoiceoverContextProps>({
    voiceover: undefined,
    setVoiceover: undefined,
    playVoiceover: () => {},
    stopVoiceover: () => {}
});

// set the defaults
// export const VoiceoverContext = React.createContext({
//     volume: 0.5,
//     setVolume: () => {}
// });

export default VoiceoverContext;
