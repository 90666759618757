import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AlgorithmGame, { TAlgorithmQuestion } from '../../../../interactive/algorithm/AlgorithmGame';

const VaultGame: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TAlgorithmQuestion[] = [
        {
            question: '111... 126... 141... 156... 171...',
            answers: ['Add15'],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <AlgorithmGame gameData={gameData} onGameComplete={nextSlide} />
        </Slide>
    );
};

export default VaultGame;
