import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import intel1 from './units/2-intel-1';
import intel2 from './units/3-intel-2';
import ciphers from './units/4-ciphers';
import cipherLab from './units/5-cipher-lab';
import goodbye from './units/6-goodbye';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type CodeCrackingData = {
    name: string;
    setName: (name: string) => void;
};

export enum CodeCrackingColors {
    dayModalBackground = '#FFFFFF',
}

const CodeCracking: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [StartSlide, ...intro, ...intel1, ...intel2, ...ciphers, ...cipherLab, ...goodbye, EndSlide];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>
        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeMilitary.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeMilitary.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Code Cracking | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="1826f255-2926-4a19-a320-ab3c6b392d0d"
                name="codecracking"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
            />
        </React.Fragment>
    );
};

export default CodeCracking;
