import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import car_left from '../../assets/car/left.svg';
import car_right from '../../assets/car/right.svg';
import cones from '../../assets/misc/traffic-cones.svg';
import tree from '../../assets/misc/tree.png';
import bridge_2 from '../../assets/scene/bridge_2-new.svg';
import bridge from '../../assets/scene/bridge_3-new.svg';
import cloud from '../../assets/scene/cloud.svg';
import pattern_cross from '../../assets/scene/pattern_cross.png';
import pattern_dots from '../../assets/scene/pattern_dots.png';
import pattern_wave from '../../assets/scene/pattern_wave--white.png';
import Smoke from '../../components/interactive/scene/Smoke';
import TrafficLight, { TAlert } from '../../components/traffic-lights/TrafficLight';
import { breakpoint, game, size } from '../../styles/junction';
import { theme } from '../../styles/theme';
import Road, { RoadProps } from './scene/Road';
import blue_sign from '../../assets/misc/sign-blue.png';
import blue_sign_welsh from '../../assets/misc/sign-blue-welsh.png';
import red_sign from '../../assets/misc/sign-red.png';
import red_sign_welsh from '../../assets/misc/sign-red-welsh.png';
import Cookies from 'js-cookie';

interface SceneProps {
    nextSlide(): void;
    data?: TrafficData;
    roadworks?: boolean;
    crash?: boolean;
    help?: string;
    alert?: TAlert;
    lights?: boolean;
    road?: RoadProps
}

const Scene: React.FC<SceneProps> = ({ nextSlide, data, roadworks, crash, help, children, alert, lights = true, road }) => {
    const next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    );
    let lang = Cookies.get('bfg_lang');


    return (
        <SceneWrapper texture={pattern_dots}>
            {/* <Grass position={"top_left"}/> */}
            {/* <Grass position={"top_right"}/> */}
            {/* <Grass position={"bottom_right"}/> */}
            {/* <Grass position={"bottom_left"}/> */}
            <Grass texture={pattern_cross} position={"top"}>
                <Sign src={lang === 'cy' ? blue_sign_welsh : blue_sign} position={'right'} />
            </Grass>
            <Grass texture={pattern_cross} position={"bottom"}>
                <Sign src={lang === 'cy' ? red_sign_welsh : red_sign} position={'left'} />
            </Grass>

            <River texture={pattern_wave} position={"top"} />

            <Clouds>
                <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 0 }} style={{ top: '30%' }} />
                <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 3 }} style={{ top: '75%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 30, delay: 30 }} style={{ top: '50%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 50 }} style={{ top: '55%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 40 }} style={{ top: '30%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 60, delay: 0 }} style={{ top: '25%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 50, delay: 15 }} style={{ top: '70%' }} />
                <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 45, delay: 0 }} style={{ top: '40%' }} />
            </Clouds>

            <GameWrapper>

                <Tree src={tree} style={{ width: '7%', left: '-25%', top: '-21%' }} />
                <Tree src={tree} style={{ width: '10%', left: '-20%', top: '-20%' }} />
                <Tree src={tree} style={{ width: '6%', right: '-10%', top: '5%' }} />
                <Tree src={tree} style={{ width: '8%', right: '-30%', bottom: '-15%' }} />
                <Tree src={tree} style={{ width: '4%', left: '5%', bottom: '15%' }} />
                <Tree src={tree} style={{ width: '8%', left: '-30%', bottom: '22%' }} />
                <Tree src={tree} style={{ width: '5%', left: '-74%', bottom: '-20%' }} />
                <Tree src={tree} style={{ width: '8%', left: '-70%', top: '16%' }} />
                <Tree src={tree} style={{ width: '7%', right: '-70%', bottom: '16%' }} />
                <Tree src={tree} style={{ width: '7%', right: '-78%', top: '-24%' }} />

                {lights ? (<TrafficWrapper position={"top_left"} onClick={nextSlide}>
                    <TrafficLight alert={alert} red={{ enabled: true }} />
                </TrafficWrapper>) : null}
                <Bridge position={"top"} src={bridge_2} />

                {road ? <Road {...road}></Road> : null}

                {roadworks ? <><Cones position={"left"} src={cones} /><Cones position={"right"} src={cones} /></> : null}
                {crash ? <><CarCrash1 src={car_right} /><CarCrash2 src={car_left} /><Smoke /></> : null}


                {lights ? (<TrafficWrapper position={"bottom_right"}>
                    <TrafficLight green={{ enabled: true }} />
                </TrafficWrapper>) : null}

                {children}
            </GameWrapper>
            <River texture={pattern_wave} position={"bottom"}>
                <div></div>
            </River>


            {help ? <Intro>{help}</Intro> : null}
            {/* <Alert><FontAwesomeIcon icon={faExclamationTriangle} color={theme.bare_pink} /> INCIDENT REPORTED</Alert> */}
        </SceneWrapper>
    );
};

interface SignProps {
    position: string
}
const Sign = styled.img<SignProps>`
    width: 85px;
    height: 40px;
    position: absolute;
    z-index: 25;
    margin: 0 10px;
    ${(props) => props.position == 'right' ? 'bottom: 5px; right: 0;' : 'top: -10px; left: 0;'}
    @media (min-width: 1200px) {
        width: 170px;
        height: 80px;
        margin: 0 30px;
    }
`;


// Clouds

const Clouds = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    position: fixed;
    z-index: 999;
    img {
        width: 75px;
        position: absolute;
        left: -75px;
        user-select: none;
        pointer-events: none;
        z-index: 998;
        opacity: 0.5;
        &.cloud-half-hack {
            left: 40%;
        }
    }
`;


// Cones

interface ConesProps {
    position: string
}

const Cones = styled.img<ConesProps>`
  width: 7%;
  bottom: 40%;
  position: absolute;
  ${p => p.position === "left" ? 'left: 4%;' : ''};
  ${p => p.position === "right" ? 'right: 4%;' : ''};
`;

// Traffic Light Placements

interface TrafficProps {
    position: string
}
const TrafficWrapper = styled.div<TrafficProps>`
  position: absolute;
  width: 8%;
  height: 28%;
  &:hover{
      cursor: pointer;
      opacity: 0.9;
  }
  ${p => p.position === "top_left" ? 'left: 12.5%; bottom: 68%;' : ''};
  ${p => p.position === "top_right" ? 'right: 25%; bottom: 77.5%;' : ''};
  ${p => p.position === "bottom_left" ? 'left: 25%; bottom: 12.5%;' : ''};
  ${p => p.position === "bottom_right" ? 'right: 12.5%; bottom: 27.5%;' : ''};
`;

// Bridge for Level 1 Junction (2 pieces)

interface BridgeProps {
    position: string
}
const Bridge = styled.img<BridgeProps>`
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 45%;
    @media(min-width: 1000px){
        width: 300px;
    }
    ${p => p.position === "top" ? `
        ${'bottom: calc(46% + ' + game.roadWidth / 2 + 'px)'};
        @media ${breakpoint.mobileL}{
            ${'bottom: calc(46% + ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px)'};
        }
        @media ${breakpoint.laptop}{
            ${'bottom: calc(46% + ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px)'};
        }
        @media ${breakpoint.laptopL}{
            ${'bottom: calc(46% + ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px)'};
        }`
        : `
        ${'z-index: 10; bottom: calc(49% - ' + game.roadWidth / 2 + 'px)'};
        @media ${breakpoint.mobileL}{
            ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px)'};
        }
        @media ${breakpoint.laptop}{
            ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px)'};
        }
        @media ${breakpoint.laptopL}{
            ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px)'};
        }`
    };

`;

// River for Level 1 Junction (2 pieces)

interface RiverProps {
    position: string;
    texture: string
}
const River = styled.div<RiverProps>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${game.roadWidth}px;
    background-color: ${theme.bare_blue};
    background-image: url('${(props) => props.texture}');
    ${p => p.position === "top" ? 'top: 0' : 'bottom: 0'};
    border-left: 2px solid ${game.c_road_2};
    border-right: 2px solid ${game.c_road_2};
    height: ${'calc(50% - ' + (game.roadWidth / 2) + 'px)'};
    ${p => p.position === "top" ? 'height: calc(50% - ' + (game.roadWidth) / 2 + 'px)' : ''};
    div {
        width:100%;
        background-color: rgba(0,0,0,0.2);
        height: 10%;
        max-height: 50px;
    }
    width: 20%;
    max-width: 200px;
    @media ${breakpoint.mobileL}{
        ${'height: calc(50% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px)'};
        ${p => p.position === "top" ? 'height: calc(50% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px)' : ''};

    }
    @media ${breakpoint.laptop}{
        ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px)'};
        ${p => p.position === "top" ? 'height: calc(50% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px)' : ''};


    }
    @media ${breakpoint.laptopL}{

        ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px)'};
        ${p => p.position === "top" ? 'height: calc(50% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px)' : ''};

    }

`;

// Info sections above and below

const Alert = styled.p`
    background-color: #FFFFFF;
    color: ${theme.bare_pink};
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 15px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    font-family: ${theme.font_bt_extra};
    letter-spacing: 0.05em;
    left: 0;
    right: 0;
    max-width: 250px;
    width: 100%;
`;

const Intro = styled.p`
    background-color: rgba(77, 120, 134, 0.7);
    width: 100%;
    max-width: 440px;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    position: absolute;
    top: 85px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    left: 0;
    right: 0;
    pointer-events: none;
    user-select: none;
    @media(min-width:768px){
        font-size: 20px;
        line-height: 22px;
    }
    @media(min-width:1000px){
        font-size: 22px;
    }
    @media(min-width:1400px){
        font-size: 24px;
        line-height: 24px;
    }
`;

const Tree = styled.img`
    position: absolute;
`;

// Placeholder Cars


const CarCrash1 = styled.img`
    width: 17.5%;
    bottom: 42.5%;
    left: 34%;
    transform: rotate(3deg);
    position: absolute;
`;
const CarCrash2 = styled.img`
    width: 17.5%;
    top: 42.5%;
    right:34%;
    transform: rotate(3deg);
    position: absolute;
`;


// Grass shapes for different layouts

interface GrassProps {
    position: string;
    texture: string
}

const Grass = styled.div<GrassProps>`
    position: fixed;
    border: solid 0px ${game.c_road_2};
    background-color: ${theme.bare_green};
    background-image: url('${(props) => props.texture}');

    ${p => p.position === "top" ? 'box-shadow: 0 50px 200px rgba(255,255,255,0.5) inset; left: 0; top: 0; right: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: 100%; border-bottom-width: 4px;' : ''};
    ${p => p.position === "bottom" ? 'box-shadow: 0 -50px 200px rgba(255,255,255,0.5) inset; left: 0; bottom: 0; right: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: 100%;' : ''};

    ${p => p.position === "top_left" ? 'left: 0; top: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: calc(50% - ' + game.roadWidth / 2 + 'px); border-bottom-width: 5px; border-right-width: 4px; border-bottom-right-radius: 15px;' : ''};
    ${p => p.position === "top_right" ? 'right: 0; top: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: calc(50% - ' + game.roadWidth / 2 + 'px); border-bottom-width: 5px; border-left-width: 4px; border-bottom-left-radius: 15px;' : ''};
    ${p => p.position === "bottom_right" ? 'right: 0; bottom: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: calc(50% - ' + game.roadWidth / 2 + 'px); border-left-width: 3px; border-top-left-radius: 15px;' : ''};
    ${p => p.position === "bottom_left" ? 'left: 0; bottom: 0; height: calc(50% - ' + game.roadWidth / 2 + 'px); width: calc(50% - ' + game.roadWidth / 2 + 'px); border-right-width: 3px; border-top-right-radius: 15px;' : ''};

    ${p => p.position !== "top" && p.position !== "bottom" ? `
        @media ${breakpoint.mobileL}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px);'}
            ${'width: calc(50% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px);'}
        }
        @media ${breakpoint.laptop}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px);'}
            ${'width: calc(50% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px);'}
        }
        @media ${breakpoint.laptopL}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px);'}
            ${'width: calc(50% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px);'}
        }
    ` : `
        @media ${breakpoint.mobileL}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px);'}
        }
        @media ${breakpoint.laptop}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px);'}
        }
        @media ${breakpoint.laptopL}{
            ${'height: calc(50% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px);'}
        }
    `}
`;

interface WrapperProps {
    texture: string
}

const SceneWrapper = styled.div<WrapperProps>`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${game.c_road};
    background-image: url('${(props) => props.texture}');
`;

const GameWrapper = styled.div`
    width: ${size.mobile - (2 * game.paddingDefault)}px;
    height: ${size.mobile - (2 * game.paddingDefault)}px;
    position: relative;
    @media ${breakpoint.mobileL}{
        height: ${size.mobileL - (2 * game.paddingDefault)}px;
        width: ${size.mobileL - (2 * game.paddingDefault)}px;
    }
    @media ${breakpoint.laptop}{
        height: ${size.laptop - (2 * game.paddingDefault)}px;
        width: ${size.laptop - (2 * game.paddingDefault)}px;
    }
    @media ${breakpoint.laptopL}{
        height: ${size.laptopL - (2 * game.paddingDefault)}px;
        width: ${size.laptopL - (2 * game.paddingDefault)}px;
    }
`;

export default Scene;
