import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { VolumeContext } from '../../misc/volume-context';
import sfx from '../../../services/sounds';
import { shuffle } from '../../../helpers/shuffle';
import { t } from 'ttag';

const LetterInput: React.FC<{
    target: string;
    additionalLetters: Array<string>;
    onQuestionComplete(): void;
    onHintRequest(): void;
    hintLevel: number;
}> = ({ target, additionalLetters, onQuestionComplete, onHintRequest, hintLevel }) => {
    const [jumbledLetters, setJumbledLetters] = useState<Array<any>>([]);
    const [answerLetters, setAnswerLetters] = useState<Array<any>>([]);
    const [questionComplete, setQuestionComplete] = useState(false);
    const { volume } = useContext(VolumeContext);

    useEffect(() => {
        setAnswerLetters(Array.from(Array(target.length)).map(() => '*'));
        setJumbledLetters(shuffle(target.split('').concat(additionalLetters)));
        setQuestionComplete(false);
    }, [target, additionalLetters]);

    useEffect(() => {
        if (!questionComplete && answerLetters.join('').toUpperCase() === target.toUpperCase()) {
            setQuestionComplete(true);
            onQuestionComplete();
        }
    }, [answerLetters, onQuestionComplete, target]);

    const handleAddLetterClick = (index: number) => {
        const target = jumbledLetters[index];

        const answerArray = [...answerLetters];

        // find first star, replace at index
        const freeIndex = answerArray.findIndex((el) => el === '*');

        if (freeIndex === -1) {
            return false;
        }

        answerArray[freeIndex] = target;
        setAnswerLetters(answerArray);

        jumbledLetters.splice(index, 1);
        setJumbledLetters([...jumbledLetters]);
        if (volume) sfx.pop.play();
    };

    const handleRemoveLetterClick = (index: number) => {
        const target = answerLetters[index];

        if (target === '*') return;

        setJumbledLetters([...jumbledLetters, target]);

        answerLetters[index] = '*';
        setAnswerLetters([...answerLetters]);

        if (volume) sfx.pop.play();
    };

    const renderAnswerLetters = () => {
        let letterList: Array<any>;

        letterList = answerLetters.map((letter, index) => (
            <Button
                key={index}
                onClick={() => handleRemoveLetterClick(index)}
                className={`${letter === '*' ? 'text-white' : null} slot--answer`}
            >
                {letter.toUpperCase()}
            </Button>
        ));

        const classes = classNames({
            'text-success': questionComplete,
        });

        return <ul className={classes}>{letterList}</ul>;
    };

    const renderJumbledLetters = () => {
        return jumbledLetters.map((letter, index) => (
            <Button key={index} onClick={() => handleAddLetterClick(index)} className="m-1 text-lowercase">
                {letter.toUpperCase()}
            </Button>
        ));
    };

    // if (questionComplete) {
    //     return <p className={"text-success"}>Correct! You got this question correct!</p>;
    // }

    return (
        <Container>
            <Row>
                <Col className="ab-slots__wrapper text-center">{renderAnswerLetters()}</Col>
            </Row>
            <Row>
                <Col className="text-center">
                    {renderJumbledLetters()}
                    {hintLevel < 4 ? (
                        <Button onClick={onHintRequest} className={'btn-3d-help ml-1'}>
                            <i className={'fas fa-question mr-2'}></i>
                            {t`Hint`}
                        </Button>
                    ) : undefined}
                </Col>
            </Row>
        </Container>
    );
};

export default LetterInput;
