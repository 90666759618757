import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Button, Fade } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../core/Slide';
import car_left from '../assets/car/left.png';
import car_right from '../assets/car/right.png';
import cones from '../assets/misc/traffic-cones.svg';
import tree from '../assets/misc/tree.png';
import start_poster from '../assets/start/start_poster.jpg';
import start_poster_welsh from '../assets/start/start_poster-welsh.jpg';
import CarRight from '../components/cars/CarRight';
import Smoke from '../components/interactive/scene/Smoke';
import TrafficLight from '../components/traffic-lights/TrafficLight';
import { breakpoint, game, size } from '../styles/junction';
import { theme } from '../styles/theme';
import { TrafficData } from './..';
import Cookies from 'js-cookie';



interface SceneProps {
    nextSlide(): void;
    data?: TrafficData;
    roadworks?: boolean;
    crash?: boolean;
    help?: string;
}
const StartSlide: React.FC<ISlide & SceneProps> = ({ nextSlide, data, roadworks, crash, help, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );
    const [show, setShow] = useState(false);

    let lang = Cookies.get('bfg_lang');


    useEffect(() => {
        const timer = setTimeout(() => setShow(true), 4500);
        return () => { clearTimeout(timer) }
    });

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <SceneWrapper>
                {/* <Grass position={"top_left"}/> */}
                {/* <Grass position={"top_right"}/> */}
                {/* <Grass position={"bottom_right"}/> */}
                {/* <Grass position={"bottom_left"}/> */}
                <Grass position={"top"} />
                <Grass position={"bottom"} />

                <GameWrapper>
                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3 }} src={tree} style={{ width: '10%', left: '-20%', top: '-20%' }} />
                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3 }} src={tree} style={{ width: '6%', right: '-10%', top: '15%' }} />
                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3 }} src={tree} style={{ width: '8%', right: '-30%', bottom: '-15%' }} />
                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3 }} src={tree} style={{ width: '4%', left: '5%', bottom: '15%' }} />

                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3, delay: 1 }} src={tree} style={{ width: '14%', right: '-30%', top: '-20%' }} />
                    <Tree initial={{ x: 1500, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, 0, -1500] }} transition={{ loop: Infinity, duration: 3, delay: 1.5 }} src={tree} style={{ width: '14%', right: '-30%', bottom: '-15%' }} />

                    {/* <TrafficWrapper position={"top_left"} onClick={nextSlide}><TrafficLight alert={alert} red={{enabled: true}}/></TrafficWrapper> */}
                    {/* <TrafficWrapper position={"top_right"}><TrafficLight color="off"/></TrafficWrapper> */}
                    {/* <Bridge position={"top"} src={bridge_2}/> */}
                    {roadworks ? <><Cones position={"left"} src={cones} /><Cones position={"right"} src={cones} /></> : null}
                    {crash ? <><CarCrash1 src={car_right} /><CarCrash2 src={car_left} /><Smoke /></> : null}
                    {/* <Bridge position={"bottom"} src={bridge}/> */}

                    <TrafficWrapper initial={{ x: 1000, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, -0, -1500] }} transition={{ loop: Infinity, duration: 3, repeatDelay: 6 }} position={"top_left"}><TrafficLight green={{ enabled: true }} /></TrafficWrapper>

                    <CarRight />

                    <TrafficWrapper initial={{ x: 1000, opacity: 0 }} animate={{ opacity: [0, 1, 0], x: [1500, -0, -1500] }} transition={{ loop: Infinity, duration: 3, repeatDelay: 6 }} position={"bottom_right"}><TrafficLight green={{ enabled: true }} /></TrafficWrapper>
                    {/* <TrafficWrapper position={"bottom_left"}><TrafficLight alert={true} color="off"/></TrafficWrapper> */}

                </GameWrapper>

                <StartPoster animate={{ rotate: [-90, 0, 0, 0], opacity: [0, 1, 1, 1, 1], scaleX: [0.5, 0.5, 1, 1], scaleY: [0.5, 0.5, 0.5, 1], backgroundColor: ['#6400aa', '#6400aa', '#6400aa', theme.bare_green] }} transition={{ duration: 2.5 }}>
                    <motion.img animate={{ x: ['-100%', '0%'] }} transition={{ delay: 3.5, duration: 1 }} src={lang === 'cy' ? start_poster_welsh : start_poster} width="300" height="124" />
                </StartPoster>
                <Fade in={show}>
                    <StartButton onClick={nextSlide}>{t`Start`}</StartButton>
                </Fade>
            </SceneWrapper>
        </Slide>
    );
};

const StartButton = styled(Button)`
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    opacity:
`;

const StartPoster = styled(motion.div)`
    position: absolute;
    z-index: 10;
    width: 300px;
    left: calc(50% - 150px);
    box-shadow: 0 25px 50px rgba(0,0,0,0.25);
    bottom: 90px;
    overflow: hidden;
    background-color: #6400aa;
    border: 2px white solid;
`;


// Cones

interface ConesProps {
    position: string
}

const Cones = styled.img<ConesProps>`
    width: 7%;
    bottom: 40%;
    position: absolute;
    ${p => p.position === "left" ? 'left: 4%;' : ''};
    ${p => p.position === "right" ? 'right: 4%;' : ''};
`;

// Traffic Light Placements

interface TrafficProps {
    position: string
}
const TrafficWrapper = styled(motion.div) <TrafficProps>`
    position: absolute;
    width: 16%;
    height: 56%;
    &:hover{
    cursor: pointer;
    opacity: 0.9;
    }
    ${p => p.position === "top_left" ? 'left: 12.5%; bottom: 75%;' : ''};
    ${p => p.position === "top_right" ? 'right: 25%; bottom: 77.5%;' : ''};
    ${p => p.position === "bottom_left" ? 'left: 25%; bottom: 12.5%;' : ''};
    ${p => p.position === "bottom_right" ? 'right: 12.5%; bottom: 5.5%;' : ''};
`;

// Bridge for Level 1 Junction (2 pieces)

interface BridgeProps {
    position: string
}
const Bridge = styled.img<BridgeProps>`
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    ${p => p.position === "top" ? `
        ${'bottom: calc(49.75% + ' + 150 / 2 + 'px)'};
        @media ${breakpoint.mobileL}{
            ${'width:' + (150 * 1.25 * game.scaleMobileL) + 'px'};
            ${'bottom: calc(49.75% + ' + (150 * game.scaleMobileL) / 2 + 'px)'};
        }
        @media ${breakpoint.laptop}{
            ${'width:' + (150 * 1.25 * game.scaleLaptop) + 'px'};
            ${'bottom: calc(49.75% + ' + (150 * game.scaleLaptop) / 2 + 'px)'};
        }
        @media ${breakpoint.laptopL}{
            ${'width:' + (150 * 1.25 * game.scaleLaptopL) + 'px'};
            ${'bottom: calc(49.75% + ' + (150 * game.scaleLaptopL) / 2 + 'px)'};
        }`
        : `
        ${'bottom: calc(46% - ' + 150 / 2 + 'px)'};
        @media ${breakpoint.mobileL}{
            ${'width:' + (150 * 1.25 * game.scaleMobileL) + 'px'};
            ${'bottom: calc(46% - ' + (150 * game.scaleMobileL) / 2 + 'px)'};
        }
        @media ${breakpoint.laptop}{
            ${'width:' + (150 * 1.25 * game.scaleLaptop) + 'px'};
            ${'bottom: calc(46% - ' + (150 * game.scaleLaptop) / 2 + 'px)'};
        }
        @media ${breakpoint.laptopL}{
            ${'width:' + (150 * 1.25 * game.scaleLaptopL) + 'px'};
            ${'bottom: calc(46% - ' + (150 * game.scaleLaptopL) / 2 + 'px)'};
        }`
    };
`;

// River for Level 1 Junction (2 pieces)

interface RiverProps {
    position: string
}
const River = styled.div<RiverProps>`
    position: absolute;
    width: ${150}px;
    background-color: ${theme.bare_blue};
    ${p => p.position === "top" ? 'top: 0' : 'bottom: 0'};
    border-left: 2px solid ${game.c_road_2};
    border-right: 2px solid ${game.c_road_2};
    height: ${'calc(50% - ' + (150 / 2) + 'px)'};
    @media ${breakpoint.mobileL}{
    ${'width:' + (150 * game.scaleMobileL) + 'px'};
    ${'height: calc(50% - ' + (150 * game.scaleMobileL) / 2 + 'px)'};
    }
    @media ${breakpoint.laptop}{
    ${'width:' + (150 * game.scaleLaptop) + 'px'};
    ${'height: calc(50% - ' + (150 * game.scaleLaptop) / 2 + 'px)'};
    }
    @media ${breakpoint.laptopL}{
    ${'width:' + (150 * game.scaleLaptopL) + 'px'};
    ${'height: calc(50% - ' + (150 * game.scaleLaptopL) / 2 + 'px)'};
    }
`;

// Info sections above and below

const Alert = styled.p`
    background-color: #FFFFFF;
    color: ${theme.bare_pink};
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 15px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    font-family: ${theme.font_bt_extra};
    letter-spacing: 0.05em;
    left: 0;
    right: 0;
    max-width: 250px;
    width: 100%;
`;



const Tree = styled(motion.img)`
    position: absolute;
`;

// Placeholder Cars


const CarCrash1 = styled.img`
    width: 17.5%;
    bottom: 42.5%;
    left: 34%;
    transform: rotate(3deg);
    position: absolute;
`;
const CarCrash2 = styled.img`
    width: 17.5%;
    top: 42.5%;
    right:34%;
    transform: rotate(3deg);
    position: absolute;
`;


// Grass shapes for different layouts

interface GrassProps {
    position: string
}

const Grass = styled.div<GrassProps>`
    position: fixed;
    border: solid 0px ${game.c_road_2};
    background-color: ${theme.bare_green};

    ${p => p.position === "top" ? 'left: 0; top: 0; right: 0; height: calc(50% - ' + 150 / 2 + 'px); width: 100%; border-bottom-width: 4px;' : ''};
    ${p => p.position === "bottom" ? 'left: 0; bottom: 0; right: 0; height: calc(50% - ' + 150 / 2 + 'px); width: 100%;' : ''};

    ${p => p.position === "top_left" ? 'left: 0; top: 0; height: calc(50% - ' + 150 / 2 + 'px); width: calc(50% - ' + 150 / 2 + 'px); border-bottom-width: 5px; border-right-width: 4px; border-bottom-right-radius: 15px;' : ''};
    ${p => p.position === "top_right" ? 'right: 0; top: 0; height: calc(50% - ' + 150 / 2 + 'px); width: calc(50% - ' + 150 / 2 + 'px); border-bottom-width: 5px; border-left-width: 4px; border-bottom-left-radius: 15px;' : ''};
    ${p => p.position === "bottom_right" ? 'right: 0; bottom: 0; height: calc(50% - ' + 150 / 2 + 'px); width: calc(50% - ' + 150 / 2 + 'px); border-left-width: 3px; border-top-left-radius: 15px;' : ''};
    ${p => p.position === "bottom_left" ? 'left: 0; bottom: 0; height: calc(50% - ' + 150 / 2 + 'px); width: calc(50% - ' + 150 / 2 + 'px); border-right-width: 3px; border-top-right-radius: 15px;' : ''};

    ${p => p.position !== "top" && p.position !== "bottom" ? `
    @media ${breakpoint.mobileL}{
        ${'height: calc(50% - ' + (150 * game.scaleMobileL) / 2 + 'px);'}
        ${'width: calc(50% - ' + (150 * game.scaleMobileL) / 2 + 'px);'}
    }
    @media ${breakpoint.laptop}{
        ${'height: calc(50% - ' + (150 * game.scaleLaptop) / 2 + 'px);'}
        ${'width: calc(50% - ' + (150 * game.scaleLaptop) / 2 + 'px);'}
    }
    @media ${breakpoint.laptopL}{
        ${'height: calc(50% - ' + (150 * game.scaleLaptopL) / 2 + 'px);'}
        ${'width: calc(50% - ' + (150 * game.scaleLaptopL) / 2 + 'px);'}
    }
    ` : `
    @media ${breakpoint.mobileL}{
        ${'height: calc(50% - ' + (150 * game.scaleMobileL) / 2 + 'px);'}
    }
    @media ${breakpoint.laptop}{
        ${'height: calc(50% - ' + (150 * game.scaleLaptop) / 2 + 'px);'}
    }
    @media ${breakpoint.laptopL}{
        ${'height: calc(50% - ' + (150 * game.scaleLaptopL) / 2 + 'px);'}
    }
    `}
`;

const SceneWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${game.c_road};
    `;

const GameWrapper = styled.div`
    width: ${size.mobile - (2 * game.paddingDefault)}px;
    height: ${size.mobile - (2 * game.paddingDefault)}px;
    position: relative;
    @media ${breakpoint.mobileL}{
    height: ${size.mobileL - (2 * game.paddingDefault)}px;
    width: ${size.mobileL - (2 * game.paddingDefault)}px;
    }
    @media ${breakpoint.laptop}{
    height: ${size.laptop - (2 * game.paddingDefault)}px;
    width: ${size.laptop - (2 * game.paddingDefault)}px;
    }
    @media ${breakpoint.laptopL}{
    height: ${size.laptopL - (2 * game.paddingDefault)}px;
    width: ${size.laptopL - (2 * game.paddingDefault)}px;
    }
`;

export default StartSlide;
