import Definition from "./Definition";
import LevelTwo from "./LevelTwo";
import TouristGame from "./TouristGame";
import TouristProblem from "./TouristProblem";

export default [
    LevelTwo,
    TouristProblem,
    TouristGame,
    Definition
];
