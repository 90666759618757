import React from 'react';
import styled from 'styled-components';

export type TCharacter = {
  characterName: string;
  characterImage: string;
  characterWidth?: number;
  characterHeight?: number;
};

interface ICharacter {
  character: TCharacter;
}

interface CharacterProps {
  characterWidth?: number;
  characterHeight?: number;
}

const Character2: React.FC<ICharacter> = ({ children, character }) => {
  return (
    <>
      <Character src={character.characterImage} />
      {children}
    </>


  );
};



const Character = styled.img`
    position: absolute;
    z-index: -1;
    top: -70px;
    left: 15px;
    width: 50px;
`;


export default Character2;
