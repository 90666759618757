import React from 'react';
import { t } from 'ttag';
import boy from '../../../../../assets/games/athletes/athlete_boy.svg';
import girl from '../../../../../assets/games/athletes/athlete_girl.svg';
import girl_2 from '../../../../../assets/games/athletes/athlete_girl_2.svg';
import jamaica from '../../../../../assets/games/flags/flags/jamaica.svg';
import japan from '../../../../../assets/games/flags/flags/japan.svg';
import uk from '../../../../../assets/games/flags/flags/uk.svg';
import bottom_gb from '../../../../../assets/games/kits/bottom_gb.svg';
import bottom_jamaica from '../../../../../assets/games/kits/bottom_jamaica.svg';
import bottom_japan from '../../../../../assets/games/kits/bottom_japan.svg';
import bottom_spain from '../../../../../assets/games/kits/bottom_spain.svg';
import equip_bike from '../../../../../assets/games/kits/equip_bike.svg';
import equip_kayak from '../../../../../assets/games/kits/equip_kayak.svg';
import head_helmet from '../../../../../assets/games/kits/head_helmet.svg';
import head_kayak from '../../../../../assets/games/kits/head_kayak.svg';
import top_gb from '../../../../../assets/games/kits/top_gb.svg';
import top_jamaica from '../../../../../assets/games/kits/top_jamaica.svg';
import top_japan from '../../../../../assets/games/kits/top_japan.svg';
import top_spain from '../../../../../assets/games/kits/top_spain.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import KitMatch, { TKitMatchQuestion } from '../../../../interactive/kitmatch/KitMatch';

const DebugGame2: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const gameData: TKitMatchQuestion[] = [
        {
            id: 'JAM',
            name: t`Jamaica`,
            image: jamaica,
            athlete: boy,
            sport: t`Running`,
            items: [
                { tid: 'JAP', type: 'head', image: head_helmet },
                { tid: 'SPA', type: 'top', image: top_spain },
                { tid: 'JAP', type: 'top', image: top_japan },
                { tid: 'JAM', type: 'head', image: '' },
                { tid: 'GBR', type: 'bottom', image: bottom_gb },
                { tid: 'GBR', type: 'top', image: top_gb },
                { tid: 'GBR', type: 'equipment', image: equip_bike },
                { tid: 'JAP', type: 'bottom', image: bottom_japan },
                { tid: 'SPA', type: 'bottom', image: bottom_spain },
                { tid: 'SPA', type: 'head', image: head_kayak },
                { tid: 'SPA', type: 'equipment', image: equip_kayak },
                { tid: 'JAM', type: 'top', image: top_jamaica },
                { tid: 'JAM', type: 'bottom', image: bottom_jamaica },
                { tid: 'JAM', type: 'equipment', image: '' },
            ],
        },
        {
            id: 'JPN',
            name: t`Japan`,
            image: japan,
            athlete: girl_2,
            sport: t`Cycling`,
            items: [
                { tid: 'GBR', type: 'top', image: top_gb },
                { tid: 'JPN', type: 'top', image: top_japan },
                { tid: 'JPN', type: 'bottom', image: bottom_japan },
                { tid: 'GBR', type: 'equipment', image: equip_kayak },
                { tid: 'JPN', type: 'head', image: head_helmet },
                { tid: 'SPA', type: 'top', image: top_spain },
                { tid: 'SPA', type: 'bottom', image: bottom_spain },
                { tid: 'SPA', type: 'equipment', image: '' },
                { tid: 'JPN', type: 'equipment', image: equip_bike },
                { tid: 'JAM', type: 'head', image: '' },
                { tid: 'GBR', type: 'bottom', image: bottom_gb },
                { tid: 'JAM', type: 'top', image: top_jamaica },
                { tid: 'JAM', type: 'bottom', image: bottom_jamaica },
            ],
        },
        {
            id: 'GBR',
            name: t`Great Britain`,
            image: uk,
            athlete: girl,
            sport: t`Kayaking`,
            items: [
                { tid: 'JPN', type: 'top', image: top_japan },
                { tid: 'JPN', type: 'bottom', image: bottom_japan },
                { tid: 'JPN', type: 'head', image: head_helmet },
                { tid: 'SPA', type: 'top', image: top_spain },
                { tid: 'SPA', type: 'bottom', image: bottom_spain },
                { tid: 'SPA', type: 'equipment', image: '' },
                { tid: 'JPN', type: 'equipment', image: equip_bike },
                { tid: 'JAM', type: 'bottom', image: bottom_jamaica },
                { tid: 'JAM', type: 'top', image: top_jamaica },
                { tid: 'JAM', type: 'head', image: '' },
                { tid: 'GBR', type: 'bottom', image: bottom_gb },
                { tid: 'GBR', type: 'top', image: top_gb },
                { tid: 'GBR', type: 'head', image: head_kayak },
                { tid: 'GBR', type: 'equipment', image: equip_kayak },
            ],
        },
    ];

    const header = (
        <h3>
            {t`Task 5:`} <span className="font-bt-400">{t`Fix the mistakes in the athletes' kits`}</span>
        </h3>
    );

    return (
        <Slide header={header}>
            <KitMatch gameData={gameData} onGameComplete={nextSlide}></KitMatch>
        </Slide>
    );
};

export default DebugGame2;
