import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import mission_one from './units/2-mission-one';
import mission_two from './units/3-mission-two';
import mission_three from './units/4-mission-three';
import mission_four from './units/5-mission-four';
import mission_five from './units/6-mission-five';
import goodbye from './units/7-goodbye';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type VariablesData = {
    name: string;
    setName: (name: string) => void;
    gameScore: number;
    setGameScore: (score: number) => void;
    gameScoreObject: number;
    setGameScoreObject: (scoreObj: object) => void;
};

const Variables: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const [gameScore, setGameScore] = useState(0);
    const [gameScoreObject, setGameScoreObject] = useState({});
    const { volume } = useContext(VolumeContext);

    const slides = [
        StartSlide,
        ...intro,
        ...mission_one,
        ...mission_two,
        ...mission_three,
        ...mission_four,
        ...mission_five,
        ...goodbye,
        EndSlide,
    ];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>
        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeSpace.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeSpace.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Asteroid Blasters | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="f52c472a-bae6-43b8-8285-5824eb67d66a"
                name="variables"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName, gameScore, setGameScore, gameScoreObject, setGameScoreObject }}
            />
        </React.Fragment>
    );
};

export default Variables;
