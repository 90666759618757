
export const game = {
  footerHeight: 80,
  headerHeight: 80,
  paddingDefault: 15,
  roadWidth: 80,
  c_road: "#586984",
  c_road_2: '#0FA78F',
  scaleMobileL: 1.3,
  scaleLaptop: 1.8,
  scaleLaptopL: 2.4,
};


export const size = {
  mobile: 320,
  mobileL: 414,
  laptop: 576,
  laptopL: 768,
};


export const breakpoint = {
  mobileL: `(min-width: ${size.mobileL}px) and (min-height: ${size.mobileL + game.footerHeight + game.headerHeight}px)`,
  laptop: `(min-width: ${size.laptop}px) and (min-height: ${size.laptop + game.footerHeight + game.headerHeight}px)`,
  laptopL: `(min-width: ${size.laptopL}px) and (min-height: ${size.laptopL + game.footerHeight + game.headerHeight}px)`,
};
