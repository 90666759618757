import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import WordDiscovery from '../../../../general/WordDiscovery';
import { t } from 'ttag';

const CipherIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`A cipher is something that turns information in to a code to conceal its meaning.`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Cipher`}
                wordDefinition={t`Something that turns information in to a code to conceal its meaning.`}
            ></WordDiscovery>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default CipherIntro;
