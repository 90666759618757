import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
import light from '../../../assets/lights/traffic_placeholder.png';
import alert_img from '../../../assets/lights/light_alert.svg';

import {motion} from 'framer-motion';


const CarRight: React.FC = () => {
    return (
        <>
          <Wrapper initial={{x: -1000}} animate={{x: [-1000, 0], opacity:[1,1], scale: [0.8, 1]}} transition={{delay: 1, duration: 3.5}}>
            <StyledSVG
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 370 370"
            >
              <defs>
                <clipPath id="a7d52622-59fc-476d-b24c-2ce50121c775">
                  <path
                    fill="#00d891"
                    d="M301.2 99.57c-7.93-3.73-29.84-21-46.08-29.69s-46.44-19.52-73.63-19.52c-24.34 0-100.26-.28-115.9-.34a3.57 3.57 0 00-3.49 2.74L51.3 98.11l-3.14 4.35a7.69 7.69 0 00-1.4 3.69l-4.45 44v85.62c0 4.2-.16 8.41 0 12.6q.06 1.86.27 3.69c.27 2.37 1.69 7.94 1.8 8.41s1 3.57 2 3.91a4.11 4.11 0 001.31.13l7.64-.13a21.89 21.89 0 011.42-4.67c.32-.75.74-1.45 1.09-2.18.2-.44.3-.93.46-1.38a25.49 25.49 0 011.17-2.75 47.43 47.43 0 013-5.14 37 37 0 0131.75-16.95c40.78 0 41.07 49 41.07 49l102.23-1.69s-2.94-47.68 38.41-48c12.14-.1 25 1.69 33.24 10.16a31.13 31.13 0 013 3.61c1.82 2.53 3.76 5.18 4.58 8.23a7.11 7.11 0 01.3 2.85 9.73 9.73 0 000 1.43 15.15 15.15 0 00.39 2.86 31.86 31.86 0 003.71.06l1.95-.15 3.92-.31a17 17 0 005-.89 8.7 8.7 0 004.75-4.71c.23-.55.36-1.13.54-1.7a10.33 10.33 0 00.42-1.22 9.12 9.12 0 00.09-1.81c.07-2.95.21-5.9.34-8.84q.25-5.46.53-10.92c.13-2.51.27-5 .38-7.51v-.43q.3-7.53.69-15.07l.45-8.15c.13-2.16.74-24.69.55-29.35q-.48-11.53-1.47-23.05a45.16 45.16 0 00-1.93-11c-1.94-5.6-5.9-10.25-9.77-14.76a79.89 79.89 0 00-26.39-20.38z"
                  ></path>
                </clipPath>
              </defs>
              <g data-name="Car Wrapper">
                <ellipse
                  cx="184.26"
                  cy="249.5"
                  fillOpacity="0.05"
                  rx="184.2"
                  ry="82.5"
                ></ellipse>
                <motion.g animate={{y:[0, -5,0]}} transition={{loop: Infinity, duration: 0.2, repeatDelay: 2}} data-name="Right Wheel">
                <path
                  fill="#4b4d4d"
                  d="M316.36 265.17c.4 27.41-11.33 39.05-38.33 39.05s-39.84-17.39-39.84-38.11 10.58-40.73 39.46-40.73 38.71 19.71 38.71 39.79z"
                ></path>
                <ellipse
                  cx="278.41"
                  cy="273.57"
                  fill="#9b9b9b"
                  rx="22.75"
                  ry="17.36"
                ></ellipse>
                <ellipse
                  cx="278.22"
                  cy="269.54"
                  fill="#fff"
                  rx="12.11"
                  ry="9.35"
                ></ellipse>
                </motion.g>
                <motion.g animate={{y:[0, -5,0]}} transition={{loop: Infinity, duration: 0.2, repeatDelay: 2, delay: 0.2}} data-name="Right Wheel">

                <path
                  fill="#4b4d4d"
                  d="M133.28 269.73c.4 27.4-11.33 39-38.32 39s-39.84-17.38-39.84-38.1 10.57-40.74 39.46-40.74 38.7 19.71 38.7 39.8z"
                ></path>
                <ellipse
                  cx="95.33"
                  cy="278.13"
                  fill="#9b9b9b"
                  rx="22.75"
                  ry="17.36"
                ></ellipse>
                <ellipse
                  cx="95.14"
                  cy="274.09"
                  fill="#fff"
                  rx="12.11"
                  ry="9.35"
                ></ellipse>
                </motion.g>
                <motion.g animate={{y:[0, 5,0]}} transition={{loop: Infinity, duration: 1, delay: 0.2}} data-name="Rest of Car">
                <rect
                  width="19.26"
                  height="134.59"
                  x="35.83"
                  y="147.41"
                  fill="#656565"
                  rx="4.85"
                  transform="rotate(-180 45.46 214.7)"
                ></rect>
                <path
                  fill="#F41C92"
                  d="M301.2 99.57c-7.93-3.73-29.84-21-46.08-29.69s-46.44-19.52-73.63-19.52c-24.34 0-100.26-.28-115.9-.34a3.57 3.57 0 00-3.49 2.74L51.3 98.11l-3.14 4.35a7.69 7.69 0 00-1.4 3.69l-4.45 44v85.62c0 4.2-.16 8.41 0 12.6q.06 1.86.27 3.69c.27 2.37 1.69 7.94 1.8 8.41s1 3.57 2 3.91a4.11 4.11 0 001.31.13l7.64-.13a21.89 21.89 0 011.42-4.67c.32-.75.74-1.45 1.09-2.18.2-.44.3-.93.46-1.38a25.49 25.49 0 011.17-2.75 47.43 47.43 0 013-5.14 37 37 0 0131.75-16.95c40.78 0 41.07 49 41.07 49l102.23-1.69s-2.94-47.68 38.41-48c12.14-.1 25 1.69 33.24 10.16a31.13 31.13 0 013 3.61c1.82 2.53 3.76 5.18 4.58 8.23a7.11 7.11 0 01.3 2.85 9.73 9.73 0 000 1.43 15.15 15.15 0 00.39 2.86 31.86 31.86 0 003.71.06l1.95-.15 3.92-.31a17 17 0 005-.89 8.7 8.7 0 004.75-4.71c.23-.55.36-1.13.54-1.7a10.33 10.33 0 00.42-1.22 9.12 9.12 0 00.09-1.81c.07-2.95.21-5.9.34-8.84q.25-5.46.53-10.92c.13-2.51.27-5 .38-7.51v-.43q.3-7.53.69-15.07l.45-8.15c.13-2.16.74-24.69.55-29.35q-.48-11.53-1.47-23.05a45.16 45.16 0 00-1.93-11c-1.94-5.6-5.9-10.25-9.77-14.76a79.89 79.89 0 00-26.39-20.38z"
                ></path>
                <g clipPath="url(#a7d52622-59fc-476d-b24c-2ce50121c775)">
                  <path
                    fill="#E5007D"
                    d="M328 170.37c0 7.67-.06 15.36-.74 23a16.86 16.86 0 01-1.38 6.13c-2 4-6.58 5.88-10.9 7.05-9.32 2.52-19.06 3.1-28.71 3.5-29.25 1.2-58.55.88-87.82.55l-48.85-.55c-22.62 0-45.32-1.05-68-1.49-6.93-.14-14.41-.46-19.84-4.74-5-3.92-7.14-10.4-8-16.65a267.42 267.42 0 01-2.38-43.82q.79-25.89 1.75-51.79a202.17 202.17 0 00-24-.52c3.22 18.47 3.22 37.33 3.21 56.07l.02 149.19q140.77-7.49 281.82-7.59c13.21 0 29.44-1.85 34.47-14 2-4.88 1.6-10.37 1-15.62-.53-5-1.7-9.57-.67-14.44.62-3 .4-6.06.4-9.08q0-10.47-.24-20.95-.48-20.94-1.89-41.86-1.77-26.13-5-52.13c-.48-3.83-1.07-7.89-3.54-10.87s-7.51-4.2-10.24-1.45c-1.05 1.06-.41 6.69-.39 8.17 0 2.73.62 5.55.42 8.26-.4 5.49-.36 11.09-.38 16.63z"
                  ></path>
                  <path
                    fill="#F41C92"
                    d="M100.92 132.34c.21 5.47-4.88 45.29-5.52 60-9.48-.11-25 2.18-31.86-5.12-5.73-8.6-5-19.84-5.91-29.83a79 79 0 013.3-37.6c.32-1 1 3.23 3.1 6.48 5.5 9.82 33.24 4.58 36.89 6.07z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M337.27 130.17a16.85 16.85 0 01.12 4.81 3 3 0 01-2.93-1.3 8.48 8.48 0 01-1.21-3.13l-1.76-7.24c1.46-.14 2.65 1.09 3.51 2.26a16.61 16.61 0 012.27 4.6z"
                  ></path>
                </g>
                <path
                  fill="#5cf9f9"
                  d="M61.15 68.62c-.12 23.78 1.64 47.57-.55 71.32q-3.41 19-6.83 38h-.47V99.17c2.11-9.64 4.19-19.28 6.38-28.9.14-.62.96-1.1 1.47-1.65zM293.69 96.79l4.19 4.86a42.59 42.59 0 01.79 5.74q.09 35.4 0 70.78a54.83 54.83 0 01-1 7.21c-2.65-1.82-5.63-3.31-7.9-5.51-17.76-17.14-38-30.76-60-41.83-7.26-3.65-7.28-3.59-5.39-10.32a19.55 19.55 0 00.79-3.78q.06-25.29 0-50.58c-.14-1.28-.3-6.83-.34-8.37-.17-1.2-1.11-2.3-2.27-4.5a48.47 48.47 0 015.36.81c20.68 6 39.23 16.13 56.33 28.91 3.15 2.3 5.58 5.33 9.44 6.58zM236.78 192.56V148.3c3.1 1.53 5.93 2.72 8.56 4.25a211 211 0 0146.22 36.11c1.32 1.36 3.29 2.1 5 3.13a32.82 32.82 0 01-4.73.76q-27.55.06-55.05.01zM224.2 192.52H92.85c.24-6.54.29-13.11.76-19.64 1-13.47 2.23-26.94 3.38-40.75a34.3 34.3 0 013.91-.4c18.43 0 36.87-.35 55.32.1 20.34.49 40.63 2 60.35 7.74 4.7 1.36 7.17 3 7 8.62-.12 14.81.43 29.56.63 44.33z"
                ></path>
                <path
                  fill="#fff"
                  d="M338 204.89a44.64 44.64 0 01-.33 7.31 44.16 44.16 0 01-.79 4.43 5 5 0 01-.91 2.26 14.49 14.49 0 01-7.14 5.22 11 11 0 01-4.17-.32 21.53 21.53 0 01-5.73-3.51 7.2 7.2 0 01-2.44-6c2-.73 4-1.46 6-2.37a8.68 8.68 0 002.39-1.4 11.42 11.42 0 012-1.38 16.2 16.2 0 005-5 15.17 15.17 0 001.55-3.19c.37-1-.1-3.15 1.9-2.17 1 .49 1.91.9 2.27 2a14.26 14.26 0 01.4 4.12z"
                ></path>
                <path
                  fill="#4b4d4d"
                  d="M291.05 78.8a6.32 6.32 0 012.66 3 7.4 7.4 0 01.3 2.1c.13 2.81.24 5.75-.94 8.31-.32.68-.84 1.39-1.6 1.43a2.12 2.12 0 01-1.08-.32 13.14 13.14 0 01-4.34-3.63 2.76 2.76 0 01-.46-.77 3 3 0 01-.11-.94l-.08-7.59a5.85 5.85 0 01.41-2.73 2.35 2.35 0 01.84-.93 1.42 1.42 0 011.08-.28c.3.09.53.44.76.64a13 13 0 001.19.87zM294.47 205.41l.24 7.25a9 9 0 01-.13 2.37 6.84 6.84 0 01-2.31 3.36 14.64 14.64 0 01-2.37 1.7l-.63.34c-.14.07-.29.13-.42.21a1.49 1.49 0 01-.14.12 3 3 0 01-.58.24 4.24 4.24 0 01-.61.17 2.2 2.2 0 01-1.32-.1 2 2 0 01-1-1 3.2 3.2 0 01-.19-.69 4.52 4.52 0 00-.08-.63 2.88 2.88 0 01-.11-.42 6.26 6.26 0 010-.75q.12-9.27 1.15-18.49c0 .1 4.91.1 5.55.17 1.49.15 2.15 1.39 2.53 2.72a15 15 0 01.42 3.43z"
                ></path>
                <path
                  fill="#656565"
                  d="M47.56 114.24c-.05 4.31-1.05 8.56-1.39 12.85a4.82 4.82 0 01-.29 1.6 1.56 1.56 0 01-1.2 1 1.83 1.83 0 01-1.43-.69 7.24 7.24 0 01-1.64-4.19 17.93 17.93 0 011.76-10c.35-.71.78-1.74 1.64-1.94a2.12 2.12 0 011.11.12 6.88 6.88 0 01.74.32c.23.12.55.25.65.51a1 1 0 01.05.42z"
                ></path>
                <path
                  fill="#9b9b9b"
                  d="M44.82 139.58l-.25 3.36a13.6 13.6 0 01-.49 3.19 6.77 6.77 0 01-1.08 2 1.48 1.48 0 01-1.17.72c-.5 0-.85-.5-1.13-.93l-.88-1.36a7.88 7.88 0 01-.91-1.69v-.08a7.09 7.09 0 01-.27-2.15 3.38 3.38 0 00-.1-.71 2 2 0 010-.78c.2-1.53.47-3.05.8-4.56.15-.74.33-1.48.52-2.22.09-.37.19-.74.29-1.1a4.48 4.48 0 01.11-.5 23.83 23.83 0 011.09-2.55 5.31 5.31 0 01.87-1.4 2 2 0 011.47-.66 1.8 1.8 0 011.27.76 2.71 2.71 0 01.43 1 .78.78 0 010 .4 4.43 4.43 0 00-.06 1c0 .87-.06 1.74-.11 2.61s-.1 1.75-.16 2.63l-.18 2.61z"
                ></path>
                <path
                  fill="#656565"
                  d="M47.13 185.33c-.05 4.32-1.05 8.56-1.38 12.86a4.77 4.77 0 01-.3 1.59 1.54 1.54 0 01-1.2 1 1.85 1.85 0 01-1.43-.7 7.14 7.14 0 01-1.63-4.19 17.45 17.45 0 01-.06-3.39 17.59 17.59 0 011.87-6.57c.35-.7.77-1.73 1.63-1.93a2 2 0 011.11.12 4.86 4.86 0 01.74.32c.24.11.56.24.66.5a1 1 0 01-.01.39z"
                ></path>
                <path
                  fill="#9b9b9b"
                  d="M44.39 210.68l-.24 3.32a13.76 13.76 0 01-.49 3.19 6.7 6.7 0 01-1.09 2 1.5 1.5 0 01-1.16.71c-.51 0-.86-.5-1.13-.93l-.88-1.36a8.31 8.31 0 01-.92-1.68.24.24 0 010-.09 6.76 6.76 0 01-.27-2.15 2.76 2.76 0 00-.11-.7 2.27 2.27 0 010-.78c.21-1.53.48-3.06.8-4.57.16-.74.33-1.48.52-2.22l.29-1.1c0-.17 0-.33.11-.49a24.06 24.06 0 011.1-2.56 5.47 5.47 0 01.87-1.4 1.92 1.92 0 011.47-.65 1.73 1.73 0 011.26.75 2.8 2.8 0 01.44 1 .81.81 0 010 .41 4.34 4.34 0 00-.07 1c0 .87-.06 1.74-.1 2.61s-.1 1.76-.16 2.63-.12 1.74-.19 2.61z"
                ></path>
                <path
                  fill="#4b4d4d"
                  d="M340.82 263.55a15 15 0 01-6.72 6.75c-3.83 1.77-8.26 1.41-12.46 1-1.08-.1-1.34-1.08-1.67-2s-.63-1.69-.94-2.54c-.91-2.44-1.43-5.06-2.13-7.58 0 0 2.94-.06 3.22-.05 1.14 0 2.26.17 3.39.25a3.63 3.63 0 001-.11c.52-.1 1.06-.08 1.59-.13 1.1-.1 2.19-.29 3.29-.43a5.29 5.29 0 002.55-.6c.88-.56 1.76-1.16 2.59-1.81a7.11 7.11 0 002.75-4.77c.6-3.61 2.82-69.26 2.53-95.64.91 2.34 1.8 4.66 2.86 6.92a7 7 0 01.34 3v84.77c0 3 .44 6.16-.49 8.94a26 26 0 01-1.7 4.03z"
                ></path>
                </motion.g>
              </g>
            </StyledSVG>

          </Wrapper>
        </>
    );
};

const AlertWrapper = styled.div`
  position:absolute;
  left: -50%;
  bottom: 0%;
  width: 150%;
  padding-top: 150%;
  font-size: 16px;
  img { width: 100%; position: absolute; top: 0; right: 0; left: 0; bottom: 0;}
`;
const Wrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 90%;
`;

const StyledSVG = styled.svg`
  width: 70%;
  display: block;
  margin: 0 auto;
`;

export default CarRight;
