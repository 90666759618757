import React, { useContext, useEffect, useState } from 'react';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';
import VoiceoverContext from '../../misc/voiceover-context';

export type TChatMessage = GameQuestion & {
    user: string;
    userImage?: string;
    message: string;
    messageSpriteName: string;
    actions: TChatAction[];
};

type TChatAction = {
    text: string;
    textSpriteName: string;
    action?: () => void;
    icon?: string;
};

interface IChat {
    title: string;
    nickname: string;
    avatar: string;
}

const FishFoodChat: React.FC<GameProps<TChatMessage> & IChat> = ({
    gameData,
    index = 0,
    onGameComplete,
    title,
    nickname,
    avatar,
}) => {
    const [show, setShow] = useState(false);
    const [initial, setInitial] = useState(false);
    const { playVoiceover } = useContext(VoiceoverContext);
    const [previousMessage, setPreviousMessage] = useState<string | null>(null);

    const newMessageText = useAnimation();
    const answers_anim = useAnimation();

    const onNextChatMessage = () => {
        // setPreviousMessage(chatMessage ?? null);
        // doAnimation();
    };

    const handleGameComplete = () => {
        onGameComplete();
    };

    const [chatMessage, nextChatMessage] = useGameLogic<TChatMessage>(
        gameData,
        handleGameComplete,
        onNextChatMessage,
        index,
    );

    const handleClick = () => {
        setShow(false);
        setTimeout(() => {
            nextChatMessage();
        }, 100);
        setTimeout(() => {
            setShow(true);
        }, 3000);
    }

    useEffect(() => {
        if (chatMessage) {
            if(!initial){
                setInitial(true);
                const answers = setTimeout(() => {setShow(true)}, 6000);
                return () => {clearTimeout(answers);};
            }
        }
    }, [chatMessage?.message]);
    useEffect(() => {
        if (chatMessage) {
            const timer = setTimeout(() => playVoiceover(chatMessage.messageSpriteName), 1000);
            return () => clearTimeout(timer);
        }
    }, [chatMessage?.message]);

    if (!chatMessage) {
        return null;
    }

    return (
        <>
            <StyledContainer>
                <PhoneHeader>
                    <PhoneHeaderTitle>{title}</PhoneHeaderTitle>
                </PhoneHeader>

                <PhoneContent>
                    {previousMessage ? (
                        <StyledRow>
                            <Col xs={{ span: 9, offset: 1 }}>
                                <Message

                                >
                                    <h6 className={'mb-1'}>
                                        <b>{nickname ? nickname : 'Player'}</b>
                                    </h6>
                                    <p>{previousMessage}</p>
                                </Message>
                            </Col>
                            <Col xs={'2'} className={'pl-0'}>
                                <UserCircle>
                                    <img src={avatar} alt="Avatar" width={50}></img>
                                </UserCircle>
                            </Col>
                        </StyledRow>
                    ) : null}
                    <StyledRow>
                        <Col xs={'2'} className={'pr-0'}>
                            <UserCircle>
                                {chatMessage.userImage ? (
                                    <img src={chatMessage.userImage} alt="Avatar" width={50}></img>
                                ) : (
                                    <p>?</p>
                                )}
                            </UserCircle>
                        </Col>
                        <Col xs={'9'}>
                            <Message>
                                <h6 className={'mb-1'}>
                                    <b>{chatMessage.user}</b>
                                </h6>
                                <p>{chatMessage.message}</p>
                            </Message>
                        </Col>
                    </StyledRow>

                    <PhoneContentAnswers show={show}>
                        {chatMessage.actions.map((action, index) => (
                            <MessageBtn
                                key={index + 'action'}
                                onClick={() => {
                                    setPreviousMessage(action.text);
                                    action.action ? action.action() : handleClick();
                                }}
                                onMouseEnter={() => playVoiceover(action.textSpriteName)}
                            >
                                {action.text}
                            </MessageBtn>
                        ))}
                    </PhoneContentAnswers>
                </PhoneContent>
            </StyledContainer>
        </>
    );
};

const StyledRow = styled(Row)`
    flex-shrink: 0;
`;

const StyledContainer = styled(Container)`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
`;

const Message = styled(motion.div)`
    background-color: #ffffff;
    border-radius: 10px;
    color: #6400aa;
    padding: 4%;
    margin-bottom: 1em;
    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
    }
`;

const MessageBtn = styled(Button)`
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 100%;
    margin-bottom: 15px;
    color: #6400aa;
`;
const UserCircle = styled.div`
    border-radius: 50%;
    width: 100%;
    padding-top: 100%;
    position: relative;
    max-height: 40px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    img {
        margin-bottom: 0;
        font-weight: 700;
        position: absolute;
        width: 150%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
    p {
        margin-bottom: 0;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
`;

const PhoneHeaderTitle = styled.p`
    align-self: flex-end;
    margin: 0;
`;
const App = styled.img`
    width: 100%;
    padding-left: 10%;
    display: block;
`;

const PhoneContent = styled.div`
    flex: 1;
    padding: 5% 5% 3% 5%;
    display: flex;
    flex-flow: column;
`;
interface BtnProps {
    show: boolean
}

const PhoneContentAnswers = styled(motion.div)<BtnProps>`
    margin-top: auto;
    align-self: flex-end;
    width: 100%;
    flex-shrink: 0;
    display: ${(props) => props.show ? 'block' : 'none'};
`;

const PhoneHeader = styled.div`
    width: 100%;
    background-color: #6400aa;
    padding: 10% 5% 3% 5%;
    flex-shrink: 0;
`;

export default FishFoodChat;
