import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toaster from 'toasted-notes';
import { t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import CorrectModal from './CorrectModal';
import KitSelector from './KitSelector';

export type TKitMatchQuestion = GameQuestion & {
    id: string;
    name: string;
    image: string;
    athlete: string;
    sport: string;
    items: TKitMatchItem[];
};

export type TKitMatchItem = {
    tid: string;
    type: TKitMatchItemType;
    image: string;
};

type KitAnswerObject = {
    head: boolean;
    top: boolean;
    bottom: boolean;
    equipment: boolean;
};

export type TKitMatchItemType = 'head' | 'top' | 'bottom' | 'equipment';

const KitMatch: React.FC<GameProps<TKitMatchQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setDisabled(false);
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TKitMatchQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const [answers, setAnswers] = useState<KitAnswerObject>({
        head: false,
        top: false,
        bottom: false,
        equipment: false,
    });
    const handleAnswer = (type: TKitMatchItemType, correct: boolean) => {
        answers[type] = correct;
        setAnswers((prevState: any) => {
            return { ...prevState, ...answers };
        });
    };

    const { volume } = useContext(VolumeContext);

    useEffect(() => {
        // if (answers.head && answers.top && answers.bottom && answers.equipment) {
        //     setAnswers({head: false, top: false, bottom:false, equipment: false})
        //     if (volume) sfx.success.play() ;
        //     setDisabled(true);
        //     setTimeout(() => {
        //         setShow(true);
        //         setDisabled(false);
        //     }, 2500)
        // }
    }, [answers]);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleAnswerSubmit = () => {
        if (answers.head && answers.top && answers.bottom && answers.equipment) {
            setAnswers({ head: false, top: false, bottom: false, equipment: false });
            if (volume) sfx.success.play();
            setShow(true);
        } else {
            if (volume) sfx.incorrect.play();
            toaster.notify(t`Oh no! That was incorrect. Try another combination!`, {
                duration: 2000,
                position: 'bottom',
            });
        }
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <p className={'ck-kit__howto'}>
                {t`Fix the athlete's kit using the arrows,`}
                <br />
                {t`then press`} <strong>{t`Submit`}</strong>
            </p>
            <div className="ck-kit__card">
                <div className="ck-kit__flag-col">
                    <img className={'ck-kit__flag'} src={question.image} alt={t`Country's Flag`}></img>
                    {/* TODO: Describe flags in alt */}
                </div>
                <div className="ck-kit__details-col">
                    <div className={'ck-kit__detail-col'}>
                        <p className={'ck-kit__label'}>{t`Country`}</p>
                        <p className={'ck-kit__detail'}>{question.name}</p>
                    </div>
                    <div className={'ck-kit__detail-col'}>
                        <p className={'ck-kit__label'}>{t`Sport`}</p>
                        <p className={'ck-kit__detail'}>{question.sport}</p>
                    </div>
                </div>
            </div>
            <div className={'ck-kit ' + (disabled ? ' ck-kit--complete' : '')}>
                <img
                    className={'ck-kit__athlete'}
                    src={question.athlete}
                    height={'300px'}
                    width={'200px'}
                    alt={t`Athlete`}
                />
                <div className="ck-kit__types-wrapper">
                    <KitSelector
                        targetType="head"
                        items={question.items}
                        targetId={question.id}
                        onAnswer={handleAnswer}
                        isDisabled={disabled}
                    ></KitSelector>
                    <KitSelector
                        targetType="top"
                        items={question.items}
                        targetId={question.id}
                        onAnswer={handleAnswer}
                        isDisabled={disabled}
                    ></KitSelector>
                    <KitSelector
                        targetType="bottom"
                        items={question.items}
                        targetId={question.id}
                        onAnswer={handleAnswer}
                        isDisabled={disabled}
                    ></KitSelector>
                    <KitSelector
                        targetType="equipment"
                        items={question.items}
                        targetId={question.id}
                        onAnswer={handleAnswer}
                        isDisabled={disabled}
                    ></KitSelector>
                </div>
            </div>
            <div className="text-center">
                <Button onClick={handleAnswerSubmit}>{t`Submit`}</Button>
            </div>
        </React.Fragment>
    );
};

export default KitMatch;

export const filterKitItems = (items: Array<TKitMatchItem>, type: TKitMatchItemType) => {
    return items.filter((item) => item.type === type);
};
