import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { FlubFlubIntro1, FlubFlubIntro2, FlubFlubIntro3 } from '../../characters/Aliens';

const VariablesIntro2: React.FC<ISlide> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: FlubFlubIntro1,
            text: t`Imagine this box is one of the variables. We’ll call it ‘Small Asteroids’ as it’ll keep track of how many small asteroids you blast. The computer will set it to 0 when you start your mission.`,
            buttonText: t`Next`,
        },
        {
            character: FlubFlubIntro2,
            text: t`You can change the data stored inside a variable. Here the ship’s computer will increase the variable by 1 every time you blast a small asteroid.`,
            buttonText: t`Next`,
        },
        {
            character: FlubFlubIntro3,
            text: t`You can also see the value stored in the variable at any time, so you can always see how many small asteroids you’ve blasted. I’ll create the same for large asteroids too. Perfect!`,
            buttonText: t`Got it!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default VariablesIntro2;
