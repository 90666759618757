import React from 'react';
import { t } from 'ttag';
import circle_green from '../../../../../assets/games/shapes/0-green.svg';
import circle_red from '../../../../../assets/games/shapes/0-red.svg';
import triangle_green from '../../../../../assets/games/shapes/3-green.svg';
import triangle_yellow from '../../../../../assets/games/shapes/3-yellow.svg';
import square_blue from '../../../../../assets/games/shapes/4-blue.svg';
import square_green from '../../../../../assets/games/shapes/4-green.svg';
import square_red from '../../../../../assets/games/shapes/4-red.svg';
import pent_blue from '../../../../../assets/games/shapes/5-blue.svg';
import pent_red from '../../../../../assets/games/shapes/5-red.svg';
import pent_yellow from '../../../../../assets/games/shapes/5-yellow.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import ShapeGroup, { ShapeGroupQuestion } from '../../../../interactive/shapegroup/ShapeGroup';

const AtrributesGame2: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const gameData: ShapeGroupQuestion[] = [
        {
            question: t`Select all the shapes with the colour yellow`,
            answer: t`You just grouped the shapes by one of their attributes: colour`,
            answers: [
                {
                    image: circle_green,
                    correct: false,
                },
                {
                    image: triangle_yellow,
                    correct: true,
                },
                {
                    image: triangle_green,
                    correct: false,
                },
                {
                    image: square_blue,
                    correct: false,
                },
                {
                    image: pent_yellow,
                    correct: true,
                },
                {
                    image: circle_red,
                    correct: false,
                },
            ],
        },
        {
            question: t`Select all the shapes with 5 sides`,
            answer: t`You just grouped the shapes by one of their attributes: number of sides`,
            answers: [
                {
                    image: square_red,
                    correct: false,
                },
                {
                    image: pent_blue,
                    correct: true,
                },
                {
                    image: pent_yellow,
                    correct: true,
                },
                {
                    image: triangle_green,
                    correct: false,
                },
                {
                    image: pent_red,
                    correct: true,
                },
                {
                    image: square_green,
                    correct: false,
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <ShapeGroup gameData={gameData} onGameComplete={nextSlide} />
        </Slide>
    );
};

export default AtrributesGame2;
