import React from 'react';
import Slide, { ISlide } from '../../../core/Slide';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import background from '../assets/background/background.jpg';
import bg_right from '../assets/start/start-right_1.svg';
import bg_left from '../assets/start/start-left_2.svg';
import bg_ship from '../assets/start/bg-ship.svg';
import wave_1 from '../assets/start/wave-1.svg';
import wave_2 from '../assets/start/wave-2.svg';
import phisherman from '../assets/characters/phisherman.svg';
import pufferfish from '../assets/characters/2-pufferfish-1.svg';
import { motion } from 'framer-motion';
import { t } from 'ttag';

const StartSlide: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            { t`Begin` }
        </Button>
    );

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Background></Background>
            <Pufferfish
                animate={{ rotate: [15, 15, 15], y: [0, -15, 0] }}
                transition={{ loop: Infinity, duration: 6, ease: 'easeInOut' }}
                width="75px"
                src={pufferfish}
            />
            <BGShip src={bg_ship} />
            <BGLeft src={bg_left} />
            <BGRight src={bg_right} />
            <Overlay />
            <StartCard>
                <Wave
                    style={{ zIndex: 1 }}
                    src={wave_2}
                    width="300"
                    aria-hidden={true}
                    animate={{ y: [8, -3, 8] }}
                    transition={{ duration: 8, loop: Infinity, ease: 'easeInOut' }}
                />
                <Wave
                    style={{ zIndex: 5 }}
                    src={wave_1}
                    width="300"
                    aria-hidden={true}
                    animate={{ y: [0, 5, 0] }}
                    transition={{ duration: 6, loop: Infinity, ease: 'easeInOut' }}
                />
                <Phisherman
                    src={phisherman}
                    width="300"
                    aria-hidden={true}
                    animate={{ y: [-3, 5, -3], x: ['-50%', '-50%', '-50%'], rotate: [5, 11, 5] }}
                    transition={{ duration: 6, loop: Infinity, ease: 'easeInOut' }}
                />
                <Content>
                    <h2>{ t`The Phisherman` }</h2>
                    <h4>{ t`An Underwater Adventure` }</h4>
                    <Button onClick={nextSlide}>{ t`Start` }</Button>
                </Content>
            </StartCard>
        </Slide>
    );
};

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

const Pufferfish = styled(motion.img)`
    position: fixed;
    left: 15%;
    top: 15%;
    width: 75px;
    @media (max-width: 767px) {
        width: 60px;
    }
`;
const BGRight = styled.img`
    position: fixed;
    right: 0;
    bottom: 0;
    width: 40%;
    max-width: 800px;
`;
const BGLeft = styled.img`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 40%;
    max-width: 800px;
`;

const BGShip = styled.img`
    position: fixed;
    right: 0;
    bottom: 5%;
    width: 60%;
    max-width: 1300px;
    transform: rotate(10deg);
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(${background}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.5;
    mix-blend-mode: multiply;
`;

const StartCard = styled.div`
    width: 100%;
    display: block;
    margin: 15px auto;
    max-width: 400px;
    text-align: center;
    background-color: rgba(255,255,255,0.1);
    position relative;
    padding: 15px;
    border-radius: 5px 5px 0 0 ;
    overflow: hidden;
    z-index: 999;
    @media (min-width: 1200px){
        max-width: 475px;
    }
`;

const Content = styled.div`
    padding-top: 50%;
    position: relative;
    z-index: 50;
    h4 {
        font-size: 1rem;
        opacity: 0.7;
        letter-spacing: 0.03em;
        font-family: 'BT Font Bold';
        text-transform: uppercase;
    }
    @media (max-width: 400px) {
        h2 {
            font-size: 7vw;
        }
        h4 {
            font-size: 5vw;
        }
    }
`;

const Wave = styled(motion.img)`
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
`;

const Phisherman = styled(motion.img)`
    position: absolute;
    left: 50%;
    width: 80%;
    bottom: 50%;
    z-index: 2;
    @media (max-width: 400px) {
        bottom: 45%;
    }
`;

export default StartSlide;
