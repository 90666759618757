import React from 'react';
import { Button } from 'react-bootstrap';
import { TMultiChoiceAnswer } from './MultiChoice';

const MultiChoiceAnswer: React.FC<{ answer: TMultiChoiceAnswer; onAnswer: (correct: boolean) => void }> = ({
    answer,
    onAnswer,
}) => {
    let btnClass = answer.correct && answer.completed ? 'btn-3d-true' : 'btn-3d-dark';
    return (
        <div className={'btn--answer__wrapper'}>
            <Button
                variant="primary"
                className={'btn-3d btn-block btn--answer ' + btnClass}
                onClick={() => onAnswer(answer.correct)}
            >
                {answer.text}
            </Button>
        </div>
    );
};

export default MultiChoiceAnswer;
