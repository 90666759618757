import ranger from '../../../../assets/characters/ranger-standing.svg';
import ranger_search from '../../../../assets/characters/ranger-searching.svg';
import { TCharacter } from '../../../general/Character';
import { t } from 'ttag';

const RangerJack: TCharacter = {
    characterName: t`Ranger Jack`,
    characterImage: ranger,
};
export const RangerJackSearch: TCharacter = {
    characterName: t`Ranger Jack`,
    characterImage: ranger_search,
};

export default RangerJack;
