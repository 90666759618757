import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import alert_warning from '../../assets/lights/alert-warning.svg';
import cloud from '../../assets/scene/cloud.svg';
import town_scene from '../../assets/scene/town_scene.svg';
import town_scene_welsh from '../../assets/scene/town_scene-welsh.svg';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Cookies from 'js-cookie';

const lang = Cookies.get('bfg_lang');

const TownIntro: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {

    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [nameShow, setNameShow] = useState(false);
    const [show, setShow] = useState(true);
    const [alert, setAlert] = useState(false);
    const [town, setTown] = useState(true);

    const [nextDialogue, setNextDialogue] = useState<any>(null);


    const next = data.name ? (
        <Button className="btn-3d" onClick={() => {
            if (typeof nextDialogue === 'function') {
                nextDialogue();
                nextDialogue();
                setAlert(true);
                setNameShow(false);
            }
        }}>
            {t`Next`}
        </Button>
    ) : null;

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`Welcome, it's lovely to have you here! I'm Shahid, the head of traffic control.`,
            textSpriteName: `1`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Our towns are expanding and we are going to need your help to get the roads up to scratch.`,
            textSpriteName: `2`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Before we get you onboard, I’m going to need your nickname!`,
            textSpriteName: `3`,
            buttonText: t`Next`,
            buttonAction: () => {
                data.playVoiceover(`4`)
                setNameShow(true);
            }
        },
        {
            character: EngineerShahid,
            text: t`Perfect, ${data.name}. We’ve got some work to do! Now for your first task. Please select the bridge to have a closer look.`,
            textSpriteName: `5`,
            buttonText: t`Sure!`,
            buttonAction: () => setShow(false)
        },
    ];


    const handleAlertClick = () => {
        nextSlide()
    }

    return (
        <Slide header={defaultHeader} footerHidden={true}>

            <TownWrapper>
                {alert ?
                    <Alert src={alert_warning} animate={{ scale: [0, 1], opacity: [0, 1] }} transition={{ type: 'spring' }} onClick={handleAlertClick} />
                    : null}

                <Clouds>
                    <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 0 }} style={{ top: '30%' }} />
                    <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 3 }} style={{ top: '75%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 30, delay: 30 }} style={{ top: '50%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 50 }} style={{ top: '55%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 40 }} style={{ top: '30%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 60, delay: 0 }} style={{ top: '25%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 50, delay: 15 }} style={{ top: '70%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 45, delay: 0 }} style={{ top: '40%' }} />
                </Clouds>

            </TownWrapper>


            {show ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} /> : null}
            {nameShow ? <Layout>
                <Content>
                    <Character src={EngineerShahid.characterImage} />
                    <motion.div
                        className="island island--dialogue rounded mb-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <p className="mb-0">{t`What is your nickname?`}</p>
                    </motion.div>
                    <input
                        className="form-control mb-2"
                        type="text"
                        placeholder={t`Nickname`}
                        onChange={(e) => data.setName(e.target.value)}
                    />
                    <ButtonWrapper>{next}</ButtonWrapper>
                </Content>
            </Layout> : null}
        </Slide>
    );
};

// Clouds

const Clouds = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    position: fixed;
    z-index: 999;
    img {
        width: 75px;
        position: absolute;
        left: -75px;
        user-select: none;
        pointer-events: none;
        z-index: 998;
        opacity: 0.5;
        &.cloud-half-hack {
            left: 40%;
        }
    }
`;


const Alert = styled(motion.img)`
    cursor: pointer;
    height: 100px;
    width: 100px;
    &:hover{
        opacity: 0.8;
    }
`;


const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
`;


const ButtonWrapper = styled.div`
    min-height: 60px;
    .btn-3d {
        background-color: #6400aa;
        color: #ffffff !important;
        &:hover, &:active, &:focus {
            background-color: #540e85 !important;
            color: #DDDDDD !important;
        }
    }
`;
const Character = styled.img`
    position: absolute;
    z-index: -1;
    top: -70px;
    left: 15px;
    width: 50px;
`;

const Content = styled.div`
    position:relative;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 1rem;
    background-color: #ffffff;
    @media (min-width: 576px){
        border-radius: 5px;
    }
    min-height: 136px;
`;


const Layout = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    .island {
        background-color: #EAF4F7;
        padding: 6px 12px !important;
        width: 100%;
        margin: 0 0 10px !important;
        font-size: 18px;
        line-height: 24px;
        min-height: auto;
        box-shadow: none;
        &::after{
            content: '';
            position: absolute;
            left: 8px;
            top: -12px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #EAF4F7;
            clear: both;
        }
    }
    .island__author--dialogue {
        font-size: 12px;
        top: -55px;
        left: 50px;
        padding: 2px 8px;
        letter-spacing: 0.05em;
        border-radius: 5px;
    }
    @media (min-width: 576px){
        bottom: 10px;
        .island {
            border-radius: 5px !important;
            margin-bottom: 10px !important;
        }
        ${Content} {
            max-width: 400px;
        }
    }
    @media (min-width: 1200px){
        .island {
            font-size: 20px;
        }
        ${Content} {
            max-width: 500px;
        }
    }
`;

export default TownIntro;
