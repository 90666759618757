import alien1 from '../../../../assets/characters/glib_glob.svg';
import alien2 from '../../../../assets/characters/flub_flub.svg';
import aliens from '../../../../assets/characters/aliens.svg';
import intro1 from '../../../../assets/misc/variable_intro1.svg';
import intro1_cy from '../../../../assets/misc/variable_intro1-cy.svg';
import intro2 from '../../../../assets/misc/variable_intro2.svg';
import intro2_cy from '../../../../assets/misc/variable_intro2-cy.svg';
import intro3 from '../../../../assets/misc/variable_intro3.svg';
import intro3_cy from '../../../../assets/misc/variable_intro3-cy.svg';
import { TCharacter } from '../../../general/Character';
import { t } from 'ttag';
import Cookies from 'js-cookie';
let lang = Cookies.get('bfg_lang');

export const GlibGlob: TCharacter = {
    characterName: t`Captain Fizz`,
    characterImage: alien1,
    characterWidth: 120,
    characterHeight: 170,
};

export const FlubFlub: TCharacter = {
    characterName: t`Technician Buzz`,
    characterImage: alien2,
    characterWidth: 120,
    characterHeight: 160,
};

export const AllAliens: TCharacter = {
    characterName: t`Fizz & Buzz`,
    characterImage: aliens,
    characterWidth: 190,
    characterHeight: 140,
};

export const FlubFlubIntro1: TCharacter = {
    characterName: t`Technician Buzz`,
    characterImage: lang === 'cy' ? intro1_cy : intro1,
    characterWidth: 220,
    characterHeight: 200,
};
export const FlubFlubIntro2: TCharacter = {
    characterName: t`Technician Buzz`,
    characterImage: lang === 'cy' ? intro2_cy : intro2,
    characterWidth: 220,
    characterHeight: 200,
};
export const FlubFlubIntro3: TCharacter = {
    characterName: t`Technician Buzz`,
    characterImage: lang === 'cy' ? intro3_cy : intro3,
    characterWidth: 200,
    characterHeight: 220,
};
