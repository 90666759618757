import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { GlibGlob } from '../../characters/Aliens';
import { VariablesData } from '../..';

const VariablesQuizOutro: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: GlibGlob,
            text: t`Hmmm... I wish we could keep track of the number of asteroids we destroy more easily. We shouldn’t have to try and keep count ourselves!`,
            buttonText: t`You're right!`,
        },
        {
            character: GlibGlob,
            text: t`Let’s chat to Buzz, the chief technician aboard my ship...`,
            buttonText: t`Hi there!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default VariablesQuizOutro;
