import './services/translation';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './styles/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './styles/toolkit.scss';

Bugsnag.start({
    apiKey: '7a40049fd389b70965a2f14ce943f316',
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary: any = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
