import React, {useContext, useEffect} from 'react';
import VoiceoverContext from '../../../../misc/voiceover-context';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';
import { t } from 'ttag';
import questioner from '../../characters/Turtle';
import styled from 'styled-components';

const TurtleQuestion: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        playVoiceover('pufferfish-question-1');
    }, [])
    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`Which features of the call seemed suspicious?`,
            answers: [
                { text: t`Called from an unknown number.`, correct: true },
                { text: t`Offered an unexpected reward.`, correct: true },
                { text: t`They knew my name.`, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <QuestionCard>
                <div className="island__author--dialogue">{questioner.characterName}</div>
                <Character>
                    <div>
                        <img
                            src={questioner.characterImage}
                            alt={questioner.characterName}
                            width={questioner.characterWidth}
                            height={questioner.characterHeight}
                        />
                        <Shadow />
                    </div>
                </Character>
                <MultipleChoice
                    gameData={gameData}
                    onGameComplete={nextSlide}
                    onCorrectAnswer={() => {
                        playVoiceover('sound-8');
                        setTimeout(() => playVoiceover('pufferfish-question-1-correct'), 1000);
                    }}
                    onIncorrectAnswer={() => {
                        playVoiceover('sound-1');
                        setTimeout(() => playVoiceover('question-incorrect'), 1000);
                    }}
                    forceOnAnswer={true}
                    ></MultipleChoice>
                <p style={{ marginBottom: '0', fontSize: '16px' }}>{t`Select ALL that apply`}</p>
            </QuestionCard>
        </Slide>
    );
};

const Shadow = styled.div`
    border-radius: 50%;
    display: block;
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: -20px;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0.4;
    -webkit-filter: blur(10px);
    -webkit-transform: scale(1, 0.2);
    z-index: 0;
`;

const Character = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    img {
        position: relative;
        z-index: 2;
        max-width: 100%;
    }
`;

const QuestionCard = styled.div`
    width: 100%;
    background-color: #ffffff;
    color: #6400aa;
    max-width: 100%;
    display: block;
    margin: 20px auto 0 auto;
    position: relative;
    padding: 1.5rem 1.25rem 0.75rem 1.25rem;
    margin-top: 3rem;
    border-radius: 5px;
    max-width: 400px;
`;

export default TurtleQuestion;
