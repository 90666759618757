import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Puzzle, { TPuzzleQuestion } from '../../components/interactive/puzzle/Puzzle';

import bearHead from '../../assets/puzzles/bear/top.png';
import bearHeadShadow from '../../assets/puzzles/bear/top-shadow.svg';
import bearBody from '../../assets/puzzles/bear/middle.png';
import bearBodyShadow from '../../assets/puzzles/bear/middle-shadow.svg';
import bearBottom from '../../assets/puzzles/bear/legs.png';
import bearBottomShadow from '../../assets/puzzles/bear/legs-shadow.svg';
import bear from '../../assets/puzzles/bear/bear.svg';

import snowmanBody from '../../assets/puzzles/snowman/middle.png';
import snowmanHead from '../../assets/puzzles/snowman/top.png';
import bearBodyDummy from '../../assets/puzzles/bear/middle-alt.png';
import robotBody from '../../assets/puzzles/robot/robot-middle.png';
import bearBottomDummy from '../../assets/puzzles/bear/legs-alt.png';
import soldierHeadDummy from '../../assets/puzzles/soldier/head-alt.png';
import robotHeadDummy from '../../assets/puzzles/robot/robot-top-alt.png';

const Puzzle2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TPuzzleQuestion[] = [
        {
            name: 'Teddy Bear',
            image: bear,
            pieces: [
                {
                    image: bearHead,
                    shadow: bearHeadShadow,
                    position: 'top',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 3,
                        position: 'relative',
                        filter: 'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-15px',
                        width: '110px',
                        height: '90px',
                    },
                    completed: false,
                },
                {
                    image: bearBody,
                    shadow: bearBodyShadow,
                    position: 'middle',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 2,
                        position: 'relative',
                        filter: 'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-20px',
                        width: '135px',
                        height: '95px',
                    },
                    completed: false,
                },
                {
                    image: bearBottom,
                    shadow: bearBottomShadow,
                    position: 'bottom',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 1,
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        width: '104px',
                        height: '50px',
                    },
                    completed: false,
                },
                { image: snowmanBody, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotBody, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: bearBottomDummy, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: bearBodyDummy, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: snowmanHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: soldierHeadDummy, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotHeadDummy, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Puzzle gameData={gameData} onGameComplete={nextSlide}></Puzzle>
        </Slide>
    );
};

export default Puzzle2;
