import React, { useContext, useEffect } from 'react';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import Island, { TIslandArea } from '../../components/Island';
import { t } from 'ttag';
import sfx from '../../../../../services/sounds';
import VoiceoverContext from '../../../../misc/voiceover-context';

const WhaleIsland: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data, gotoSlide }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        if (data.availableIslandAreas.length === 0) {
            data.setAvailableIslandAreas([{ name: 'house', onSelect: () => gotoSlide(51) }]);
        }
    }, [data]);


    useEffect(() => {
        data.playMusic(sfx.themeCybersecurity);
        if (data.availableIslandAreas.find((area) => area.name === 'house') || data.availableIslandAreas.length === 0) {
            playVoiceover('island-msg-hall')
        } else {
            playVoiceover('island-msg-character');
        }
    }, []);

    return (
        <Slide header={defaultHeader} backgroundColor={'rgba(0,0,0,0.4);'} footerHidden={true}>
            <Island
                notification={data.availableIslandAreas}
                nextSlide={nextSlide}
                onSelect={(location: TIslandArea) => data.removeIslandLocation(location)}
                infoText={
                    data.availableIslandAreas.find((area) => area.name === 'house')
                        ? t`Select the Village Hall to continue`
                        : t`Select a villager to help them`
                }
            ></Island>
        </Slide>
    );
};

export default WhaleIsland;
