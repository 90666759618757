import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import ContextualMuteButton from '../../misc/ContextualMuteButton';
import VoiceoverContext from '../../misc/voiceover-context';
import VoiceoverMuteButton from '../../misc/VoiceoverMuteButton';
import { VolumeContext } from '../../misc/volume-context';
// import voiceoverSpriteSheetDefault from './assets/voiceovers/vo-trickytraffic-spritesheet';
import voiceoverSpriteSheetChrome from './assets/voiceovers/vo-trickytraffic-spritesheet-chrome';
import Grass from './components/Wrapper';
import intro from './units/1-intro';
import tourists from './units/2-tourists';
import decomposition from './units/3-decomposition';
import end from './units/4-end';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';

export type TrafficData = {
    name: string;
    setName: (name: string) => void;
    playVoiceover: (sprite: string) => void;
    stopVoiceover: () => void;
    voiceover: Howl | undefined;
};

const Traffic: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);
    const { voiceover, setVoiceover } = useContext(VoiceoverContext);
    const { musicMuted } = useContext(VolumeContext);
    const [music, setMusic] = useState<Howl | undefined>(sfx.themeTraffic);
    const { playVoiceover, stopVoiceover } = useContext(VoiceoverContext);

    const slides = [
        StartSlide,
        ...intro,
        ...tourists,
        ...decomposition,
        // ...sequencing,
        // ...junctions,
        // ...game,
        ...end,
        EndSlide,
    ];

    const barefootLogo = (
        <StyledHeader>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <VoiceoverMuteButton></VoiceoverMuteButton>
            <ContextualMuteButton context={music} zIndex={5}></ContextualMuteButton>
        </StyledHeader>
    );

    useEffect(() => {
        if (!setVoiceover || ((Cookies.get('bfg_lang') ?? 'en') !== 'en')) {
            return;
        }

        setVoiceover(
            new Howl({
                src: require('./assets/voiceovers/vo-trickytraffic-2.mp3'),
                sprite: isSafari || isFirefox ? voiceoverSpriteSheetChrome : voiceoverSpriteSheetChrome,//voiceoverSpriteSheetDefault
                html5: true,
                volume: 0.3,
                preload: true,
            })
        );
    }, [])


    const playMusic = (m: Howl) => {
        if (m) {
            let vo: number;
            stopMusic()
            let mInstance = m.load();
            setMusic(mInstance);

            if (musicMuted === false) {
                vo = mInstance.play();
            }
        }
    }

    const stopMusic = () => {
        if (music) {
            music.stop();
        }
    }

    useEffect(() => {
        if (musicMuted) {
            stopMusic()
        } else if (musicMuted === false && music) {
            playMusic(music);
        }

        return () => {
            stopMusic();
        }
    }, [music, musicMuted])


    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Tricky Traffic | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="e5d81645-de2b-45f8-b4b3-57819e9c6065"
                name="traffic"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName, playVoiceover, stopVoiceover, voiceover }}
                Wrapper={Grass}
            />
        </React.Fragment>
    );
};

const StyledHeader = styled.div`
    .btn--mute {
        right: 10px;
        bottom: 10px;
    }
    .btn--mute-voice {
        right: 55px;
        bottom: 10px;
    }
`;

export default Traffic;
