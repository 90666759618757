import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentRio from '../../characters/AgentRio';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import WordDiscovery from '../../../../general/WordDiscovery';

const SeqQuizIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentRio,
            text: t`Looks like this is some sort of number sequence following a rule. The rule is an algorithm – I’ve learnt about these in agent training.`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Algorithm`}
                wordDefinition={t`An algorithm is a precise sequence of instructions or a set of rules to get something done.`}
            ></WordDiscovery>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default SeqQuizIntro;
