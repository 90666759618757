import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const MissionOneIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide onStart={nextSlide} header={defaultHeader} number={1}>
            {t`Fix the cipher's incorrect offset to uncover the meaning of the codes.`}
        </MissionSlide>
    );
};

export default MissionOneIntro;
