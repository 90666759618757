import React, { useEffect, useState, useContext } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { TEmailClientQuestion } from './EmailClient';
import styled from 'styled-components';
import { t } from 'ttag';
import toaster from 'toasted-notes';
import Turtle from '../../../characters/Turtle';
import { motion, useAnimation } from 'framer-motion';
import CharacteristicFound from '../../CharacteristicFound';
import turtle from '../../../assets/characters/2-turtle.svg';
import sfx from '../../../../../../services/sounds';
import VolumeContext from '../../../../../misc/volume-context';

import icon from '../../../assets/characteristics/tutorial/slide_1.svg';
import EmailComponent from './EmailComponent';

import VoiceoverContext from '../../../../../misc/voiceover-context';


interface EmailProps {
    email: TEmailClientQuestion;
    close: () => void;
    onComplete: () => void;
    onIncorrect: () => void;
}

const CustomToast: React.FC = ({ children }) => {
    return <StyledToast>{children}</StyledToast>;
};

const Email: React.FC<EmailProps> = ({ email, close, onComplete, onIncorrect }) => {
    // ask trustworth
    // if sus aspects ask to click
    // enable clickable areas as per data
    // run on complete
    const { volume } = useContext(VolumeContext);
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('octo-help-2');
    }, [])

    const [questionTotal, setQuestionTotal] = useState(1);

    const [notQuiteText, setNotQuiteText] = useState('');

    const anim_popup = useAnimation();
    const popUpVariants = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2,
                delay: 2,
            },
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.2,
            },
        },
    };



    const [source, setSource] = useState(false);

    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');

    const [trustworthyComplete, setTrustworthyComplete] = useState(false);

    useEffect(() => {
        let amount = 0;
        if (email) {
            if (!trustworthyComplete) {
                amount++;
            } else {
                playVoiceover('octo-help-3')

            }

            if (email.dodgyAttachment) {
                amount++;
            }

            if (email.dodgyEmail) {
                amount++;
            }

            if (email.dodgyBody) {
                amount++;
            }

            setQuestionTotal(amount);
        }

    }, [email, trustworthyComplete]);

    useEffect(() => {
        if (questionTotal === 0) {
            // onComplete();
        }
    }, [questionTotal]);

    async function animateNotQuite(text: string) {
        setNotQuiteText(text);
        if (volume) sfx.incorrect.play();
        await anim_popup.start('show');
        await anim_popup.start('hidden');
    }
    const handleTrustworthy = (correct: boolean, trustworthy: boolean) => {
        if (!correct) {
            if (volume) sfx.beep.play();
            playVoiceover('octo-incorrect-toast');
            toaster.notify(() => <CustomToast>{t`Are you sure? Take a closer look...`}</CustomToast>, {
                duration: 1250,
                position: 'bottom',
            });
        } else {
            if (volume) sfx.correct.play();
            setTrustworthyComplete(true);
            subQuestionCompeleted();

            if (trustworthy) {
                onComplete();
            }
        }
    };

    const subQuestionCompeleted = (msg?: string) => {
        if (msg) {
            // toaster.notify(() => <CustomToast>{msg}</CustomToast>,{
            //     duration: 4000,
            //     position: 'bottom'
            //   });
        }
        setQuestionTotal(questionTotal - 1);
    };

    const renderQuestion = () => {
        if (!trustworthyComplete) {
            return (
                <>
                    <Trustworthy
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ y: [25, 0], scale: [0.9, 1], opacity: [0, 1] }}
                        transition={{ duration: 0.6, ease: 'easeInOut' }}
                    >
                        <Message>
                            <Character
                                animate={{ y: [0, -10, 0, -10, 0] }}
                                transition={{ loop: Infinity, repeatDelay: 10, duration: 1, ease: 'easeInOut' }}
                                alt={Turtle.characterName}
                                src={Turtle.characterImage}
                                width={Turtle.characterWidth}
                            />
                            <p>{t`Does this look trustworthy?`}</p>
                            <Thumb
                                direction="up"
                                onClick={() => handleTrustworthy(email.trustworthy, email.trustworthy)}
                            >
                                <i className="fa fa-thumbs-up"></i>
                            </Thumb>
                            <Thumb
                                direction="down"
                                onClick={() => handleTrustworthy(!email.trustworthy, email.trustworthy)}
                            >
                                <i className="fa fa-thumbs-down "></i>
                            </Thumb>
                        </Message>
                    </Trustworthy>
                </>
            );
        }

        if (email.dodgyAttachment || email.dodgyEmail) {
            return (
                <>
                    <Trustworthy
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ y: [25, 0], scale: [0.9, 1], opacity: [0, 1] }}
                        transition={{ duration: 0.6, ease: 'easeInOut' }}
                    >
                        <Message>
                            <Character
                                animate={{ y: [0, -10, 0, -10, 0] }}
                                transition={{ loop: Infinity, repeatDelay: 14, duration: 1, ease: 'easeInOut' }}
                                alt={Turtle.characterName}
                                src={Turtle.characterImage}
                                width={Turtle.characterWidth}
                            />
                            <h4>{t`Excellent! Now click on anything untrustworthy in this email.`}</h4>
                        </Message>
                    </Trustworthy>
                </>
            );
        }

        return null;
    };

    const handleDodgyAttachment = () => {
        if (volume) sfx.synthSuccess.play();
        playVoiceover('octo-char-2');
        setTitle(t`Dangerous Attachment`);
        setDescription(
            t`Emails can have attachments infected with software that intentionally damages your computer or steals personal details. If you're unsure about an attachment use your antivirus software to scan it.`,
        );
        setSource(true);
        subQuestionCompeleted(
            t`Excellent! If unsure about an attachment use your antivirus to scan the email attachment.`,
        );
        email.dodgyAttachment = false;
    };

    const handleDodgyEmail = () => {
        if (volume) sfx.synthSuccess.play();
        playVoiceover('octo-char-1')
        setTitle(t`Untrustworthy Email`);
        setDescription(
            t`Look out for email addresses that are unfamiliar or contain spelling mistakes.`,
        );
        setSource(true);
        subQuestionCompeleted(t`Perfect, the email in this case is not what we expect.`);
        email.dodgyEmail = false;
    };

    const handleDodgyBody = () => {
        if (volume) sfx.synthSuccess.play();
        playVoiceover('octo-char-3')
        setTitle(t`Suspicious Content`);
        setDescription(
            t`Be careful with emails that promise rewards, gifts and prizes. Often if the email sounds too good to be true, it is likely to be a scam.`,
        );
        setSource(true);
        subQuestionCompeleted(t`Perfect, the email in this case is not what we expect.`);
        email.dodgyBody = false;
    };

    const handleIncorrectSelection = (text: string) => {
        if (volume) sfx.incorrect.play();
        onIncorrect();
        animateNotQuite(text);
    };

    return (
        <>
            <PopUp animate={anim_popup} variants={popUpVariants}>
                <>
                    <h3>{ t`Not Quite!` }</h3>
                    <p className="mb-0">{notQuiteText}</p>
                    <Character src={turtle} />
                </>
            </PopUp>
            <CharacteristicFound
                show={source}
                title={title}
                icon={icon}
                onClose={() => {
                    setSource(false);

                    if (questionTotal === 0) {
                        onComplete();
                    }
                }}
            >
                {description}
            </CharacteristicFound>
            <LaptopContent>
                <Row>
                    <Col xs={'12'} sm={{ span: '10', offset: '1' }}>
                        <EmailComponent
                            enabled={trustworthyComplete}
                            onSelect={() => handleIncorrectSelection(t`The subject of this email looks fine.`)}
                        >
                            <h3>{email.subject} <i className="fa fa-volume-up" onClick={() => playVoiceover(email.contentSpriteName)}></i></h3>
                        </EmailComponent>

                        <EmailCard>
                            <Row className={'align-items-center'}>
                                <Col xs={'2'} md={'1'} className={'px-0'}>
                                    <UserCircle>
                                        <p>?</p>
                                    </UserCircle>
                                </Col>
                                <Col>
                                    <EmailComponent
                                        classes={'mb-0'}
                                        enabled={trustworthyComplete}
                                        onSelect={() =>
                                            handleIncorrectSelection(t`This display name looks legitimate.`)
                                        }
                                    >
                                        <b>{email.name}</b>
                                    </EmailComponent>
                                    <EmailComponent
                                        classes={'mb-0 d-inline-block'}
                                        enabled={trustworthyComplete}
                                        onSelect={
                                            email.dodgyEmail
                                                ? handleDodgyEmail
                                                : () =>
                                                      handleIncorrectSelection(t`This email address looks legitimate.`)
                                        }
                                    >
                                        {email.email}
                                    </EmailComponent>
                                </Col>
                                <Col xs={'1'} className={'pl-0'}>
                                    <span onClick={close}>
                                        <i className={'fa fa-times'}></i>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={'12'} className={'px-0'}>
                                    <EmailContent>
                                        <EmailComponent
                                            enabled={trustworthyComplete}
                                            onSelect={
                                                email.dodgyBody
                                                    ? handleDodgyBody
                                                    : () =>
                                                          handleIncorrectSelection(
                                                              t`The content of this email looks legitimate.`,
                                                          )
                                            }
                                        >
                                            {email.content}
                                        </EmailComponent>
                                        {email.attachment ? <hr /> : null}
                                        {email.attachment ? (
                                            <EmailComponent
                                                classes={'email-button-attachment'}
                                                enabled={trustworthyComplete}
                                                onSelect={handleDodgyAttachment}
                                            >
                                                <Button className={'btn-3d-dark'}>{email.attachment}</Button>
                                            </EmailComponent>
                                        ) : null}
                                    </EmailContent>
                                </Col>
                            </Row>
                        </EmailCard>
                    </Col>
                </Row>
            </LaptopContent>
            {renderQuestion()}
        </>
    );
};

const PopUp = styled(motion.div)`
    pointer-events: none;
    user-select: none;
    position: fixed;
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    left: 50%;
    z-index: 999;
    padding: 20px;
    border-radius: 5px;
    top: 50%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6);
    transform: translateY(-50%) translateX(-50%);
    background-color: #e60050;
    h3,
    p {
        color: #ffffff;
    }
    h3 {
        font-size: 24px;
    }
    p {
        font-size: 18px;
    }
`;

// 3 types

//mobile = full bottom
// up to 767px

//small landscape = bottom
// up to 1200 and min-height 650

// desktop = right
// 1200 onwards

// large desktop = bottom
// 1200 onwards and min-height 850

const EmailText = styled.p<{ hover: boolean }>`
    border-bottom: ${(props) => (props.hover ? 'solid pink 4px' : 'none')};
`;

interface ThumbProps {
    direction: string;
}

const Thumb = styled.button<ThumbProps>`
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 45%;
    margin: 0 2%;
    border: 0 !important;
    color: #ffffff !important;
    font-size: 1rem;
    &:hover {
        opacity: 0.8;
    }
    padding: 5px 0;
    background-color: ${(props) => (props.direction === 'up' ? '#00C3A5' : '#E60050')};direction
`;

const Trustworthy = styled(motion.div)`
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 100%;
    max-width: 320px;
    z-index: 999;
    left: calc(50% - 160px);
    p {
        margin-bottom: 5px !important;
        font-size: 16px;
        font-family: 'BT Font Bold';
        line-height: 14px;
    }
    h4 {
        font-size: 20px;
        line-height: 25px;
    }
`;

const Message = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 350px;
    height: 100%;
    position: relative;
`;

const EmailContent = styled.div`
    padding-top: 3%;
    hr {
        margin-top: 2%;
        margin-bottom: 2%;
    }
`;

const EmailCard = styled.div`
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    padding: 3%;
    a {
        color: #6400aa;
    }
    .btn-3d,
    .btn-3d-dark {
        padding: 1% 2%;
        font-size: 2vw;
        @media screen and (min-width: 768px) {
            font-size: 1rem;
        }
    }
    .email-dodgy {
        border-bottom: solid pink 4px;
    }
    .attachment-dodgy {
        border: solid 2px pink;
    }
`;

const LaptopContent = styled.div`
    padding: 5% 1% 10% 1%;
    @media screen and (min-width: 768px) {
        h3 {
            font-size: 1.5rem;
        }
    }
`;

const UserCircle = styled.div`
    border-radius: 50%;
    width: 100%;
    padding-top: 100%;
    position: relative;
    max-height: 40px;
    p {
        color: #ffffff;
        margin-bottom: 0;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
    background-color: #3b0a5d;
`;

const StyledToast = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    color: #6400aa;
    position: relative;
`;

const Character = styled(motion.img)`
    position: absolute;
    z-index: -1;
    width: 50px;
    right: 5px;
    top: -50px;
`;

export default Email;
