import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import keypad from '../../../../../assets/misc/th-keypad-fake.svg';
import { t } from 'ttag';

const IntroKeypad: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showFooter, setShowFooter] = useState(false);
    const next = showFooter ? <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button> : null;

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 2000);
    }, []);

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={{ offset: 2, span: 8 }} md={{ offset: 4, span: 4 }}>
                    <h3 className={'text-center mb-4'}>{t`Keypad Found`}</h3>
                    <img
                        src={keypad}
                        width={'300px'}
                        className={'w-100 d-block px-2 th-keypad__intro'}
                        onClick={() => nextSlide()}
                        alt={`Keypad`}
                    />
                </Col>
            </Row>
        </Slide>
    );
};

export default IntroKeypad;
