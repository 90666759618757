import React, {useContext, useEffect} from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import CyberCard from '../../components/CyberCard';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import VoiceoverContext from '../../../../misc/voiceover-context';

const BlockUser: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('fish-cyber-block');
    }, [])
    return (
        <Slide header={defaultHeader} footer={next}>
            <CyberCard
                title={t`Blocked!`}
                description={t`Nice one! This was not an official account and they even made a spelling mistake... Good job!`}
                points={20}
                gameData={data}
            />
        </Slide>
    );
};

export default BlockUser;
