import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import caesar_bottom from '../../../assets/misc/caesar_bottom.svg';
import caesar_top from '../../../assets/misc/caesar_top.svg';

const CaesarCipherWheel: React.FC<{
    offset: number;
    offsetInputDisabled?: boolean;
    onOffsetChange?: (o: number) => void;
}> = ({ offset = 0, offsetInputDisabled = true, onOffsetChange = () => null }) => {
    return (
        <div className="cc-cipher__wrapper">
            <div className={'cc-cipher'}>
                <label className={'cc-cipher__offset-label'}>{t`OFFSET`}</label>
                <input
                    className={'cc-cipher__offset'}
                    name="offset"
                    type="number"
                    value={offset}
                    onChange={(e) => onOffsetChange(parseInt(e.target.value || '0'))}
                    disabled={true}
                    min="0"
                    max="25"
                />
                {!offsetInputDisabled ? (
                    <React.Fragment>
                        <Button
                            className={'cc-cipher__btn cc-cipher__btn--down'}
                            onClick={() => (offsetInputDisabled ? null : onOffsetChange(offset - 1))}
                        >
                            <i className={'fas fa-minus'}></i>
                        </Button>
                        <Button
                            className={'cc-cipher__btn cc-cipher__btn--up'}
                            onClick={() => (offsetInputDisabled ? null : onOffsetChange(offset + 1))}
                        >
                            <i className={'fas fa-plus'}></i>
                        </Button>
                    </React.Fragment>
                ) : (
                    <span></span>
                )}
                <WheelRotater
                    offset={offset}
                    className={'cc-cipher__disc cc-cipher__disc--top'}
                    src={caesar_top}
                    width={'500px'}
                    height={'500px'}
                />
                <img
                    className={'cc-cipher__disc cc-cipher__disc--bottom'}
                    src={caesar_bottom}
                    width={'500px'}
                    height={'500px'}
                    alt=""
                />
            </div>
        </div>
    );
};

interface WheelRotaterProps {
    offset: number;
}
const WheelRotater = styled.img<WheelRotaterProps>`
    transform: rotate(${(props) => (360 / 26) * props.offset}deg);
`;

export default CaesarCipherWheel;
