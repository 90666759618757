import DecompositionQuestion from "./DecompositionQuestion";
import AlgoDefinition from "./Definition";
import LevelThree from "./LevelThree";
import SequenceGame from "./SequenceGame";
import SequenceSimulation from "./SequenceSimulation";
import TimingsSimulation from "./TimingsSimulation";

export default [
    LevelThree,
    DecompositionQuestion,
    SequenceGame,
    SequenceSimulation,
    TimingsSimulation,
    AlgoDefinition,
];
