import React from 'react';
import { t } from 'ttag';
import boy from '../../../../../assets/games/athletes/athlete_boy.svg';
import girl from '../../../../../assets/games/athletes/athlete_girl.svg';
import spain from '../../../../../assets/games/flags/flags/spain.svg';
import uk from '../../../../../assets/games/flags/flags/uk.svg';
import bottom_gb from '../../../../../assets/games/kits/bottom_gb.svg';
import bottom_jamaica from '../../../../../assets/games/kits/bottom_jamaica.svg';
import head_kayak from '../../../../../assets/games/kits/head_kayak.svg';
import head_blank from '../../../../../assets/games/kits/head_blank.svg';
import top_gb from '../../../../../assets/games/kits/top_gb.svg';
import top_spain from '../../../../../assets/games/kits/top_spain.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import KitMistake, { TKitMistakeQuestion } from '../../../../interactive/kitmistake/KitMistake';

const DebugGame: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const gameData: TKitMistakeQuestion[] = [
        {
            athlete: boy,
            sport: t`Tennis`,
            country: t`Spain`,
            flag: spain,
            answers: [
                { type: `head`, image: head_blank, correct: false },
                { type: `top`, image: top_spain, correct: false },
                { type: `bottom`, image: bottom_jamaica, correct: true },
                { type: `equipment`, image: ``, correct: false },
            ],
        },
        {
            athlete: girl,
            sport: t`Football`,
            country: t`Great Britain`,
            flag: uk,
            answers: [
                { type: `head`, image: head_kayak, correct: true },
                { type: `top`, image: top_gb, correct: false },
                { type: `bottom`, image: bottom_gb, correct: false },
                { type: `equipment`, image: ``, correct: false },
            ],
        },
    ];

    const header = (
        <h3>
            {t`Task 4:`} <span className="font-bt-400">{t`Find the mistakes in the athletes' kits`}</span>
        </h3>
    );

    return (
        <Slide header={header}>
            <KitMistake gameData={gameData} onGameComplete={() => nextSlide()} />
        </Slide>
    );
};

export default DebugGame;
