import { Howl } from 'howler';

const themes = {
    themeAbstraction: new Howl({
        src: [require('../assets/sounds/theme_abstraction.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeAthletics: new Howl({
        src: [require('../assets/sounds/theme_athletics.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeFarm: new Howl({
        src: [require('../assets/sounds/theme_farm.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeHeist: new Howl({
        src: [require('../assets/sounds/theme_heist.mp3')],
        loop: true,
        volume: 0.2,
        preload: false,
    }),
    themeMilitary: new Howl({
        src: [require('../assets/sounds/theme_military.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeSpace: new Howl({
        src: [require('../assets/sounds/theme_space.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeCybersecurity: new Howl({
        src: [require('../assets/sounds/theme_cybersecurity.mp3')],
        loop: true,
        volume: 0.04,
        preload: false,
    }),
    themeCybersecurityWhale: new Howl({
        src: [require('../assets/sounds/cybersecurity/disco2.mp3')],
        loop: true,
        volume: 0.03,
        preload: false,
    }),
    themeCybersecurityFish: new Howl({
        src: [require('../assets/sounds/cybersecurity/disco1.mp3')],
        loop: true,
        volume: 0.03,
        preload: false,
    }),
    themeCybersecurityPufferfish: new Howl({
        src: [require('../assets/sounds/cybersecurity/feelgood1.mp3')],
        loop: true,
        volume: 0.03,
        preload: false,
    }),
    themeCybersecurityOcto: new Howl({
        src: [require('../assets/sounds/cybersecurity/dolphin1.mp3')],
        loop: true,
        volume: 0.03,
        preload: false,
    }),
    themeCybersecurityBattle: new Howl({
        src: [require('../assets/sounds/cybersecurity/battle3.mp3')],
        loop: true,
        volume: 0.03,
        preload: false,
    }),
    themeFestive: new Howl({
        src: [require('../assets/sounds/theme_festive.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
    themeTraffic: new Howl({
        src: [require('../assets/sounds/traffic/theme.mp3')],
        loop: true,
        volume: 0.1,
        preload: false,
    }),
};

const sfx = {
    ...themes,
    pop: new Howl({
        src: [require('../assets/sounds/pop.mp3')],
        volume: 0.2,
    }),
    success: new Howl({
        src: [require('../assets/sounds/success-1.mp3')],
        volume: 0.2,
    }),
    correct: new Howl({
        src: [require('../assets/sounds/correct-1.mp3')],
        volume: 0.2,
    }),
    incorrect: new Howl({
        src: [require('../assets/sounds/lose.mp3')],
        volume: 0.2,
    }),
    voice: new Howl({
        src: [require('../assets/sounds/voice.mp3')],
        volume: 0.2,
    }),
    click: new Howl({
        src: [require('../assets/sounds/click.mp3')],
        volume: 0.2,
    }),
    cheer: new Howl({
        src: [require('../assets/sounds/cheer.mp3')],
        volume: 0.15,
    }),
    beep: new Howl({
        src: [require('../assets/sounds/beep.mp3')],
        volume: 0.2,
    }),
    failure: new Howl({
        src: [require('../assets/sounds/failure.mp3')],
        volume: 0.2,
    }),
    beepbop: new Howl({
        src: [require('../assets/sounds/beep-bop.mp3')],
        volume: 0.2,
    }),
    explosion: new Howl({
        src: [require('../assets/sounds/explosion.mp3')],
        volume: 0.2,
    }),
    startgame: new Howl({
        src: [require('../assets/sounds/startgame.mp3')],
        volume: 0.2,
    }),
    ringtone: new Howl({
        src: [require('../assets/sounds/ringtone.mp3')],
        volume: 0.2,
    }),
    synthSuccess: new Howl({
        src: [require('../assets/sounds/synth_success.mp3')],
        volume: 0.2,
    }),
    synthFail: new Howl({
        src: [require('../assets/sounds/synth_fail.mp3')],
        volume: 0.2,
    }),
    coinsCollect: new Howl({
        src: [require('../assets/sounds/coins_collect.mp3')],
        volume: 0.2,
    }),
    crash: new Howl({
        src: [require('../assets/sounds/traffic/crash.mp3')],
        volume: 0.1,
    }),
};

export default sfx;
