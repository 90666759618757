import { TCharacter } from '../../../general/Character';
import image from '../../../../assets/characters/agent_andrew.svg';
import { t } from 'ttag';

const AgentSparrow: TCharacter = {
    characterName: t`Agent Sparrow`,
    characterImage: image,
};

export default AgentSparrow;
