import React from 'react';
import { TPuzzlePiece } from './Puzzle';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

interface PuzzlePieceProps {
    piece: TPuzzlePiece;
}

const PuzzlePiece: React.FC<PuzzlePieceProps> = ({ piece }) => {
    const [{ opacity }, dragRef] = useDrag({
        item: { type: 'piece', position: piece.position, correct: piece.correct },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    });
    return (
        <StyledCol ref={dragRef}>
            <img src={piece.image} style={{ ...piece.styles, opacity }}></img>
        </StyledCol>
    );
};

const StyledCol = styled.span`
    display: inline-block;
    padding: 0 15px;
    vertical-align: top;
    img {
        max-width: 70px;
        height: auto !important;
        max-height: 80px;
        margin-bottom: 5px !important;
        cursor: move;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        cursor: grab;
        @media (max-width: 414px) {
            max-width: 40px;
        }
    }
`;

export default PuzzlePiece;
