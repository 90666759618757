import React from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Turtle from '../../characters/Turtle';

const TurtleOutro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`This is worrying, the Phisherman is getting more and more dangerous. He just tried to phish you right in the middle of this game itself! `,
            textSpriteName: 'endturtle-2-1',
            buttonText: t`Next`,
        },
        {
            character: Turtle,
            text: t`We need to stop him! Can you use all of your phishing knowledge to outsmart him and defeat him once and for all?`,
            textSpriteName: 'endturtle-2-2',
            buttonText: t`Let's go!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TurtleOutro;
