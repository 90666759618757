import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const ConfusedTouristsIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: RangerJack,
            text: t`Wow, you really are a natural at this. Thank you so much for your help so far, can you help me with another problem I have been having?`,
            buttonText: t`Sure thing!`,
        },
        {
            character: RangerJack,
            text: t`Brilliant. So we have some tourists visiting our animals here but they are having trouble identifying which animal is which... Can you help?`,
            buttonText: t`Let's go!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default ConfusedTouristsIntro;
