import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import CaesarCipherWheel from '../../../../interactive/caesarcipher/CaesarCipherWheel';
import Content from '../../../../general/Content';
import { t } from 'ttag';

const CaesarCipherGameIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showFooter, setShowFooter] = useState(false);

    const next = showFooter ? (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    ) : null;
    const header = (
        <h3>
            {t`Caesar Cipher:`} <p>{t`How to use`}</p>
        </h3>
    );

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 5000);
    }, []);

    return (
        <Slide header={header} footer={next}>
            <Row>
                <Col xs={'12'} md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
                    <div className={'d-block mx-auto w-75 mb-3'}>
                        <CaesarCipherWheel offset={4} offsetInputDisabled={true}></CaesarCipherWheel>
                    </div>
                    <Content type={'island'} styles={'p-3'}>
                        <p>
                            {t`The given offset will shift the alphabet by four. Meaning that:`}
                            <br />
                            {t`A turns into E`}
                            <br />
                            {t`B turns into F`}
                            <br />
                            {t`... and so on.`}
                            <br />
                            {t`This cipher would convert`} <strong>ZKC</strong> {t`into`} <strong>DOG</strong>
                        </p>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default CaesarCipherGameIntro;
