import React, { useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerAisha from '../../characters/EngineerAisha';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';






const TimingsSimulation: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
  const [footer, buttonRenderer] = useFooterButtonRenderer();
  const [show, setShow] = useState(false);
  const [dialogueShow, setDialogueShow] = useState(true);
  const [nextDialogue, setNextDialogue] = useState<any>(null);
  const { width } = useWindowDimensions();


  const dialogue: DialogueData[] = [
    {
      character: EngineerAisha,
      text: t`Great work! Now we can see if that has fixed our timing problem.`,
      textSpriteName: '91',
      buttonText: t`Next`,
    },
    {
      character: EngineerAisha,
      text: t`Phew... You did it! Now we can sit back and relax while the traffic lights run automatically.`,
      textSpriteName: '92',
      buttonText: t`Next`,
      buttonAction: nextSlide

    }
  ];

  const trafficLight1: TRoadLight = {
    sequence: [
      { red: { enabled: true }, duration: 4000 },
      { amber: { enabled: true }, red: { enabled: true }, duration: 1000 },
      { green: { enabled: true }, red: { enabled: false }, duration: 4000 },
      { amber: { enabled: true }, red: { enabled: false }, duration: 1000 },
    ],
    pos: 1280 - 300,
    direction: 'clockwise'
  };

  const trafficLight2: TRoadLight = {
    sequence: [
      { green: { enabled: true }, red: { enabled: false }, duration: 4000 },
      { amber: { enabled: true }, red: { enabled: false }, duration: 1000 },
      { red: { enabled: true }, duration: 4000 },
      { amber: { enabled: true }, red: { enabled: true }, duration: 1000 },
    ],
    pos: 1280 + 300,
    direction: 'clockwise'
  };

  const [carData, setCarData] = useState<TCar[]>([
    { id: 'go-1', pos: 0, moving: true, direction: 'clockwise' },
    { id: 'go-2', pos: width, moving: true, direction: 'anticlockwise' },
  ]);


  return (
    <Slide header={defaultHeader} footerHidden={true}>
      <Scene nextSlide={nextSlide} lights={false} road={{ carData: carData, trafficLight1, trafficLight2 }} data={data}>
        {dialogueShow ?
          <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />
          : null}

      </Scene>
    </Slide>
  );
}

export default TimingsSimulation;
