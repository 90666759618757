import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';

const SeqQuiz: React.FC<ISlide> = ({ defaultHeader, nextSlide, gotoSlide, currentIndex }) => {
    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`Which algorithm (rule) does this sequence use?`,
            questionContext: `19 ... 27 ... 35 ... 43 ...`,
            answers: [
                { text: t`Multiply the previous number by 2`, correct: false },
                { text: t`Add 8 to the previous number`, correct: true },
                { text: t`Subtract 6 from the previous number`, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Row>
                <Col
                    xs={{ offset: 1, span: 10 }}
                    md={{ offset: 3, span: 6 }}
                    lg={{ offset: 4, span: 4 }}
                    xl={{ offset: 3, span: 6 }}
                >
                    <Content type={'island'} styles={'p-3'}>
                        <MultipleChoice
                            gameData={gameData}
                            onGameComplete={nextSlide}
                            onIncorrectAnswer={() => gotoSlide(currentIndex - 1)}
                        />
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default SeqQuiz;
