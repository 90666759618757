import React from 'react';
import swirl_green from '../../assets/background/swirl-green.svg';
import swirl_yellow from '../../assets/background/swirl-yellow.svg';
import square_pink from '../../assets/background/square-pink.svg';
import triangle_blue from '../../assets/background/triangle-blue.svg';

export const Wrapper: React.FC = ({ children }) => {
    return (
        <div className="app-wrapper">
            <div className="bg-wrapper">
                <img src={swirl_green} alt="" className="bg__graphic bg__graphic--swirl-green" />
                <img src={swirl_yellow} alt="" className="bg__graphic bg__graphic--swirl-yellow" />
                <img src={square_pink} alt="" className="bg__graphic bg__graphic--square-pink" />
                <img src={triangle_blue} alt="" className="bg__graphic bg__graphic--triangle-blue" />
            </div>
            {children}
        </div>
    );
};

export default Wrapper;
