import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { ZookeeperFiona } from '../../characters/Zookeepers';
import { SortingData } from '../..';

const Intro: React.FC<ISlide<SortingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: ZookeeperFiona,
            text: t`Hi ${data.name}! I’m Fiona and I’ll be helping you create algorithms later for sorting the animals.`,
            buttonText: t`Enter`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
