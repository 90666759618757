import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import Typist from 'react-typist';
import VoiceoverContext from '../misc/voiceover-context';
import { TCharacter } from './Character';

export type DialogueData = {
    character: TCharacter;
    text: string;
    textSpriteName?: string;
    buttonText: string;
    buttonTextAudio?: [number, number] | [number, number, boolean];
    buttonAction?: () => void;
};

export interface DialogueProps {
    data: DialogueData;
    onTypingDone: any;
    Wrapper?: React.FC;
    type: boolean;
}

export const Dialogue: React.FC<DialogueProps> = ({ onTypingDone, data, Wrapper = React.Fragment, type = false }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    const formattedText = data.text.split('\n').map((line, i) => (
        <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: i ? i * 1.5 : 0.25 }}
            key={i}
        >
            {line}
            <br />
        </motion.span>
    ));
    const delay = data.text.length * 25;

    const handleAnimationComplete = () => {
        setTimeout(onTypingDone, delay);
    };

    useEffect(() => {
        // grab howl instance
        // get start and end time
        if (data.textSpriteName) {
            playVoiceover(data.textSpriteName);
        }
    }, []);

    return (
        <Wrapper {...data}>
            <motion.div
                className="island island--dialogue rounded mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onAnimationComplete={type ? undefined : handleAnimationComplete}
            >
                <div className="island__author--dialogue">
                    <b>{data.character.characterName}</b>
                </div>
                {/* {type ? (<Typist avgTypingDelay={30} stdTypingDelay={0} cursor={{ show: false }} onTypingDone={onTypingDone}>{data.text}</Typist>) : {formattedText }} */}
                {/* <Typist avgTypingDelay={20} stdTypingDelay={0} cursor={{show: false}} onTypingDone={onTypingDone}>{formattedText}</Typist> */}
                {type ? (<Typist avgTypingDelay={30} stdTypingDelay={0} cursor={{ show: false }} onTypingDone={onTypingDone}>{data.text}</Typist>) : formattedText}
            </motion.div>
        </Wrapper>
    );
};

export default Dialogue;
