import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { ColourfulKitsData } from '../..';
import { t } from 'ttag';

const AttributesQuizIntro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`Well done ${data.name}! You now know what attributes are, they are going to be very useful for planning the next competition.`,
            buttonText: t`Next`,
        },
        {
            character: CoachAlbert,
            text: t`We would like some more ideas for the competition logo. Are you able to group some more shapes by their common attributes?`,
            buttonText: t`Yes!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default AttributesQuizIntro;
