import React, { useContext } from 'react';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { Button, Col, Row } from 'react-bootstrap';
import { VolumeContext } from '../../../misc/volume-context';
import sfx from '../../../../services/sounds';
import Reward from 'react-rewards';
import endslide from '../../../../assets/misc/so-end-slide.svg';
import endslide_cy from '../../../../assets/misc/so-end-slide-cy.svg';
import duck from '../../../../assets/misc/so-end-duck.svg';
import { t } from 'ttag';

import { motion } from 'framer-motion';
import Cookies from 'js-cookie';

const EndSlide: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;
    const { volume } = useContext(VolumeContext);

    let moduleName = t`Sorting it out!`;

    if (volume) sfx.cheer.play();
    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}

                    <Content type="island_dark" styles={'my-2 p-2'}>
                        <Reward ref={(ref: any) => (ref ? ref.rewardMe() : null)} type="confetti" />
                        <div className="so-end__image">
                            <img
                                src={lang === 'cy' ? endslide_cy : endslide}
                                className={`w-50 mb-3`}
                                width={'250px'}
                                alt=""
                            />
                            <motion.img
                                src={duck}
                                className={`so-end__duck`}
                                alt=""
                                width={'60px'}
                                animate={{ right: [-100, 700], rotate: [3, -5, 3], y: [0, -10, 0] }}
                                transition={{
                                    right: { loop: Infinity, duration: 9, delay: 5, repeatDelay: 5 },
                                    rotate: { loop: Infinity, duration: 0.7, repeatDelay: 0.1 },
                                    y: { loop: Infinity, duration: 0.3, repeatDelay: 1 },
                                }}
                            />
                        </div>

                        <div className={'character__shadow'}></div>
                        <h3>{t`You have completed
                        ${moduleName}`}</h3>
                        <p>{t`Congratulations!`}</p>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about in ${moduleName} and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/sorting"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20the%20Barefoot%Farm%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/sorting"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndSlide;
