import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { ZookeeperJacob } from '../../../sorting/characters/Zookeepers';
import pattern from '../../../../../assets/icons/icon_pattern.svg';
import SkillModal from '../../../../general/SkillModal';

const MissionOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: ZookeeperJacob,
            text: t`You spotted what’s the same for the sheep, chickens and cows. Nice work!`,
            buttonText: t`Next`,
        },
        {
            character: ZookeeperJacob,
            text: t`Brilliant! You’ve helped us sort some of the animals on the farm.`,
            buttonText: t`No problem!`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`PATTERN`}
                skillImage={pattern}
                skillDescription={t`You spotted patterns by looking at what was the same for the animals.`}
            />
        </Slide>
    );
};

export default MissionOutro;
