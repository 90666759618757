import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
import * as Chat from '../../../../interactive/chat/Chat';
import Phone from '../../components/Phone';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';

const NikNakChat: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    const gameData: Chat.TChatMessage[] = [
        {
            user: t`NikNak78`,
            message: t`Download the new version of NikNak!
            Enjoy NikNak Videos and make some yourself! Now NikNak is only available in your region as NikNak Pro. Donwload below!
            Link: http://tiny.cc/dlink`,
            messageSpriteName: 'fish-chat-1',
            actions: [
                {
                    text: t`Who are you?`,
                    textSpriteName: 'fish-chat-a-1',
                },
                {
                    text: t`Click the link`,
                    textSpriteName: 'fish-chat-a-2',
                    action: () => gotoSlide(currentIndex + 4),
                },
                {
                    text: t`Block this user`,
                    textSpriteName: 'fish-chat-a-3',
                    action: () => gotoSlide(currentIndex + 1),
                },
            ],
        },

        {
            user: t`NikNak78`,
            message: t`This is NikNak, please follow the link: http://tiny.cc/dlink`,
            messageSpriteName: 'fish-chat-2',
            actions: [
                {
                    text: t`Click the link`,
                    textSpriteName: 'fish-chat-a-2',
                    action: () => gotoSlide(currentIndex + 4),
                },
                {
                    text: t`Block this user`,
                    textSpriteName: 'fish-chat-a-3',
                    action: () => gotoSlide(currentIndex + 1),
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Phone backgroundColor={'#E1DEF9'}>
                <Chat.default
                    title={t`NikNak78`}
                    nickname={data.name}
                    avatar={data.avatar}
                    gameData={gameData}
                    onGameComplete={() => nextSlide()}
                ></Chat.default>
            </Phone>
        </Slide>
    );
};
export default NikNakChat;
