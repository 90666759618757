import React from 'react';
import ModalOverlay from '../../general/ModalOverlay';
import { TSortAlgorithmQuestion } from './SortAlgorithmGame';
import { Button, Row, Col } from 'react-bootstrap';
import { t } from 'ttag';

const SortAlgorithmQuestionModal: React.FC<{ show: boolean; question: TSortAlgorithmQuestion; setShow: any }> = ({
    show,
    question,
    setShow,
}) => {
    return (
        <ModalOverlay
            show={show}
            header={<div></div>}
            footer={<Button onClick={() => setShow(false)}>{t`Create Algorithm`}</Button>}
            backgroundColor="#2D0954"
        >
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <p className={'ck-kit__howto'}>{question.question.text}</p>
                    <div className="island bg-secondary-700 island--guide island--rounded p-3 mx-auto">
                        {question.question.objects.map((object) => (
                            <img className={'so-algo__img'} width="100px" src={object.image} alt={object.name} />
                        ))}
                    </div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default SortAlgorithmQuestionModal;
