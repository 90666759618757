import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Tile from './Tile';
import { Feature } from './ShapeGroup';

import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css'; // optional styles
import { t } from 'ttag';

export type GameTileGroup = {
    tiles: [Feature, Feature, Feature, Feature, Feature, Feature];
    onComplete: () => void;
};

const TileGroup: React.FC<GameTileGroup> = ({ tiles, onComplete }) => {
    const [targetCorrect, setTargetCorrect] = useState(0);
    const [answersCorrect, setAnswersCorrect] = useState(0);

    const [questionTiles, setQuestionTiles] = useState(tiles);
    const [resetQuestion, setResetQuestion] = useState(false);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setQuestionTiles(tiles);
    }, [tiles]);

    useEffect(() => {
        let correctAnswers = 0;
        for (let i = 0; i < tiles.length; ++i) {
            if (tiles[i].correct === true) {
                correctAnswers++;
            }
        }

        setAnswersCorrect(0);
        setTargetCorrect(correctAnswers);
    }, [tiles]);

    useEffect(() => {
        if (answersCorrect > 0 && answersCorrect === targetCorrect) {
            // QUESTION COMPLETE
            setAnswersCorrect(0);
            onComplete();
        }
    }, [answersCorrect, onComplete, targetCorrect]);

    const onAnswer = (correct: boolean) => {
        if (correct === false) {
            // RESET QUESTION? OnIncorrect
            // maybe pass the question with the onanswer so we have the hint
            toaster.notify(t`Oh no! That was incorrect.`, {
                duration: 2000,
                position: 'bottom',
            });
            setDisabled(true);

            setTimeout(() => {
                setResetQuestion(!resetQuestion);
                setAnswersCorrect(0);
                setDisabled(false);
            }, 1000);
        } else {
            setAnswersCorrect(answersCorrect + 1);
        }
    };

    return (
        <Container>
            <Row className="mt-3">
                <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
                    <Row>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[0]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[1]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[2]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[3]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[4]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                        <Col xs={4}>
                            <Tile
                                feature={questionTiles[5]}
                                onAnswer={onAnswer}
                                resetQuestion={resetQuestion}
                                isDisabled={disabled}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default TileGroup;
