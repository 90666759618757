import React from 'react';
import { t } from 'ttag';
import animals from '../../../../../assets/games/flags/groups/animals.svg';
import circles from '../../../../../assets/games/flags/groups/circles.svg';
import crosses from '../../../../../assets/games/flags/groups/crosses.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import FeatureMatch, { FeatureMatchQuestion } from '../../../../interactive/groupflags/GroupFlags';

const LogicGame: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const gameData: FeatureMatchQuestion[] = [
        {
            subject: {
                name: ``,
                image: circles,
                answer: t`These flags were grouped as they all had at least one circle in their design.`,
            },
            features: [
                { name: t`creature`, correct: false, reason: `` },
                { name: t`colour`, correct: false, reason: `` },
                { name: t`stripes`, correct: false, reason: `` },
                { name: t`circles`, correct: true, reason: `` },
                { name: t`crosses`, correct: false, reason: `` },
                { name: t`stars`, correct: false, reason: `` },
            ],
        },
        {
            subject: {
                name: ``,
                image: crosses,
                answer: t`These flags were grouped as they all had at least one cross in their design.`,
            },
            features: [
                { name: t`creature`, correct: false, reason: `` },
                { name: t`colour`, correct: false, reason: `` },
                { name: t`squares`, correct: false, reason: `` },
                { name: t`circles`, correct: false, reason: `` },
                { name: t`crosses`, correct: true, reason: `` },
                { name: t`stars`, correct: false, reason: `` },
            ],
        },
        {
            subject: {
                name: ``,
                image: animals,
                answer: t`These flags were grouped as they all had at least one creature in their design.`,
            },
            features: [
                { name: t`creature`, correct: true, reason: `` },
                { name: t`colour`, correct: false, reason: `` },
                { name: t`stripes`, correct: false, reason: `` },
                { name: t`circles`, correct: false, reason: `` },
                { name: t`crosses`, correct: false, reason: `` },
                { name: t`stars`, correct: false, reason: `` },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <FeatureMatch gameData={gameData} onGameComplete={nextSlide} />
        </Slide>
    );
};

export default LogicGame;
