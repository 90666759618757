import React, { useEffect, useState, useContext } from 'react';
import VoiceoverContext from '../../../../misc/voiceover-context';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import Dodged from '../../components/Dodged';
import { t } from 'ttag';

const UserDodge: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {

    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        const dodged = setTimeout(() => playVoiceover('octo-phished-desc'), 4000);
        return () => {clearTimeout(dodged)}
    }, []);
    return (
        <Slide header={defaultHeader}>
            <Dodged
                points={20}
                gameData={data}
                onClose={nextSlide}
                show={true}
                explanation={t`You successfully ignored the emails with bad grammar and from unexpected sources!`}
            ></Dodged>
        </Slide>
    );
};

export default UserDodge;
