import React, { useEffect, useState, useContext } from 'react';

import star from '../assets/gamification/star.png';
import heart from '../assets/gamification/heart.png';
import hook from '../assets/gamification/hook.svg';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import ModalOverlay from '../../../general/ModalOverlay';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import { CyberSecurityData } from '..';
import fish from '../assets/characters/avatars/fish.png';
import toaster from 'toasted-notes';
import sfx from '../../../../services/sounds';
import VoiceoverContext from '../../../misc/voiceover-context';

type PhishedProps = {
    show: boolean;
    onClose: () => void;
    livesLost?: number;
    pointsLost: number;
    gameData: CyberSecurityData;
    explanation?: string;
};

const CustomToast: React.FC = ({ children }) => {
    return (
        <StyledToast>
            <i className="mr-2 fa fa-star"></i>
            {children}
        </StyledToast>
    );
};

const Phished: React.FC<PhishedProps> = ({ onClose, show, pointsLost, livesLost = 1, gameData, explanation }) => {
    const [footer, setFooter] = useState(<></>);

    const hooking = useAnimation();
    const hooked = useAnimation();
    const reveal = useAnimation();
    const { playVoiceover } = useContext(VoiceoverContext);

    async function sequence() {
        await hooking.start({ y: -70, opacity: 1, transition: { duration: 1.2 } });
        await (hooking.start({ y: -150, opacity: 0, transition: { duration: 0.3, delay: 0.75 } }),
        hooked.start({ y: -100, opacity: 0, transition: { duration: 0.3, delay: 0.75 } }));
        await reveal.start({ opacity: 1 });
        toaster.notify(() => <CustomToast>{t`-${pointsLost} Points`}</CustomToast>, {
            duration: 2000,
            position: 'top',
        });
        setFooter(<Button onClick={handleClose}>{t`Next`}</Button>);
    }

    useEffect(() => {
        const phished = setTimeout(() => playVoiceover('phished-words'), 1000);
        sfx.synthFail.play();
        const timer = setTimeout(() => {
            sequence();
        }, 2000);
        return () => {clearTimeout(timer); clearTimeout(phished)}
    }, []);

    const handleClose = () => {
        gameData.removeLife();
        gameData.addPoints(-pointsLost);
        onClose();
    };
    return (
        <ModalOverlay show={show} header={<></>} footer={footer} backgroundColor="#9E0F6F" headerHidden={true}>
            <Card>
                <Wrapper>
                    <Phishing>
                        <Avatar src={gameData.avatar} alt="Avatar" width="200" animate={hooked} />
                        <Hook src={hook} alt="Hook" width="50" animate={hooking} initial={{ y: -340, opacity: 0 }} />
                    </Phishing>
                    <Details animate={reveal}>
                        <Result>
                            <Lives>
                                <img src={heart} alt={'Heart'} width={'75'} />
                                <span>-{livesLost}</span>
                            </Lives>
                            <Points>
                                <img src={star} alt={'Star'} width={'75'} />
                                <span>-{pointsLost}</span>
                            </Points>
                        </Result>
                        <Summary>
                            <img src={heart} alt={'Heart'} width={'50'} />
                            <span>{gameData.lives}</span>
                            <img src={star} alt={'Star'} width={'50'} />
                            <span>{gameData.points}</span>
                        </Summary>
                    </Details>
                </Wrapper>
                <Title>
                    <motion.p
                        animate={{ opacity: [0, 1, 1, 0], x: [200, 20, -20, -200] }}
                        transition={{ duration: 2, delay: 1, times: [0, 0.3, 0.7, 1] }}
                        className={'text-center mt-4 mb-0'}
                    >{t`You got`}</motion.p>
                    <motion.h1
                        animate={{ opacity: [0, 1, 1, 0], x: [-200, -20, 20, 200] }}
                        transition={{ duration: 2, delay: 1.3, times: [0, 0.3, 0.7, 1] }}
                        className={'text-center'}
                    >{t`PHISHED`}</motion.h1>
                    {explanation ? (
                        <Explanation animate={{ opacity: [0, 1] }} transition={{ delay: 3, duration: 0.3 }}>
                            {explanation}
                        </Explanation>
                    ) : null}
                </Title>
            </Card>
        </ModalOverlay>
    );
};

const StyledToast = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    color: #fabe01;
    position: relative;
`;

const Card = styled.div`
    background-color: #872666;
    width: 100%;
    max-width: 450px;
    display: block;
    margin: 5px auto 0 auto;
    padding: 15px;
    border: 7px rgba(0, 0, 0, 0.05) solid;
    overflow: hidden;
`;
const Explanation = styled(motion.p)`
    padding: 1rem;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: center;
`;

const Avatar = styled(motion.img)`
    width: 250px;
    display: block;
    margin: 1rem auto;
    max-width: 100%;
    position: relative;
    z-index: 5;
`;

const Hook = styled(motion.img)`
    left: calc(50% - 25px);
    position: absolute;
    bottom: 0;
    width: 50px;
`;

const Phishing = styled.div`
    position: relative;
`;

const Lives = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 10px;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-family: 'BT Font Extra Bold';
    }
`;
const Points = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 10px;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-family: 'BT Font Extra Bold';
        color: #612300;
    }
`;
const Details = styled(motion.div)`
    text-align: center;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
`;

const Summary = styled.div`
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    text-align: center;
    display: inline-block;
    padding: 10px 20px;
    img {
        width: 30px;
        margin: -3px 5px 0 10px;
    }
    span {
        font-family: 'BT Font Extra Bold';
        margin: 0 10px;
    }
`;

const Result = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`;

const Title = styled(motion.div)`
    max-width: 320px;
    display: block;
    margin: 2rem auto 1rem auto;
`;

const Wrapper = styled.div`
    max-width: 320px;
    display: block;
    margin: 1rem auto;
    position: relative;
`;

export default Phished;
