import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import vault_f from '../../../../../assets/misc/th-vault--front.svg';
import vault_b from '../../../../../assets/misc/th-vault--back.svg';
import vault_room from '../../../../../assets/misc/th-room--vault2.svg';
import { motion } from 'framer-motion';

const VaultAnimOpen: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showFooter, setShowFooter] = useState(false);
    const next = showFooter ? <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button> : null;

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 4000);
    }, []);

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
                    <h3 className={'my-3 text-center'}>{t`VAULT UNLOCKED`}</h3>
                    <div className="th-vanim">
                        <motion.img
                            src={vault_f}
                            alt="Front of Vault"
                            className="th-vanim__v th-vanim__v--front"
                            animate={{
                                rotate: [0, 30, 30],
                                opacity: [1, 1, 0],
                            }}
                            transition={{
                                delay: 0.5,
                                duration: 2,
                                times: [0, 0.9, 1],
                            }}
                        />
                        <motion.img
                            src={vault_b}
                            alt="Back of Vault"
                            className="th-vanim__v th-vanim__v--back"
                            animate={{
                                opacity: [1, 0],
                            }}
                            transition={{
                                delay: 2,
                                duration: 1,
                            }}
                        />
                        <motion.img
                            src={vault_room}
                            width={'100px'}
                            height={'100px'}
                            alt="VaultRoom"
                            className="th-part__room"
                            animate={{
                                scale: [0.5, 1],
                            }}
                            transition={{
                                delay: 3.5,
                                duration: 2,
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Slide>
    );
};

export default VaultAnimOpen;
