import React, { useState, useEffect, useContext } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import { CyberSecurityData } from '../..';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const CharacterSelector: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {

    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-avatar')
    }, [])


    let next = (
        <Button
            className="btn-3d"
            onClick={() => {
                nextSlide();
            }}
        >
            { t`Select` }
        </Button>
    );
    const [selectorIndex, setSelectorIndex] = useState(0);

    useEffect(() => {
        data.setAvatar(data.avatars[selectorIndex]);
    }, [selectorIndex]);

    const nextAvatar = () => {
        if (selectorIndex > data.avatars.length - 2) {
            setSelectorIndex(0);
        } else {
            setSelectorIndex(selectorIndex + 1);
        }
    };

    const prevAvatar = () => {
        if (selectorIndex === 0) {
            setSelectorIndex(data.avatars.length - 1);
        } else {
            setSelectorIndex(selectorIndex - 1);
        }
    };

    return (
        <Slide header={defaultHeader} footer={next}>
            <h2 className={'mt-3 text-center'}>{t`Choose an Avatar`}</h2>
            <Selector>
                <Arrow onClick={prevAvatar} style={{ left: 0 }}>
                    <i className={'fa fa-arrow-left'}></i>
                </Arrow>
                <Arrow onClick={nextAvatar} style={{ right: 0 }}>
                    <i className={'fa fa-arrow-right'}></i>
                </Arrow>
                <Avatar
                    src={data.avatars[selectorIndex]}
                    width="300"
                    animate={{ y: [0, 15, 0] }}
                    transition={{ ease: 'easeInOut', duration: 6, loop: Infinity }}
                />
                <Shadow />
            </Selector>
        </Slide>
    );
};

const Avatar = styled(motion.img)`
    display: block;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;
`;
const Selector = styled.div`
    width: 100%;
    position: relative;
    max-width: 600px;
    display: block;
    margin: 1rem auto;
`;

const Shadow = styled.div`
    width: 200px;
    max-width: 100%;
    height: 100px;
    left: calc(50% - 100px);
    border-radius: 50%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.4;
    -webkit-filter: blur(10px);
    -webkit-transform: scale(1, 0.2);
    z-index: 0;
`;

const Arrow = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
`;

export default CharacterSelector;
