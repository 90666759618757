import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Slide, { ISlide } from '../../../core/Slide';
import { t } from 'ttag';
import Cookies from 'js-cookie';
import title from '../assets/start/title.svg';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.15,
            },
        },
    };
    const pathVariants = {
        hidden: {
            fill: 'rgba(255,255,255,0)',
            pathLength: 0,
        },
        visible: {
            fill: '#E2D7FF',
            pathLength: 1,
            transition: {
                duration: 1,
                ease: 'easeInOut',
            },
        },
    };
    const snowVariants = {
        hidden: {
            opacity: 0,
            y: -20,
            scale: 0.7,
        },
        visible: {
            y: 0,
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.6,
                ease: 'easeInOut',
            },
        },
    };
    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Welcome>
                {/* <img src={title} className="w-100 mb-4"/> */}
                <SVGContainer>
                    <StartSVG
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        viewBox="0 0 865 388"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g>
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M321.1,71.2c3,25.7-1.3,49.8-18.1,70.2c-14,16.8-35.8,21.2-57.7,13.4c-24.8-8.8-40.3-30.5-41.2-60.1
                        c-0.1-2,0.1-3.9,0.4-5.9c1-6,2-12,2.6-18.1c1-8.3,2.3-16.8,1.9-25.2c-0.4-9.1,2.9-16.4,8.3-22.8c1.9-2.2,6.2-3.3,9.4-3.6
                        c4.6-0.5,7.7-1.9,9.2-6.5c1.3-4.3,4.6-5.8,9-6.3c8.6-1.3,17.7-0.9,25.7-5.9c1.7-1.1,5.5-0.3,7.2,1c6.5,4.9,12.9,9.8,21.4,10.8
                        c1.6,0.2,3.8,1.9,4.3,3.3c1.1,3.7,3.4,5.5,6.8,6.5c4.6,1.3,6.2,4.7,6.4,9.3c0.2,5.5,1.1,11.2,1,16.7c-0.1,2-2.2,5.3-3.8,5.7
                        c-11,1.8-22.1,3.3-33.3,4.3c-1.8,0.2-4.7-2.2-5.7-4.2c-2.8-5.8-5-7.1-10.8-4.3c-11.7,6-18.1,16.3-20.8,28.8
                        c-2.8,12.8-2.7,25.2,5.7,36.6c2.9,3.9,6,4.2,9.6,3.1c3.8-1.2,7.1-1.1,10.7,1c3.2,1.9,5.8,1,7.2-2.7c1.2-3,2.7-5.8,3.7-8.7
                        c1.5-3.9-0.4-5.2-4.2-4.9c-3.9,0.3-7.8,0.4-11.7,0.6c-3.7,0.2-5.9-1.5-5.8-5.2c0.3-7.2,0.6-14.5,1.3-21.7c0.4-4.5,3.6-6.8,7.9-7.2
                        c15-1.5,30.1-3,45-4.2C317.2,64.5,320.5,66.5,321.1,71.2z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M278.9,117.8c0.2-0.3,0.6-0.4,1-0.3l0,0c0.6,0.1,0.9,0.7,0.8,1.2c-0.4,1.7-0.8,3.3-1.3,5
                        c-0.8,2.9-3.8,2.2-6.6,3.2l0,0c-0.1,0-0.1,0-0.2,0.1c-2.9,1.5-5.6,3.4-7.8,5.9c-1.1,1.3-1.1,3.4-1.1,5.5c0,3.2-2.7,2.5-3-0.7l0,0
                        c-0.2-2.1,0.1-4.5-0.7-6.3c-0.5-1.1-1.3-2.1-2.2-3.1c-1.3-1.5-3.6-1.6-4.9-0.1l0,0c-1,1-2.5,1.4-3.8,0.7c-0.2-0.1-0.4-0.3-0.6-0.4
                        c-3.7-2.3,4.8-6.7,9.1-7.4c1.4-0.2,2.9-0.6,4.3-1.1c1.2-0.3,2.8,0.2,4.1,0.6C274,123.4,274.3,123.4,278.9,117.8z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M306.6,23.3c7.9,1.8,8.2,5.4,7.5,13.4c-0.4,4.3-1.9,8.5-2.9,12.7c-0.1,0.6-0.7,1-1.3,0.9l0,0
                        c-0.5,0-0.9-0.4-0.9-1c-0.2-2.6-0.3-5.3-0.9-7.8c-0.4-1.5-0.9-2.9-1.5-4.2c-0.9-2-3.4-2.6-5-1.1l0,0c-1.6,1.4-4.2,0.8-6.2,0
                        c-0.8-0.3-1.6-0.4-2.5-0.3c-2.2,0.2-4.5,0.3-6.2-1.1c-3.3-2.9-6.2-5.7-9.5-7.8c-4.5-3-8.6-1.8-12.2,2.5c-6.6,8-8.3,8.1-16.4,1.7
                        c-4.5-3.5-6.5-3-9.6,2c-1.1,1.7-2.3,4.2-4.1,4.7c-6.8,1.8-7,7-7.6,12.4l0,0c-0.2,2.2-2.7,3.3-4.5,4.5c-1.4,0.9-1.9,2.9-2.2,5
                        c-0.9,6.2-1.3,12.4-2,18.6l0,0c-0.1,0.8-1.6,1-2,0.3c-1.7-3.7-3.4-7.5-5.2-11.4c-0.4-1-1.4-2.1-1.2-2.9c3-12.3-2.1-26,6.8-37.3
                        c2.8-3.5,5.5-6,10.1-5.4c5.4,0.5,8.7-1.8,10.6-6.9c1.2-3.3,3.3-5,7-5.3c6-1,11.9-2.1,17.8-3c0.7-0.1,1.6,0.1,2.1-0.3
                        c8.1-7.4,13.8-0.4,20,3.3c3.4,2,7.5,2.8,11,4.6c2.2,1.2,4.2,3.1,6.1,4.8C303.6,20.2,304.7,22.9,306.6,23.3z"
                            />
                        </g>
                        <g id="letter-if">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M466.9,10.9c-2.1-4.1-4.8-6.2-9.4-6.4c-3.3,0-6.6-0.7-9.9-1.1c-3.8-0.3-7.9-1.3-11.6-0.7
                        c-3.9,0.6-7.6,3.1-11.4,4.2c-2.6,0.7-6.6,1.4-7.9,0.1c-5.2-5.4-10.9-5.9-17.4-3.3C398.2,4,396.9,4,395.7,4
                        c-4.3-0.1-7.4,1.4-9.8,5.1c-3,4.5-10.5,1.6-14.9-1.6c-2.7-1.9-6-2.7-9.7-2.9c-1.7-0.1-3.4-1.2-5.1-1.4c-1.9-0.2-3.8-0.5-5.7-0.8
                        c-0.2,0-0.4,0-0.6,0.2c-0.8-0.1-1.6-0.3-2.4-0.4c-1.8-0.3-3.6,0-5.4,0c-6.4,1.2-13,4.7-13.3,11.8c-0.3,6.4-5.2,13.9,2,19.7
                        c0.3,0.3,0.2,1.4,0.2,2c-0.1,22.4-0.1,44.8-0.2,67.2c-0.1,14.8-0.3,29.5-0.1,44.2c0.1,8.6,3.1,11.2,11.9,11.3
                        c6.1,0.1,12,0,18.1-0.1c9.7-0.2,11.1-1.6,11.2-11.2c0.3-25.5,0.5-51.1,0.9-76.7c0.1-11.7,0-23.5,0.5-35.2c0.2-3.2,0.9-8,3.2-10.3
                        c0.2-0.2,0.5-0.5,0.7-0.8c4.3,1.2,3.7,4.1,3.7,6.7c0.1,25.5,0.1,50.9,0.2,76.2c0.1,15.1,0.2,30.3,0.7,45.3c0.2,7,2.8,8.5,9.7,7.9
                        c7.6-0.7,15.2-1.4,22.8-1.9c6.6-0.4,7.4-1.1,7.1-7.6c-0.4-12.9-0.7-25.9-1.4-38.8c-0.2-4.4,0.9-6.6,5.7-6.8
                        c6.6-0.2,13.1-1.4,19.6-1.8c4.1-0.2,6-2.1,6.1-5.9c0.2-6.1,0.3-12,0.3-18.1c0-3.1-1.6-4.8-5-4.7c-4.1,0.1-8.2,0-12.3,0
                        c-15.5,0-15.4,0-15.4-15.5c0-4.9,1.5-6.9,6.7-6.9c11.3-0.1,22.7-0.5,34-1.4c2.3-0.1,6.4-2.3,6.5-3.8
                        C467.3,35.2,473.4,23.1,466.9,10.9z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M374.2,16.5c0.6,2.8,1,5.3,0,7.5c-0.6,1.7-2,3.1-4.5,4.2c-1,0.4-2,1.7-2.2,2.8c-0.9,3.9-2.8,5.5-7,5.3l0,0
                        c-3.9-0.2-9.6-0.4-10.7,3.4c-0.2,0.7-0.4,1.8-0.4,3.1c-0.2,3.4-0.4,6.9-0.6,10.2c0,0.5-0.4,0.9-0.9,0.9l0,0c-0.5,0-0.9-0.4-0.9-0.8
                        c-0.2-2.4-0.3-4.8-0.6-7.3c-0.4-3.7-1.3-6.9-6.2-6.9c-2.7,0-10.6-11.4-10.2-14.1c0.1-1.3,0.3-2.5,0.3-3.7
                        c0.2-11.7,8.6-18.8,19.1-17.2c1.6,0.2,3.3,0.6,4.9,1.3c3.1,1.2,6.4,1.9,9.7,2.5C369.2,8.6,372.9,11.3,374.2,16.5z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M467,17.2c2,3.1,1.8,5.3-1.5,7.6l0,0c-2.6,1.7-5.4,2.1-8.4,1.1c-2.3-0.8-4.9-0.2-8.6,1.8
                        c-1.1,0.5-2.3,1.1-3.4,1c-4.8-0.4-9.6-1.6-14.4-1.6c-7.4-0.1-14.8,0.3-22.2,0.7c-0.6,0-1.6,1.7-1.8,2.8l0,0
                        c-0.5,1.9-2.5,2.8-3.9,1.3c-1.5-1.6-3.1-3.3-4.8-4.7c-1.9-1.6-3.6-1.8-5.1-1.3c-3.6,1.2-7.2,1.4-10-1.2l0,0
                        c-4.6-4.1-4.7-4.5-0.2-8.3c0.4-0.3,1-0.6,1.2-1.1c4.4-8.7,13-9.2,21.1-10.2c2.1-0.3,5.4,0.2,6.7,1.7c5.4,6,10.9,4.2,16.8,1.4
                        c3.5-1.6,7.2-2.7,11-3.6c1.4-0.3,3.1,0.1,4.7,0.4c4.3,0.9,8.4,2.1,12.8,2.5c4.1,0.2,6.7,1.5,8,5.5C465.6,14.4,466.2,16,467,17.2z"
                            />
                        </g>

                        <g id="letter-t">
                            <motion.path
                                variants={pathVariants}
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#E2D7FF"
                                d="M596,41.6c0-10.9-0.2-21.9-1.2-32.7c-0.5-6.2-9.4-10.2-13.6-6.5
                        c-4.6,3.7-8.6,3.3-13.2,1c-4.8-2.4-9.9-3.1-14.8-0.6c-7.6,3.7-15,3.2-22.7,0.6c-2.2-0.7-4.3-1.2-6.5-1.3c-0.2,0-0.3,0-0.5,0.1
                        c-0.2-0.1-0.4-0.2-0.6-0.2c-2.5,0.2-5,1-7.4,2.6c-2.4,1.5-6.9,0.9-9.7-0.3c-6.9-3.1-12.9-0.9-18.9,1.4c-2.7,1-5.4,2-8.2,2.6
                        c-1.2,0.2-2.4,3.4-2.5,5.2c0,0.6,0,1.2-0.1,1.8c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.4,0.2,0.5c0,0.1,0,0.1,0,0.2
                        c-0.2,8.4-0.3,16.8,0,25.2c0.2,4.5,3.9,5.9,8.1,5.7c7.8-0.4,15.5-1,23.3-1.6c7.3-0.5,9.2,0.4,9.3,7.8c0.2,11.3,0.2,22.6,0.1,33.9
                        c0,4.5,0,8.9,0,13.4c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.2c0,5,0,9.9,0,14.9c0,10.1,0,20.2,0,30.3c0,8.4,2.5,10.8,10.8,11
                        c3.1,0.1,6.2,0,9.2,0c1.5,0,3.1,0,4.6,0c6.6,0,9.5-1.9,10.3-8.6c1-7.6,1.2-15.4,1.3-23.2c0.3-12,0.5-23.9,0.6-35.9
                        c0.2-12,0.4-24,0.6-35.9c0.2-7.5,1.9-9,9.4-8.9c7.8,0.2,15.5,0.6,23.3,1.4C593.3,47.5,596,46.6,596,41.6z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M592.5,11.6c0.2,0.4-0.6,1.4-1.3,2c-1.7,1.7-3.2,3.5-5.2,4.8c-1.4,0.9-4.3,1.7-4.8,1.1
                        c-1.4-1.6-2.6-2.4-3.9-2.5c-2.8-0.3-6.1-0.8-8.8-1.7l0,0c-2.7-0.9-6.6-0.8-9.4-1.3c-1.5-0.3-3.2,0.4-5.1,1.9
                        c-3.8,3.1-8,2.9-12.5,0.6c-2.1-1.1-5.9-1.4-7.6-0.2c-1.1,0.8-2,1.4-2.7,1.9c-3.9,2.6-10.1,4.1-10.5,8.8c-0.1,1.6-0.3,3.1-0.4,4.6
                        c0,0.4-0.4,0.7-0.8,0.8l0,0c-0.3,0-0.6-0.2-0.7-0.5c-0.4-1.6-0.8-3.3-1.4-4.9c-1.7-4.4-4.6-6.8-9.5-5.2c-4.2,1.4-6.4-0.1-7.6-4.2
                        c-1.3-4.2-3.4-6.4-8.3-4.3c-2.6,1.2-5.8,1-8.1,2.3c-0.6,0.3-1.1,0.8-1.5,1.2c-2,2-5.2,1.6-5.2-1.2l0,0c0-5,3.2-7.5,9.6-8.1
                        c1.9-0.2,3.7-1.3,5.7-1.4c3.7-0.2,7.7-0.7,11.1,0.3c4.7,1.5,9,2.2,13.1-1c1.9-1.4,3.8-1.9,5.8-1.9c2.6,0,5.1,0.9,7.5,1.7
                        c8,2.9,15.6,3,23.1-0.7c4.8-2.3,9.4-1.5,13.9,0.5c5.1,2.3,9.8,3.2,14.8-1.1c1.3-1.2,5.4-0.7,7,0.5C590.7,6.1,591.4,9.1,592.5,11.6z
                        "
                            />
                        </g>
                        <g id="letter-w">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M172.7,209.5c4.9-1,8.5,2.6,8.3,7.9c-0.1,3-0.7,6.2-2.1,8.7c-6.5,11.6-7.8,24.6-10.4,37.2
                        c-6.3,29.4-12.4,59.1-18.6,88.6c-1.9,9.1-5.5,11.8-14.7,12.3c-6.4,0.3-12.8,1.1-19.1,1.3c-7.6,0.3-10.9-1.7-13.3-8.8
                        c-5.2-15.1-10-30.3-14.9-45.5c-0.7-1.9-1.3-3.8-2-5.7c-0.2-0.4-0.6-0.7-1-0.7l0,0c-0.5,0-0.9,0.3-1,0.8c-3.6,12.6-7.2,25.4-10.8,38
                        c-1.5,5.1-2.8,10.2-4.4,15.4c-1.2,3.6-3.6,5.3-7.7,5.1c-4.1-0.2-8.2,0-12.2,0.7c-12.4,2.1-17.8-1-20.4-13.2
                        c-2.9-13.6-5.1-27.5-7.7-41.4c-4.4-23-8.5-46.1-13.2-69C6.2,235,3.7,229,1.9,223c-0.7-2.6-1.4-5.2-1.3-7.8c0.1-4.8,2.7-7.7,7.7-7.8
                        c3,0,6,0.6,9,0.7c5.4,0.1,11,0.7,16.3,0c8.2-1.3,10.2,0.4,11.3,8.7c2.7,22.1,5.9,44.1,8.8,66.2l0,0c0.2,1.2,2,1.4,2.6,0.3
                        c0.2-0.3,0.3-0.6,0.4-0.9c3.4-13.2,6.9-26.3,10.1-39.6c1.5-6.2,6.7-9.5,13.8-8.5c4.9,0.7,9.9,1.5,14.7,1.2c5.1-0.3,6.8,1.8,7.9,6.3
                        c3.6,14.3,7.5,28.5,11.6,42.5c0.9,3,2.9,5.7,4.4,8.6c0.3,0.5,0.9,0.9,1.5,0.8l0,0c0.7,0,1.2-0.5,1.5-1.1c1.4-3.7,3.3-7.2,4.1-11
                        c2.8-14.5,5.5-28.9,7.6-43.5c1.1-7.6,0.6-15.2,0.5-22.9c-0.1-6.5,3.1-10,9.2-7.8C153,211.3,162.6,211.4,172.7,209.5z"
                            />

                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M42.3,217c1.8,9.4,3.2,18.8,1.6,28.3c-0.4,2.3-0.5,4.6-0.8,6.9c-0.1,0.5-0.4,0.9-0.9,0.9l0,0
                        c-0.5,0.1-1.1-0.2-1.3-0.7c-1-2.5-2.2-4.9-2.9-7.4c-1.2-5.1-2.8-9.3-9.5-7.9c-1.3,0.2-3.2-1.1-4.3-2.2c-1.8-2-2.9-6.1-4.7-6.3
                        c-3.4-0.4-7,1.2-10.7,2l0,0c-1.4,0.3-2.8,0.4-3.1-1c-1.2-4.6-2.5-8.7-3.3-12.9c-0.7-4.1,1.5-6.5,5.7-6.5c3.2,0,6.3,0.6,9.5,0.7
                        c5.1,0.1,10.2,0.6,15.1-0.1C40.1,209.6,40.9,210.3,42.3,217z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M98.9,238.9c2.1,1.6,3.6,13.9,2.8,16.3c-1.1,3.1-1.2,6.5-2,9.6c0,0.1-0.1,0.2-0.1,0.3
                        c-0.7,1.9-4,1.8-4.5-0.1l0,0c-0.4-1.5,0.1-3.3-0.5-4.7c-0.5-1.3-1.3-2.5-2-3.8c-0.8-1.3-2.7-1.5-3.9-0.6l0,0
                        c-1.2,0.9-2.8,0.5-3.6-0.8c-0.4-0.6-0.8-1.3-1.2-1.9c-1.4-2.1-4.3-2.4-5.9-0.5l0,0c-1.2,1.5-3.4,1.7-3.6-0.2
                        c-0.1-0.8-0.2-1.7-0.2-2.6c-0.4-4-5.8-8.6-2.7-11.3c1.3-1.2,3.3-1.7,6-1.6c6.5,0.1,13,0.7,19.5,1.2
                        C97.6,238.4,98.3,238.6,98.9,238.9z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M171.3,212.1c5.3-0.9,8.4,2,7.1,7.2c-0.4,1.8-1,3.4-1.7,5.2c-1.1,2.7-5.6,2.2-8,4l0,0
                        c-2.4,1.9-4.8,1.7-7.4,0c-0.7-0.5-1.5-0.6-2.3-0.3c-1.6,0.7-3.1,1.7-4.8,1.7c-1.4,0-2.7,0-4.1,0c-7.3,0-13-5.9-13.2-13.2l0,0
                        c-0.2-6.4,1.5-7.6,7.8-6.2c6.3,1.3,12.8,2,19.2,2.7C166.4,213.4,168.8,212.4,171.3,212.1z"
                            />
                        </g>
                        <g id="letter-r">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M276.8,336.4c6.4,7.4,6.4,10.2,0,17.5c-1.9,2.1-4.1,4.1-6.3,5.7c-6.4,4.6-12.3,4.6-17.6-1.2
                        c-7.9-8.4-15.1-17.5-22.6-26.2c-1.6-1.9-3.2-3.8-4.7-5.8c-0.5-0.6-1.3-0.8-2-0.4l0,0c-0.5,0.3-0.9,0.8-0.9,1.4
                        c0,6.5-0.3,13,0.1,19.5c1,15.2-1.6,18.3-16.8,18.7c-16.6,0.4-18.4-1.3-18.3-17.8c0.2-32.1,0.7-64.3,0.9-96.4
                        c0-9.5,0.6-19.1-2.9-28.4c-2-5.4,0-8.1,5.8-9.9c6.2-1.9,12.4-4.5,18.8-5.5c4.5-0.9,9.3,0,14,0.4c4.9,0.5,9.8,1.1,14.5,2.3
                        c8.2,2.5,16.5,4.8,24.2,8.5c5.1,2.5,6,8.4,6.6,13.9c0.6,5.4,1.2,10.9,2,16.2c3,19.1-3.4,34.1-19.1,45.3l0,0c-4.3,3-5,9.1-1.5,13
                        C259.8,317.1,268.4,326.8,276.8,336.4z M234.2,266.8c3.9-7.2,1.4-16.3-5.5-20.5c-3.1-1.8-5.5-1.3-5.7,2.3
                        c-0.1,4.1-0.2,8.2-0.2,12.3C222.8,268.6,230.6,273.5,234.2,266.8L234.2,266.8z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M261.5,220.8c3.6,2.5,5.6,7.5,6,12.5c0.5,6.7-6.9,10-13,7.3l0,0c-1.7-0.7-3.6-2.2-4.4-3.9
                        c-2.1-4.3-4.8-6.3-9.8-4.5c-1.6,0.5-3.9,1-5.1,0.1c-4.6-3.1-9.1-1.5-13.8-0.7c-3.1,0.5-6.5-0.4-9.7-1.2c-2-0.5-3.8-1.8-5.8-2.7
                        c-6.5-2.9-9.7-1.1-10.8,5.9l0,0c-0.3,1.9-3,2-3.6,0.2c-1.4-4.2-2.5-7.8-3.7-11.5c-1.1-3.6,0.7-5.8,4.2-6.7
                        c7.1-2.1,14.2-4.7,21.4-6.2c3.4-0.6,7.2,0.7,10.9,1.3c4.5,0.6,9,1.8,13.4,2C246.4,213.1,254.4,215.9,261.5,220.8z"
                            />
                        </g>
                        <g id="letter-a">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M402.3,349.8c2.2,10.4,1,12.2-9.5,12.9c-5.1,0.4-10.2,0.7-14.7,1.1c-10.1-0.1-11.3-0.9-14.7-9.8
                        c-2.7-6.9-5.1-14-7.5-21c-1.5-4.5-4.3-6.4-9.1-6.4c-14.8,0-14.7-0.1-18.8,14.3c-1.5,5.3-3,10.6-4.6,15.9c-1.5,4.8-4.8,7.4-9.9,7.1
                        c-7.6-0.3-15.2-0.7-22.8-1.2c-6.6-0.3-7.9-1.9-6.2-8.3c3.7-14.1,7.6-28,11.4-42.1c3.2-11.6,7-23.1,9.6-34.9
                        c1.6-7.1,1.4-14.7,1.6-22.1c0-1.7-1-4.1-2.1-5.2c-6.4-5.9-6-14.9,0.9-20.5c3-2.5,5.2-6,8.1-8.7c1.4-1.4,3.3-3.1,5.1-3.1
                        c5.1-0.1,8.7-2.3,12.2-5.8c6.9-6.9,12.8-6.9,19.5,0.4c3.6,4.1,7.7,6.6,13.2,6.7l0,0c1.1,0,2,0.5,2.9,1.2
                        c5.5,3.9,11.4,7.3,15.9,12.2c2.3,2.5,3.4,8.6,1.9,11.6c-3.8,8.2-3.4,16.6-1.7,24.4c5.7,26.1,12.4,52,18.8,78
                        C402.2,347.7,402.1,348.8,402.3,349.8L402.3,349.8z M345.1,297.9c2.8,0.5,4.7-0.7,4.1-3.8c-1.5-7.7-3.2-15.3-4.8-22.9
                        c-0.1-0.5-0.6-0.9-1.1-0.9l0,0c-0.5,0-0.9,0.3-1,0.8c-1.4,5.7-2.7,11.4-4.3,17C335.3,297.6,336.3,296.2,345.1,297.9z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M380.8,233.1c3.4,3,3.8,7.1,1.4,12c-1.9,3.8-1.3,9-1.7,13.4c-0.2,1.7-0.1,3.4,0,5.2c0,0.6-0.4,1.1-1,1.1
                        l0,0c-0.6,0-1.2-0.4-1.4-1c-0.4-1.3-0.8-2.6-0.6-3.7c1.1-5-2.3-8.1-4.5-11.6c-0.4-0.6-3.6-0.6-4.5,0.2c-6.9,6.6-10.6,6.7-16.5-0.4
                        c-2.9-3.5-5.7-4.8-9.1-0.7c-2.6,3-4.3,2.7-6.2-1.1c-0.7-1.4-3.9-1.6-6.1-2c-0.5-0.1-1.3,0.9-1.9,0.7c-4-0.4-6,1.2-6.8,3.6
                        c-0.5,1.3-2.3,2.1-3.2,1l0,0c-0.7-0.9-2.3-0.7-2.7,0.4c-0.6,1.5-1.2,3-1.4,4.6c-0.6,6.9-0.9,13.9-1.5,20.7
                        c-0.2,1.4-0.9,2.8-1.6,4.2c-0.2,0.5-0.8,0.8-1.3,0.7l0,0c-0.5-0.1-0.9-0.5-1-1c-0.2-1.5-0.5-3-0.5-4.5c-0.1-5.5,0.1-11,0-16.5
                        c-0.1-4.5-0.2-8.4-4.9-11.4c-4.2-2.8-1.7-12,3.1-15.9c2.9-2.3,5.1-5.5,7.9-8.2c1.6-1.5,3.6-3.4,5.4-3.4c5,0.1,8.3-2.3,11.5-5.5
                        c6.8-6.7,11.4-6.8,17.8,0.5c3.5,4.1,7.5,6.2,12.7,6.4l0,0c1.1,0,2,0.5,2.9,1.1C370.5,225.5,376,228.9,380.8,233.1z"
                            />
                        </g>
                        <g id="letter-p">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M492.1,228.3c8.2,16,9.8,31.7,3.1,48.1c-7.9,19.3-23.9,27.8-43.4,30.7c-8.3,1.2-8.6,1.4-8.3,10.6
                        c0.3,11.3,0.5,22.7,0.3,34c-0.2,11.2-0.6,11.6-11.7,12.5c-5.4,0.4-11-0.1-16.4-0.1c-4.6,0-6.9-2.3-6.9-6.9c0-6.6,0.1-13.1,0.2-19.7
                        c0-14.3,0-28.7,0-43c0-0.1-0.1-0.2-0.2-0.2l0,0c-0.1,0-0.2-0.1-0.2-0.2c0-19.5,0-38.9-0.1-58.5c0-2.6-0.5-5.2-1.4-7.7
                        c-1.9-6.2,1.1-9.9,5.9-13.9c6.7-5.5,13.1-6.7,21.4-5.9c7.2,0.7,14.7-1.4,22.1-2.1c1.9-0.2,4.2-0.4,5.8,0.4
                        C472.9,212.8,485.5,215.5,492.1,228.3z M452,271c5.3-2.9,9.7-6.6,9.5-13.5c-0.2-5.5-5.7-13.5-10.8-15.5c-3.3-1.3-6.2-0.6-6.9,3.3
                        c-0.7,4.1-1,8.3-1.5,12.4c0,0.1,0.1,0.2,0.2,0.2l0,0c0.1,0,0.1,0.1,0.2,0.1c0.1,2.6,0.2,5.2,0.4,7.8
                        C443.4,271.9,446.5,273.8,452,271z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M481.1,218.5c4.2,2.1,7.4,7.6,6.8,12.3c-0.1,1-0.2,2.1-0.4,3.1c-0.4,2.5-2.7,2.2-3.8-0.1l0,0
                        c-1-1.9-2.1-2.5-3.4-2.3c-2.3,0.4-4.5-0.1-6-1.9l0,0c-1-1.3-2.6-2.8-4.1-2.9c-5.8-0.2-11.3-0.4-16,4.8c-1.5,1.7-6.7,1-9.9,0.1
                        c-5.2-1.5-10.1-3.1-15.5-0.6c-1.1,0.5-3.2,0.2-3.8-0.5c-0.5-0.5-1-1-1.5-1.3c-3.3-2.3-9.2-0.8-12.5-3.2l0,0
                        c-0.7-0.5-1.2-1.1-1.6-1.9c-1.1-2.6,0.3-4.8,3.8-7.2c6.5-4.5,12.6-6.7,20.9-5.8c7.7,1,15.8-1.7,23.7-2.1c2.8-0.2,5.9,1,8.5,2.1
                        C471.6,213.3,476.3,216,481.1,218.5z"
                            />
                        </g>
                        <g id="letter-p_1_">
                            <motion.path
                                variants={pathVariants}
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#E2D7FF"
                                d="M562.7,208.5c-0.1,0-0.1-0.1-0.2-0.1c-2.3-0.9-5.4-2-7.3-2.1
                        c-5.2-0.5-10.9-0.9-15.8,0.7c-3.6,1.2-7.5,1.1-11.3,1.1c-6.7-0.1-13.5-0.1-19.1,6.2c-1.3,1.5-3.5,4-4.8,5.9c0,0,0,0,0,0
                        c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-1,2.1-1.3,4.3-0.4,7l0.1,0.3c0.6,1.8,1.3,3.9,1.3,5.8c0.1,20.1,0.1,40,0.1,60
                        c0,0.1,0.1,0.3,0.3,0.3c0.1,0,0.3,0.1,0.3,0.3v45c0,3.1-0.1,6.1-0.1,9.1c-0.1,3-0.1,6-0.1,9c0,4.3,2.2,6.5,6.6,6.5
                        c2.2,0,4.3,0,6.5,0.1c4.4,0.1,8.8,0.1,13.1-0.1c6.8-0.3,8.3-1.9,8.4-8.8c0.2-14.1,0.2-28,0-42.1c0-4.5,1.7-5.6,6-6
                        c13.5-0.9,25.8-5.3,35.4-15.1c19.4-19.9,19.4-49.7,1.2-72.8c-0.9-1-2.2-1.7-3.6-2.4c-0.6-0.3-1.2-0.6-1.7-0.9
                        c-1-0.5-1.9-1.1-2.9-1.6c-2.5-1.4-5.1-2.9-7.8-4c-0.6-0.3-1.2-0.5-1.8-0.8c-0.6-0.3-1.2-0.5-1.8-0.8
                        C562.9,208.5,562.8,208.5,562.7,208.5z M558.1,257.9c0,5.5-4.7,11.5-10.8,13.6c-4.8,1.6-6.9,0.2-7.6-4.5c-0.3-1.8-0.4-3.5-0.5-5.2
                        l0-0.2c0-1.2-0.1-2.5-0.2-3.6c0-0.1,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-1.3,0.3-2.7,0.5-4c0.3-2.8,0.6-5.6,1.1-8.3
                        c0.6-3.6,3.2-4.4,6.4-3.4C552.1,243.5,558.1,252,558.1,257.9z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M578.2,219c4.3,2.6,7,6.4,6.4,11.9c-0.1,0.5-0.2,1.1-0.2,1.7c-0.3,2.4-3.3,2.4-4.5,0.3
                        c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.8-0.4c-1.1-0.3-2.3,0.3-4.3,1.6
                        c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.2-0.7,0.2-1,0.1l0,0c-0.2-0.1-0.3-0.1-0.4-0.3c-1.3-2.1-2.8-6.4-3.6-6.2c-5,0.7-10.9-2.7-15,2.7
                        c-3.1,3.9-7.4,4.3-11.5,2.7c-5.5-2.1-11.1-3.8-17-1.1c-0.9,0.4-2.5,0.3-3-0.2c-1-1.1-2-1.9-2.9-2.2c-3.4-1.4-7.7,0.2-10.8-1.9l0,0
                        c-1.9-1.3-3.7-3.5-2.7-5.6c0.1-0.2,0.2-0.4,0.4-0.6c0.9-1.3,2.1-2.6,4.1-3.8c6.4-4.5,12.3-6.5,20.4-5.5c7.7,1,15.7-1.6,23.6-2.2
                        c2.2-0.2,4.7,0.3,6.8,1.3c0.2,0.1,0.5,0.2,0.7,0.3C567.4,212.9,573,215.8,578.2,219z"
                            />
                        </g>
                        <g id="letter-e">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M679.3,328.2c6.7-0.2,7.8,0.9,7.7,7.6c0,5.3-0.3,10.7-0.7,16c-0.5,5.8-3.3,9.1-9,9.4
                        c-19.5,1.2-38.9,2.5-58.4,2.7c-12.4,0.1-17.1-5.7-17.1-17.9c-0.1-18.8,0-37.7,0-56.5c0-0.2,0.2-0.4,0.5-0.4l0,0
                        c0.2,0,0.4-0.2,0.4-0.4c0-22.2-0.3-44.4,0.1-66.6c0.2-9.5,4.8-12,14.1-10.2c3.7,0.7,7.9,0.2,11.7-0.1c7.4-0.6,14.5-2,21.4-5.3
                        c2.1-1,5.3-0.5,7.8,0.2c7.9,2.1,15.7,4.4,23.2,7.1c1.9,0.6,3.9,3.7,3.9,5.9c0.3,7.6,0.1,15.2-0.5,22.9c-0.5,7.8-4.1,11-11.9,11.5
                        c-9.1,0.5-18,1.1-27.1,1.3c-3.5,0.1-5,1.5-5.9,4.8c-3.8,16.2-2.8,17.8,13.5,17.6c5.6-0.1,11.3-0.2,17.1-0.9
                        c5.1-0.5,7.2,1.9,7.4,6.6c0.1,4.1,0.4,8.2,0.5,12.3c0.1,8.8-2.6,11.5-11.3,11.6c-7.1,0.1-14.2,0.2-21.3,0.1c-4.5-0.1-6.5,2-6.5,6.4
                        c0,3.9-0.2,7.8-0.4,11.7c-0.3,3.5,1.2,4.7,4.6,4.5C655.2,329.1,667.2,328.6,679.3,328.2z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M678.1,331.2c4.3-0.2,6.6,1.3,6.1,5.6c-0.2,1.5-1.5,3.4-2.8,3.9c-5,1.7-10.1,3.4-15.4,0.4
                        c-4.3-2.3-8.5-3.2-13.2-1.3c-1.1,0.4-2.5,0.3-3.7,0.4c-9.4,0.2-9.4,0.2-12.4,9.1c-0.2,0.6-0.6,1.2-0.9,1.7
                        c-0.2,0.2-0.4,0.4-0.7,0.3l0,0c-0.2-0.1-0.4-0.3-0.4-0.6c0-2.4,0-4.9,0-7.3c0-5.7,4.5-10.4,10.2-10.7
                        C656.1,332.3,667.1,331.7,678.1,331.2z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M678.2,214.9c3.1,0.9,5.2,2.3,4.8,5.9c-0.1,1.3-0.3,2.6-0.4,4c-0.3,2.6-3.4,3.7-5.8,2.5
                        c-3-1.4-4.6,0.1-6.6,5.2c-0.5,1.2-2.3,1-2.4-0.3l0,0c-0.3-3.4-1.9-4.9-5.7-4.8c-1.9,0.1-4.3-0.7-5.9-1.9c-4.5-3.2-9.1-2.7-13.6-1.1
                        c-3.3,1.2-6,2-9.4-0.3c-3.4-2.3-6.4-0.2-6.8,4.2l0,0c-0.1,1.2-1.8,1.5-2.2,0.3c-0.4-1.1-0.8-2.1-1.2-3.1c-0.4-1-1.6-1.5-2.6-1
                        c-1.5,0.7-3,1.4-4.5,2.2c-1.1,0.5-2.3,0.4-3.4-0.3c-1.8-1.2-2.8-1.5-4-0.6c-1.6,1.2-4.2,0.6-4.1-1.5l0,0c0.7-10.7,3.1-12.3,13.3-10
                        c3.3,0.6,6.9-0.2,10.3-0.4c1.7-0.1,3.5-0.4,5.2-0.7c4.1-1,8.3-1.5,12-3.1c4.9-2,9.5-2.2,14.4-0.5
                        C665.9,211.5,672.1,213.1,678.2,214.9z"
                            />
                        </g>

                        <g id="letter-d">
                            <motion.path
                                variants={pathVariants}
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#E2D7FF"
                                d="M765.8,349.3c27.9-20.6,40.3-56.9,29.1-89.2
                        c-0.3-0.9-0.6-1.8-0.9-2.7c-2.8-8-5.5-15.8-6.5-24.4c-1.1-8.9-6.7-14.6-15.9-16.2c-3.7-0.6-8.2-1.6-10.6-4.1
                        c-5.8-5.7-11.7-7.9-17.8-7.7c-0.1,0-0.2,0-0.3,0.1c-4.2,0.4-8.6,1.8-13.2,3.7c-5.7,2.4-12.3,2.8-18.6,3.1l-0.2,0
                        c-0.6,0-1.1,0-1.7,0.1c-4.9,0.1-9.9,0.3-12.6,5.3c-0.4,0.6-0.8,1.4-1.1,2.2c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2
                        c-1.1,2.8-1.8,5.9-1.9,8.8c-0.3,15.6-0.3,31.3-0.2,47c0,5.2,0,10.4,0,15.6c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
                        c0,7.2,0,14.4,0,21.6c0,14.4,0,28.8,0,43.2c0,6.5,1.5,7.8,8.3,8.2l6.4,0.3C728.7,365.7,748.3,362.2,765.8,349.3z M755.8,283.6
                        c1.4,9.3,0.3,18.7-7.4,25.5c-1.3,1.2-2.9,1.9-4.2,2.6c-0.8,0.5-1.7,1-2.5,1.4c-1.7,1-3.3,2-5.1,2.7c-2,1-4.3,1.7-6.6,1.8
                        c-0.5,0-1.6-3.2-1.6-4.9c-0.2-6.1-0.1-12.2-0.1-18.4c0-2,0-4,0-6.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0,0.1-0.1v-22.8
                        c0-7.2,2.3-8.9,9.2-6.5C749.4,262.8,754.1,272.4,755.8,283.6z"
                            />
                            <g>
                                <motion.path
                                    variants={snowVariants}
                                    fill="#FFFFFF"
                                    d="M788,253.1L788,253.1c0.2,1.3-2,1.6-2.7,0.5c-0.1-0.1-0.2-0.3-0.2-0.4c-2-4.3-3.7-8.7-6.2-12.8
                            c-0.7-1.2-3.2-1.7-4.9-2c-3.8-0.5-7.7-0.9-11.5-0.9c-2.5,0-5.1,0-7.1,1.2c-6.2,3.3-12.2,2.9-18.4,0.9c-5.1-1.6-9.6-2-12.3,4.5
                            c-0.5,1.3-2.3,2-4.3,2.9c-1.4,0.6-3-0.1-4.4-0.6c-0.6-0.2-1.2-0.2-1.8,0c-1.7,0.6-3.6,0.3-4.5-1.2l0,0c-1.2-2.3-2-5.3-3.9-6.6
                            c-1.8-1.3-5.3-1.7-7.2-0.9l0,0c-2.1,0.8-3.6,1.1-3.6-1.2c0.1-4.6,0.3-9.2,1.1-13.6c0.2-0.9,0.4-1.7,0.7-2.6
                            c1.6-3.7,5.1-6.1,10.1-5.5c9.8,1.1,19-0.7,27.7-5.2c2.5-1.3,4.9-1.9,7.4-2.1c4.7-0.3,9.3,1.5,13.3,5.1c4.5,3.9,9.4,6.6,15.6,7.4
                            c9.1,1.1,13.1,7.7,14.7,16C786.7,241.5,787.4,247.4,788,253.1z"
                                />
                            </g>
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M747.5,312.5c2.2-1.3,4.7,0.6,4,3c-0.1,0.4-0.2,0.8-0.3,1.2c-0.4,1.4-2.2,1.8-3.3,0.9l0,0
                        c-0.9-0.8-2.1-0.5-2.9,0.4c-0.5,0.6-0.9,1.2-1.4,1.8c-0.9,1.1-2.3,1.5-3.6,1.6c-1.6,0.1-2.5,0.8-3,1.7c-2.8,5.5-5.1,5.5-3-0.3
                        c1.4-3.9,4.7-6.9,9.5-8.4C744.8,313.9,746.2,313.3,747.5,312.5z"
                            />
                        </g>

                        <g id="letter-_x21_">
                            <motion.path
                                variants={pathVariants}
                                fill="#E2D7FF"
                                d="M855.4,210.1C855.4,210.1,855.5,210.1,855.4,210.1L855.4,210.1z M862.7,197.2c2.5,4.2,2,10.4,1.5,15.8
                        c-0.5,6.6-3,13-3.5,19.6c-2.6,29.5-4.7,59.1-6.9,88.6c-0.2,1.9-0.3,3.8-0.5,5.9c-1,7.1-3.3,9.6-10.6,10.1c-4.3,0.3-8.5,0-12.8-0.1
                        c-4.4-0.1-6.2-2-6.1-6.6c0.2-19.2,1.9-38.3,1.9-57.5c0-17.9,0.2-35.8-0.1-53.7c-0.1-6-1.3-11.9-1.9-17.8c-0.6-5.9,1.6-9.5,7.4-10.3
                        c6-0.9,11.9-1,18-1.4l0,0c0.7,0,1.4,0.3,2,0.6C855.3,192.4,860.8,193.9,862.7,197.2z"
                            />
                            <motion.path
                                variants={pathVariants}
                                opacity="0.9"
                                fill="#E2D7FF"
                                enable-background="new    "
                                d="M821.5,379.7c-2.8-3.8-2.7-11.4-0.5-18.2c1-2.9,2.8-5.6,5.8-6.4
                        c8.4-2.3,25.5-0.7,27.6,2.1c2.5,3.4,4.2,10.6,3,19.5c-1.3,8.9-8.9,7.6-14.8,9.8C836.6,388.6,824.8,384.3,821.5,379.7z"
                            />
                            <motion.path
                                variants={snowVariants}
                                fill="#FFFFFF"
                                d="M862.2,201.1c0.2,0.7,0.3,1.5,0.3,2.3c0.2,2.6-1.6,4.9-4.1,5.6c-0.3,0.1-0.7,0.2-1.1,0.3
                        c-1.4,0.4-3-0.1-3.9-1.2l0,0c-1.5-1.7-4.1-1.9-5.8-0.3c-1.2,1.1-2.3,2.3-3,3.7c-1.1,2-0.5,4.9-1,7.4c-0.3,1.6-0.8,3.2-1.2,4.8
                        c-0.2,0.6-0.7,1-1.3,0.9l0,0c-0.5,0-0.9-0.4-1.1-0.9c-1-3.4-2-6.9-3.2-11c-0.5-1.6-3-1.5-4.1-0.2l0,0c-1,1.1-2.3,0.3-3-1
                        c-1.5-2.9-3.1-5.7-3.7-8.7c-1.3-6.3,1.1-9,7.5-9.6c5.1-0.5,10.2-0.4,15.5-0.2c1.5,0.1,3.3,1.2,4.3,2.3c0.7,0.9,1.3,1.8,2,2.4
                        C857.6,199.4,861.5,198.4,862.2,201.1L862.2,201.1z"
                            />
                        </g>

                        <StartSVG></StartSVG>
                    </StartSVG>
                </SVGContainer>
                <motion.span animate={{ opacity: 1 }}>
                    <Button className="btn-3d" onClick={nextSlide}>
                        {t`Begin`}
                    </Button>
                </motion.span>
            </Welcome>
        </Slide>
    );
};
const SVGContainer = styled.div`
    width: 100%;
    height: 0;
    padding-top: 50%;
    position: relative;
    margin-bottom: 1rem;
`;

const StartSVG = styled(motion.svg)`
    position: absolute;
    top: 0;
    left: 0;
    stroke: #fff;
    stroke-width: 0.5;
    stroke-linejoin: round;
    stroke-linecap: round;
    width: 100%;
    margin-bottom: 1.5rem;
    path {
        stroke: #fff;
        stroke-width: 1;
    }
`;

const Welcome = styled.div`
    display: block;
    margin: 15px auto;
    max-width: 800px;
    padding: 2rem 3rem;
    text-align: center;
    border-radius: 5px;
`;

export default StartSlide;
