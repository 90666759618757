const spritesheetChrome: IHowlSoundSpriteDefinition = {
    "1": [150, 5010],
    "2": [7900, 4460],
    "3": [14800, 3500],
    "4": [20949, 1340],
    "5": [25319, 6891],
    "6": [35900, 4240],
    "7": [43660, 4630],
    "8": [51990, 8840],
    "9": [63870, 3389],
    "10": [70879, 4320],
    "11": [78430, 5610],
    "12": [86840, 5810],
    "13": [94980, 8030],
    "14": [107359, 3861],
    "15": [114980, 3830],
    "16": [123259, 4681],
    "17": [131820, 3130],
    "18": [139230, 2960],
    "19": [146250, 4230],
    "20": [155190, 3999],
    "21": [163060, 1200],
    "22": [167760, 2190],
    "23": [0, 0], // Unrecorded
    "24": [172980, 3046],
    "25": [178920, 8190],
    "26": [191240, 1500],
    "27": [196889, 950],
    "28": [201860, 1190],
    "29": [206860, 620],
    "30": [211460, 1080],
    "31": [216000, 9490],
    "32": [229139, 9680],
    "33": [242779, 2490],
    "34": [248829, 2870],
    "35": [255339, 4971],
    "36": [263960, 5546],
    "37": [272506, 4999],
    "38": [280505, 5908],
    "39": [289413, 6817],
    "40": [298230, 6817],
    "41": [307047, 5663],
    "42": [317010, 10970],
    "43": [331650, 6559],
    "44": [341959, 4251],
    "45": [350670, 1030],
    "46": [355639, 9670],
    "47": [365509, 14191],
    "48": [384030, 2069], // 1/2 Let's check if...
    "49": [395629, 1471],
    "50": [400690, 3600],
    "51": [408120, 1689],
    "52": [412439, 2040],
    "53": [417819, 7265],
    "54": [427084, 5286],
    "55": [435730, 6680],
    "56": [446120, 6180],
    "57": [455880, 7110],
    "58": [465786, 7110],
    "59": [474559, 3022],
    "60": [480000, 7000],
    "61": [490360, 4530],
    "62": [499860, 3169],
    "63": [507580, 3800],
    "64": [515599, 4310],
    "65": [523500, 3310],
    "66": [531349, 4560],
    "67": [540039, 1061],
    "68": [544720, 4560],
    "69": [552850, 580],
    "70": [556909, 420],
    "71": [560490, 1210],
    "72": [564510, 4400],
    "73": [572870, 2520],
    "74": [578990, 8579],
    "75": [590349, 5901],
    "76": [599170, 3360],
    "77": [605190, 3869],
    "78": [612459, 4161],
    "79": [619899, 1251],
    "80": [624650, 4169],
    "81": [632250, 1830],
    "82": [637330, 710],
    "83": [641530, 4220],
    "84": [649280, 800],
    "85": [653030, 840],
    "86": [656599, 630],
    "87": [659759, 680],
    "88": [662529, 790],
    "89": [666200, 1539],
    "90": [671150, 5870],
    "91": [680610, 4160],
    "92": [687439, 6500],
    "93": [697280, 6030],
    "94": [706810, 4010],
    "95": [714690, 6070],
    "96": [724750, 4070],
    "97": [733080, 12820],
    "98": [750000, 3560],
    "99": [756000, 2000],
    "100": [761770, 1720],
    "101": [767360, 1760],
    "102": [779830, 7040],
    "103": [790619, 1210],
    "104": [795129, 1680],
    "105": [800189, 12341],
    "106": [815980, 5130],
    "107": [825170, 6620],
    "108": [836380, 1400],
    "109": [842490, 1220],
    "110": [848230, 8369],
    "111": [861330, 5739],
    "112": [871479, 870],
    "113": [876079, 2000],
    "114": [881079, 5500],
    "115": [389030, 3069], // 2/2 What is Decomp...
    "116": [887000, 4500],
    "117": [896000, 14500],
    "118": [929500, 3500],
};

export default spritesheetChrome;
