import React from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Phisherman from '../../characters/Phisherman';

const PhisherOutro: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Phisherman,
            textSpriteName: 'endturtle-phisherman-1',
            text: t`I got you! That pop-up was put in by me and didn't come from Barefoot, so I managed to steal your email and password!`,
            buttonText: t`Next`,
            buttonAction: () => gotoSlide(currentIndex + 2),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default PhisherOutro;
