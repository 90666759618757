import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Kitty from '../../characters/Kitty';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Kitty,
            text: t`Hi, I'm Amber. I am in charge of the athletes’ kits here. Patrick tells me you did a great job using logical reasoning to help organise his flags!`,
            buttonText: t`Hello Amber!`,
        },
        {
            character: Kitty,
            text: t`The teams’ kits have got in a bit of a muddle. Somehow the British athletes have a green kit and our cyclists are wearing swimming trunks... Can you help?`,
            buttonText: t`Of course!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
