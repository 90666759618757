import { motion } from 'framer-motion';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import icons from '../../assets/misc/a-icons.svg';
import strokes from '../../assets/misc/a-strokes.svg';
import Turtle from '../../characters/Turtle';

const TurtleAdvert: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const advert = (
        <Advert>
            <Graphics onClick={() => gotoSlide(currentIndex + 2)}>
                <motion.img
                    animate={{ x: [-20, 20, -20], opacity: [0.4, 0.2, 0.4] }}
                    transition={{ duration: 2.5, ease: 'easeInOut', loop: Infinity }}
                    src={strokes}
                    width="200"
                />
                <motion.img
                    animate={{ opacity: [0.8, 1, 0] }}
                    transition={{ duration: 0.8, loop: Infinity }}
                    src={icons}
                    width="200"
                />
            </Graphics>
            <Text>
                <div>
                    <i role="button" className={'fa fa-times'} onClick={nextSlide}></i>
                    <h3 onClick={() => gotoSlide(currentIndex + 2)}>{t`BEAT THE PHISHERMAN!`}</h3>
                    <p
                        onClick={() => gotoSlide(currentIndex + 2)}
                    >{t`Quick! Click here to complete this game instantly.`}</p>
                    <Button onClick={() => gotoSlide(currentIndex + 2)}>{t`COMPLETE GAME`}</Button>
                </div>
            </Text>
        </Advert>
    );

    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`I think you have met everyone! You have done so well that you have run out of people to help... The Phisherman can’t keep up with you!`,
            textSpriteName: 'endturtle-1-1',
            buttonText: ``,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={advert}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

const Advert = styled.div`
    height: 200px;
    position: relative;
    bottom: -10px;
    z-index: 999;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    background-color: #361559;
    @media (min-width: 768px) {
        height: 200px;
        max-width: 800px;
    }
    display: flex;
    overflow: hidden;
`;
const Graphics = styled.div`
    overflow: hidden;
    width: 100px;
    cursor: pointer;
    @media (min-width: 768px) {
        width: 200px;
    }
    position: relative;
    background-color: #6400aa;
    height: 100%;
    img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        display: block !important;
        height: 100%;
    }
`;

const Text = styled.div`
    padding: 2% 3%;
    flex: 1;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    h3,
    p {
        color: #ffffff;
        margin-bottom: 5px;
    }
    @media (max-width: 767px) {
        h3 {
            font-size: 4.25vw;
            line-height: 1.5;
        }
        p {
            font-size: 3.5vw;
            line-height: 1.5;
        }
    }
    @media (min-width: 768px) {
        height: 200px;
    }
    i {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: #ffffff;
        &:hover {
            opacity: 0.7;
        }
        background-color: rgba(0, 0, 0, 0.2);
        padding: 4px 6px;
        border-radius: 100px;
    }
`;

export default TurtleAdvert;
