import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const AbstractionIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide onStart={nextSlide} header={defaultHeader} number={1}>
            {t`Help Ranger Jack find the missing animal using only the features Ranger Jack has given you.`}
        </MissionSlide>
    );
};

export default AbstractionIntro;
