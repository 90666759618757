import { motion } from 'framer-motion';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import paint_stroke from '../../../../assets/misc/paint-stroke.svg';
import ModalOverlay from '../../../general/ModalOverlay';
import { t } from 'ttag';

const TimeoutModal: React.FC<{ onClose: () => void; show: boolean }> = ({ onClose, show }) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-stopwatch mr-2"></i> {t`Time's up!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    const footer = <Button onClick={() => onClose()}>{t`Retry`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#E73981">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <h3 className="mb-3">{t`Too slow!`}</h3>
                    <p>
                        {t`You did not manage to destroy enough asteroids in the given time. See if you can destroy more next time`}
                        .
                    </p>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default TimeoutModal;
