import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { Button, Col, Row } from 'react-bootstrap';
import ModalOverlay from '../../../../general/ModalOverlay';
import { AbstractionData } from '../..';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import { t, jt } from 'ttag';

const ConfusedTouristsOutro: React.FC<ISlide<AbstractionData>> = ({ nextSlide, defaultHeader, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue = [
        {
            character: RangerJack,
            text: t`Wow ${data.name}, it actually worked! The tourists are not confused anymore thanks to you. If I’m honest, I was pretty confused too!`,
            buttonText: t`Woohoo!`,
        },
        {
            character: RangerJack,
            text: t`Abstraction helped us focus on only the important features of each animal. This guide is going to make my life so much easier...`,
            buttonText: t`Great news`,
            buttonAction: () => setShow(true),
        },
    ];

    let title = <span className="font-bt-400">{t`Help the Tourists`}</span>;
    const header = (
        <React.Fragment>
            <h3>{jt`Mission 2: ${title}`}</h3>
            {/* <div className={'section--header__complete'}>COMPLETE</div> */}
        </React.Fragment>
    );

    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h2 className="text-white text-center">
                <i className="fas fa-star mr-2"></i> {t`GUIDE COMPLETE`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    return (
        <Slide header={header} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />

            <ModalOverlay
                show={show}
                header={modalHeader}
                footer={<Button onClick={() => nextSlide()}>{t`Next`}</Button>}
                backgroundColor="#00A0D6"
            >
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                            <i className="fas fa-book-open mb-3 icon--achievement"></i>
                            <h2>{t`ANIMAL GUIDE`}</h2>
                            <h5>
                                {t`You have used abstraction to list the important features of each of Ranger Jack’s animals.`}
                            </h5>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
        </Slide>
    );
};

export default ConfusedTouristsOutro;
