export const theme = {
  bt_purple: "#6400aa",
  bt_purple_700: "#540E85",
  bt_purple_900: "#361559",
  bare_pink: "#E5007D",
  bare_green: "#00C3A5",
  bare_yellow: "#FFE202",
  bare_blue: "#0AB9F0",
  bare_blue_700: "#1782A4",
  font_bt_light: "'BT Font Light'",
  font_bt: "'BT Font Regular'",
  font_bt_bold: "'BT Font Bold'",
  font_bt_extra: "'BT Font Extra Bold'",
  gray_900: "#333333",
  white: "#FFFFFF",
};
