import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Seal from '../../characters/Seal';

const SealIntro2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Seal,
            text: t`Thanks! I have a lot of followers as you can see... but I always appreciate another one! `,
            textSpriteName: 'fish-2-1',
            buttonText: t`Next`,
        },
        {
            character: Seal,
            text: t`Be careful on NikNak though, there are plenty of scammers trying to phish your account.`,
            textSpriteName: 'fish-2-2',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default SealIntro2;
