import Cookies from 'js-cookie';

const VOLUME_COOKIE = 'bfg_volume';

function getVolume(): number {
    let v = Cookies.get(VOLUME_COOKIE);
    return parseInt(v ?? '-1');
}

export function saveVolume(volume: number) {
    Cookies.set(VOLUME_COOKIE, volume.toString());
}

// setup
export const volume: number = getVolume();
