import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/phisherman.svg';
import { t } from 'ttag';

const Turtle: TCharacter = {
    characterName: t`Phisherman`,
    characterImage: image,
    characterWidth: 300,
    characterHeight: 140,
};

export default Turtle;
