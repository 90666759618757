import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import chicken1 from '../../../../../assets/games/stock/so-algo--chicken1.jpg';
import cow1 from '../../../../../assets/games/stock/so-algo--cow1.jpg';
import cow2 from '../../../../../assets/games/stock/so-algo--cow2.jpg';
import dog1 from '../../../../../assets/games/stock/so-algo--dog1.jpg';
import dog2 from '../../../../../assets/games/stock/so-algo--dog2.jpg';
import dog3 from '../../../../../assets/games/stock/so-algo--dog3.jpg';
import goose1 from '../../../../../assets/games/stock/so-algo--goose1.jpg';
import goose2 from '../../../../../assets/games/stock/so-algo--goose2.jpg';
import sheep1 from '../../../../../assets/games/stock/so-algo--sheep1.jpg';
import Slide, { ISlide } from '../../../../core/Slide';
import SortAlgorithmGame, { TSortAlgorithmQuestion } from '../../../../interactive/algorithmsort/SortAlgorithmGame';

const MissionAlgorithm: React.FC<ISlide> = ({ nextSlide }) => {
    const header = (
        <h3>
            {t`Mission 3:`} <span className="font-bt-400">{t`Sort the animals using an algorithm`}</span>
        </h3>
    );

    const gameData: TSortAlgorithmQuestion[] = [
        {
            question: {
                text: t`Create an algorithm to only select the DOGS`,
                animal: t`dog`,
                objects: [
                    {
                        name: t`dog`,
                        image: dog1,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`sheep`,
                        image: sheep1,
                        tags: [t`has four legs`, t`has wool`, t`has hooves`],
                    },
                    {
                        name: t`dog`,
                        image: dog2,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`goose`,
                        image: goose1,
                        tags: [t`has webbed feet`, t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`chicken`,
                        image: chicken1,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`cow`,
                        image: cow1,
                        tags: [t`has four legs`, t`has fur`, t`has hooves`],
                    },
                    {
                        name: t`dog`,
                        image: dog3,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`cow`,
                        image: cow2,
                        tags: [t`has four legs`, t`has fur`, t`has hooves`],
                    },
                    {
                        name: t`goose`,
                        image: goose2,
                        tags: [t`has webbed feet`, t`has wings`, t`has a beak`, t`has feathers`],
                    },
                ],
            },
            answers: [t`has paws`, t`has four legs`, t`has fur`],
        },

        {
            question: {
                text: t`Create an algorithm to only select the GEESE`,
                animal: t`goose`,
                objects: [
                    {
                        name: t`dog`,
                        image: dog1,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`sheep`,
                        image: sheep1,
                        tags: [t`has four legs`, t`has wool`, t`has hooves`],
                    },
                    {
                        name: t`dog`,
                        image: dog2,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`goose`,
                        image: goose1,
                        tags: [t`has webbed feet`, t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`chicken`,
                        image: chicken1,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`cow`,
                        image: cow1,
                        tags: [t`has four legs`, t`has fur`, t`has hooves`],
                    },
                    {
                        name: t`dog`,
                        image: dog3,
                        tags: [t`has paws`, t`has four legs`, t`has fur`],
                    },
                    {
                        name: t`cow`,
                        image: cow2,
                        tags: [t`has four legs`, t`has fur`, t`has hooves`],
                    },
                    {
                        name: t`goose`,
                        image: goose2,
                        tags: [t`has webbed feet`, t`has wings`, t`has a beak`, t`has feathers`],
                    },
                ],
            },
            answers: [t`has webbed feet`, t`has wings`, t`has a beak`, t`has feathers`],
        },
    ];

    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <Row>
                <Col xs={'12'} className={'text-center'}>
                    <SortAlgorithmGame gameData={gameData} onGameComplete={nextSlide} />

                    {/* <Button onClick={()=>nextSlide()}>Next</Button> */}
                </Col>
            </Row>
        </Slide>
    );
};

export default MissionAlgorithm;
