import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentRio from '../../characters/AgentRio';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const BlueprintOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentRio,
            text: t`We have no time to waste, if we want to catch Dr Mischief unaware! Here’s your parachute, we are flying in tonight!`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default BlueprintOutro;
