import React from 'react';
import { GamePicture } from './FourPicturesGame';
import PictureTile from './PictureTile';

export type GamePictureTileGroup = {
    pictures: [GamePicture, GamePicture, GamePicture, GamePicture];
    hintLevel: number;
};

const PictureTileGroup: React.FC<GamePictureTileGroup> = ({ pictures, hintLevel }) => (
    <div className={`fourpics__wrapper`}>
        <PictureTile picture={pictures[0]} hint={hintLevel > 0} />
        <PictureTile picture={pictures[1]} hint={hintLevel > 1} />
        <PictureTile picture={pictures[2]} hint={hintLevel > 2} />
        <PictureTile picture={pictures[3]} hint={hintLevel > 3} />
    </div>
);

export default PictureTileGroup;
