import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';

const Recap: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const footer = <Button onClick={nextSlide}>{t`Close`}</Button>;

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs={'12'} className={'cc-container'}>
                    <h4 className={'cc-intel__heading'}>{t`MISSION DEBRIEF`}</h4>
                    <Content type="island" styles={'p-3 cc-intel__island'}>
                        <p>{t`SUMMARY OF LEARNING`}</p>
                        <ul>
                            <li>
                                {t`CIPHERS`}
                                <br />
                                {t`You have learnt how ciphers turn information into code.`}
                            </li>
                            <li>
                                {t`LOGIC`}
                                <br />
                                {t`You worked your way through the different offsets to output the message correctly`}
                            </li>
                            <li>
                                {t`ALGORITHMS`}
                                <br />
                                {t`You have followed the step by step process to decode the message.`}
                            </li>
                        </ul>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default Recap;
