import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import Island from '../../components/Island';
import styled from 'styled-components';
import logo from '../../../../../assets/brand/logo-barefoot.svg';
import heart from '../../assets/gamification/heart.svg';
import star from '../../assets/gamification/star.svg';
import { CyberSecurityData } from '../..';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const Welcome: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-island')
    }, []);

    return (
        <Slide header={defaultHeader} backgroundColor={'rgba(0,0,0,0.6)'} footerHidden={true}>
            <WelcomeWrapper>
                <WelcomeContent>
                    <div className="w-100">
                        <h3>
                            {t`Welcome to`}
                            <br />
                            {t`Kelpy Deeps`}
                        </h3>
                        <Button className="btn-3d" onClick={() => nextSlide()}>{t`Enter`}</Button>
                    </div>
                </WelcomeContent>
                <Island nextSlide={nextSlide} infoText={t`Select Turtle to continue`}></Island>
            </WelcomeWrapper>
        </Slide>
    );
};
const WelcomeWrapper = styled.div`
    position: relative;
    div:nth-child(2) {
        opacity: 0.2;
    }
`;

const WelcomeContent = styled.div`
    position: absolute;
    width: 280px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 140px);
    z-index: 999;
    display: flex;
    align-items: center;
    text-align: center;
`;

export default Welcome;
