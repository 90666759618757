import React from 'react';
import { t } from 'ttag';
import room from '../../../../../assets/misc/th-room--keypad.svg';
import Slide, { ISlide } from '../../../../core/Slide';

const FindKeypad: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    return (
        <Slide header={defaultHeader}>
            <h3 className={'my-3 text-center'}>{t`FIND THE KEYPAD`}</h3>
            <div className={`island island--rounded bg-secondary-700 th-part`}>
                <img
                    src={room}
                    className={'th-part__room'}
                    width={'300px'}
                    height={'350px'}
                    alt={t`Room with lasers`}
                />
                <div className="th-keypad__overlay" onClick={() => nextSlide()}></div>
            </div>
        </Slide>
    );
};

export default FindKeypad;
