import React, { useState, useContext } from 'react';
import TileGroup from './TileGroup';
import CorrectModal from './CorrectModal';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';
import { useGameLogic, GameProps } from '../../core/Game';
import { t } from 'ttag';

export type Subject = {
    name: string;
    image: string;
    answer: string;
};

export type Feature = {
    name: string;
    correct: boolean;
    reason: string;
};

export type FeatureMatchQuestion = {
    subject: Subject;
    features: [Feature, Feature, Feature, Feature, Feature, Feature];
};

const FeatureMatch: React.FC<GameProps<FeatureMatchQuestion>> = ({ gameData, onGameComplete }) => {
    const [show, setShow] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<FeatureMatchQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
    );

    const { volume } = useContext(VolumeContext);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    if (question === null) {
        return null;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>

            <div className="text-center">
                <div className="character__wrapper">
                    <img className={'ck-flags'} src={question.subject.image} alt="" width="150px" />
                    <p className={`mt-2 text-center`}>{t`Which attribute was likely used to group these flags?`}</p>
                </div>
                <h2 className="my-3">{question.subject.name}</h2>
            </div>
            <TileGroup tiles={question.features} onComplete={handleQuestionComplete}></TileGroup>
        </React.Fragment>
    );
};

export default FeatureMatch;
