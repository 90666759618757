import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Phone from '../../components/Phone';
import { t } from 'ttag';
import * as PhoneCall from '../../components/interactive/phonecall/PhoneCall';
import { CyberSecurityData } from '../..';

const UnknownCall: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {

    const gameData: PhoneCall.TPhoneCallQuestion[] = [
        {
            message: t`Good afternoon, ${data.name}.`,
            messageSpriteName: 'pufferfish-phone-1',
            actions: [
                {
                    text: t`Hi there!`,
                    textSpriteName: 'pufferfish-phone-a1-1',
                },
                {
                    text: t`Who is this?`,
                    textSpriteName: 'pufferfish-phone-a1-2',
                },
            ],
        },

        {
            message: t`This is your phone contract provider, as you are such a loyal customer we are offering you a brand new PS5! Before we continue, we need to ask you a couple of questions.`,
            messageSpriteName: 'pufferfish-phone-2',
            actions: [
                {
                    text: t`Why is this an unknown number?`,
                    textSpriteName: 'pufferfish-phone-a2-1',
                },
                {
                    text: t`A new PS5... go ahead!`,
                    textSpriteName: 'pufferfish-phone-a2-2',
                },
            ],
        },

        {
            message: t`As part of authentication, we would like to know your home address so that we can send you your free PS5 right now today!`,
            messageSpriteName: 'pufferfish-phone-3',
            actions: [
                {
                    text: t`Give your home address`,
                    textSpriteName: 'pufferfish-phone-a3-1',
                    icon: 'home',
                    action: () => gotoSlide(currentIndex + 3),
                },
                {
                    text: t`Sorry I’m hanging up.`,
                    textSpriteName: 'pufferfish-phone-a3-2',
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Phone backgroundColor={'#007BFF'}>
                <PhoneCall.default gameData={gameData} onGameComplete={() => nextSlide()}></PhoneCall.default>
            </Phone>
        </Slide>
    );
};

export default UnknownCall;
