import WhaleIsland from '../3-fishfood/WhaleIsland';
import CloseAd from './CloseAd';
import PhisherOutro from './PhisherOutro';
import PhisherOutroDodged from './PhisherOutroDodged';
import PhishingSite from './PhishingSite';
import TurtleAdvert from './TurtleAdvert';
import TurtleIsland from './TurtleIsland';
import TurtleOutro from './TurtleOutro';

export default [WhaleIsland, TurtleAdvert, CloseAd, PhishingSite, PhisherOutro, PhisherOutroDodged, TurtleOutro];
