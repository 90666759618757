import React, { useContext, useState } from 'react';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import CaesarCipherQuestion from './CaesarCipherQuestion';
import CorrectModal from './CorrectModal';

export type TCaesarCipherQuestion = GameQuestion & {
    answer: string;
    answerOffset: number;
    answerInputDisabled?: boolean;
    offsetInputDisabled?: boolean;
    offset: number;
};

const CaesarCipher: React.FC<GameProps<TCaesarCipherQuestion>> = ({ gameData, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TCaesarCipherQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    if (question === null) {
        return null;
    }

    return (
        <React.Fragment>
            <CorrectModal question={question} onClose={handleModalClose} show={show}></CorrectModal>
            <CaesarCipherQuestion
                question={question}
                onQuestionComplete={handleQuestionComplete}
            ></CaesarCipherQuestion>
        </React.Fragment>
    );
};

export default CaesarCipher;
