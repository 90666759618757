import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const CipherEnigmaIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`With the enemy turning all of their messages into gibberish, we can’t see what they’re up to. We need to decipher the message back to what it was before, which is hard when we don't know their cipher's settings!`,
            buttonText: t`Next`,
        },
        {
            character: AgentGreen,
            text: t`We have heard some intelligence that the enemy is using something called the Enigma Machine to cipher their messages. Let me show you what it looks like.`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default CipherEnigmaIntro;
