import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { useInterval } from '../../../../../../hooks/interval';
import useWindowDimensions from '../../../../../../hooks/window';
import bridge from '../../../assets/scene/bridge_3-new.svg';
import road_stripes from '../../../assets/scene/road-stripes.svg';
import { breakpoint, game } from '../../../styles/junction';
import TrafficLightSequence, { TTrafficLightSequence } from '../../traffic-lights/TrafficLightSequence';
import Cars from './Cars';

export type RoadProps = {
    carData: TCar[];
    trafficLight1?: TRoadLight;
    trafficLight2?: TRoadLight;
    onCrash?: () => void;
    onRoadComplete?: () => void;
    paused?: boolean;
    setPause?: (status: boolean) => void;
    gameReset?: boolean;
}

export type TRoadLight = {
    sequence: TTrafficLightSequence[],
    direction: TRoadDirection,
    highlight?: boolean,
    pos: number,
    onClick?: () => void,
}

export type TCar = {
    id: string;
    pos: number;
    moving: boolean;
    crashed?: boolean;
    direction: TRoadDirection;
    central?: boolean;
    hidden?: boolean;
    reset?: boolean;
    completed?: boolean;
    onComplete?: () => void;
    hideOnComplete?: boolean;
    loop?: boolean;
    debug?: boolean;
}

type TRoadDirection = 'clockwise' | 'anticlockwise'

const Road: React.FC<RoadProps> = ({ carData, trafficLight1, trafficLight2, onCrash, onRoadComplete, paused = false, gameReset, setPause }) => {
    const { width } = useWindowDimensions();
    const scale = width >= 1000 ? 1 : width / 1000;
    const speed = 0.4;
    const carWidth = width >= 1000 ? 1 * 130 : (width / 1000) * 120;

    const t2 = trafficLight2;

    const [lightIndex, setLightIndex] = useState<number | null>(0);
    const [lightIndex2, setLightIndex2] = useState<number | null>(0);
    const [debug, setDebug] = useState(false);

    const [cars, setCars] = useState<TCar[]>(carData);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            setDebug(false);
        }
    }, []);

    useEffect(() => {
        // Spawn vehicles on carData change
        let spawnList: TCar[] = [];
        carData.forEach((car) => {
            // check to see if already spawned & not reset
            if (cars && !cars.find((c) => c.id === car.id)) {// && !car.reset
                spawnList.push(car);
            }
        })

        setCars([
            ...cars,
            ...spawnList,
        ]);
    }, [carData]);

    const inRangeOfPos = (pos1: number, pos2: number, distance = 10 * scale) => {
        return pos1 >= pos2 - distance && pos1 <= pos2;
    }

    const inRange = (x: number, lower: number, upper: number) => {
        return ((x - lower) * (x - upper) <= 0);
    }

    useEffect(() => {
        // spawnCar()
    }, []);

    const RADIUS = {
        SMALL: 100 * scale,
        MEDIUM: 200 * scale,
        LARGE: 300 * scale,
    }

    // GUIDE VALUES
    const centre = (width / 2);
    const bridgeLowerBound = width >= 1000 ? centre - 150 : (width * 0.4);
    const bridgeUpperBound = width >= 1000 ? centre + 150 : (width * 0.6);

    const leftTrafficLight = width >= 1000 ? centre - 300 : (width * 0.25);
    const rightTrafficLight = width >= 1000 ? centre + 300 : (width * 0.75);


    const updateCarPos = () => {
        // Collision Check
        let collision = false;
        let collidedIds: string[] = [];
        cars.forEach((car, index) => {
            collision = cars.some((c, i) => {
                // Meet at same position and not same car // REMOVED doesn't make sense
                if (c.pos === car.pos && c.id !== car.id && c.direction != car.direction && (c.central && car.central) && (!car.hidden || !c.hidden)) {
                    collidedIds.push(c.id);
                    collidedIds.push(car.id);
                    return true;
                }

                // if opposite directions and v close AND is central AND not hidden
                if (c.direction != car.direction && (inRangeOfPos(c.pos, car.pos, RADIUS.SMALL)) && (c.central && car.central) && (!car.hidden || !c.hidden)) {
                    // console.log('crash b')

                    collidedIds.push(c.id);
                    collidedIds.push(car.id);
                    return true;
                }

                return false;
            })
        })

        // New Car Positions
        let newCarPos = cars;
        let carsCompleted = 0;
        newCarPos = newCarPos.map((car, index) => {
            // some defaults
            car.central = false;
            car.hidden = false;
            car.moving = true;
            car.completed = car.completed ?? false;

            // Reset cars outside of boundary
            if (car.pos > (width) && car.direction === 'clockwise') {
                car.pos = 0;
                car.completed = true;
                car.hidden = true;
                if (car.onComplete) {
                    car.onComplete();
                }
            } else if (car.pos < 0) {
                car.pos = width;
                car.completed = true;
                car.hidden = true;
                if (car.onComplete) {
                    car.onComplete();
                }
            }

            // given the upper and lower bound of the bridge, is this value within
            const centre = (width / 2);
            const bridgeLowerBound = width >= 1000 ? centre - 150 : (width * 0.4);
            const bridgeUpperBound = width >= 1000 ? centre + 150 : (width * 0.6);
            console.log(bridgeLowerBound, bridgeUpperBound)

            if (inRange(car.pos, bridgeLowerBound, bridgeUpperBound)) {
                car.central = true;
            }

            // check for traffic ahead
            cars.some((car2, i) => {
                // Same direction and close proximity AND it is in front
                if (
                    i !== index &&
                    car.direction === 'clockwise' &&
                    car.direction === car2.direction &&
                    car.pos < car2.pos && ((car2.pos - car.pos) <= (1.5 * carWidth))

                ) {
                    car.moving = false;
                }

                if (
                    i !== index &&
                    car.direction === 'anticlockwise' &&
                    car.direction === car2.direction &&
                    car.pos > car2.pos && ((car2.pos - car.pos) >= (-1.5 * carWidth))

                ) {
                    car.moving = false;
                }

            })

            //INVESTIGATE WHY CAR SITS AT LIGHT

            const leftTrafficLight = width >= 1000 ? centre - 300 : (width * 0.25);
            const rightTrafficLight = width >= 1000 ? centre + 300 : (width * 0.75);

            // Stop cars at traffic lights
            if (lightIndex !== null) {
                if (trafficLight1?.sequence[lightIndex].red?.enabled && !trafficLight1?.sequence[lightIndex].green?.enabled && car.direction === 'clockwise' && inRangeOfPos(car.pos, leftTrafficLight, RADIUS.SMALL)) {//
                    car.moving = false;
                } else {

                }
            }

            if (lightIndex2 !== null) {
                if (t2?.sequence[lightIndex2].red?.enabled && !t2?.sequence[lightIndex2].green?.enabled && car.direction === 'anticlockwise' && inRangeOfPos(car.pos, rightTrafficLight, RADIUS.SMALL)) {
                    car.moving = false;
                }
            }

            // Stop all cars after collision
            if (collision) {
                car.moving = false;
            }

            // if crashed car, show
            if (collidedIds.find((id) => car.id === id)) {
                car.crashed = true;
                car.moving = false;
                collision = true;
            }

            if (car.crashed) {
                car.moving = false
            }

            if (collision) {
                if (onCrash) {
                    onCrash();

                }
            }


            if (car.completed) {
                if (!car.loop) {
                    car.hidden = true;
                    car.moving = false;
                }

                carsCompleted++;

            }


            return {
                id: car.id,
                moving: car.moving,
                crashed: car.crashed,
                completed: car.completed,
                onComplete: car.onComplete,
                pos: car.moving ? (car.direction === 'clockwise' ? car.pos + (carWidth * speed) : car.pos - (carWidth * speed)) : car.pos,
                direction: car.direction,
                central: car.central,
                hidden: car.hidden,
                hideOnComplete: car.hideOnComplete,
                loop: car.loop,
            }
        })

        if (cars && carsCompleted === cars.length && onRoadComplete && cars.length > 0) {
            onRoadComplete();
        }

        setCars(newCarPos);
    }

    // what about reset game with traffic lights??

    const resetGame = () => {
        resetCars();
        resetLights();
    }

    const resetCars = () => {
        setCars(carData);
    }

    const resetLights = () => {
        setLightIndex(null);
        setLightIndex2(null);
        setReset(true);
    }

    useEffect(() => {
        resetCars()
    }, [gameReset]);

    const [reset, setReset] = useState(false);

    useInterval(() => {
        updateCarPos();
    }, paused ? null : 250);// might do a double

    useEffect(() => {
        if (setPause)
            setPause(true);
    }, [setPause]);
    return (
        <>
            { setPause && paused ? <ResetButton onClick={() => setPause(false)}><FontAwesomeIcon icon={faPlay} />{t`Run Test`}</ResetButton> : null}

            {/* <ResetButton onClick={resetGame}><FontAwesomeIcon icon={faSync} />{t`Reset Game`}</ResetButton> */}
            <RoadWrapper>
                {/*<button onClick={resetLights}>Reset Lights</button>
            <button onClick={resetCars}>Reset Cars</button> */}
                {(trafficLight1 && trafficLight1.sequence) ? (
                    <TrafficWrapper position={"top_left"} >
                        <TrafficLightSequence highlight={trafficLight1.highlight} setLightIndex={setLightIndex} loop={true} sequence={trafficLight1.sequence} reset={reset} onClick={trafficLight1.onClick}></TrafficLightSequence>
                    </TrafficWrapper>
                ) : null}
                {debug ?
                    <>
                        <BridgeGuidelines left={bridgeLowerBound} right={bridgeUpperBound} />
                        <LightGuidelines x={leftTrafficLight} />
                        <LightGuidelines x={rightTrafficLight} />
                    </>
                    : null}
                <RoadStopLine position={'left'} x={leftTrafficLight} />
                <RoadStripes src={road_stripes} left={true} width={leftTrafficLight} />
                <RoadStripes src={road_stripes} left={false} width={leftTrafficLight} />
                <RoadStopLine position={'right'} x={rightTrafficLight} />
                <Cars debug={debug} cars={cars} scale={scale} />

                <Bridge position={"bottom"} src={bridge} />

                {(t2 && t2.sequence) ? (
                    <TrafficWrapper position={"bottom_right"} >
                        <TrafficLightSequence highlight={t2.highlight} setLightIndex={setLightIndex2} loop={true} sequence={t2.sequence} reset={reset} onClick={t2.onClick}></TrafficLightSequence>
                    </TrafficWrapper>
                ) : null}

            </RoadWrapper>
        </>
    );
}

interface LightGuide {
    x: number;
}
const LightGuidelines = styled.div<LightGuide>`
    position: fixed;
    border: solid red 2px;
        z-index: 999;
     height: 100%;
     left: ${(props) => props.x}px;
`;

interface BridgeGuide {
    left: number;
    right: number;
}
const BridgeGuidelines = styled.div<BridgeGuide>`
    position: fixed;
    border: solid cyan 2px;
        z-index: 999;
     height: 100%;
     left: ${(props) => props.left}px;
     width: ${(props) => props.right - props.left}px;
`;


const ResetButton = styled(Button)`
    svg { margin-right: 10px }
    bottom: 10px;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: 999;
`;

interface StopProps {
    x: number,
    position: string
}

const RoadStopLine = styled.div<StopProps>`
    width: 2%;
    max-width: 6px;
    height: calc( ${game.roadWidth / 2}px - 4%);
    position: absolute;

    background-color: rgba(255,255,255,0.2);
    ${(props) => props.position == 'left' ? 'top: 2%;' : 'bottom: 2%;'}
    left: ${(props) => props.x}px;
    @media ${breakpoint.mobileL}{
        ${'height:  calc(' + (game.roadWidth * game.scaleMobileL) / 2 + 'px - 4%)'};
    }
    @media ${breakpoint.laptop}{
        ${'height:  calc(' + (game.roadWidth * game.scaleLaptop) / 2 + 'px - 4%)'};
    }
    @media ${breakpoint.laptopL}{
        ${'height:  calc(' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px - 4%)'};
    }

`;

interface StripesProps {
    left: boolean,
    width: number,
}

const RoadStripes = styled.img<StripesProps>`
    width: 300%;
    position: absolute;
    bottom: 50%;
    opacity: 0.6;
    width: ${(props) => props.width}px;
    ${(props) => props.left ? 'left: 0;' : 'right: 0;'}

`;


const RoadWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    height: ${game.roadWidth}px;
    top: 50%;
    transform: translateY(-50%);
    @media ${breakpoint.mobileL}{
        ${'height: ' + (game.roadWidth * game.scaleMobileL) + 'px'};
    }
    @media ${breakpoint.laptop}{
        ${'height: ' + (game.roadWidth * game.scaleLaptop) + 'px'};
    }
    @media ${breakpoint.laptopL}{
        ${'height: ' + (game.roadWidth * game.scaleLaptopL) + 'px'};
    }
`


interface TrafficProps {
    position: string
}
export const TrafficWrapper = styled.div<TrafficProps>`
  position: absolute;
  z-index: 999;
  width: 10%;
  max-width: 40px;
  transform: translateX(-50%);
  &:hover{
      cursor: pointer;
      opacity: 0.9;
  }
  ${p => p.position === "top_left" ? `
    left: 35%;
    bottom:110%;
    @media(min-width:1000px){
        left: calc(50% - 150px);
        top: -150px;
    }
  ` : ''}
  ${p => p.position === "bottom_right" ? `
    left: 70%;
    bottom:-20%;
    @media(min-width:1000px){
        bottom: -40px;
        left: calc(50% + 150px);
    }
  ` : ''}

`
// Bridge for Level 1 Junction (2 pieces)

interface BridgeProps {
    position: string
}
const Bridge = styled.img<BridgeProps>`
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40%;
    z-index: 100;

    max-width: 130px;
    @media(min-width: 1000px){
        width: 300px;
    }

    ${'z-index: 100; bottom: calc(49% - ' + game.roadWidth / 2 + 'px)'};
    @media ${breakpoint.mobileL}{
        max-width: 172px;
        ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleMobileL) / 2 + 'px)'};
    }
    @media ${breakpoint.laptop}{
        max-width: 245px;
        ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleLaptop) / 2 + 'px)'};
    }
    @media ${breakpoint.laptopL}{
        max-width: 332px;
        ${'bottom: calc(49.5% - ' + (game.roadWidth * game.scaleLaptopL) / 2 + 'px)'};
    }
    @media(min-width: 1000px){
        max-width: 300px;
        width: 300px;
    }
`
export default Road;
