import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import { Button, Col, Row } from 'react-bootstrap';
import variables from '../../../../../assets/icons/icon_variables.svg';
import evaluation from '../../../../../assets/icons/icon_evaluation.svg';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const Recap: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const footer = <Button onClick={nextSlide}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    <h4 className={'mt-4'}>{t`You have completed`}</h4>
                    <h1>{t`Asteroid Blasters`}</h1>
                    <img className="w-50 mx-auto d-block mb-4" alt="" src={paint_stroke} />
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 6, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={variables} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'6'} className={'text-md-left'}>
                                <h3>{t`Variables`}</h3>
                                <p className={'mb-0'}>{t`You learnt what a variable is and why they're useful.`}</p>
                            </Col>
                        </Row>
                    </Content>
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 6, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={evaluation} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'6'} className={'text-md-left'}>
                                <h3>{t`Evaluation`}</h3>
                                <p className={'mb-0'}>{t`You made judgements on how to use the variables.`}</p>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default Recap;
