import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/2-whale-bowie.svg';
import { t } from 'ttag';

const Whale: TCharacter = {
    characterName: t`Whale`,
    characterImage: image,
    characterWidth: 150,
    characterHeight: 100,
};

export default Whale;
