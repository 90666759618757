import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import TrafficLight, { TTrafficLight } from './TrafficLight';

type TrafficLightEditorStaticProps = {
    onAnswerSubmit: (light: TTrafficLight) => void,
    onClose: () => void;
}

const TrafficLightEditorStatic: React.FC<TrafficLightEditorStaticProps> = ({ onAnswerSubmit, onClose }) => {
    const [light, setLight] = useState<TTrafficLight>({});


    const handleLightDataChange = (data: TTrafficLight) => {
        setLight(data);
    }

    return (
        <OverlayContainer initial={{ opacity: 0 }} animate={{ y: [100, 0], opacity: [0, 1] }} transition={{ duration: 0.4, ease: "easeInOut" }}>
            <Editor>
                <TrafficWrap>
                    <CloseButton onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} color={'#777777'} />
                    </CloseButton>

                    {
                        <TrafficLight {...light} showEditor={true} reportDataChange={handleLightDataChange}></TrafficLight>

                    }
                </TrafficWrap>

                <Button className="btn-3d btn-3d-dark mb-3 d-block mx-auto" onClick={() => onAnswerSubmit(light)}>{t`Change`}</Button>
            </Editor>
        </OverlayContainer>
    );
}
const CloseButton = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    background-color: #dddddd;
    svg { font-size: 14px !important;}
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.8
    }
`;

const TrafficWrap = styled.div`
    width: 50px;
    display: block;
    margin: 10px auto;

`;

const Editor = styled.div`
    width: 140px;
    height: 260px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 10px 25px rgba(0,0,0,0.25);
    text-align: center;
    padding-bottom: 20px;
    position: relative;

`;

const OverlayContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 75px;
    background-color: rgba(0,0,0,0.07);
    position: fixed;
    z-index: 998;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;
export default TrafficLightEditorStatic;
