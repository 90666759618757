import { motion, useAnimation } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import { sendAnalytics } from '../../../../../services/analytics';
import Slide, { ISlide } from '../../../../core/Slide';
import VoiceoverContext from '../../../../misc/voiceover-context';
import phisherman_head from '../../assets/characters/phisherman-head.svg';
import bronze_medal from '../../assets/gamification/bronze_medal.svg';
import end_slide_1 from '../../assets/gamification/end_slide-1.svg';
import end_slide_2 from '../../assets/gamification/end_slide-2.svg';
import end_slide_3 from '../../assets/gamification/end_slide-3.svg';
import end_slide_4 from '../../assets/gamification/end_slide-4.svg';
import gold_medal from '../../assets/gamification/gold_medal.svg';
import heart from '../../assets/gamification/heart.svg';
import silver_medal from '../../assets/gamification/silver_medal.svg';
import star from '../../assets/gamification/star.svg';

const Award: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, setFooter] = useState(<></>);

    // const [lives, setLives] = useState(data.lives);
    // const [points, setPoints] = useState(data.points);
    const [progress, setProgress] = useState(0);
    const [maxPoints, setMaxPoints] = useState(300);

    const [award, setAward] = useState(t`Cyber Apprentice`);
    const [medal, setMedal] = useState(bronze_medal);
    const { playVoiceover } = useContext(VoiceoverContext);


    const checkAward = () => {
        let score = data.lives * 30 + data.points;
        setTimeout(() => playVoiceover('end-awarded-1'), 9300);
        setTimeout(() => playVoiceover('end-phisherman-2'), 13000);

        if (score < 200 ){
            setTimeout(() => playVoiceover('end-award-1'), 11000);
        }
        if (score >= 250) {
            setAward(t`Cyber Master`);
            setMedal(gold_medal);
            setTimeout(() => playVoiceover('end-award-3'), 11000);
        } else if (score >= 200) {
            setAward(t`Cyber Pro`);
            setMedal(silver_medal);
            setTimeout(() => playVoiceover('end-award-2'), 11000);
        }

        sendAnalytics('cybersecurity', 'CYBER_AWARD', {
            award,
            score,
            lives: data.lives,
            points: data.points,
        });
    };
    useEffect(() => {
        checkAward();
        playVoiceover('end-awarding-1');
    }, []);

    const anim_lives = useAnimation();
    const anim_points = useAnimation();
    const anim_progress = useAnimation();
    const anim_medal = useAnimation();
    const anim_score = useAnimation();
    const anim_boom = useAnimation();

    async function sequence() {
        await anim_score.start({ opacity: 1, scale: 1 });
        await anim_lives.start({ opacity: 1, transition: { delay: 1 } });
        await anim_progress.start({ width: ((data.lives * 30) / maxPoints) * 100 + '%' });
        await anim_lives.start({ opacity: 0 });
        await anim_points.start({ opacity: 1 });
        await anim_progress.start({ width: ((data.lives * 30) / maxPoints + data.points / maxPoints) * 100 + '%' });
        await anim_points.start({ opacity: 0 });
        await anim_score.start({ opacity: 0 });
        await anim_medal.start({ opacity: 1, scale: 1, rotate: -1080 });
        await anim_boom.start({ opacity: 1, scale: 1 });
        setFooter(<Button onClick={nextSlide}>{t`Next`}</Button>);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            sequence();
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Slide header={defaultHeader} footer={footer}>
            <TitleWrapper>
                <StyledTitle className={'text-center'}>
                    <img src={phisherman_head} className="d-inline-block" width="60" height="60" />{' '}
                    {t`Phisherman defeated!`}{' '}
                    <PhishermanMessage
                        animate={{ scale: [0.9, 1], y: [10, 0], opacity: [0, 1] }}
                        transition={{ delay: 13, duration: 0.3 }}
                    >
                        <span>{t`Grrr, I'll get you next time!`}</span>
                    </PhishermanMessage>
                </StyledTitle>
            </TitleWrapper>
            <Avatar>
                <BoomWrapper animate={anim_boom} initial={{ opacity: 0, scale: 0.4 }} transition={{ duration: 0.5 }}>
                    <Boom
                        animate={{ rotate: [360, 0], x: ['-50%', '-50%'], y: ['-50%', '-50%'] }}
                        transition={{ duration: 16, loop: Infinity }}
                        src={end_slide_1}
                        style={{ opacity: 0.4 }}
                        width="80"
                    />
                    <Boom
                        animate={{ rotate: [0, 360], x: ['-50%', '-50%'], y: ['-50%', '-50%'] }}
                        transition={{ duration: 20, loop: Infinity }}
                        src={end_slide_2}
                        style={{ opacity: 0.6 }}
                        width="100"
                    />
                    <Boom
                        animate={{ rotate: [360, 0], x: ['-50%', '-50%'], y: ['-50%', '-50%'] }}
                        transition={{ duration: 15, loop: Infinity }}
                        src={end_slide_3}
                        style={{ opacity: 0.1 }}
                        width="60"
                    />
                    <Boom
                        animate={{ rotate: [0, 360], x: ['-50%', '-50%'], y: ['-50%', '-50%'] }}
                        transition={{ duration: 18, loop: Infinity }}
                        src={end_slide_4}
                        style={{ opacity: 0.1 }}
                        width="55"
                    />

                </BoomWrapper>
                <img src={data.avatar} alt={data.name} width="300" style={{ position: 'relative', maxWidth: '100%' }} />
            </Avatar>
            <Details>
                <Score animate={anim_score} initial={{ scale: 0.95, opacity: 0 }} transition={{ duration: 0.5 }}>
                    <Result>
                        <Lives animate={anim_lives} transition={{ duration: 0.4, delay: 0.7 }} initial={{ opacity: 0 }}>
                            <img src={heart} width="40" />
                            <span>{data.lives}</span>
                        </Lives>
                        <Points
                            animate={anim_points}
                            transition={{ duration: 0.4, delay: 0.7 }}
                            initial={{ opacity: 0 }}
                        >
                            <img src={star} width="40" />
                            <span>{data.points}</span>
                        </Points>
                    </Result>
                    <Progress>
                        <Gold src={gold_medal} width="35" />
                        <Silver src={silver_medal} width="35" />
                        <Bronze src={bronze_medal} width="35" />
                        <ProgressLevel animate={anim_progress} transition={{ duration: 1 }}></ProgressLevel>
                    </Progress>
                </Score>
                <Medal animate={anim_medal} initial={{ opacity: 0, scale: 0 }} transition={{ duration: 0.8 }}>
                    <div>
                        <img src={medal} alt={award + ' Award'} width="75" />
                        <h4 className={'mb-1'}>{ t`Award Received` }</h4>
                        <h2 className="mb-3">{award}</h2>
                    </div>
                </Medal>
            </Details>
        </Slide>
    );
};
const PhishermanMessage = styled(motion.p)`
    background-color: #ffffff;
    color: #6400aa;
    position: absolute;
    border-radius: 5px;
    margin-bottom: 0;
    height: 40px;
    top: -40px;
    left: 0;
    line-height: 18px;
    padding: 10px;
    font-size: 0.8rem;
    span {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 5px;
            bottom: -16px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-top: 5px solid #ffffff;
            border-right: 10px solid transparent;
            clear: both;
        }
    }
`;

const TitleWrapper = styled.div`
    display: block;
    width: 100%;
    text-align: center;
`;

const StyledTitle = styled.h2`
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.2rem;
    position: relative;
    margin-top: 45px;
    display: inline-block;
    margin-left: -10px;
    margin-right: -10px;
`;

interface BoomProps {
    width: number;
}
const BoomWrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    #confettiContainer {
        position: absolute;
        left: 50%;
        top: 50%;
    }
`;
const Boom = styled(motion.img)`
    width: ${(props) => props.width}%;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
`;

const Gold = styled.img`
    left: 83.33%;
    height: 100%;
    padding: 4px 0;
    position: absolute;
    z-index: 10;
    transform: translateX(-50%);
`;
const Silver = styled.img`
    left: 66.66%;
    height: 100%;
    padding: 4px 0;
    position: absolute;
    z-index: 10;
    transform: translateX(-50%);
`;

const Bronze = styled.img`
    left: 40%;
    height: 100%;
    padding: 4px 0;
    position: absolute;
    z-index: 10;
    transform: translateX(-50%);
`;

const Avatar = styled.div`
    max-width: 300px;
    display: block;
    margin: 15px auto;
    position: relative;
`;
const Details = styled.div`
    position: relative;
    display: block;
    margin: 15px auto;
    max-width: 400px;
`;
const Result = styled.div`
    margin-bottom: 1rem;
    text-align: center;
`;
const Progress = styled.div`
    background: #540e85;
    border: 3px solid #361559;
    border-radius: 100px;
    height: 40px;
    position: relative;
    overflow: hidden;
`;
const ProgressLevel = styled(motion.div)`
    position: absolute;
    height: 100%;
    left: -2px;
    background: linear-gradient(180deg, #3df3ff 0%, #005cb1 100%);
    width: 0%;
    border-right: solid 2px #00d1ff;
`;
const Medal = styled(motion.div)`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    transform-origin: center;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    img {
        max-width: 100%;
    }
`;
const Score = styled(motion.div)``;

const Lives = styled(motion.div)`
    span {
        margin-left: 15px;
        font-family: 'BT Font Extra Bold';
    }
    display: inline-block;
    opacity: 0;
`;

const Points = styled(motion.div)`
    span {
        margin-left: 15px;
        font-family: 'BT Font Extra Bold';
    }
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0;
`;

export default Award;
