import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { Button, Col, Row } from 'react-bootstrap';
import ModalOverlay from '../../../../general/ModalOverlay';
import { AbstractionData } from '../..';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import { t, jt } from 'ttag';

const StormOutro: React.FC<ISlide<AbstractionData>> = ({ nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue = [
        {
            character: RangerJack,
            text: t`${data.name} you are a superstar. You saved the animals from the storm! I don’t know what I’d do without you and your abstraction skills!`,
            buttonText: t`I’m just here to help`,
            buttonAction: () => setShow(true),
        },
    ];

    let title = <span className="font-bt-400">{t`Save the animals`}</span>;
    const header = (
        <React.Fragment>
            <h3>{jt`Mission 3: ${title}`}</h3>
            {/* <div className={'section--header__complete'}>COMPLETE</div> */}
        </React.Fragment>
    );

    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h2 className="text-white text-center">
                <i className="fas fa-cloud-sun-rain mr-2"></i> {t`STORM OVER`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );
    return (
        <Slide header={header} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />

            <ModalOverlay
                show={show}
                header={modalHeader}
                footer={<Button onClick={() => nextSlide()}>{t`Next`}</Button>}
            >
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                            <i className="fas fa-trophy mb-3 icon--achievement"></i>
                            <h2>{t`ANIMALS SAVED`}</h2>
                            <h5>{t`You saved all of Ranger Jack’s animals from the storm.`}</h5>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
        </Slide>
    );
};

export default StormOutro;
