import { motion } from 'framer-motion';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import ModalOverlay from '../../general/ModalOverlay';
import { TKitMatchItem, TKitMatchItemType, TKitMatchQuestion } from './KitMatch';

const CorrectModal: React.FC<{ question: TKitMatchQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-thumbs-up mr-2"></i> {t`CORRECT!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    const footer = <Button onClick={() => onClose()}>{t`Continue`}</Button>;

    const getCorrectItemByType = (id: string, type: TKitMatchItemType): TKitMatchItem | undefined => {
        return question.items.find((item) => {
            return item.tid === id && item.type === type;
        });
    };

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#00C3A5">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <div className={'ck-kit'}>
                        <img
                            className={'ck-kit__athlete'}
                            src={question.athlete}
                            height={'300px'}
                            width={'200px'}
                            alt={t`Correct athlete with kit`}
                        />
                        <div className="ck-kit__types-wrapper">
                            <div className={`ck-kit__type ck-kit__type--head`}>
                                <img
                                    className={`ck-kit__img ck-kit__img--head`}
                                    src={getCorrectItemByType(question.id, 'head')?.image}
                                    alt=""
                                />
                            </div>
                            <div className={`ck-kit__type ck-kit__type--top`}>
                                <img
                                    className={`ck-kit__img ck-kit__img--top`}
                                    src={getCorrectItemByType(question.id, 'top')?.image}
                                    alt=""
                                />
                            </div>
                            <div className={`ck-kit__type ck-kit__type--bottom`}>
                                <img
                                    className={`ck-kit__img ck-kit__img--bottom`}
                                    src={getCorrectItemByType(question.id, 'bottom')?.image}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <p>{t`You figured out the correct kit!`}</p>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default CorrectModal;
