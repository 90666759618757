import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import duck1 from '../../../../../assets/games/stock/so-algo-duck1.jpg';
import duck2 from '../../../../../assets/games/stock/so-algo-duck2.jpg';
import duck3 from '../../../../../assets/games/stock/so-algo-duck3.jpg';
import goat1 from '../../../../../assets/games/stock/so-algo-goat1.jpg';
import goat2 from '../../../../../assets/games/stock/so-algo-goat2.jpg';
import horse1 from '../../../../../assets/games/stock/so-algo-horse1.jpg';
import horse2 from '../../../../../assets/games/stock/so-algo-horse2.jpg';
import pig1 from '../../../../../assets/games/stock/so-algo-pig1.jpg';
import pig2 from '../../../../../assets/games/stock/so-algo-pig2.jpg';
import Slide, { ISlide } from '../../../../core/Slide';
import SortAlgorithmGame, { TSortAlgorithmQuestion } from '../../../../interactive/algorithmsort/SortAlgorithmGame';

const MissionAlgorithm: React.FC<ISlide> = ({ nextSlide }) => {
    const header = (
        <h3>
            {t`Mission 2: `}
            <span className="font-bt-400">{t`Sort the animals using an algorithm`}</span>
        </h3>
    );

    const gameData: TSortAlgorithmQuestion[] = [
        {
            question: {
                text: t`Create an algorithm to only select the HORSES`,
                animal: t`horse`,
                objects: [
                    {
                        name: t`horse`,
                        image: horse1,
                        tags: [t`has hooves`, t`has a mane`],
                    },
                    {
                        name: t`pig`,
                        image: pig1,
                        tags: [t`has a curly tail`, t`has a snout`],
                    },
                    {
                        name: t`goat`,
                        image: goat1,
                        tags: [t`has hooves`, t`has horns`],
                    },
                    {
                        name: t`duck`,
                        image: duck1,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`horse`,
                        image: horse2,
                        tags: [t`has hooves`, t`has a mane`],
                    },
                    {
                        name: t`pig`,
                        image: pig2,
                        tags: [t`has a curly tail`, t`has a snout`],
                    },
                    {
                        name: t`goat`,
                        image: goat2,
                        tags: [t`has hooves`, t`has horns`],
                    },
                    {
                        name: t`duck`,
                        image: duck2,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`duck`,
                        image: duck3,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                ],
            },
            answers: [t`has hooves`, t`has a mane`],
        },
        {
            question: {
                text: t`Create an algorithm to only select the DUCKS.`,
                animal: t`duck`,
                objects: [
                    {
                        name: t`horse`,
                        image: horse1,
                        tags: [t`has hooves`, t`has a mane`],
                    },
                    {
                        name: t`pig`,
                        image: pig1,
                        tags: [t`has a curly tail`, t`has a snout`],
                    },
                    {
                        name: t`goat`,
                        image: goat1,
                        tags: [t`has hooves`, t`has horns`],
                    },
                    {
                        name: t`duck`,
                        image: duck1,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`horse`,
                        image: horse2,
                        tags: [t`has hooves`, t`has a mane`],
                    },
                    {
                        name: t`pig`,
                        image: pig2,
                        tags: [t`has a curly tail`, t`has a snout`],
                    },
                    {
                        name: t`goat`,
                        image: goat2,
                        tags: [t`has hooves`, t`has horns`],
                    },
                    {
                        name: t`duck`,
                        image: duck2,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                    {
                        name: t`duck`,
                        image: duck3,
                        tags: [t`has wings`, t`has a beak`, t`has feathers`],
                    },
                ],
            },
            answers: [t`has wings`, t`has a beak`, t`has feathers`],
        },
    ];

    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <Row>
                <Col xs={'12'} className={'text-center'}>
                    <SortAlgorithmGame gameData={gameData} onGameComplete={nextSlide} />

                    {/* <Button onClick={()=>nextSlide()}>Next</Button> */}
                </Col>
            </Row>
        </Slide>
    );
};

export default MissionAlgorithm;
