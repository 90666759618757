import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import edit_frame from '../../assets/misc/edit-frame.png';
import { ToggleWrapper } from '../interactive/sequence/TrafficSequence';
import TrafficFrame from './TrafficFrame';
import TrafficLight, { TTrafficLight } from './TrafficLight';

type TrafficLightEditorProps = {
    answers: Partial<TTrafficLight>[],
    onCorrectAnswer: () => void,
    index?: number | null;
    updateCorrect: (correct: number) => void;
}

const TrafficLightEditor: React.FC<TrafficLightEditorProps> = ({ answers, onCorrectAnswer, index, updateCorrect }) => {
    const [lights, setLights] = useState<TTrafficLight[]>(new Array(answers.length).fill({}));
    const [lightIndex, setLightIndex] = useState<number>(0);

    useEffect(() => {
        let correct = 0;

        if (!answers || !lights) {
            return;
        }


        for (let i: number = 0; i < answers.length; i++) {
            if (
                (answers[i].red?.enabled ?? false) == lights[i].red?.enabled &&
                (answers[i].amber?.enabled ?? false) == lights[i].amber?.enabled &&
                (answers[i].green?.enabled ?? false) == lights[i].green?.enabled
            ) {
                correct++;
                updateCorrect(correct);
            }
        }

        if (correct === answers.length) {
            setLights(new Array(answers.length).fill({}));
            onCorrectAnswer();
        }
    }, [lights]);

    const handleLightDataChange = (data: TTrafficLight) => {
        if (lightIndex === null) {
            throw new Error('Index not set');
        }
        // console.log('new data', data);

        let newData = [...lights];
        newData[lightIndex] = data;
        setLights(newData);
    }

    return (
        <>
            {
                lights.map((light, i) => (
                    <>
                        { i === lightIndex ? <TrafficLight {...light} showEditor={true} reportDataChange={handleLightDataChange}></TrafficLight> : null}
                    </>
                ))
            }
            <ToggleWrapper>
                {
                    lights.map((light, i) => (
                        <Slot>
                            { i === lightIndex ? <img src={edit_frame} /> : null}
                            <TrafficFrame key={`tf${i}`} isActive={false} onClick={() => setLightIndex(i)} {...light} />
                        </Slot>
                    ))
                }
            </ToggleWrapper>

        </>
    );
}

export default TrafficLightEditor;

const Slot = styled.div`
    width: 39px;
    height: 65px;
    padding: 3px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px;
    position: relative;
    cursor: pointer;
    &:hover {
        div {
            opacity: 0.95;
        }
    }
    img {
        position: absolute;
        width: 140%;
        top: -8%;
        left: -20%;
        pointer-events: none;
        user-select: n
    }
`;
