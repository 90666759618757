import React, { useState, useEffect, useContext } from 'react';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import VoiceoverContext from '../../../../../misc/voiceover-context';

export type TPhoneCallQuestion = GameQuestion & {
    message: string;
    messageSpriteName: string;
    actions: TPhoneCallAction[];
};

type TPhoneCallAction = {
    text: string;
    textSpriteName: string;
    action?: () => void;
    icon?: string;
};

const PhoneCall: React.FC<GameProps<TPhoneCallQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const [initial, setInitial] = useState(false);
    const { playVoiceover } = useContext(VoiceoverContext);

    const handleNextCallMessage = () => {};

    const handleGameComplete = () => {
        onGameComplete();
    };
    const handleClick = () => {
        setShow(false);
        setTimeout(() => {
            nextCallMessage();
        }, 100);
        setTimeout(() => {
            setShow(true);
        }, 6000);
    };

    const [call, nextCallMessage] = useGameLogic<TPhoneCallQuestion>(
        gameData,
        handleGameComplete,
        handleNextCallMessage,
        index,
    );



    useEffect(() => {
        if (call) {
            playVoiceover(call.messageSpriteName);
            if(!initial){
                setInitial(true);
                const timer = setTimeout(() => {setShow(true)}, 3000);
                return () => {clearTimeout(timer);};
            }
        }
    }, [call?.message]);

    const renderActionButton = (action: TPhoneCallAction) => {
        return (

            <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                exit={{ opacity: 0 }}
            >
                <MessageBtn key={index + 'action'} onClick={action.action ??  handleClick} onMouseEnter={() => playVoiceover(action.textSpriteName)}>
                    {action.text}
                </MessageBtn>
            </motion.span>
        );
    }

    if (!call) {
        return null;
    }

    return (
        <>
            <StyledContainer>
                <PhoneHeader></PhoneHeader>
                <PhoneContent>
                    <Row>
                        <Col>
                            <Icon className="fa fa-volume-up" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Message>
                                <p>{call.message}</p>
                            </Message>
                        </Col>
                    </Row>
                    <PhoneContentAnswers  show={show} >
                    {call.actions.map((action) => renderActionButton(action))}
                    </PhoneContentAnswers>
                </PhoneContent>
            </StyledContainer>
        </>
    );
};

const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
`;

const Message = styled.div`
    p {
        margin-top: 1rem;
    }
`;

const Icon = styled.i`
    font-size: 2rem;
    @media screen and (min-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 3rem;
    }
`;

interface BtnProps {
    show: boolean
}
const MessageBtn = styled(Button)`
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 100%;
    margin-bottom: 15px;
    color: #6400aa;
`;
const UserCircle = styled.div`
    border-radius: 50%;
    width: 100%;
    padding-top: 100%;
    position: relative;
    max-height: 40px;
    p {
        margin-bottom: 0;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
    background-color: #3b0a5d;
`;

const App = styled.img`
    width: 100%;
    padding-left: 10%;
    display: block;
`;

const PhoneContent = styled.div`
    flex: 1;
    padding: 5% 5% 3% 5%;
    display: flex;
    flex-flow: column;
`;

const PhoneContentAnswers = styled.div<BtnProps>`
    margin-top: auto;
    align-self: flex-end;
    width: 100%;
    display: ${(props) => props.show ? 'block' : 'none'};
`;

const PhoneHeader = styled.div`
    width: 100%;
    padding: 10% 5% 3% 5%;
    flex-shrink: 0;
`;

const PhoneFooter = styled.div`
    height: 13%;
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    @media screen and (min-width: 1000px) {
        display: block;
    }
    overflow: hidden;
`;

export default PhoneCall;
