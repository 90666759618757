import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import CaesarCipher, { TCaesarCipherQuestion } from '../../../../interactive/caesarcipher/CaesarCipher';

const CaesarCipherGame: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TCaesarCipherQuestion[] = [
        {
            answer: t`READY THE PLANES`,
            answerOffset: 1,
            offsetInputDisabled: true,
            offset: 1,
        },
        {
            answer: t`START AT NOON`,
            answerOffset: 3,
            offsetInputDisabled: true,
            offset: 3,
        },
        {
            answer: t`GOOD LUCK SOLDIERS`,
            answerOffset: 10,
            offsetInputDisabled: true,
            offset: 10,
        },
    ];

    const header = (
        <h3 className={'cc-header--small'}>
            {t`Code Cracking:`} <span className="font-bt-400">{t`Decipher the code using the cipher wheel!`}</span>
        </h3>
    );

    return (
        <Slide header={header}>
            <CaesarCipher gameData={gameData} onGameComplete={nextSlide}></CaesarCipher>
        </Slide>
    );
};

export default CaesarCipherGame;
