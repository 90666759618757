import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/2-octopus.svg';
import { t } from 'ttag';

const Squid: TCharacter = {
    characterName: t`Octo`,
    characterImage: image,
    characterWidth: 150,
    characterHeight: 124,
};

export default Squid;
