import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { GlibGlob } from '../../characters/Aliens';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: GlibGlob,
            text: t`Hi! I am Fizz, the captain of the Asteroid Blaster ship. It’s a pleasure to have you onboard. What's your alien name?`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
