import React, { useContext, useEffect, useState } from 'react';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import pattern from './units/2-pattern';
import logic from './units/3-logic';
import debugging from './units/4-debugging';
import recap from './units/5-recap';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type ColourfulKitsData = {
    name: string;
    setName: (name: string) => void;
};

const ColourfulKits: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [StartSlide, ...intro, ...pattern, ...logic, ...debugging, ...recap, EndSlide];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>

        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeAthletics.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeAthletics.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Module
                devIndex={0}
                id="23f71141-e16b-494c-a79c-135e343d6ccb"
                name="colourfulkits"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
            />
        </React.Fragment>
    );
};

export default ColourfulKits;
