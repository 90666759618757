import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import Island from '../../components/Island';
import { CyberSecurityData } from '../..';
import { t } from 'ttag';

const TurtleIsland: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    useEffect(() => {
        data.setAvailableIslandAreas([{ name: 'turtle', onSelect: () => nextSlide() }]);
    }, []);

    return (
        <Slide header={defaultHeader} backgroundColor={'rgba(0,0,0,0.4);'} footerHidden={true}>
            <Island
                notification={data.availableIslandAreas}
                nextSlide={nextSlide}
                infoText={t`Select Turtle to continue`}
            ></Island>
        </Slide>
    );
};

export default TurtleIsland;
