import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import blueprint from './units/2-blueprint';
import parachute from './units/3-parachute';
import keypad from './units/4-keypad';
import keypad2 from './units/5-keypad2';
import vault from './units/6-vault';
import end from './units/7-end';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type DiamondData = {
    name: string;
    setName: (name: string) => void;
};

const Diamond: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [
        StartSlide,
        ...intro,
        ...blueprint,
        ...parachute,
        ...keypad,
        ...keypad2,
        ...vault,
        ...end,
        EndSlide,
    ];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>
        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeHeist.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeHeist.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>The Diamond | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="fea56b0a-1024-4b36-8036-d7efacfedf25"
                name="thediamond"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
            />
        </React.Fragment>
    );
};

export default Diamond;
