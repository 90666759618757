import { motion, useMotionValue } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../../../../../hooks/window';
import car_left_crashed from '../../../assets/car/left-blue-crashed.svg';
import car_left from '../../../assets/car/left-blue.svg';
import car_right_crashed from '../../../assets/car/right-crashed.svg';
import car_right from '../../../assets/car/right.svg';
import { TCar } from './Road';


const Car: React.FC<TCar & { scale: number }> = ({ pos = 0, direction, moving, central = false, hidden = false, crashed = false, scale = 1, debug = false }) => {
    const { width } = useWindowDimensions();
    const carPos = useMotionValue(pos);
    const [carImage, setCarImage] = useState('');

    const moveCar = (distance: number = 100) => {
        carPos.set(carPos.get() + distance)
    }

    const bottomPercentage = direction === 'clockwise' ? '42.5%' : '12.0%'

    useEffect(() => {
        // console.log(carPos.get() + (100 + (pos - carPos.get())), direction)
        // console.log(carPos.get(), pos, pos - carPos.get())
        if (moving) {
            moveCar((direction === 'clockwise' ? 100 * scale : -100 * scale) + (pos - carPos.get()))
        }
    }, [pos])

    useEffect(() => {
        // console.log(carPos.get() + (100 + (pos - carPos.get())), direction)
        // console.log(carPos.get(), pos, pos - carPos.get())
        if (moving) {
            if (direction === 'clockwise') {
                setCarImage(car_right)
            }
            if (direction === 'anticlockwise') {
                setCarImage(car_left)
            }
        }
        else {
            if (direction === 'clockwise' && crashed) {
                setCarImage(car_right_crashed)
            }
            if (direction === 'anticlockwise' && crashed) {
                setCarImage(car_left_crashed)
            }
        }
    }, [moving])

    return (
        <motion.div style={{
            width: `${120 * scale}px`,
            // bottom: direction === 'clockwise' ? '42.5%' : '15.0%',
            position: 'absolute',
            visibility: hidden ? 'hidden' : 'visible',
            zIndex: 15,

        }}
            initial={{
                x: pos,
                bottom: bottomPercentage,
            }}
            animate={{
                x: pos,
                bottom: central ? '30%' : bottomPercentage,
                opacity: hidden ? 0 : 1
            }}
            transition={{
                ease: "linear",
                duration: 0.25,
                bottom: {
                    duration: 0.5,
                },
                opacity: {
                    duration: 0.2
                }
            }}

        ><StyledCar debug={debug && central} left={direction === 'clockwise'} src={carImage} /></motion.div>
        // <CarImage src={car_right} />
    );
}


const StyledCar = styled.img<{ left: boolean, debug: boolean }>`
   width: 100%;
   margin-left: ${(props) => props.left ? '-40%' : '0'};
   ${(props) => props.debug ? 'border: solid red 1px;' : 'border: none;'}

`;

export default Car;
