import React, { useContext, useState } from 'react';
import { t } from 'ttag';
import sfx from '../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../core/Game';
import { VolumeContext } from '../../../misc/volume-context';
import CorrectModal from './CorrectModal';
import IncorrectModal from './IncorrectModal';
import MultiChoiceQuestion from './MultiChoiceQuestion';

export type TMultiChoiceAnswer = {
    text: string;
    correct: boolean;
    completed?: boolean;
};

export type TMultiChoiceQuestion = GameQuestion & {
    question: string;
    hint?: string;
    questionContext?: string;
    answers: Array<TMultiChoiceAnswer>;
};

interface MultiChoiceProps {
    onIncorrectAnswer: () => void;
    onCorrectAnswer?: () => void;
    incorrectModalDisabled?: boolean;
    forceOnAnswer?: boolean
}

const MultipleChoice: React.FC<GameProps<TMultiChoiceQuestion> & MultiChoiceProps> = ({
    gameData,
    index = 0,
    onGameComplete,
    onCorrectAnswer = () => {},
    onIncorrectAnswer,
    incorrectModalDisabled = false,
    forceOnAnswer = false,
}) => {
    const [show, setShow] = useState(false);
    const [showIncorrectModal, setShowIncorrectModal] = useState(false);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TMultiChoiceQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    const handleIncorrectAnswer = () => {
        if (volume) sfx.incorrect.play();



        if (incorrectModalDisabled) {
            setTimeout(onIncorrectAnswer, 0);
        } else {
            if (forceOnAnswer) {
                setTimeout(onIncorrectAnswer, 0);
            }
            setShowIncorrectModal(true);
        }
    };

    const handleIncorrectModalClose = () => {
        setShowIncorrectModal(false);
        if (!forceOnAnswer) {
            setTimeout(onIncorrectAnswer, 0);
        }
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <IncorrectModal
                show={showIncorrectModal}
                question={question}
                onClose={handleIncorrectModalClose}
            ></IncorrectModal>
            <MultiChoiceQuestion
                question={question}
                onQuestionComplete={handleQuestionComplete}
                onCorrectAnswer={onCorrectAnswer}
                onIncorrectAnswer={handleIncorrectAnswer}
            />
        </React.Fragment>
    );
};

export default MultipleChoice;
