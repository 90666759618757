import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import pattern_snowflake from '../../../assets/boxing/pattern-snow.svg';

interface BtnProps {
    icon: string;
    title_top: string;
    title_bottom: string;
    active: boolean;
    read: boolean;
    handleClick?: () => void;
    hoverIndex: () => void;
}

const CharacteristicBtn: React.FC<BtnProps> = ({
    icon,
    title_top,
    title_bottom,
    active,
    read,
    hoverIndex,
    handleClick,
}) => {
    return (
        <CharSlot>
            <CharBtn read={read} active={active} onFocus={hoverIndex} onMouseEnter={hoverIndex} onClick={handleClick}>
                <img src={icon} />
            </CharBtn>
            <CharTitle>
                {title_top}
                <br />
                {title_bottom}
            </CharTitle>
        </CharSlot>
    );
};

interface StyledProps {
    active: boolean;
    read: boolean;
}

const CharSlot = styled.div`
    width: 20%;
    padding: 0 5px;
    @media (min-width: 650px) {
        padding: 0 10px;
    }
`;
const CharBtn = styled.button<StyledProps>`
  background-color: #E60050;
  border: solid 3px transparent;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  img {width: 100%}
  ${(props) => (props.active ? 'border-color: rgba(255,255,255,0.5);' : null)}
  ${(props) => (props.read ? 'background-color: #cccccc;' : null)}
  ${(props) => (props.read ? ' filter: grayscale(100%);' : null)}
  &:hover{
    background-color: #FF1768;
  }
`;

const CharTitle = styled.h4`
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 2.75vw;
    font-size: 2.75vw;
    font-family: 'BT Font Extra Bold';
    @media (min-width: 650px) {
        font-size: 18px;
        line-height: 20px;
    }
`;

export default CharacteristicBtn;
