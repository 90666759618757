import TurtleQuestion1 from './TurtleQuestion1';
import TurtleIntro2 from './TurtleIntro2';
import TurtleIntro from './TurtleIntro';
import TurtleIsland from './TurtleIsland';
import Welcome from './Welcome';
import TurtleOutro from './TurtleOutro';
import YourDetails from './YourDetails';
import PersonalDetails from './PersonalDetails';
import TurtleIntro3 from './TurtleIntro3';

export default [
    Welcome,
    TurtleIsland,

    TurtleIntro,
    PersonalDetails,
    YourDetails,

    TurtleIntro2,
    TurtleQuestion1,

    TurtleIntro3,

    TurtleOutro,
];
