import React from 'react';
import { ISlide } from '../../../../core/Slide';
import TaskSlide from '../../../../general/TaskSlide';
import { t } from 'ttag';

const ShapeGroupingIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <TaskSlide onStart={nextSlide} header={defaultHeader} number={1}>
            {t`Help Anita create a new logo by grouping shapes by their common features.`}
        </TaskSlide>
    );
};

export default ShapeGroupingIntro;
