import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Grass: React.FC = ({ children }) => {
    return (
        <div className="app-wrapper">
            <div className="bg-wrapper">
                <Green />

            </div>
            {children}
        </div>
    );
};


const Green = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(0, 195, 165);

`;

export default Grass;
