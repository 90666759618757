import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import large from '../../../../assets/games/space/asteroid--large.svg';
import small from '../../../../assets/games/space/asteroid--small.svg';
import rocket from '../../../../assets/games/space/spaceship.svg';
import stars_1 from '../../../../assets/games/space/stars_1.svg';
import stars_2 from '../../../../assets/games/space/stars_2.svg';
import stars_3 from '../../../../assets/games/space/stars_3.svg';
import { randomIntFromBetween } from '../../../../helpers/numbers';
import Slide, { ISlide } from '../../../core/Slide';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );
    const [smallX, setSmallX] = useState(randomIntFromBetween(8, 92));
    const [smallY, setSmallY] = useState(randomIntFromBetween(0, 280));
    const [largeX, setLargeX] = useState(randomIntFromBetween(8, 92));
    const [largeY, setLargeY] = useState(randomIntFromBetween(0, 280));

    const controls_small = useAnimation();
    const controls_large = useAnimation();

    useEffect(() => {
        const moveSmall = () => {
            setSmallX(randomIntFromBetween(8, 92));
            setSmallY(randomIntFromBetween(0, 280));
            controls_small.start({
                scale: [0, 1.1, 1, 1, 1.1, 0],
                transition: { duration: 2, times: [0, 0.2, 0.3, 0.7, 0.8, 1] },
            });
        };

        const moveLarge = () => {
            setLargeX(randomIntFromBetween(8, 92));
            setLargeY(randomIntFromBetween(0, 280));
            controls_large.start({
                scale: [0, 1.1, 1, 1, 1.1, 0],
                transition: { duration: 2, times: [0, 0.2, 0.3, 0.7, 0.8, 1] },
            });
        };

        let a = setInterval(() => moveSmall(), 5000);
        let b = setInterval(() => moveLarge(), 3000);

        return () => {
            clearInterval(a);
            clearInterval(b);
        };
    }, [controls_small, controls_large]);

    return (
        <Slide header={defaultHeader} footer={begin}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                    <StartContent>
                        <Asteroid top={smallY} left={smallX}>
                            <motion.img
                                src={small}
                                animate={controls_small}
                                className={'va-asteroid va-asteroid--small'}
                            />
                        </Asteroid>
                        <Asteroid top={largeY} left={largeX}>
                            <motion.img src={large} animate={controls_large} className={'va-asteroid'} />
                        </Asteroid>
                        <motion.img
                            src={rocket}
                            animate={{
                                y: [-0, 50, -25, 0, -15, 20, 40, 5, 0],
                                x: [0, -25, 0, -30, -10, 15, 30, 10, 0],
                            }}
                            transition={{
                                x: { type: 'inertia', loop: Infinity, duration: 30, ease: 'easeInOut' },
                                y: { type: 'inertia', loop: Infinity, duration: 30, ease: 'easeInOut' },
                            }}
                            className={'va-rocket'}
                        />
                        <motion.img
                            src={stars_1}
                            animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                            transition={{ loop: Infinity, duration: 10 }}
                            className={'va-stars va-stars--home'}
                        />
                        <motion.img
                            src={stars_2}
                            animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                            transition={{ loop: Infinity, duration: 8, delay: 4 }}
                            className={'va-stars va-stars--home'}
                        />
                        <motion.img
                            src={stars_3}
                            animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                            transition={{ loop: Infinity, duration: 12, delay: 8 }}
                            className={'va-stars va-stars--home'}
                        />
                        <TitleWrap>
                            <Title className={'text-center'}>{t`Asteroid Blasters`}</Title>
                        </TitleWrap>
                    </StartContent>
                </Col>
            </Row>
        </Slide>
    );
};

const Title = styled.h1`
    font-family: 'BT Font Extra Bold';
    background-color: black;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid #e60050;
    margin: 0;
    font-size: 30px;
`;

const TitleWrap = styled.div`
    font-family: 'BT Font Extra Bold';
    background-color: transparent;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #ffe202;
    position: relative;
    z-index: 999;
`;

const StartContent = styled.div`
    font-family: 'BT Font Extra Bold';
    width: 100%;
    text-align: center;
    position: relative;
    padding: 30px 0;
    overflow: hidden;
`;
interface WrapperProps {
    top: number;
    left: number;
}

const Asteroid = styled.div<WrapperProps>`
    position: absolute;
    bottom: ${(props) => props.top || '0'}px;
    left: calc(${(props) => props.left || '0'}% - 20px);
    height: 40px;
    width: 40px;
    z-index: 0;
`;

export default StartSlide;
