import React from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Turtle from '../../characters/Turtle';

const TurtleIntro2: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`${data.name}, you look great! As you complete challenges on our adventure you’ll earn Cyber Points so you can see how well you are doing.`,
            textSpriteName: 'turtle-2-1',
            buttonText: t`Next`,
        },
        {
            character: Turtle,
            text: t`But for every mistake you make, you’ll also lose a life. In a moment you shall be able to see how many lives you have left at the top of your screen.`,
            textSpriteName: 'turtle-2-2',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TurtleIntro2;
