import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import lang_en from '../../assets/misc/lang_en.svg';
import lang_cy from '../../assets/misc/lang_wa.svg';
import { addLocale, useLocale } from 'ttag';
import useLanguage from '../../hooks/language';

const LangButton: React.FC = () => {
    const [lang, setLang] = useLanguage(Cookies.get('bfg_lang') ?? 'en');

    return (
        <React.Fragment>
            <Dropdown className={'lang-switch'}>
                <Dropdown.Toggle className={'lang-switch__btn'} id="langSwitch">
                    {lang === 'cy' ? (
                        <img src={lang_cy} width={30} height={20} />
                    ) : (
                        <img src={lang_en} width={30} height={20} />
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {lang !== 'en' ? (
                        <Dropdown.Item
                            onClick={() => {
                                if (window.confirm('This action will reset your progress. Do you want to continue?')) {
                                    setLang('en');
                                    window.location.reload();
                                }
                            }}
                        >
                            <img src={lang_en} alt={'English Language'} width={30} height={20} />
                        </Dropdown.Item>
                    ) : null}
                    {lang !== 'cy' ? (
                        <Dropdown.Item
                            onClick={() => {
                                if (window.confirm('This action will reset your progress. Do you want to continue?')) {
                                    setLang('cy');
                                    window.location.reload();
                                }
                            }}
                        >
                            <img src={lang_cy} alt={'Welsh Language'} width={30} height={20} />
                        </Dropdown.Item>
                    ) : null}
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
};

export default LangButton;
