import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Whale from '../../characters/Whale';


const WhaleOutro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data, gotoSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    useEffect(() => {
        data.setAvailableIslandAreas([
            { name: 'seal', onSelect: () => gotoSlide(38) },
            { name: 'pufferfish', onSelect: () => gotoSlide(21) },
            { name: 'squid', onSelect: () => gotoSlide(32) },
        ]);
    }, []);

    const dialogue: DialogueData[] = [
        {
            character: Whale,
            text: t`Hopefully you learned a thing or two about how to spot phishing scams. Now see if you can help out the other villagers!`,
            textSpriteName: 'whale-3-1',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default WhaleOutro;

// switch (index) {
//     case 0: {
//         return t`Phishing scams can be littered with poor grammar and spelling errors. This is a warning sign as companies avoid communicating in such an unprofessional way.`;
//     }
//     case 1: {
//         return t`Intimidation by putting pressure on you to make a decision is a popular tactic for phishing scams. Phrases such as 'Urgent action required' are common.`;
//     }
//     case 2: {
//       return t`Extremely good offers and eye-catching or attention-grabbing statements are designed to attract your attention immediately, but don't fall for it!`;
//     }
//     case 3: {
//       return t`Some phishing scams look genuine but often there are strange quirks in there design that give it away. Keep an eye out for something looking a bit off!`;
//     }
//     case 4: {
//       return t`Whether it looks like it's from someone you don't know or someone you do know, if anything seems out of the ordinary or just suspicious in general don't click on it!`;
//     }
