import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AsteroidGame, { TAsteriodQuestion } from '../../../../interactive/asteroid/AsteroidGame';
import smallAsteroid from '../../../../../assets/games/space/asteroid--small.svg';
import largeAsteroid from '../../../../../assets/games/space/asteroid--large.svg';
import { VariablesData } from '../..';
import TimeoutModal from '../../components/TimeoutModal';
import { t } from 'ttag';

const MissionGame: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data, currentIndex, gotoSlide }) => {
    const [show, setShow] = useState(false);
    const gameData: TAsteriodQuestion[] = [
        {
            scoreVisible: true,
            zinonVisible: true,
            nameVisible: true,
            name: data.name,
            duration: 45,
            types: [
                { asteroidValue: 1, asteroidName: t`Small`, asteroidImage: smallAsteroid, scoreVisible: true },
                {
                    asteroidValue: 2,
                    asteroidName: t`Large`,
                    asteroidImage: largeAsteroid,
                    scoreVisible: true,
                    asteroidWidth: 40,
                    zinionEnabled: true,
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true} overflow={false}>
            <TimeoutModal show={show} onClose={() => gotoSlide(currentIndex - 1)} />
            <AsteroidGame
                gameData={gameData}
                onGameComplete={() => {}}
                reportGameScore={(score: number) => {
                    data.setGameScore(score);
                    if (score >= 20) {
                        nextSlide();
                    } else {
                        setShow(true);
                    }
                }}
            ></AsteroidGame>
        </Slide>
    );
};

export default MissionGame;
