import React from 'react';
import styled from 'styled-components';
import festive_landscape from '../assets/backgrounds/festive_landscape_2.svg';
import bg_test from '../assets/backgrounds/bg-image.svg';
import { motion } from 'framer-motion';
import Particles, { MoveDirection, OutMode } from 'react-particles-js';

export const Wrapper: React.FC = ({ children }) => {
    return (
        <div className="app-wrapper">
            <div className="bg-wrapper">
                <Gradient />
                <Pattern />
                <Bubbles params={bubblez} />
                <Landscape src={festive_landscape} />
            </div>
            {children}
        </div>
    );
};

const bubblez = {
    particles: {
        number: {
            value: 160,
            density: {
                enable: false,
            },
        },
        size: {
            value: 3,
            random: true,
            anim: {
                speed: 4,
                size_min: 0.3,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            random: true,
            speed: 1,
            direction: MoveDirection.bottom,
            outMode: OutMode.out,
        },
    },
    interactivity: {
        events: {
            resize: true,
        },
    },
};
const Bubbles = styled(Particles)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
const Landscape = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
`;
const Gradient = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(127, 33, 193);
    background: -moz-radial-gradient(
        circle,
        rgba(127, 33, 193, 1) 0%,
        rgba(100, 0, 170, 1) 60%,
        rgba(74, 5, 122, 1) 100%
    );
    background: -webkit-radial-gradient(
        circle,
        rgba(127, 33, 193, 1) 0%,
        rgba(100, 0, 170, 1) 60%,
        rgba(74, 5, 122, 1) 100%
    );
    background: radial-gradient(circle, rgba(127, 33, 193, 1) 0%, rgba(100, 0, 170, 1) 60%, rgba(74, 5, 122, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7f21c1",endColorstr="#4a057a",GradientType=1);
`;
const Pattern = styled.div`
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    opacity: 1;
    background: url(${bg_test}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
`;

export default Wrapper;
