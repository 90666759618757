import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentRio from '../../characters/AgentRio';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const KeypadGameIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentRio,
            text: t`Brilliant. Looks like it’s another of Dr Mischief’s mischievous sequences. Let’s see if you can complete it yourself using your logical reasoning skills. This one seems trickier...`,
            buttonText: t`Continue`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default KeypadGameIntro;
