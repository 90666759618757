import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import room from '../../../../../assets/misc/th-room--vault.svg';
import diamond from '../../../../../assets/misc/th-diamond.svg';
import arm from '../../../../../assets/misc/th-diamond__arm.svg';
import { motion, useAnimation } from 'framer-motion';
import { t } from 'ttag';

const DiamondGrab: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const controls_diamond = useAnimation();
    const controls_arm = useAnimation();

    const triggerGrab = () => {
        controls_diamond.start({
            x: -250,
            y: 0,
            transition: { duration: 0.7, delay: 0.7, ease: 'linear' },
        });
        controls_arm.start({
            x: [-10, 220, -30],
            y: [10, 0, 20],
            rotate: [-20, 0, -20],
            transition: { duration: 1.5 },
        });
        setTimeout(() => nextSlide(), 2000);
    };

    return (
        <Slide header={defaultHeader}>
            <h3 className={'my-3 text-center'}>{t`GRAB DIAMOND`}</h3>
            <div className={`island island--rounded bg-secondary-700 th-part`}>
                <img src={room} className={'th-part__room'} width={'300px'} height={'350px'} alt="" />
                <motion.img
                    src={diamond}
                    className={'th-part__diamond'}
                    width={'60px'}
                    height={'60px'}
                    animate={controls_diamond}
                    onClick={() => triggerGrab()}
                    alt={t`The Diamond in the open vault`}
                />
                <motion.img
                    src={arm}
                    className={'th-part__arm'}
                    width={'160px'}
                    height={'150px'}
                    animate={controls_arm}
                    alt=""
                />
            </div>
        </Slide>
    );
};

export default DiamondGrab;
