import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

interface ModalOverlayProps extends ModalProps {
    show: boolean;
    header: JSX.Element | null;
    footer: JSX.Element | null;
    backgroundColor?: string;
    headerHidden?: boolean;
}

const ModalOverlay: React.FC<ModalOverlayProps> = (props) => {
    return <Modal dialogAs={Default} {...props}></Modal>;
};

const Default: React.FC<{
    header: JSX.Element;
    footer: JSX.Element;
    backgroundColor: string;
    headerHidden: boolean;
}> = ({ header, footer, children, backgroundColor = '#00A0D6', headerHidden }) => {
    // return (
    //     <Slide header={<span></span>}>
    //         {children}
    //     </Slide>
    // );
    // TODO: Convert to slide
    return (
        <AppWrapper className="app-wrapper-overlay" backgroundColor={backgroundColor}>
            <div className="content-wrapper">
                {!headerHidden ? (
                    <section className="section--header section--header-overlay">
                        <Container className="h-100">
                            <Row className="h-100 align-items-end justify-content-center">
                                <Col>{header}</Col>
                            </Row>
                        </Container>
                    </section>
                ) : null}
                <motion.section className="section--main" initial="hidden" animate="visible" variants={variants}>
                    <Container className="h-100">
                        <Row className="h-100 align-items-center justify-content-center">
                            <Col>{children}</Col>
                        </Row>
                    </Container>
                </motion.section>
                <FooterSection className="section--footer" backgroundColor={backgroundColor}>
                    <div className="section--footer__content">
                        <Container>
                            <Row>
                                <Col>{footer}</Col>
                            </Row>
                        </Container>
                    </div>
                </FooterSection>
            </div>
        </AppWrapper>
    );
};

const AppWrapper = styled.div`
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
`;

const FooterSection = styled.section`
    background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
`;

export default ModalOverlay;
