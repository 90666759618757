import React, { useState } from 'react';
import styled from 'styled-components';

interface EmailComponentProps {
    enabled: boolean;
    classes?: string;
    onSelect: () => void;
}

const EmailComponent: React.FC<EmailComponentProps> = ({ children, enabled, onSelect, classes = '' }) => {
    const [answered, setAnswered] = useState(false);
    const [hover, setHover] = useState(false);

    const hovering = enabled && hover && !answered;

    const handleClick = () => {
        setAnswered(true);
        onSelect();
    };

    return (
        <Wrapper
            className={classes}
            hover={hovering}
            onMouseEnter={() => enabled && !answered && setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => (enabled && !answered ? handleClick() : undefined)}
        >
            <Highlight hover={hovering}>{children}</Highlight>
        </Wrapper>
    );
};

const Highlight = styled.div<{ hover: boolean }>`
    cursor: pointer;
    display: inline-block;
    border: solid transparent 2px;
    border-color: ${(props) => (props.hover ? 'pink' : 'transparent')};
`;

const Wrapper = styled.div<{ hover: boolean }>`
    width: 100%;
    display: block;
    &.email-button-attachment {
        ${Highlight} {
            padding: 2% 8% 2% 1%;
        }
        button {
            padding: 5% 10% !important;
        }
    }
`;
export default EmailComponent;
