import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { ZookeeperLeanne } from '../../../sorting/characters/Zookeepers';
import { SortingData } from '../..';
import algorithm from '../../../../../assets/icons/icon_algorithms.svg';
import SkillModal from '../../../../general/SkillModal';

const MissionOutro: React.FC<ISlide<SortingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: ZookeeperLeanne,
            text: t`Awesome! Making algorithms with those rules makes sorting the animals so easy!`,
            buttonText: t`Next`,
        },
        {
            character: ZookeeperLeanne,
            text: t`All of the animals are finally in the right place. Your algorithms may have saved the farm! Thanks ${data.name}!`,
            buttonText: t`Woohoo!`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`ALGORITHMS`}
                skillImage={algorithm}
                skillDescription={t`You created sets of rules to sort the animals.`}
            />
        </Slide>
    );
};

export default MissionOutro;
