import React from 'react';
import ModalOverlay from './ModalOverlay';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

interface IDiscoveryModal {
    show: boolean;
    onClose: () => void;
    word: string;
    wordDefinition: string;
    title?: string;
}

const WordDiscovery: React.FC<IDiscoveryModal> = ({
    onClose,
    show,
    word,
    wordDefinition,
    title = t`WORD DISCOVERED`,
}) => {
    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h3 className="text-white text-center">
                <i className="fas fa-star mr-2"></i> {title}
            </h3>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    return (
        <ModalOverlay
            show={show}
            header={modalHeader}
            footer={<Button onClick={onClose}>{t`Next`}</Button>}
            backgroundColor="#E60050"
        >
            <Row>
                <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                    <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                        <i className={'fas fa-star icon--achievement mb-3'}></i>
                        <h2>{word}</h2>
                        <h5>{wordDefinition}</h5>
                    </motion.div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default WordDiscovery;
