import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';

const VariablesQuiz: React.FC<ISlide> = ({ defaultHeader, nextSlide, gotoSlide, currentIndex }) => {
    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`How should we calculate the total score of asteroids blasted?`,
            answers: [
                { text: t`Total = Small Asteroids + Large Asteroids`, correct: true },
                { text: t`Total = Small Asteroids - Large Asteroids`, correct: false },
                { text: t`Total = Small Asteroids x Large Asteroids`, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Row>
                <Col xs={12} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }} xl={{ offset: 3, span: 6 }}>
                    <Content type={'island'} styles={'p-3 mt-2'}>
                        <MultipleChoice
                            gameData={gameData}
                            onGameComplete={nextSlide}
                            onIncorrectAnswer={() => gotoSlide(currentIndex - 1)}
                        />
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default VariablesQuiz;
