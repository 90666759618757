import { TCharacter } from '../../../general/Character';
import image from '../../../../assets/characters/bletchley_lady.svg';
import { t } from 'ttag';

const AgentGreen: TCharacter = {
    characterName: t`Agent Green`,
    characterImage: image,
};

export default AgentGreen;
