import React, { useState, useContext } from 'react';
import TileGroup from './TileGroup';
import CorrectModal from './CorrectModal';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';
import { useGameLogic, GameProps } from '../../core/Game';
import { Container, Row, Col } from 'react-bootstrap';

type Question = {
    text: string;
    images: [string, string, string, string];
    colour: string;
};

export type Feature = {
    image: string;
    correct: boolean;
    text: string;
};

export type SimilarFeaturesQuestion = {
    question: Question;
    answerText: string;
    answerImage: string;
    answers: [Feature, Feature, Feature, Feature, Feature, Feature, Feature, Feature, Feature];
};

const SimilarFeatures: React.FC<GameProps<SimilarFeaturesQuestion>> = ({ gameData, onGameComplete }) => {
    const [show, setShow] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<SimilarFeaturesQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
    );

    const { volume } = useContext(VolumeContext);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    if (question === null) {
        return null;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>

            <Container>
                <Row className="mt-3">
                    <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
                        <Row>
                            <Col xs={6}>
                                <img className={'so-stockimg--feature'} src={question.question.images[0]} alt="" />
                            </Col>
                            <Col xs={6}>
                                <img className={'so-stockimg--feature'} src={question.question.images[1]} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <img className={'so-stockimg--feature'} src={question.question.images[2]} alt="" />
                            </Col>
                            <Col xs={6}>
                                <img className={'so-stockimg--feature'} src={question.question.images[3]} alt="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <div className="text-center">
                <h4 className="my-3">{question.question.text}</h4>
            </div>
            <TileGroup question={question} onComplete={handleQuestionComplete}></TileGroup>
        </React.Fragment>
    );
};

export default SimilarFeatures;
