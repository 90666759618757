import React, { useContext, useState } from 'react';
import { jt, t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import CorrectModal from './CorrectModal';
import TileGroup from './TileGroup';

export type Subject = {
    name: string;
    image: string;
};

export type Feature = {
    name: string;
    correct: boolean;
    reason: string;
};

export type FeatureMatchQuestion = {
    subject: Subject;
    features: [Feature, Feature, Feature, Feature, Feature, Feature, Feature, Feature, Feature];
};

const FeatureMatch: React.FC<GameProps<FeatureMatchQuestion>> = ({ gameData, onGameComplete }) => {
    const [show, setShow] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<FeatureMatchQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
    );

    const { volume } = useContext(VolumeContext);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    if (question === null) {
        return null;
    }

    let definingCharText = <strong>{t`defining characteristics`}</strong>;
    let animalName = question.subject.name.toLowerCase();

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>

            <div className="text-center feature__question">
                <div className="character__wrapper">
                    <img className={'char'} src={question.subject.image} alt="" width="150px" />
                    <div className="character__shadow character__shadow--animal"></div>
                </div>
                <h2 className="my-3">{animalName}</h2>
            </div>
            <TileGroup tiles={question.features} onComplete={handleQuestionComplete}></TileGroup>
            <p className={`mt-2 text-center`}>{jt`Find the four most ${definingCharText} of the ${animalName}`}</p>
        </React.Fragment>
    );
};

export default FeatureMatch;
