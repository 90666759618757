import React from 'react';
import { t } from 'ttag';
import { ColourfulKitsData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Patrick from '../../characters/Patrick';

const Intro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Patrick,
            text: t`Hi ${data.name}, thanks for helping Anita with that. I am Patrick and I look after all of the flags for this competition. We have quite a few!`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
