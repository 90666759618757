import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import heart from '../../assets/gamification/heart.png';
import star from '../../assets/gamification/star.png';
import { CyberSecurityData } from '../..';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const LivesExplanation: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const startingLives = 5;
    const startingPoints = 0;

    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-lives')
    }, [])

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Row>
                <Col className={'text-center'}>
                    <h2 className={'mt-3'}>{t`Be careful!`}</h2>
                </Col>
            </Row>
            <Card>
                <p>
                    <b>{t`Lives`}</b> {t`and`} <b>{t`Points`}</b>{' '}
                    {t`count towards your final score. Avoid getting phished and keep your details safe to avoid losing lives! Get questions right to gain points and try to avoid losing points by choosing wrong answers.`}
                </p>
                <Lives>
                    <img src={heart} alt={'Heart'} width={'75'} />
                    <span>{startingLives}</span>
                </Lives>
                <img src={star} alt={'Star'} width={'75'} />
                <span>{startingLives}</span>
                <Button
                    className={'btn-3d-dark d-block mx-auto mt-3'}
                    onClick={() => {
                        data.setLives(startingLives);
                        setTimeout(nextSlide, 500);
                    }}
                >{t`Add Lives & Points`}</Button>
            </Card>
        </Slide>
    );
};

const Card = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 500px;
    padding: 1rem 2rem 3rem 2rem;
    p {margin-bottom: 5px; color: #6400aa}
    display; block;
    margin: 1rem auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    position:relative;
`;

const Lives = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 10px;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-family: 'BT Font Extra Bold';
    }
`;

export default LivesExplanation;
