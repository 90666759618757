import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toaster from 'toasted-notes';
import { c, t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import SortAlgorithmCorrectModal from './SortAlgorithmCorrectModal';
import SortAlgorithmQuestionModal from './SortAlgorithmQuestionModal';
import SortAlgorithmSelector from './SortAlgorithmSelector';
import SortAlgorithmWrongModal from './SortAlgorithmWrongModal';

type SortObject = {
    name: string;
    image: string;
    tags: string[];
};

export type TSortAlgorithmQuestion = GameQuestion & {
    question: {
        text: string;
        animal: string;
        objects: SortObject[];
    };
    answers: string[];
};

const SortAlgorithmGame: React.FC<GameProps<TSortAlgorithmQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const [incorrectShow, setIncorrectShow] = useState(false);
    // const [disabled, setDisabled] = useState(false);
    const [answer, setAnswer] = useState<{ [key: string]: string }>({});
    const { volume } = useContext(VolumeContext);
    const [questionModalShow, setQuestionModalShow] = useState(true);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
        setQuestionModalShow(true);
    };

    const [question, nextQuestion] = useGameLogic<TSortAlgorithmQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleAnswerSubmit = () => {
        let correctAnswers = 0;
        // setDisabled(true);

        // convert obj to array
        let answerArray = Object.values(answer);

        // if arrays match then pass
        if (answerArray.sort().join(',') === question.answers.sort().join(',')) {
            //  correct answer
            console.log('correct');
            if (volume) sfx.success.play();
            setShow(true);
        } else {
            setIncorrectShow(true);
            toaster.notify(t`That was incorrect, try again.`, {
                duration: 2000,
                position: 'bottom',
            });
            console.log('wrong, not enough correct', correctAnswers, question.answers.length);
            if (volume) sfx.incorrect.play();

            // setTimeout(() => setDisabled(false), 1000);
            // incorrect
        }
    };
    useEffect(() => {
        console.log(answer);
    }, [answer]);

    const handleAnswerChange = (sid: string, selection: string) => {
        console.log('total selection', sid, selection);
        setAnswer({
            ...answer,
            [sid]: selection,
        });
    };

    const handleModalClose = () => {
        // setDisabled(false);

        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    if (!question) {
        return <p></p>;
    }

    return (
        <React.Fragment>
            <SortAlgorithmQuestionModal
                question={question}
                show={questionModalShow}
                setShow={setQuestionModalShow}
            ></SortAlgorithmQuestionModal>

            <SortAlgorithmCorrectModal
                show={show}
                question={question}
                onClose={handleModalClose}
            ></SortAlgorithmCorrectModal>
            <SortAlgorithmWrongModal
                show={incorrectShow}
                question={question}
                onClose={() => setIncorrectShow(false)}
                answersInput={answer}
            ></SortAlgorithmWrongModal>

            <div className="text-center so-algo__container">
                <div className="so-algo__question">
                    <div className="row">
                        <div className="col-10 offset-1 col-md-6 offset-md-0">
                            <div className="so-algo__image">
                                <img
                                    src={
                                        question.question.objects.find(
                                            (object) => object.name === question.question.animal,
                                        )?.image
                                    }
                                    width={'200px'}
                                    alt={t`Animal`}
                                />
                                <p className={'so-algo__animal'}>{question.question.animal}</p>
                            </div>
                        </div>
                        <div className="col-10 offset-1 col-md-6 offset-md-0 text-center text-md-left">
                            <p
                                className={'so-algo__instructions mb-2'}
                            >{t`Create an algorithm to only select the given animal.`}</p>
                            <Button className="mr-1" onClick={() => setQuestionModalShow(true)}>
                                <i className={'fas fa-arrow-left'}></i> {t`Back`}
                            </Button>
                        </div>
                    </div>
                </div>

                <p className={'my-2'}>{c('If the object it has x...').t`If it...`}</p>
                {question.answers.map((q, index) => (
                    <SortAlgorithmSelector
                        sid={`${index}`}
                        onSelectionChange={handleAnswerChange}
                        question={question}
                        answersInput={answer}
                    ></SortAlgorithmSelector>
                ))}
                <p>
                    {c('it is an animal').t`...it is a`} {question.question.animal}.
                </p>

                <Button onClick={handleAnswerSubmit}>{t`Submit`}</Button>
            </div>
        </React.Fragment>
    );
};

export default SortAlgorithmGame;
