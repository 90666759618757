import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Slide, { ISlide } from '../../../core/Slide';
import { t } from 'ttag';
import diamond from '../../../../assets/misc/th-start__diamond.svg';
import diamond_cy from '../../../../assets/misc/th-start__diamond-cy.svg';
import rio from '../../../../assets/misc/th-start__thief.svg';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );
    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={begin}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                    <div className="th-home">
                        <motion.img
                            src={rio}
                            width={'270px'}
                            height={'400px'}
                            className={'th-home__rio'}
                            alt="Agent Rio"
                            animate={{ x: [-5, 5, -5], y: [-5, 5, -5], rotate: [-4, 4, -4] }}
                            transition={{
                                loop: Infinity,
                                duration: 6,
                                rotate: { type: 'inertia', loop: Infinity, duration: 8 },
                            }}
                        />
                        <img
                            src={lang === 'cy' ? diamond_cy : diamond}
                            width={'250px'}
                            height={'200px'}
                            className={'th-home__diamond'}
                            alt="The Diamond"
                        />
                    </div>
                </Col>
            </Row>
        </Slide>
    );
};

export default StartSlide;
