import React, { useContext } from 'react';
import { c, t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import Keypad from './Keypad';

export type TKeypadGameQuestion = GameQuestion & {
    question: string;
    answer: string;
};

const KeypadGame: React.FC<GameProps<TKeypadGameQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    // const [show, setShow] = useState(false);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        // setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        // setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TKeypadGameQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleAnswerSubmit = (answer: string) => {
        if (answer === question.answer) {
            // correct answer
            nextQuestion();
            if (volume) sfx.beepbop.play();
        } else {
            console.log('fail');
            if (volume) sfx.failure.play();
        }
    };

    if (!question) {
        return <p>Question not found</p>;
    }

    return (
        <React.Fragment>
            {/* <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal> */}
            <p className={'ck-kit__howto'}>
                {t`Finish the sequence,`}
                <br />
                {c('Finish the sequence...').t`then press `}
                <strong>
                    <i className={'fas fa-check'}></i>
                </strong>
            </p>

            <div className="text-center">
                <Keypad onSubmit={handleAnswerSubmit} prefix={question.question} />

                {/* <Button onClick={handleAnswerSubmit}>Submit</Button> */}
            </div>
        </React.Fragment>
    );
};

export default KeypadGame;
