import React from 'react';
import TaskSlide from '../../../../general/TaskSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const DebugGame2Intro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <TaskSlide onStart={nextSlide} header={defaultHeader} number={5}>
            {t`Fix the mistakes you found in the athletes' kits.`}
        </TaskSlide>
    );
};

export default DebugGame2Intro;
