import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import Snowy from './components/Wrapper';
import Intro from './units/1-intro';
import Building from './units/2-building';
import Boxing from './units/3-boxing';
import Gift from './units/4-gift';
import Sending from './units/5-sending';
import End from './units/6-end';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';







export type FestiveData = {
    name: string;
    setName: (name: string) => void;
};

const Festive: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [StartSlide, ...Intro, ...Building, ...Boxing, ...Gift, ...Sending, ...End, EndSlide];

    const barefootLogo = (
        <>
            <div>
                <img src={logo} height="50px" alt="BT-Barefoot" />
                <MuteButton></MuteButton>
            </div>
        </>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeFestive.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeFestive.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Gift Wrapped | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="bfe46535-ef90-485d-b07f-2d833ab601e5"
                name="festive"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
                Wrapper={Snowy}
            />
        </React.Fragment>
    );
};

export default Festive;
