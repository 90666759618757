import React, { useEffect, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { c } from 'ttag';
import { shuffle } from '../../../helpers/shuffle';
import { TSortAlgorithmQuestion } from './SortAlgorithmGame';

const AlgorithmSelector: React.FC<{
    onSelectionChange: (sid: string, selection: string) => void;
    sid: string;
    question: TSortAlgorithmQuestion;
    answersInput: { [key: string]: string };
}> = ({ onSelectionChange, sid, question, answersInput }) => {
    const [valOne, setValOne] = useState<string>('');

    const [options, setOptions] = useState<any>([]);

    useEffect(() => {
        // send to parent
        onSelectionChange(sid, valOne);
    }, [valOne]);

    useEffect(() => {
        let tempOpts = question.question.objects.map((obj) => obj.tags).flat();
        let a = Array.from(new Set(tempOpts));
        // remove already selected opts if not equal to valOne
        a = a.filter((opt) => {
            return answersInput[sid] === opt || !Object.values(answersInput).includes(opt);
        });

        a = shuffle(a);

        setOptions(a);
    }, [question, answersInput]);

    useEffect(() => {
        setValOne('');
    }, [question]);

    return (
        <Form>
            <Form.Group controlId="formAlgoSelect">
                {parseInt(sid) > 0 ? (
                    <p className={'mb-2'}>{c('If the object it has xyz... and it also has...').t`and it...`}</p>
                ) : null}
                <Form.Control
                    as="select"
                    value={valOne}
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setValOne(e.currentTarget.value)}
                >
                    {options.map((op: string) => (
                        <option>{op}</option>
                    ))}
                    <option disabled></option>
                </Form.Control>
            </Form.Group>
        </Form>
    );
};

export default AlgorithmSelector;
