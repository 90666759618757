import React from 'react';
import { GamePicture } from './FourPicturesGame';

const PictureTile: React.FC<{ picture: GamePicture; hint: boolean }> = ({ picture, hint }) => (
    <div className="grid-img__wrapper" style={{ backgroundImage: hint ? `url(${picture.image})` : undefined }}>
        <h5 className="grid-img__title">{picture.text}</h5>
    </div>
);

export default PictureTile;
