import React, { useContext, useEffect, useState } from 'react';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';
import { TKitMistakeAnswer } from './KitMistake';

const KitBox: React.FC<{
    kit: TKitMistakeAnswer;
    onAnswer: (correct: boolean) => void;
    resetQuestion: boolean;
    isDisabled: boolean;
}> = ({ kit, onAnswer, resetQuestion, isDisabled }) => {
    const [selected, setSelected] = useState(false);
    const { volume } = useContext(VolumeContext);

    useEffect(() => {
        setSelected(false);
    }, [kit, resetQuestion]);

    const handleSelected = () => {
        setSelected(true);
        if (kit.correct) {
            if (volume) sfx.correct.play();
        } else {
            if (volume) sfx.incorrect.play();
        }
        onAnswer(kit.correct);
    };

    return (
        <div
            className={`ck-kit__type ck-kit__type ck-kit__type--${kit.type} ${
                kit.correct && selected && kit.type !== 'equipment' ? 'ck-kit__type--true' : ''
            } ${!kit.correct && selected && kit.type !== 'equipment' ? 'ck-kit__type--false' : ''}`}
            onClick={selected || isDisabled ? undefined : handleSelected}
        >
            <img className={`ck-kit__img ck-kit__img--${kit?.type}`} src={kit?.image} alt={kit.type} />
        </div>
    );
};

export default KitBox;
