import React from 'react';
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import LevelSelector from '../../components/interactive/levels/LevelSelector';

const LevelFive: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {

    const recap = t`You have used decomposition to break down the issues that were being experienced by the traffic lights. Breaking the problem down into smaller parts allowed us to correctly create an algorithm, which we used to program the traffic lights.`


    return (
        <Slide header={defaultHeader} footerHidden={true} >
            <LevelSelector level={4} nextSlide={nextSlide} recap={recap} textSpriteName='97' />
        </Slide>
    );
};




export default LevelFive;
