import React, { useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';

const TouristProblem: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const { width } = useWindowDimensions();
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(true);
    const [show2, setShow2] = useState(false);

    const [carData, setCarData] = useState<TCar[]>([
        { id: 'go-1', pos: 0, moving: true, direction: 'clockwise' },
    ]);

    const [nextDialogue, setNextDialogue] = useState<any>(null);

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`Thanks for your help with the traffic light earlier. Unfortunately, that’s not the end of our problems! Look what happens when there is a morning rush to get into Red Town. `,
            textSpriteName: `31`,
            buttonText: t`Watch`,
            buttonAction: () => {
                setShow(false);
                setCarData([
                    { id: 'gi-1', pos: width, moving: true, direction: 'anticlockwise', loop: true },
                    { id: 'gi-2', pos: width + 100, moving: true, direction: 'anticlockwise', loop: true },
                    { id: 'gi-3', pos: width + 200, moving: true, direction: 'anticlockwise', loop: true },
                    { id: 'gi-4', pos: width + 300, moving: true, direction: 'anticlockwise', loop: true },
                    { id: 'gi-5', pos: width + 400, moving: true, direction: 'anticlockwise', loop: true },
                ])

                setTimeout(() => {
                    setShow2(true);
                }, 5000);
            },
        },
    ];

    const dialogue2: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`The morning rush makes it impossible for cars to leave Red Town! Our lead engineer suggested we would need two traffic lights to solve the problem of making the bridge safer.`,
            textSpriteName: `32`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Let’s try setting up another traffic light now.`,
            textSpriteName: `33`,
            buttonText: t`Play`,
            buttonAction: nextSlide
        },
    ];

    const trafficLight1: TRoadLight = {
        sequence: [
            { red: { enabled: true }, duration: 1000 },
        ],
        pos: width - 300, // TODO REMOVE THINGS LIKE THIS
        direction: 'clockwise'
    };


    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Scene
                nextSlide={nextSlide}
                data={data}
                alert={'warning'}
                roadworks={false}
                crash={false}
                lights={false}
                road={{ carData: carData, trafficLight1 }}//onCrash: setTimeout(nextSlide, 5000)
            >
                {show ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} /> : false}
                {show2 ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue2} /> : false}


            </Scene>
        </Slide>
    );
};

export default TouristProblem;
