import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

type TGiftBuilderSelector = {
    name: string;
    variants: { html: JSX.Element; value: any }[];
};

const GiftBuilderSelector: React.FC<{ type: string; onSelect: (value: any) => void }> = ({
    type = 'pattern',
    onSelect,
}) => {
    const [index, setIndex] = useState(0);
    const [selectorType, setSelectorType] = useState<TGiftBuilderSelector | null>(null);

    const selectorTypes: TGiftBuilderSelector[] = [
        {
            name: 'pattern',
            variants: [
                { html: <i className="fa fa-snowflake"></i>, value: 'snowflake' },
                { html: <i className="fa fa-star"></i>, value: 'star' },
                { html: <i className="fa fa-tree"></i>, value: 'tree' },
            ],
        },
        {
            name: 'size',
            variants: [
                { html: <span>1</span>, value: 1 },
                { html: <span>2</span>, value: 2 },
                { html: <span>3</span>, value: 3 },
                { html: <span>4</span>, value: 4 },
                { html: <span>5</span>, value: 5 },
                { html: <span>5</span>, value: 5 },
                { html: <span>6</span>, value: 6 },
                { html: <span>7</span>, value: 7 },
                { html: <span>8</span>, value: 8 },
                { html: <span>9</span>, value: 9 },
                { html: <span>10</span>, value: 10 },
            ],
        },
        {
            name: 'colour',
            variants: [
                { html: <SelectionColor color={'#6B7280'} />, value: '#6B7280' },
                { html: <SelectionColor color={'#EF4444'} />, value: '#EF4444' },
                { html: <SelectionColor color={'#F59E0B'} />, value: '#F59E0B' },
                { html: <SelectionColor color={'#10B981'} />, value: '#10B981' },
                { html: <SelectionColor color={'#3B82F6'} />, value: '#3B82F6' },
                { html: <SelectionColor color={'#8B5CF6'} />, value: '#8B5CF6' },
                { html: <SelectionColor color={'#EC4899'} />, value: '#EC4899' },
            ],
        },
    ];

    useEffect(() => {
        let selector = selectorTypes.find((selector) => selector.name === type);

        setSelectorType(selector ?? null);
    }, [type]);

    useEffect(() => {
        if (!selectorType) return;
        onSelect(selectorType?.variants[index].value);
    }, [index]);

    const handleNext = () => {
        if (!selectorType) {
            return;
        }

        if (index + 1 >= selectorType?.variants.length ?? 0) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    };

    const handlePrev = () => {
        if (!selectorType) {
            return;
        }

        if (index - 1 < 0) {
            setIndex(selectorType?.variants.length - 1);
        } else {
            setIndex(index - 1);
        }
    };

    return (
        <>
            <EditButton onClick={handlePrev}>
                <i className="fa fa-arrow-left"></i>
            </EditButton>
            <Selection>{selectorType ? selectorType?.variants[index].html : null}</Selection>
            <EditButton onClick={handleNext}>
                <i className="fa fa-arrow-right"></i>
            </EditButton>
        </>
    );
};

const Selection = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    overflow: hidden;
    text-align: center;
    span {
        font-size: 20px;
        line-height: 50px;
        color: #ffffff;
        font-family: 'BT Font Extra Bold';
    }
    i {
        line-height: 50px;
    }
`;

const EditButton = styled(Button)`
    font-size: 0.75rem;
    padding: 5px;
`;

interface ColorProps {
    color: string;
}

const SelectionColor = styled.div<ColorProps>`
    background-color: ${(props) => (props.color ? props.color : '#000000')};
    height: 100%;
    width: 100%;
`;

export default GiftBuilderSelector;
