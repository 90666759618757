import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/2-pufferfish-1.svg';
import puffed from '../assets/characters/2-pufferfish-2.svg';
import { t } from 'ttag';

const Pufferfish: TCharacter = {
    characterName: t`Pufferfish`,
    characterImage: image,
    characterWidth: 94,
    characterHeight: 58,
};
export const Puffed: TCharacter = {
    characterName: t`Pufferfish`,
    characterImage: puffed,
    characterWidth: 120,
    characterHeight: 105,
};

export default Pufferfish;
