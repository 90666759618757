import React, {useState, useEffect, useRef} from 'react';
import frame from '../assets/devices/laptop-frame.svg';
import bottom from '../assets/devices/laptop-bottom.svg';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
// import scroll from '../assets/misc/email-scroll.png';
import {t} from 'ttag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { isIOS, isMobile } from 'react-device-detect';

interface ILaptop {
    backgroundColor?: string;
    url: string;
}

const Laptop: React.FC<ILaptop> = ({ children, url = 'example.com', backgroundColor = '#6400aa' }) => {
    const [show, setShow] = useState(true);

    const handleScroll = (e:any) => {
        if (!isMobile && show) {
            setShow(false);
        } else {
            const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10; //10 for a small buffer
            if (bottom) { setShow(false)}
            else {setShow(true)};
        }
    }

    useEffect(() => {
        if (isMobile) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [isMobile])

    return (
        <>
        <LaptopContainer>
            <LaptopBlock>
                <LaptopContent backgroundColor={backgroundColor}>
                    <LaptopContentScroll onScroll={handleScroll}>
                        <StyledContainer  >
                            <LaptopHeader>
                                <AddressBar>
                                    <span>
                                        <i className={'fa fa-lock'}></i>
                                        {url}
                                    </span>
                                </AddressBar>
                            </LaptopHeader>
                            {children}
                        </StyledContainer>
                    </LaptopContentScroll>
                    <ScrollNudge show={show}> <FontAwesomeIcon icon={faArrowDown} color="#6400aa"/> </ScrollNudge>
                    <LaptopFrame src={frame} alt="Laptop" />
                </LaptopContent>
                <LaptopBottom src={bottom} alt="" />
            </LaptopBlock>
        {/* <StyledScroll className="d-block d-md-none"> <img src={scroll}/>{t`Scroll`}</StyledScroll> */}
        </LaptopContainer>
        </>
    );
};


interface ScrollProps {
    show: boolean;
}

const ScrollNudge = styled.div<ScrollProps>`
position: absolute;
bottom: 0;
height: 7%;
margin-bottom: 3.75%;
width: 100%;
left: 0;
background-color: rgba(255,255,255,0.25);
display: ${(props) => props.show ? 'flex' : 'none'};
text-align: center;
align-items: center;
justify-content: center;
svg { font-size: 2.5vw}
@media (min-width: 1200px) { display: none }
`;

const StyledScroll = styled.p`
    opacity: 0.6;
    img{
        width: 23px;
        height: 25px;
        display: inline-block;
        margin-right: 10px;
    }
    text-align: center;
    padding-top: 15%;
    font-size: 18px !important;
`;

const LaptopContainer = styled.div`
    height: calc(100vh - 75px);
    z-index: 2;
    overflow: hidden;
    @media screen and (max-width: 670px) {
        font-size: 2.75vw;
        line-height: unset;
        p {
            font-size: 3vw;
            margin-bottom: 5px;
            line-height: unset;
        }
        h3 {
            font-size: 3.5vw;
            margin-bottom: 10px;
            line-height: unset;
        }
    }
`;

const LaptopBlock = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 700px;
    position: relative;

    margin-bottom: -10%;
    @media screen and (min-width: 500px) {
        margin-bottom: -50px;
        top: 10px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 1000px;
    }
`;

interface LaptopContentProps {
    backgroundColor?: string;
}

const LaptopContent = styled.div<LaptopContentProps>`
    position: relative;
    height: 97.5%;
    width: 97.5%;
    padding-top: 64%;
    border-radius: 1.8vw;
    background-color: ${(props: LaptopContentProps) => props.backgroundColor};
    overflow: hidden;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        overflow: visible
    }
    margin: 0 auto;
    display: block;
    @media screen and (min-width: 1200px) {
        border-radius: 25px;
    }
`;

const LaptopContentScroll = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: absolute;
    top: 0;
    padding: 3%;
    max-height: calc(100vh - 75px);
`;

const LaptopFrame = styled.img`
    width: 100%;
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 3;
    top: 0;
`;

const LaptopBottom = styled.img`
    width: 100%;
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 3;
    height: 4%;
    bottom: -3%;
`;

const LaptopHeader = styled.div`
    width: 100%;
    padding: 2.25% 0 1.75% 0;
    flex-shrink: 0;
    background-color: #c7c3e8;
    @media screen and (max-width: 767px) {
        line-height: 0;
    }
    span {
        color: #6400aa;
        font-size: 3vw;
        @media screen and (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
`;
const AddressBar = styled.div`
    background-color: #ffffff;
    width: 83.33%;
    margin-left: 8.33%;
    border-radius: 100px;
    padding: 1% 3%;
    i {
        margin-right: 3%;
    }
    vertical-align: middle;
`;

const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
    .row {
        margin: 0;
    }
    color: #6400aa;
`;
const Message = styled.div`
    p {
        margin-top: 1rem;
    }
`;

export default Laptop;
