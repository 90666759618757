import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../';
import Slide, { ISlide } from '../../../core/Slide';
import characters from '../assets/characters/characters.png';
import cloud from '../assets/scene/cloud.svg';
import town_scene from '../assets/scene/town_scene.svg';
import town_scene_welsh from '../assets/scene/town_scene-welsh.svg';

import Cookies from 'js-cookie';

const lang = Cookies.get('bfg_lang');

const EndSLide: React.FC<ISlide<TrafficData>> = ({ defaultHeader, onComplete, data }) => {
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;

    useEffect(() => {
        data.playVoiceover('110')
    }, []);

    return (
        <Slide header={defaultHeader} footer={footer}>

            <TownWrapper>

                <Clouds>
                    <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 0 }} style={{ top: '30%' }} />
                    <motion.img className="cloud-half-hack" src={cloud} animate={{ x: [0, 750, 1500], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 3 }} style={{ top: '75%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 30, delay: 30 }} style={{ top: '50%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 50 }} style={{ top: '55%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.6, 0] }} transition={{ loop: Infinity, duration: 40, delay: 40 }} style={{ top: '30%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 60, delay: 0 }} style={{ top: '25%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 50, delay: 15 }} style={{ top: '70%' }} />
                    <motion.img src={cloud} animate={{ x: [-50, 1000, 2050], opacity: [0, 0.5, 0] }} transition={{ loop: Infinity, duration: 45, delay: 0 }} style={{ top: '40%' }} />
                </Clouds>

                <Content animate={{ y: [500, 0], opacity: [0, 1], scale: [0.5, 1] }}>
                    <Characters src={characters} animate={{ opacity: [0, 1], y: [100, 0], x: ['-50%', '-50%'] }} transition={{ delay: 3 }} />
                    <motion.div
                        className="island island--dialogue rounded mb-5 py-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <h6 className="mb-0">{t`You have completed...`}</h6>
                        <h3 className="mb-0">{t`TRICKY TRAFFIC`}</h3>
                    </motion.div>
                    <p className={'p--endshare'}>{t`Let an adult know what you’ve learnt about in Tricky Traffic and, if they know anyone who might also enjoy it, they can share it with them too!`}</p>

                </Content>

            </TownWrapper>



        </Slide>
    );
};

// Clouds

const Characters = styled(motion.img)`
    position: absolute;
    z-index: -1;
    width: 120px;
    height: 150px;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
`;

const Clouds = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;

    img {
        width: 75px;
        position: absolute;
        left: -75px;
        user-select: none;
        pointer-events: none;
        z-index: 998;
        opacity: 0.5;
        &.cloud-half-hack {
            left: 40%;
        }
    }
`;

const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled(motion.div)`
    position:relative;
    width: 100%;
    padding: 1rem;
    background-color: #ffffff;
    @media (min-width: 576px){
        border-radius: 5px;
    }
    box-shadow: 0 25px 50px rgba(0,0,0,0.25);
    min-height: 136px;
    p {color: #6400aa; padding: 0 15px}
    .island {
        background-color: #EAF4F7;
        padding: 6px 12px !important;
        width: 100%;
        margin: 0 0 10px !important;
        font-size: 18px;
        line-height: 24px;
        min-height: auto;
        box-shadow: none;
    }

    @media (min-width: 576px){
        .island {
            border-radius: 5px !important;
            margin-bottom: 10px !important;
        }
        max-width: 400px;
    }
    @media (min-width: 1200px){
        .island {
            font-size: 20px;
        }
        max-width: 500px;
    }
`;




export default EndSLide;
