import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import { Button, Col, Row } from 'react-bootstrap';
import icon_abstraction from '../../../../../assets/icons/icon_abstraction.svg';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const Recap: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const footer = <Button onClick={nextSlide}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    <h4 className={'mt-4'}>{t`You have completed`}</h4>
                    <h1>{t`Barefoot Zoo`}</h1>
                    <img className="w-50 mx-auto d-block mb-4" alt="" src={paint_stroke} />
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 6, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={icon_abstraction} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'6'} className={'text-md-left'}>
                                <h3>{t`Abstraction`}</h3>
                                <p className={'mb-0'}>{t`You discovered a new word!`}</p>
                            </Col>
                        </Row>
                    </Content>
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 6, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <i className={'fas fa-book-open icon--achievement'}></i>
                            </Col>
                            <Col xs={'12'} md={'6'} className={'text-md-left'}>
                                <h3>{t`Great Guide`}</h3>
                                <p className={'mb-0'}>{t`You helped the tourists.`}</p>
                            </Col>
                        </Row>
                    </Content>
                    <Content type="island_dark" styles={'mt-2 mb-4 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 6, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <i className={'fas fa-cloud-sun-rain icon--achievement'}></i>
                            </Col>
                            <Col xs={'12'} md={'6'} className={'text-md-left'}>
                                <h3>{t`Storm Saver`}</h3>
                                <p className={'mb-0'}>{t`You saved the animals!`}</p>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default Recap;
