import React, { useState, useEffect } from 'react';
import { GameQuestionProps } from '../../core/Game';
import { TCaesarCipherQuestion } from './CaesarCipher';
import CaesarCipherWheel from './CaesarCipherWheel';
import { caesar } from '../../../helpers/ciphers';
import Content from '../../general/Content';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';

interface CaesarCipherQuestionProps extends GameQuestionProps {
    question: TCaesarCipherQuestion;
}

const CaesarCipherQuestion: React.FC<CaesarCipherQuestionProps> = ({ question, onQuestionComplete }) => {
    const [answer, setAnswer] = useState<string | null>(null);
    const [gameComplete, setGameComplete] = useState(false);

    useEffect(() => {
        setAnswer(null);
        setGameComplete(false);
    }, [question]);

    useEffect(() => {
        if (answer !== null && answer.toLowerCase() === question.answer.toLowerCase()) {
            setGameComplete(true);
        } else {
            setGameComplete(false);
        }
    }, [answer, question]);

    // if answer disabled, get the offset and caesar it, use as value

    const [wheelOffset, setWheelOffset] = useState(question.offset ?? 0);

    useEffect(() => {
        setWheelOffset(question.offset ?? 0);
    }, [question]);

    useEffect(() => {
        if (question.answerInputDisabled) {
            setAnswer(caesar(caesar(question.answer, question.answerOffset), 26 - wheelOffset) ?? '');
        }
    }, [question.answer, question.answerInputDisabled, question.answerOffset, wheelOffset]);

    const handleOffsetChange = (offset: number) => {
        if (offset >= 0 && offset < 26) {
            setWheelOffset(offset);
        }

        if (offset === -1) {
            setWheelOffset(25);
        }

        if (offset === 26) {
            setWheelOffset(0);
        }
    };

    return (
        <div>
            <CaesarCipherWheel
                offset={wheelOffset}
                offsetInputDisabled={question.offsetInputDisabled}
                onOffsetChange={handleOffsetChange}
            ></CaesarCipherWheel>
            <h4 className={'cc-caesar__heading'}>{t`Enciphered Message`}</h4>
            <Content type={'island'} styles={'p-2 cc-caesar__island'}>
                <p className={'cc-caesar__code'}>{caesar(question.answer, 26 - question.answerOffset)}</p>
            </Content>
            <h4 className={'cc-caesar__heading cc-caesar__heading--yellow'}>{t`Deciphered Message`}</h4>
            {!question.answerInputDisabled ? (
                <input
                    className={`cc-caesar__input ${gameComplete ? 'cc-cipher__input--correct' : ''}`}
                    placeholder={t`Enter message`}
                    type="text"
                    onChange={(e) => setAnswer(e.target.value)}
                    value={answer ?? ''}
                    disabled={question.answerInputDisabled}
                    autoFocus
                />
            ) : (
                <input
                    className={`cc-caesar__input ${gameComplete ? 'cc-cipher__input--correct' : ''}`}
                    type="text"
                    value={caesar(caesar(question.answer, question.answerOffset), 26 - wheelOffset) ?? ''}
                    disabled={question.answerInputDisabled}
                    autoFocus
                />
            )}

            {gameComplete ? (
                <Button onClick={onQuestionComplete} className="mt-2">
                    {t`Submit`}
                </Button>
            ) : null}
        </div>
    );
};

export default CaesarCipherQuestion;
