import React, { useEffect, useState } from 'react';
import TrafficLight, { TTrafficLight } from './TrafficLight';

type TrafficLightSequenceProps = {
    sequence: TTrafficLightSequence[];
    loop: boolean;
    onSequenceComplete?: () => void;
    setLightIndex: (index: number | null) => void;
    index?: number;
    reset?: boolean;
    onClick?: () => void;
    highlight?: boolean
}

export type TTrafficLightSequence = TTrafficLight & {
    duration: number;
}

const TrafficLightSequence: React.FC<TrafficLightSequenceProps> = ({ sequence, loop, onSequenceComplete = () => { }, setLightIndex, index = 0, reset = false, onClick, highlight }) => {
    const [sequenceIndex, setSequenceIndex] = useState(index);
    const [lightProps, setLightProps] = useState(sequence[sequenceIndex]);

    useEffect(() => setSequenceIndex(index), [index]);

    useEffect(() => {
        if (reset) {
            setSequenceIndex(0);

        }
    }, [reset]);

    useEffect(() => {
        if (sequence[sequenceIndex]) {
            let timeout = setTimeout(() => {
                if (sequence.length > sequenceIndex + 1) {
                    setSequenceIndex(sequenceIndex + 1);

                } else if (loop) {
                    setSequenceIndex(0);
                } else {
                    // completed
                    setLightProps({ duration: 0 });
                    onSequenceComplete();
                }

            }, sequence[sequenceIndex].duration);

            return () => clearTimeout(timeout)
        }
    }, [sequenceIndex, sequence]);

    useEffect(() => {
        if (sequence && sequence[sequenceIndex]) {
            setLightProps(sequence[sequenceIndex]);
            setLightIndex(sequenceIndex);
        } else {
            setLightIndex(null);
        }
    }, [sequenceIndex, sequence]);

    return (
        <div onClick={onClick}>
            <TrafficLight highlight={highlight} {...lightProps} />
        </div >
    );
}

export default TrafficLightSequence;
