const spritesheetChrome: IHowlSoundSpriteDefinition = {
    "turtle-1-1": [0, 10000],
    "turtle-1-2": [10565, 7435],
    "turtle-nickname": [19000, 2000],
    "turtle-avatar": [21005, 1995],
    "turtle-2-1": [23005, 9016],
    "turtle-2-2": [32088, 10260],
    "turtle-lives": [43000, 15048],
    "turtle-island": [59048, 2952],
    "turtle-3-1": [63000, 6003],
    "turtle-3-2": [70000, 8012],
    "turtle-personal-1": [78500, 5164],
    "turtle-personal-2": [84006, 6548],
    "turtle-4": [91500, 4800],
    "turtle-question-1": [96500, 12500],
    "turtle-question-1-incorrect": [110000, 6000],
    "turtle-question-1-correct": [117500, 6002],
    "turtle-5-1": [124000, 10005],
    "turtle-5-2": [135005, 10048],
    "turtle-5-3": [146500, 11000],
    "turtle-word-discovered": [158500, 12086],
    "turtle-6-1": [171500, 5500],
    "turtle-6-2": [178200, 10000],

    "whale-1-1": [189500, 7504],
    "whale-2-1": [198005, 7995],

    "game-howto-title": [208000, 1000],
    "phished-words": [209000, 2005],
    "dodged-words": [212500, 3005],

    "whale-howto-1": [216500, 3300],
    "whale-howto-2": [221005, 3995],
    "whale-howto-3": [225005, 3001],
    "whale-howto-4": [229500, 3000],
    "whale-ready": [233200, 8000],
    "whale-char-1": [243000, 13000],
    "whale-char-2": [257000, 12005],
    "whale-char-3": [270500, 9708],
    "whale-char-4": [281500, 7503],
    "whale-char-5": [290500, 11500],
    "whale-3-1": [1075500, 8000],
    "whale-4-1": [1043000, 7500],


    "octo-1-1": [310000, 9000],
    "octo-help-1": [319000, 5000],
    "octo-help-2": [325000, 2005],
    "octo-email-1": [328000, 11005],
    "octo-help-3": [341000, 4000],
    "octo-char-1": [346000, 7033],
    "octo-char-2": [354000, 15000],
    "octo-email-2": [370000, 11055],
    "octo-email-3": [382000, 9100],
    "octo-incorrect-toast": [392000, 4008],
    "octo-char-3": [398000, 11005],
    "octo-email-4": [410000, 9005],
    "octo-incorrect-pop": [421000, 1000],
    "octo-phished-desc": [422000, 5007],
    "octo-phisherman-1": [428000, 7007],
    "octo-question-1": [436000, 15000],
    "octo-question-1-correct": [451000, 10000],



    "fish-1-1": [461000, 8800],
    "fish-follow": [469000, 3005],
    "fish-2-1": [472505, 6500],
    "fish-2-2": [479800, 5000],
    "fish-messages": [486005, 2998],
    "fish-chat-1": [491000, 18008],
    "fish-chat-1-answers": [511000, 5000],
    "fish-chat-a-1": [510000, 1500],
    "fish-chat-a-2": [511500, 1200],
    "fish-chat-a-3": [512800, 1500],
    "fish-chat-2": [516000, 3005],
    "fish-cyber-click": [519000, 8003],
    "fish-cyber-block": [527500, 7005],
    "fish-dodged-desc": [536000, 6000],
    "fish-phisherman-1": [543000, 9000],
    "fish-question-1": [552000, 14003],
    "fish-question-1-correct": [566500, 9000],

    "fish-phished-desc": [576500, 7000],
    "fish-phisherman-2": [583505, 8500],

    "pufferfish-1-1": [593500, 6907],
    "pufferfish-1-2": [601500, 4000],
    "pufferfish-1-3": [607005, 7001],
    "pufferfish-phone": [614000, 4004],
    "pufferfish-phone-1": [1065000, 3500],
    "pufferfish-phone-1-answers": [622000, 3002],
    "pufferfish-phone-a1-1": [618005, 1000], // Old pufferfish-phone-1
    "pufferfish-phone-a1-2": [622500, 1500],
    "pufferfish-phone-a2-1": [638805, 2000],
    "pufferfish-phone-a2-2": [642000, 2500],
    "pufferfish-phone-a3-1": [646600, 2000],
    "pufferfish-phone-a3-2": [649400, 2000],
    "pufferfish-phone-2": [625000, 11507],
    "pufferfish-phone-2-answers": [640000, 6000],
    "pufferfish-phone-3": [1053500, 9000],
    "pufferfish-phished-desc": [653500, 5504],
    "pufferfish-dodged-desc": [659000, 5000],
    "pufferfish-phisherman-1": [665000, 8000],
    "pufferfish-phisherman-2": [674005, 5500],
    "pufferfish-phisherman-3": [680500, 7002],
    "pufferfish-phisherman-4": [690000, 7005],
    "pufferfish-question-1": [698000, 10505],
    "pufferfish-question-1-correct": [709000, 7005],
    "pufferfish-2-1": [717000, 14005],


    "endturtle-1-1": [731500, 8500],

    "endturtle-cyber-close": [741000, 7008],

    "endturtle-phished-desc": [749000, 9000],
    "endturtle-dodged-desc": [759500, 8505],
    "endturtle-phisherman-1": [768500, 9500],
    "endturtle-phisherman-2": [778000, 10008],
    "endturtle-2-1": [788000, 9008],
    "endturtle-2-2": [797000, 7000],

    "end-howto-title": [807000, 1000],
    "end-howto-1": [807000, 3355],
    "end-howto-2": [812000, 3305],
    "end-howto-3": [817000, 3503],
    "end-howto-4": [821005, 3995],

    "end-question-1": [826200, 9000],
    "end-answers-1": [838000, 9000],
    "end-question-2": [847000, 9000],
    "end-answers-2": [859000, 10000],
    "end-question-3": [868000, 6000],
    "end-answers-3": [876000, 8000],
    "end-question-4": [884000, 6000],
    "end-answers-4": [894000, 7000],
    "end-question-5": [899500, 5000],
    "end-answers-5": [908000, 9000],
    "end-question-6": [916000, 6000],
    "end-answers-6": [926000, 11000],
    "end-question-7": [937000, 7505],
    "end-answers-7": [948000, 9000],

    "end-phisherman-1": [957000, 8000],
    "endturtle-3-1": [967000, 8000],
    "end-awarding-1": [975000, 2705],
    "end-phisherman-2": [978200, 3001],
    "end-awarded-1": [982000, 1030],
    "end-award-1": [984500, 3005],
    "end-award-2": [987500, 3005],
    "end-award-3": [990000, 2005],

    "end-finish-1": [994000, 2000],
    "end-finish-2": [996500, 8007],
    "end-finish-3": [1088000, 7000],

    "island-title": [1008000, 1005],
    "island-msg-hall": [1008500, 2700],
    "island-msg-start": [1014000, 1075],
    "island-msg-character": [1014500, 3000],

    "question-incorrect": [1018000, 6005],

    "sound-1": [1025500, 2000],
    "sound-2": [1027500, 2005],
    "sound-3": [1030000, 2000],
    "sound-4": [1032500, 2000],
    "sound-5": [1034600, 2000],
    "sound-6": [1036600, 2000],
    "sound-7": [1038600, 2000],
    "sound-8": [1040600, 2000],

    "end-a-1-a": [836000, 3000],
    "end-a-1-b": [839000, 3000],
    "end-a-1-c": [842000, 3000],
    "end-a-2-a": [857000, 5000],
    "end-a-2-b": [862000, 2000],
    "end-a-2-c": [864000, 3000],
    "end-a-3-a": [874000, 2000],
    "end-a-3-b": [876000, 3500],
    "end-a-3-c": [879500, 3000],
    "end-a-4-a": [892000, 2000],
    "end-a-4-b": [894500, 2000],
    "end-a-4-c": [896500, 2000],
    "end-a-5-a": [905000, 2500],
    "end-a-5-b": [907500, 3700],
    "end-a-5-c": [911000, 4000],
    "end-a-6-a": [923800, 4200],
    "end-a-6-b": [928000, 4000],
    "end-a-6-c": [932000, 3000],
    "end-a-7-a": [945000, 4000],
    "end-a-7-b": [949000, 2500],
    "end-a-7-c": [951500, 3500],

    "term-discovered": [1071000, 2500],
};

export default spritesheetChrome;
