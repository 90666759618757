import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const MissionTwoIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide onStart={nextSlide} header={defaultHeader} number={2}>
            {t`Decipher the codes using the Caesar cipher.`}
        </MissionSlide>
    );
};

export default MissionTwoIntro;
