import React from 'react';
import ModalOverlay from './ModalOverlay';
import { motion } from 'framer-motion';
import { Button, Row, Col } from 'react-bootstrap';
import paint_stroke from '../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

interface ISkillModal {
    show: boolean;
    onClose: () => void;
    skillName: string;
    skillImage: string;
    skillDescription: string;
}

const SkillModal: React.FC<ISkillModal> = ({ onClose, show, skillName, skillImage, skillDescription }) => {
    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h3 className="text-white text-center">
                <i className="fas fa-star mr-2"></i> {t`LEARNING COVERED`}
            </h3>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    return (
        <ModalOverlay
            show={show}
            header={modalHeader}
            footer={<Button onClick={onClose}>{t`Next`}</Button>}
            backgroundColor="#00A0D6"
        >
            <Row>
                <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                    <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                        <img src={skillImage} className="mb-5 w-25 mx-auto d-block" alt="" />
                        <h2>{skillName}</h2>
                        <h5>{skillDescription}</h5>
                    </motion.div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default SkillModal;
