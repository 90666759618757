import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { t } from 'ttag';
import home from '../../../../assets/misc/so-home-poster.svg';
import home_cy from '../../../../assets/misc/so-home-poster-cy.svg';
import windmill from '../../../../assets/misc/so-home__windmill.svg';
import Slide, { ISlide } from '../../../core/Slide';
import Cookies from 'js-cookie';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );
    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={begin}>
            <div className={'so-home'}>
                <div className="so-home__shadow"></div>
                <img
                    src={lang === 'cy' ? home_cy : home}
                    className={'so-home__poster'}
                    width="290px"
                    alt="Barefoot Family Farm"
                />
                <img src={windmill} className={'so-home__windmill'} width="60px" alt={t`Windmill Spinning`} />
            </div>
        </Slide>
    );
};

export default StartSlide;
