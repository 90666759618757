import React from 'react';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';
import FestiveTask from '../../components/FestiveTask';

const MissionIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <FestiveTask
            onStart={nextSlide}
            header={defaultHeader}
            number={1}
        >{t`The elves left the shop in a mess, help Penguin build the toys by joining up the right pieces.`}</FestiveTask>
    );
};

export default MissionIntro;
