import React, { useContext, useEffect, useState } from 'react';
import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import VolumeContext from '../../../../../misc/volume-context';
import styled from 'styled-components';
import { Row, Container, Col } from 'react-bootstrap';
import { shuffle } from '../../../../../../helpers/shuffle';
import CorrectModal from './CorrectModal';
import PuzzlePiece from './PuzzlePiece';

import { HTML5Backend } from 'react-dnd-html5-backend';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import { DndProvider } from 'react-dnd';
import PuzzleGamePiece from './PuzzleGamePiece';

export type TPuzzlePiece = {
    image: string;
    shadow: string;
    position: 'top' | 'middle' | 'bottom' | 'none';
    styles: object;
    completed: boolean;
    correct: boolean;
};

export type TPuzzleQuestion = GameQuestion & {
    name: string;
    image: string;
    pieces: TPuzzlePiece[];
};

interface PuzzleProps {}

const Puzzle: React.FC<GameProps<TPuzzleQuestion> & PuzzleProps> = ({ gameData, index = 0, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TPuzzleQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    const handleCorrectPiece = (droppedPiece: TPuzzlePiece) => {
        console.log('handling...');
        question.pieces.map((piece) => {
            if (piece.position === droppedPiece.position && droppedPiece.correct) {
                console.log('piece to completed', [piece]);
                return piece;
            }

            return piece;
        });

        let selectedCount = 0;
        let totalCorrect = 0;
        question.pieces.forEach((piece) => {
            if (piece.correct) {
                totalCorrect++;
            }

            if (piece.completed) {
                selectedCount++;
            }
        });
        console.log(selectedCount);

        if (selectedCount === totalCorrect) {
            handleQuestionComplete();
        }
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    const shuffledPieces = shuffle([...question.pieces]);
    return (
        <DndProvider backend={MultiBackend as any} options={HTML5toTouch}>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <Hint>
                <i className="fa fa-info"></i>
                Build the toy! Drag and drop the toy pieces to complete the puzzle.
            </Hint>

            <div className={'container text-center'}>
                {question.pieces.map((piece, i) => (
                    <PuzzleGamePiece key={i + 'pp'} piece={piece} handleCorrect={handleCorrectPiece}></PuzzleGamePiece>
                ))}
            </div>

            <PieceContainer>
                {shuffledPieces.map((piece, i) => (
                    <PuzzlePiece key={i + 'p'} piece={piece}></PuzzlePiece>
                ))}
            </PieceContainer>

            {/* <DragDropContext onDragEnd={onDragEnd}>
                <div className={'container text-center'}>
                    <PuzzleBoard pieces={question.pieces}></PuzzleBoard>
                </div>
                <PieceContainer>
                    <PuzzlePieces pieces={shuffledPieces}></PuzzlePieces>
                </PieceContainer>
            </DragDropContext> */}
        </DndProvider>
    );
};

const Hint = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    display: block;
    margin: 10px auto 3px auto;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    position: relative;
    @media (min-width: 768px) and (max-height: 770px) {
        position: fixed;
        top: 75px;
        left: 0;
        margin: 15px 5px;
    }
    i {
        position: absolute;
        left: 5px;
        top: -15px;
        height: 28px;
        width: 28px;
        font-size: 1rem;
        text-align: center;
        border-radius: 100px;
        padding: 5px 10px;
        color: #00a0d6;
        background-color: #ffffff;
    }
`;

const PieceContainer = styled(Container)`
    margin-top: 10px;
    border-top: 3px dashed rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default Puzzle;
