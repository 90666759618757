import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AsteroidGame, { TAsteriodQuestion } from '../../../../interactive/asteroid/AsteroidGame';
import smallAsteroid from '../../../../../assets/games/space/asteroid--small.svg';
import largeAsteroid from '../../../../../assets/games/space/asteroid--large.svg';
import { VariablesData } from '../..';
import { t } from 'ttag';

const MissionGame: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const gameData: TAsteriodQuestion[] = [
        {
            scoreVisible: false,
            name: data.name,
            nameVisible: true,
            duration: 10,
            types: [
                { asteroidValue: 1, asteroidName: t`Small`, asteroidImage: smallAsteroid, scoreVisible: true },
                {
                    asteroidValue: 1,
                    asteroidName: t`Large`,
                    asteroidImage: largeAsteroid,
                    asteroidWidth: 40,
                    scoreVisible: true,
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true} overflow={false}>
            <AsteroidGame
                gameData={gameData}
                onGameComplete={nextSlide}
                reportGameScore={data.setGameScore}
            ></AsteroidGame>
        </Slide>
    );
};

export default MissionGame;
