import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import { Button, Row, Container, Col } from 'react-bootstrap';
import {motion} from 'framer-motion';

const Smoke: React.FC = () => {
  return (
    <>
      <StyledSVG width="42" height="92" viewBox="0 0 42 92" fill="none" style={{overflow: 'visible'}} xmlns="http://www.w3.org/2000/svg">
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 0.4, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} cx="16.0634" cy="77.5173" r="6.48042" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 2.5, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} cx="23.8253" cy="69.8623" r="4.81063" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 1, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1.5], opacity: [0, 0.8, 0 ]}} cx="14.461" cy="86.8776" r="4.81063" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 0.7, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} cx="5.13973" cy="49.7635" r="4.81063" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 2, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} cx="7.73021" cy="40.9722" r="7.48411" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 0.3, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1.4], opacity: [0, 0.8, 0 ]}} cx="35.4313" cy="17.0794" r="5.27309" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 0.6, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} cx="32.8524" cy="8.78261" r="8.29677" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 1.2, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1.3], opacity: [0, 0.8, 0 ]}} cx="32.173" cy="25.8708" r="7.61734" fill="white"/>
        <motion.circle transition={{loop: Infinity, repeatDelay: 1, delay: 1.9, duration: 3}} animate={{y: [0,-10,-25], scale:[0.2, 0.6, 1], opacity: [0, 0.8, 0 ]}} r="2.78591" transform="matrix(1 0 0 -1 27.8172 33.4881)" fill="white"/>
      </StyledSVG>
    </>
  );
};

const StyledSVG = styled.svg`
  bottom: 50%;
  left: 45%;
  width: 10%;
  position: absolute;
`;

export default Smoke;
