import React from 'react';
import Content from '../../../general/Content';
import { Row, Col } from 'react-bootstrap';
import { t } from 'ttag';

const Intel: React.FC = ({ children }) => {
    return (
        <Row>
            <Col xs={'12'} className={'cc-container'}>
                <h4 className={'cc-intel__heading'}>{t`NEW INTELLIGENCE RECEIVED`}</h4>
                <Content type="island" styles={'p-3 cc-intel__island'}>
                    {children}
                </Content>
            </Col>
        </Row>
    );
};

export default Intel;
