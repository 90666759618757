import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Slide, { ISlide } from '../../../core/Slide';
import sign from '../../../../assets/misc/sign-home.svg';
import sign_cy from '../../../../assets/misc/sign-home-cy.svg';
import { t } from 'ttag';
import Cookies from 'js-cookie';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );

    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={begin}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                    {lang === 'cy' ? (
                        <img src={sign_cy} className={'home-poster'} alt="" />
                    ) : (
                        <img src={sign} className={'home-poster'} alt="" />
                    )}
                    <h4 className="text-center">
                        {t`Use the power of`} <b>{t`abstraction`}</b> {t`to help keep the zoo running smoothly.`}
                    </h4>
                </Col>
            </Row>
        </Slide>
    );
};

export default StartSlide;
