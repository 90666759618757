import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ModalOverlay from '../../../general/ModalOverlay';
import { Button } from 'react-bootstrap';

import { t } from 'ttag';

interface CharacteristicProps {
    show: boolean;
    background?: string;
    title: string;
    icon: string;
    onClose: () => void;
}

const CharacteristicFound: React.FC<CharacteristicProps> = ({
    children,
    show,
    title,
    icon,
    background = 'rgba(62,0,0,0.8)',
    onClose,
}) => {
    let footer = <Button onClick={onClose}>{t`Close`}</Button>;

    return (
        <>
            <ModalOverlay
                show={show}
                header={
                    <div className="text-center pt-3">
                        <Icon src={icon} />
                        <p className="h--mini mb-1">{ t`CHARACTERISTIC FOUND` }</p>
                    </div>
                }
                footer={footer}
                backgroundColor="rgba(62, 0, 0, 0.8)"
            >
                <div className="text-center">
                    <h1 className="glitch" data-text={title}>
                        {title}
                    </h1>
                    <Card>
                        <p>{children}</p>
                    </Card>
                </div>
            </ModalOverlay>
        </>
    );
};

const Card = styled(motion.div)`
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 10px;
    position: relative;
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 10px auto;
    text-align: left;
    p {
        color: #6400aa;
        margin-bottom: 0;
    }
`;

const Icon = styled(motion.img)`
    width: 50px;
    margin: 10px auto;
    display: block;
`;

export default CharacteristicFound;
