import React, { useEffect } from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Turtle, { TurtleWanted } from '../../characters/Turtle';

const TurtleOutro: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    data,
    gotoSlide,
    currentIndex,
}) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    useEffect(() => {
        data.setAvailableIslandAreas([
            { name: 'shark', onSelect: () => gotoSlide(currentIndex + 2) },
        ]);
    }, []);

    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`I think you're now ready to help the other villagers protect themselves against the phishing attacks.`,
            textSpriteName: 'turtle-6-1',
            buttonText: t`Next`,
        },
        {
            character: TurtleWanted,
            text: t`One last thing, watch out for this scammer along the way. They go by the name of the 'Phisherman' and have been trying to phish us for weeks!`,
            textSpriteName: 'turtle-6-2',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TurtleOutro;
