import React from 'react';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';

const Vault: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={next}>
            <div className="th-vintro">
                <p>
                    <strong>{t`THIS VAULT IS SEQUENCE SECURED`}</strong>
                </p>
                <p>{t`Find the correct rules for the following sequences.`}</p>
                <p>
                    111...126...141...156...171...
                    <br />
                    1255...1265...1275...1285...
                    <br />
                    14.5...11.5...8.5...5.5...
                </p>
            </div>
        </Slide>
    );
};

export default Vault;
