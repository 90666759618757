import React from 'react';
import styled from 'styled-components';
import left_bottom from '../assets/background/left-bottom.svg';
import right_bottom from '../assets/background/right-bottom.svg';
import left_top from '../assets/background/left-top.svg';
import right_top from '../assets/background/right-top.svg';
import seafloor from '../assets/background/seafloor.svg';
import { motion } from 'framer-motion';
import Particles, { MoveDirection, OutMode } from 'react-particles-js';

export const Wrapper: React.FC = ({ children }) => {
    return (
        <div className="app-wrapper">
            <div className="bg-wrapper">
                <Gradient />
                <LeftBottom
                    animate={{ x: ['-5%', '-3%', '-5%'], opacity: [0.3, 0.5, 0.3] }}
                    transition={{ loop: Infinity, duration: 16, ease: 'linear' }}
                    src={left_bottom}
                    alt="Coral"
                />
                <RightBottom
                    animate={{ x: ['-3%', '-5%', '-3%'], opacity: [0.3, 0.5, 0.3] }}
                    transition={{ loop: Infinity, duration: 16, ease: 'linear' }}
                    src={right_bottom}
                    alt="Coral"
                />
                <SeaFloor src={seafloor} />
                <RightTop
                    animate={{ x: ['-6%', '-3%', '-6%'] }}
                    transition={{ loop: Infinity, duration: 13, ease: 'easeInOut' }}
                    src={right_top}
                    alt="Coral"
                />
                <LeftTop
                    animate={{ x: ['-3%', '-6%', '-3%'] }}
                    transition={{ loop: Infinity, duration: 13, ease: 'easeInOut' }}
                    src={left_top}
                    alt="Coral"
                />
                <Bubbles params={bubblez} />
            </div>
            {children}
        </div>
    );
};

const bubblez = {
    particles: {
        number: {
            value: 160,
            density: {
                enable: false,
            },
        },
        size: {
            value: 3,
            random: true,
            anim: {
                speed: 4,
                size_min: 0.3,
            },
        },
        line_linked: {
            enable: false,
        },
        move: {
            random: true,
            speed: 1,
            direction: MoveDirection.top,
            outMode: OutMode.out,
        },
    },
    interactivity: {
        events: {
            resize: true,
        },
    },
};
const Bubbles = styled(Particles)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const LeftBottom = styled(motion.img)`
    bottom: -4px;
    position: absolute;
    width: 45%;
    margin-left: -3%;
    left: 0;
    @media screen and (min-width: 500px) {
        width: 30%;
    }
`;

const RightBottom = styled(motion.img)`
    bottom: -4px;
    position: absolute;
    width: 45%;
    right: 0;
    margin-right: -3%;
    @media screen and (min-width: 500px) {
        width: 30%;
    }
`;
const RightTop = styled(motion.img)`
    bottom: -4px;
    position: absolute;
    width: 45%;
    right: 0;
    margin-right: -3%;
    @media screen and (min-width: 500px) {
        width: 30%;
    }
`;
const LeftTop = styled(motion.img)`
    bottom: -4px;
    position: absolute;
    width: 45%;
    left: 0;
    margin-left: -3%;
    @media screen and (min-width: 500px) {
        width: 30%;
    }
`;
const SeaFloor = styled(motion.img)`
    width: 80%;
    position: absolute;
    bottom: -5px;
    left: 10%;
`;
const Gradient = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(59, 9, 93);
    background: -moz-linear-gradient(0deg, rgba(59, 9, 93, 1) 0%, rgba(92, 8, 150, 1) 43%, rgba(100, 0, 170, 1) 100%);
    background: -webkit-linear-gradient(
        0deg,
        rgba(59, 9, 93, 1) 0%,
        rgba(92, 8, 150, 1) 43%,
        rgba(100, 0, 170, 1) 100%
    );
    background: linear-gradient(0deg, rgba(59, 9, 93, 1) 0%, rgba(92, 8, 150, 1) 43%, rgba(100, 0, 170, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b095d",endColorstr="#6400aa",GradientType=1);
`;

export default Wrapper;
