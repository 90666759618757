import React, { useState } from 'react';
import { TPuzzlePiece } from './Puzzle';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

interface PuzzleGamePieceProps {
    piece: TPuzzlePiece;
    handleCorrect: (piece: TPuzzlePiece) => void;
}

const PuzzleGamePiece: React.FC<PuzzleGamePieceProps> = ({ piece, handleCorrect }) => {
    const [select, setSelected] = useState(false);
    const [collectedProps, drop] = useDrop({
        accept: 'piece',
        drop: (item: any) => {
            if (piece.position === item.position && item.correct) {
                console.log('drop correct', collectedProps, item);
                piece.completed = true;
                handleCorrect(piece);
                setSelected(true);
            }
        },
    });

    return <img ref={drop} style={piece.styles} src={select ? piece.image : piece.shadow} />;
};

export default PuzzleGamePiece;
