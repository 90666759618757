import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Phone from '../../components/Phone';
import { t } from 'ttag';
import Characteristics, {
    TCharacteristicsQuestion,
} from '../../components/interactive/characteristics/Characteristics';
import { CyberSecurityData } from '../..';
import source_1 from '../../assets/characteristics/scenarios/source-1.svg';
import source_2 from '../../assets/characteristics/scenarios/source-2.svg';
import pressure_1 from '../../assets/characteristics/scenarios/pressure-1.svg';
import grammar_1 from '../../assets/characteristics/scenarios/grammar-1.svg';
import grammar_2 from '../../assets/characteristics/scenarios/grammar-2.svg';
import design_1 from '../../assets/characteristics/scenarios/design-1.svg';
import scam_1 from '../../assets/characteristics/scenarios/scam-1.svg';
import scam_2 from '../../assets/characteristics/scenarios/scam-2.svg';
import { shuffle } from '../../../../../helpers/shuffle';

const CharacteristicsGame: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    const gameData: TCharacteristicsQuestion[] = [
        {
            image: grammar_1,
            type: 'grammar',
        },
        {
            image: design_1,
            type: 'design',
        },
        {
            image: source_2,
            type: 'domain',
        },
        {
            image: pressure_1,
            type: 'pressure',
        },
        {
            image: scam_1,
            type: 'scam',
        },
        {
            image: source_1,
            type: 'domain',
        },
        {
            image: scam_2,
            type: 'scam',
        },
        {
            image: grammar_2,
            type: 'grammar',
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Characteristics
                gameData={gameData}
                data={data}
                onGameComplete={() => nextSlide()}
                logIncorrectAnswer={(c: string) =>
                    data.setIncorrectCharacteristics([...data.incorrectCharacteristics, c])
                }
            ></Characteristics>
        </Slide>
    );
};

export default CharacteristicsGame;
