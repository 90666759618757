import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import Intel from '../../components/Intel';
import { t } from 'ttag';

const CodeIntel: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Send intelligence`}
        </Button>
    );

    return (
        <Slide header={defaultHeader} footer={next}>
            <Intel>
                <p>{t`DECIPHERED MESSAGE`}</p>
                <ul className={'mb-3'} style={{ listStyleType: 'none' }}>
                    <li>
                        {t`ATTACK TOMORROW`}.
                        <br />
                        {t`INVADE LONDON`}.
                        <br />
                        {t`READY THE SHIPS`}.
                        <br />
                        {t`READY THE PLANES`}.
                        <br />
                        {t`START AT NOON`}.
                        <br />
                        {t`GOOD LUCK SOLDIERS`}.
                    </li>
                </ul>
                <p>{t`MESSAGE FROM HQ`}</p>
                <p>
                    {t`PRIME MINISTER, THE ENEMY PLANS TO ATTACK TOMORROW AT NOON. ATTACHED IS THE DECIPHERED
                    MESSAGE WE HAVE INTERCEPTED. READY THE AIR FORCE AND PREPARE TO DEFEND TOMORROW MORNING.`}
                </p>
            </Intel>
        </Slide>
    );
};

export default CodeIntel;
