import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toaster from 'toasted-notes';
import { t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import AlgorithmSelector from './AlgorithmSelector';

export type TAlgorithmQuestion = GameQuestion & {
    question: string;
    answers: string[];
};

const AlgorithmGame: React.FC<GameProps<TAlgorithmQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    // const [disabled, setDisabled] = useState(false);
    const [answer, setAnswer] = useState<{ [key: string]: string }>({});
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        onGameComplete();
    };

    const handleNextQuestion = () => {};

    const [question, nextQuestion] = useGameLogic<TAlgorithmQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleAnswerSubmit = () => {
        let correctAnswers = 0;
        // setDisabled(true);

        question.answers.forEach((ans, index) => {
            console.log('checking', ans, answer, index, answer[index]);
            if (ans === answer[index]) {
                correctAnswers++;
            }
        });

        if (correctAnswers === question.answers.length) {
            //  correct answer
            console.log('correct');
            if (volume) sfx.correct.play();
            nextQuestion();
            // setDisabled(false);
        } else {
            toaster.notify(t`That was incorrect. Try and make the algorithm fit the number sequence.`, {
                duration: 2000,
                position: 'bottom',
            });
            console.log('wrong, not enough correct', correctAnswers, question.answers.length);
            if (volume) sfx.incorrect.play();

            // setDisabled(false);
            // incorrect
        }
    };
    useEffect(() => {
        console.log(answer);
    }, [answer]);

    const handleAnswerChange = (sid: string, selection: string) => {
        console.log('total selection', sid, selection);
        setAnswer({
            ...answer,
            [sid]: selection,
        });
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <React.Fragment>
            {/* <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal> */}
            <p className={'ck-kit__howto'}>
                {t`Complete the algorithm,`}
                <br />
                {t`then press `}
                <strong>{t`Submit`}</strong>
            </p>

            <div className="text-center th-algo__container">
                <div className="th-vault__question">
                    <b>{question.question}</b>
                </div>

                <p>{t`Take the first number and...`}</p>
                {question.answers.map((q, index) => (
                    <AlgorithmSelector
                        sid={`${index}`}
                        onSelectionChange={handleAnswerChange}
                        question={question}
                    ></AlgorithmSelector>
                ))}
                <Button onClick={handleAnswerSubmit}>{t`Submit`}</Button>
            </div>
        </React.Fragment>
    );
};

export default AlgorithmGame;
