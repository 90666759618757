import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import pattern from '../../../../../assets/icons/icon_pattern.svg';
import SkillModal from '../../../../general/SkillModal';

const BuildingOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`Wow! That was so fast, well done! You found all the correct pieces to use! We now have enough gifts to begin packing!`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default BuildingOutro;
