import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import pattern from '../../../../../assets/icons/icon_pattern.svg';
import { ColourfulKitsData } from '../..';
import SkillModal from '../../../../general/SkillModal';
import { t } from 'ttag';

const AttributesGame2Outro2: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`They look great ${data.name}! Well done, we now have four cracking logos to choose from!`,
            buttonText: t`Woohoo!`,
            buttonAction: () => setShow(true),
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`PATTERN`}
                skillImage={pattern}
                skillDescription={t`You can see patterns across given objects and group them by their attributes.`}
            />
        </Slide>
    );
};

export default AttributesGame2Outro2;
