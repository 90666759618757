import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentSparrow from '../../characters/AgentSparrow';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CodeCrackingData } from '../..';
import { t } from 'ttag';

const TuringOutro: React.FC<ISlide<CodeCrackingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentSparrow,
            text: t`Nice to meet you Agent ${data.name}. I hope you can help us. We have a huge task ahead of us all so there’s no time to waste.`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TuringOutro;
