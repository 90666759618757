import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';
import { isIE } from 'react-device-detect';


interface TutorialProps {
  tutorialShow: boolean;
  handleClose: () => void;
  backgroundColor?: string,
  slides: TTutorial[],
  subtitle?: string
  title?: string
}

export type TTutorial = {
  text: string;
  image: string;
  textSpriteName?: string;
};

const Tutorial: React.FC<TutorialProps> = ({ tutorialShow = false, title, subtitle, handleClose, backgroundColor, slides }) => {
  const [tutorialIndex, setTutorialIndex] = useState<number | null>(null);
  const { playVoiceover } = useContext(VoiceoverContext);


  useEffect(() => {
    if (tutorialIndex !== null && typeof slides[tutorialIndex].textSpriteName !== 'undefined') {
      playVoiceover(slides[tutorialIndex].textSpriteName as string)
    }
  }, [tutorialIndex]);

  useEffect(() => {
    if (slides && tutorialShow && tutorialIndex === null) {
      setTutorialIndex(0);
    }
  }, [slides, tutorialShow])

  if (tutorialIndex === null) {
    return null;
  }

  return (
    <Wrapper tutorialShow={tutorialShow}>
      <TutorialCard
        backgroundColor={backgroundColor}
        animate={{ scale: [0, 1], opacity: [0, 1] }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
      >
        <h5 className="mb-0">{subtitle ? subtitle : t`TRAINING GAME`}</h5>
        <h3>{title ? title : t`HOW TO PLAY`}</h3>
        <hr />
        <TutorialSlides>
          <SlideArrow
            left={true}
            end={tutorialIndex <= 0}
            onClick={tutorialIndex - 1 > -1 ? () => setTutorialIndex(tutorialIndex - 1) : undefined}
          >
            <i className="fa fa-arrow-left"></i>
          </SlideArrow>
          {slides ? slides.map((slide, index) => (
            <TutorialSlide show={index == tutorialIndex}>
              <SlideImage src={slide.image} />
              <p className="my-3">{slide.text}</p>
            </TutorialSlide>
          )) : null}
          <SlideArrow
            left={false}
            end={tutorialIndex + 1 >= slides.length}
            onClick={tutorialIndex + 1 < slides.length ? () => setTutorialIndex(tutorialIndex + 1) : undefined}
          >
            <i className="fa fa-arrow-right"></i>
          </SlideArrow>
        </TutorialSlides>
        {tutorialIndex < slides.length - 1 ? (
          <TutorialProgress>
            <Progress progress={(tutorialIndex / (slides.length - 1)) * 100} />
          </TutorialProgress>
        ) : (
            <Button className="m-0" onClick={handleClose}>
              { t`Finish`}
            </Button>
          )}
      </TutorialCard>
    </Wrapper>
  );
};
interface SlideProps {
  show: boolean;
}
const TutorialSlide = styled.div<SlideProps>`
  ${(props) => props.show ? 'display: block;' : 'display: none;'}
`;

interface ArrowProps {
  left: boolean;
  end: boolean;
}
const SlideArrow = styled.button<ArrowProps>`
  ${(props) => (props.left ? 'left: 5px;' : 'right: 5px;')}
  position: absolute;
  top: calc(50% - 25px);
  background-color: #ffffff;
  padding: 10px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 0 !important;
  ${(props) => (props.end ? 'opacity: 0.3;' : null)}
`;

const SlideImage = styled.img`
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 5px;
`;

const TutorialSlides = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const TutorialProgress = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  max-width: 100px;
  border-radius: 5px;
  display: block;
  margin: 16px auto 32px auto;
  background-color: rgba(0,0,0,0.3);
  overflow: hidden;
`;
interface ProgressProps {
  progress: number;
}
const Progress = styled.div<ProgressProps>`
  background-color: #ffffff;
  transition: width 0.3s ease-in-out;
  width: ${(props) => props.progress}%;
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
`;

interface CardProps {
  backgroundColor?: string
}

const TutorialCard = styled(motion.div) <CardProps>`
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : '#e60050'};
  padding: 1rem 1.5rem;
  text-align: center;
  width: 100%;
  max-width: 350px;
  transition: height 0.5s ease-in-out;
  box-shadow: 0 10px 25px rgba(0,0,0,0.25);
  h5 {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 0.875rem;
      font-family: 'BT Font Extra Bold';
  }
  p {
      line-height: 20px;
      margin-bottom: 0;
  }
`;

interface WrapperProps {
  tutorialShow: boolean;
}
const Wrapper = styled(motion.div) <WrapperProps>`
  position: fixed;
  display: none;
  ${(props) => (props.tutorialShow ? 'display: flex;' : null)}
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${!isIE ? 'rgba(0, 0, 0, 0.6)' : 'transparent'};
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding-top: 75px;
  p {
      font-size: 16px;
      margin-bottom: 5px;
  }
`;

export default Tutorial;
