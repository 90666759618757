import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { GlibGlob } from '../../characters/Aliens';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { VariablesData } from '../..';
import { t } from 'ttag';

const TuringOutro: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: GlibGlob,
            text: t`Nice to meet you, ${data.name}. Our ship is tasked with getting rid of asteroids because a lot of our spaceships tend to crash into them.`,
            buttonText: t`Next`,
        },
        {
            character: GlibGlob,
            text: t`We destroy the asteroids using our ship's special lasers. Would you be able to help us clear some asteroids?`,
            buttonText: t`Sure!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TuringOutro;
