import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import howto1 from '../../assets/lights/howto/howto1.png';
import howto2 from '../../assets/lights/howto/howto2.png';
import howto3 from '../../assets/lights/howto/howto3.png';
import howto4 from '../../assets/lights/howto/howto4.png';
import howto5 from '../../assets/lights/howto/howto5.png';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import CrashModal from '../../components/interactive/scene/CrashModal';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';
import Tutorial, { TTutorial } from '../../components/interactive/Tutorial';
import { TTrafficLight } from '../../components/traffic-lights/TrafficLight';
import TrafficLightEditorStatic from '../../components/traffic-lights/TrafficLightEditorStatic';


const NoLightsGame: React.FC<ISlide<TrafficData>> = ({ nextSlide, previousSlide, defaultHeader, gotoSlide, currentIndex, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const { width } = useWindowDimensions();
    const [help] = useState(t`Use the traffic light to get BOTH cars across the bridge safely.`);
    const [carData] = useState<TCar[]>([
        { id: 'go-1', pos: 0, moving: true, direction: 'clockwise', onComplete: () => setShow(true) },
        {
            id: 'gi-1', pos: width, moving: true, direction: 'anticlockwise', onComplete: () => {
                data.playVoiceover(`37`);
                console.log('here?')
            }
        },

    ]);
    const [show, setShow] = useState(false);
    const [tutorialShow, setTutorialShow] = useState(true);
    const [showEditor, setShowEditor] = useState(false);
    const [showCrash, setShowCrash] = useState(false);


    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`That worked! The traffic light helped you control the flow of traffic.`,
            textSpriteName: `116`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    const [t1, setT1] = useState<TTrafficLight>({});

    const [trafficLight1, setTrafficLight1] = useState<TRoadLight>({
        sequence: [
        ],
        highlight: true,
        pos: 1280 - 300,
        direction: 'clockwise',
        onClick: () => setShowEditor(true),

    });

    useEffect(() => {
        setTrafficLight1({
            ...trafficLight1,
            sequence: [
                { ...t1, duration: 1000, }
            ]
        })
    }, [t1])

    const resetSlide = () => {
        previousSlide();
        setTimeout(() => gotoSlide(currentIndex), 0);
    }

    const slides: TTutorial[] = [
        { text: t`We use traffic lights to tell drivers when it's safe to stop or go.`, image: howto1, textSpriteName: `15` },
        { text: t`Select the traffic light to open up its control panel and start programming the lights.`, image: howto2, textSpriteName: `16` },
        { text: t`When a red light is shown, cars will come to a stop.`, image: howto3, textSpriteName: `17` },
        { text: t`Cars will go when a Green or Amber light is shown.`, image: howto4, textSpriteName: `18` },
        { text: t`Select any of the three lights to turn them on or off, then press Change.`, image: howto5, textSpriteName: `19` },
        { text: t`Press Run Test to see if the cars cross the bridge safely!`, image: howto5, textSpriteName: `20` },
    ]

    const [paused, setPaused] = useState(false);
    const [resetGame, setResetGame] = useState(false);
    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Scene
                nextSlide={nextSlide}
                roadworks={false}
                help={help}
                crash={false}
                lights={false}
                road={{
                    carData: carData,
                    trafficLight1: trafficLight1,
                    onCrash: () => {
                        setPaused(true);
                        setShowCrash(true)
                    },
                    paused: paused,
                    setPause: setPaused,
                    gameReset: resetGame
                }}
            >
                <>
                    {show ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} /> : null}
                    {showCrash ? <CrashModal onClick={() => {
                        setResetGame(!resetGame)
                        setShowCrash(false)
                        setPaused(true);
                    }}></CrashModal> : null}
                    {showEditor ?
                        <TrafficLightEditorStatic onClose={() => setShowEditor(false)} onAnswerSubmit={(light) => {
                            setShowEditor(false);
                            setT1(light);
                            // reset game
                            // but cant reset game??? then we have same problem
                            // resetSlide();
                            // reset game
                            // but cant reset game??? then we have same problem
                            // resetSlide();
                        }}></TrafficLightEditorStatic>
                        : null}
                    {/* GAME TO GET THE ONE GREEN CAR THROUGH THE LIGHTS (TASK1) */}
                    <Tutorial tutorialShow={tutorialShow} subtitle={t`TRAFFIC LIGHTS`} title={t`HOW TO USE`} backgroundColor={'#0AB9F0'} handleClose={() => {
                        setTutorialShow(false);
                        data.playVoiceover('118');
                    }} slides={slides}>

                    </Tutorial>
                </>
            </Scene>
        </Slide>
    );
}

export default NoLightsGame;
