import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Slide, { ISlide } from '../../../core/Slide';
import caesar_top from '../../../../assets/misc/caesar_top.svg';
import caesar_bottom from '../../../../assets/misc/caesar_bottom.svg';
import Content from '../../../general/Content';
import { motion } from 'framer-motion';
import { t } from 'ttag';
import Cookies from 'js-cookie';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );

    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={begin}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className="text-center">
                    <div className="cc-cipher__wrapper">
                        <div className={'cc-cipher'}>
                            <motion.img
                                animate={{
                                    scale: [0.85, 0.96, 0.96, 0.85, 0.85, 0.96, 0.96, 0.85],
                                    rotate: [0, 0, 360, 360, 360, 360, 0, 0],
                                }}
                                transition={{
                                    duration: 8,
                                    ease: 'easeInOut',
                                    times: [0, 0.1, 0.3, 0.4, 0.6, 0.7, 0.9, 1],
                                    loop: Infinity,
                                    repeatDelay: 2,
                                }}
                                className={'cc-cipher__disc cc-cipher__disc--top'}
                                src={caesar_top}
                                width={'500px'}
                                height={'500px'}
                            />
                            <motion.img
                                animate={{
                                    scale: [0.94, 0.99, 0.99, 0.94, 0.94, 0.99, 0.99, 0.94],
                                    rotate: [0, 0, -180, -180, -180, -180, 0, 0],
                                }}
                                transition={{
                                    duration: 6,
                                    ease: 'easeInOut',
                                    times: [0, 0.1, 0.3, 0.4, 0.6, 0.7, 0.9, 1],
                                    loop: Infinity,
                                    repeatDelay: 3,
                                }}
                                className={'cc-cipher__disc cc-cipher__disc--bottom'}
                                src={caesar_bottom}
                                width={'500px'}
                                height={'500px'}
                            />
                            <Content type={'island'} styles={'cc-home__island p-3'}>
                                <h2>{t`Code Cracking`}</h2>
                            </Content>
                            <Content
                                type={'island'}
                                styles={
                                    'cc-home__island cc-home__island--yellow p-1 ' +
                                    (lang === 'cy' ? 'cc-home__island--welsh' : null)
                                }
                            >
                                <p>{t`WELCOME TO HQ`}</p>
                            </Content>
                        </div>
                    </div>
                </Col>
            </Row>
        </Slide>
    );
};

export default StartSlide;
