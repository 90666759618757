import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { VolumeContext } from './volume-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { Howler } from 'howler';
import { saveVolume } from '../../services/volume';
import styled from 'styled-components';

import musicSlash from '../../../src/assets/misc/music-slash.png';

export type ContextualMuteProps = {
    context?: Howl | undefined
    zIndex?: number
}

const ContextualMute: React.FC<ContextualMuteProps> = ({context = Howler, zIndex=4}) => {
    const { volume, setVolume, musicMuted, setMusicMuted } = useContext(VolumeContext);
    const icon = faMusic;

    useEffect(() => {
        if (volume === 0) {
            context.mute(true);
            saveVolume(0);
        } else {
            if (volume && volume > 0) {
                saveVolume(-1);
            }
            context.mute(false);
        }
    }, [volume]);

    if (setVolume === undefined) {
        return null;
    }

    return (
        <React.Fragment>
            <Button
            className={'btn--mute'}
                style={{zIndex: 10}}
                onClick={() => {
                    if (setMusicMuted) {
                        volume ? setMusicMuted(true) :setMusicMuted(false);

                    }
                    context.mute(volume ? true : false);
                    return volume ? setVolume(0) : setVolume(0.5);
                }}
            >
                { volume ?  <FontAwesomeIcon icon={icon} /> : <Slash src={musicSlash}/>}

            </Button>
        </React.Fragment>
    );
};

const Slash = styled.img`
    height: 14px;
    width: 16px;
    margin-top: -2px;
    margin-left: -1px;
    @media (min-width: 992px){
        height: 15px;
        width: 17px;
    }
`;





export default ContextualMute;
