const spritesheet: IHowlSoundSpriteDefinition = {
    "turtle-1-1": [0, 10000],
    "turtle-1-2": [10565, 7435],
    "turtle-nickname": [19000, 2000],
    "turtle-avatar": [21500, 1500],
    "turtle-2-1": [23500, 8710],
    "turtle-2-2": [32880, 9600],
    "turtle-lives": [43000, 15480],
    "turtle-island": [59480, 2520],
    "turtle-3-1": [63000, 6300],
    "turtle-3-2": [70000, 8120],
    "turtle-personal-1": [79000, 4640],
    "turtle-personal-2": [84600, 5940],
    "turtle-4": [92000, 4000],
    "turtle-question-1": [97000, 12000],
    "turtle-question-1-incorrect": [110000, 6000],
    "turtle-question-1-correct": [118000, 5200],
    "turtle-5-1": [124000, 10500],
    "turtle-5-2": [135500, 10030],
    "turtle-5-3": [147000, 10700],
    "turtle-word-discovered": [159000, 11860],
    "turtle-6-1": [172000, 5250],
    "turtle-6-2": [179000, 9000],

    "whale-1-1": [190000, 7400],
    "whale-2-1": [198500, 7500],

    "game-howto-title": [208000, 1000],
    "phished-words": [210000, 1500],
    "dodged-words": [213000, 2500],

    "whale-howto-1": [217000, 3500],
    "whale-howto-2": [221500, 3500],
    "whale-howto-3": [225500, 3100],
    "whale-howto-4": [230000, 3000],
    "whale-ready": [234000, 8000],
    "whale-char-1": [244000, 12000],
    "whale-char-2": [258000, 11500],
    "whale-char-3": [271000, 9800],
    "whale-char-4": [282000, 7300],
    "whale-char-5": [291000, 11000],
    "whale-3-1": [1078200, 7720],
    "whale-4-1": [1045480, 7120],


    "octo-1-1": [311000, 8000],
    "octo-help-1": [320000, 5000],
    "octo-help-2": [326000, 1500],
    "octo-email-1": [329000, 10500],
    "octo-help-3": [341000, 4000],
    "octo-char-1": [347000, 6330],
    "octo-char-2": [355000, 14000],
    "octo-email-2": [371000, 10550],
    "octo-email-3": [383000, 9000],
    "octo-incorrect-toast": [392000, 4800],
    "octo-char-3": [399000, 10500],
    "octo-email-4": [411000, 8500],
    "octo-incorrect-pop": [421000, 1000],
    "octo-phished-desc": [423000, 4700],
    "octo-phisherman-1": [429000, 6700],
    "octo-question-1": [437000, 14000],
    "octo-question-1-correct": [453000, 8000],



    "fish-1-1": [462000, 8000],
    "fish-follow": [471000, 1500],
    "fish-2-1": [473500, 6000],
    "fish-2-2": [481000, 5000],
    "fish-messages": [487500, 1800],
    "fish-chat-1": [492000, 17800],
    "fish-chat-1-answers": [511000, 5000],
    "fish-chat-a-1": [511000, 1500],
    "fish-chat-a-2": [512500, 1200],
    "fish-chat-a-3": [513800, 1500],
    "fish-chat-2": [517000, 2500],
    "fish-cyber-click": [521000, 6300],
    "fish-cyber-block": [529000, 6500],
    "fish-dodged-desc": [537000, 5000],
    "fish-phisherman-1": [544000, 8000],
    "fish-question-1": [554000, 12300],
    "fish-question-1-correct": [568000, 8000],

    "fish-phished-desc": [578000, 6000],
    "fish-phisherman-2": [585500, 7500],

    "pufferfish-1-1": [595000, 6700],
    "pufferfish-1-2": [603000, 4000],
    "pufferfish-1-3": [608500, 6100],
    "pufferfish-phone": [616000, 2400],
    "pufferfish-phone-1": [1067800, 1840],
    "pufferfish-phone-1-answers": [622000, 3002],
    "pufferfish-phone-a1-1": [619500, 1000], // Old pufferfish-phone-1
    "pufferfish-phone-a1-2": [623500, 1500],
    "pufferfish-phone-a2-1": [639805, 2000],
    "pufferfish-phone-a2-2": [643000, 3000],
    "pufferfish-phone-a3-1": [647600, 2000],
    "pufferfish-phone-a3-2": [650400, 2000],
    "pufferfish-phone-2": [627000, 10700],
    "pufferfish-phone-2-answers": [640000, 6000],
    "pufferfish-phone-3": [1056040, 8640],
    "pufferfish-phished-desc": [655000, 4400],
    "pufferfish-dodged-desc": [661000, 4000],
    "pufferfish-phisherman-1": [666000, 8000],
    "pufferfish-phisherman-2": [675500, 4800],
    "pufferfish-phisherman-3": [682000, 7200],
    "pufferfish-phisherman-4": [692000, 6500],
    "pufferfish-question-1": [700000, 9500],
    "pufferfish-question-1-correct": [711000, 6500],
    "pufferfish-2-1": [719000, 12500],


    "endturtle-1-1": [733000, 8000],

    "endturtle-cyber-close": [742000, 6800],

    "endturtle-phished-desc": [751000, 8000],
    "endturtle-dodged-desc": [761000, 7500],
    "endturtle-phisherman-1": [770000, 8000],
    "endturtle-phisherman-2": [780000, 8800],
    "endturtle-2-1": [790000, 7800],
    "endturtle-2-2": [799000, 7000],

    "end-howto-title": [807000, 1000],
    "end-howto-1": [809000, 3500],
    "end-howto-2": [814000, 3500],
    "end-howto-3": [819000, 3300],
    "end-howto-4": [823500, 3500],

    "end-question-1": [828000, 9000],
    "end-answers-1": [838000, 9000],
    "end-question-2": [849000, 8000],
    "end-answers-2": [859000, 10000],
    "end-question-3": [870000, 4000],
    "end-answers-3": [876000, 8000],
    "end-question-4": [886000, 6000],
    "end-answers-4": [894000, 7000],
    "end-question-5": [902000, 5000],
    "end-answers-5": [908000, 9000],
    "end-question-6": [918000, 6000],
    "end-answers-6": [926000, 11000],
    "end-question-7": [939000, 7500],
    "end-answers-7": [948000, 9000],

    "end-phisherman-1": [959000, 8000],
    "endturtle-3-1": [969000, 8000],
    "end-awarding-1": [978000, 1500],
    "end-phisherman-2": [980500, 2100],
    "end-awarded-1": [984000, 1800],
    "end-award-1": [987000, 1500],
    "end-award-2": [990000, 1500],
    "end-award-3": [993000, 1500],

    "end-finish-1": [996000, 2000],
    "end-finish-2": [1000000, 6700],
    "end-finish-3": [1090000, 7000],

    "island-title": [1008000, 1500],
    "island-msg-hall": [1011000, 2000],
    "island-msg-start": [1014000, 1750],
    "island-msg-character": [1017000, 2000],

    "question-incorrect": [1021000, 5500],

    "sound-1": [1028000, 1000],
    "sound-2": [1030000, 1500],
    "sound-3": [1033000, 1000],
    "sound-4": [1035000, 1000],
    "sound-5": [1037000, 1000],
    "sound-6": [1039000, 1000],
    "sound-7": [1041000, 1000],
    "sound-8": [1043000, 1000],

    "end-a-1-a": [838000, 3000],
    "end-a-1-b": [841000, 3000],
    "end-a-1-c": [844000, 3000],
    "end-a-2-a": [859000, 5000],
    "end-a-2-b": [864000, 2000],
    "end-a-2-c": [866000, 3000],
    "end-a-3-a": [876000, 2000],
    "end-a-3-b": [878000, 3500],
    "end-a-3-c": [881500, 3000],
    "end-a-4-a": [894000, 2000],
    "end-a-4-b": [896500, 2000],
    "end-a-4-c": [898500, 2000],
    "end-a-5-a": [907000, 2500],
    "end-a-5-b": [909500, 3700],
    "end-a-5-c": [913000, 4000],
    "end-a-6-a": [925800, 4200],
    "end-a-6-b": [930000, 4000],
    "end-a-6-c": [934000, 3000],
    "end-a-7-a": [947000, 4000],
    "end-a-7-b": [951000, 2500],
    "end-a-7-c": [953500, 3500],

    "term-discovered": [1073680, 1280],
};

export default spritesheet;
