import React, { useEffect, useState } from 'react';
import toaster from 'toasted-notes';
import { t } from 'ttag';
import KitBox from './KitBox';
import { TKitMistakeAnswer } from './KitMistake';

export type GameKitGroup = {
    kits: [TKitMistakeAnswer, TKitMistakeAnswer, TKitMistakeAnswer, TKitMistakeAnswer];
    onComplete: () => void;
};

const KitBoxGroup: React.FC<GameKitGroup> = ({ kits, onComplete }) => {
    const [targetCorrect, setTargetCorrect] = useState(0);
    const [answersCorrect, setAnswersCorrect] = useState(0);
    const [resetQuestion, setResetQuestion] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        let correctAnswers = 0;
        for (let i = 0; i < kits.length; ++i) {
            if (kits[i].correct === true) {
                correctAnswers++;
            }
        }

        setAnswersCorrect(0);
        setTargetCorrect(correctAnswers);
    }, [kits]);

    useEffect(() => {
        if (answersCorrect > 0 && answersCorrect === targetCorrect) {
            // QUESTION COMPLETE
            setDisabled(true);

            setTimeout(() => {
                setAnswersCorrect(0);
                setDisabled(false);
                onComplete();
            }, 1400);
        }
    }, [answersCorrect, onComplete, targetCorrect]);

    const onAnswer = (correct: boolean) => {
        if (correct === false) {
            // RESET QUESTION? OnIncorrect
            // maybe pass the question with the onanswer so we have the hint
            toaster.notify(
                t`That was incorrect. Click on the items which do not match the athlete's country or activity`,
                {
                    duration: 2000,
                    position: 'bottom',
                },
            );
            setDisabled(true);
            setTimeout(() => {
                setResetQuestion(!resetQuestion);
                setAnswersCorrect(0);
                setDisabled(false);
            }, 2000);
        } else {
            setAnswersCorrect(answersCorrect + 1);
        }
    };

    return (
        <div className="ck-kit__types-wrapper">
            <KitBox kit={kits[0]} onAnswer={onAnswer} resetQuestion={resetQuestion} isDisabled={disabled}></KitBox>
            <KitBox kit={kits[1]} onAnswer={onAnswer} resetQuestion={resetQuestion} isDisabled={disabled}></KitBox>
            <KitBox kit={kits[2]} onAnswer={onAnswer} resetQuestion={resetQuestion} isDisabled={disabled}></KitBox>
            {/* <KitBox kit={kits[3]} onAnswer={onAnswer} resetQuestion={resetQuestion} isDisabled={disabled}></KitBox> */}
        </div>
    );
};

export default KitBoxGroup;
