import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import { TrafficData } from '../..';
import { t } from 'ttag';
import styled from 'styled-components';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder from '../../assets/levels/level-placeholder.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import LevelSelector from '../../components/interactive/levels/LevelSelector';

const LevelOne: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <LevelSelector level={1} nextSlide={nextSlide} />
        </Slide>
    );
};




export default LevelOne;
