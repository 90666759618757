import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { filterKitItems, TKitMatchItem, TKitMatchItemType } from './KitMatch';

const KitSelector: React.FC<{
    items: TKitMatchItem[];
    targetId: string;
    targetType: TKitMatchItemType;
    onAnswer: (targetType: TKitMatchItemType, correct: boolean) => void;
    isDisabled: boolean;
}> = ({ items, targetId, targetType, onAnswer, isDisabled }) => {
    const [kitIndex, setKitIndex] = useState(0);
    const [kitItem, setKitItem] = useState<TKitMatchItem | null>();

    useEffect(() => {
        setKitItem(null);
        setKitIndex(0);
    }, [items]);

    useEffect(() => {
        if (typeof items[kitIndex] !== undefined) {
            console.log('setting kit', kitIndex);
            let filteredItems = filterKitItems(items, targetType);

            setKitItem(filteredItems[kitIndex]);
        }
    }, [kitIndex, items]);

    useEffect(() => {
        if (kitItem && kitItem.tid === targetId && kitItem.type === targetType) {
            // console.log('Correct bro', kitItem)
            onAnswer(targetType, true);
        } else {
            onAnswer(targetType, false);
        }
    }, [kitItem]);

    const handleNextItem = () => {
        if (isDisabled) {
            return;
        }
        if (filterKitItems(items, targetType).length - 1 > kitIndex) {
            setKitIndex(kitIndex + 1);
        } else {
            setKitIndex(0);
        }
    };

    const handlePreviousItem = () => {
        if (isDisabled) {
            return;
        }
        if (kitIndex - 1 < 0) {
            setKitIndex(filterKitItems(items, targetType).length - 1);
        } else {
            setKitIndex(kitIndex - 1);
        }
    };

    return (
        <div className={`ck-kit__type ck-kit__type--${kitItem?.type}`}>
            <Button className={'ck-kit__btn ck-kit__btn--prev'} onClick={handlePreviousItem}>
                <i className={'fas fa-chevron-left'}></i>
            </Button>
            {kitItem && kitItem?.image ? (
                <img className={`ck-kit__img ck-kit__img--${kitItem?.type}`} src={kitItem?.image} />
            ) : null}
            <Button className={'ck-kit__btn ck-kit__btn--next'} onClick={handleNextItem}>
                <i className={'fas fa-chevron-right'}></i>
            </Button>
        </div>
    );
};

export default KitSelector;
