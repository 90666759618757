import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import pattern from '../../../../../assets/icons/icon_pattern.svg';
import SkillModal from '../../../../general/SkillModal';

const GiftboxOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`Wow, those boxes are beautiful, well done! You tinkered with the machine to identify the correct settings for each box!`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`TINKERING`}
                skillImage={pattern}
                skillDescription={t`Playing or exploring to discover what something is and how it works.`}
            />
        </Slide>
    );
};

export default GiftboxOutro;
