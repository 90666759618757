import React, { useContext } from 'react';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { Button, Col, Row } from 'react-bootstrap';
import { VolumeContext } from '../../../misc/volume-context';
import sfx from '../../../../services/sounds';
import Reward from 'react-rewards';
import { t } from 'ttag';
import image_vault from '../../../../assets/characters/thief_girl-safe.svg';

const EndSlide: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;
    const { volume } = useContext(VolumeContext);

    let moduleName = t`The Diamond`;

    if (volume) sfx.cheer.play();

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}

                    <Content type="island_dark" styles={'my-2 p-2'}>
                        <Reward ref={(ref: any) => (ref ? ref.rewardMe() : null)} type="confetti" />
                        <Reward
                            ref={(ref: any) => {
                                return ref ? setInterval(() => ref.rewardMe(), 5000) : null;
                            }}
                            type="confetti"
                        >
                            <img
                                src={image_vault}
                                alt={'Agent Anushka'}
                                width={'200px'}
                                height={'180px'}
                                className="char"
                            />
                            <div className={'character__shadow'}></div>
                        </Reward>

                        <h3 className={'mt-2'}>{t`You have completed
                        ${moduleName}!`}</h3>
                        <p>{t`Congratulations!`}</p>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about in ${moduleName} and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/the-diamond"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20The%20Diamond%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/the-diamond"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndSlide;
