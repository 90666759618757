import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import sfx from '../../../../../services/sounds';
import Slide, { ISlide } from '../../../../core/Slide';
import ModalOverlay from '../../../../general/ModalOverlay';
import KeypadGame, { TKeypadGameQuestion } from '../../../../interactive/keypad/KeypadGame';
import { VolumeContext } from '../../../../misc/volume-context';

const KeypadGameSlide: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const { volume } = useContext(VolumeContext);

    const gameData: TKeypadGameQuestion[] = [{ question: '19 ... 27 ... 35 ... 43 ...', answer: '51' }];

    const [show, setShow] = useState(false);

    return (
        <Slide header={defaultHeader}>
            <ModalOverlay
                header={<p></p>}
                footer={<Button onClick={nextSlide}>{t`Continue`}</Button>}
                show={show}
                backgroundColor="#00C3A5"
            >
                <Row>
                    <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                        <i className={'fas fa-door-open icon--achievement mb-2'}></i>
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.7 }}>
                            <p>
                                <strong>{t`DOOR UNLOCKED`}</strong>
                            </p>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
            <KeypadGame
                gameData={gameData}
                onGameComplete={() => {
                    if (volume) sfx.success.play();
                    setShow(true);
                }}
            />
        </Slide>
    );
};

export default KeypadGameSlide;
