import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import TrafficLight, { TTrafficLight } from './TrafficLight';

type TrafficFrameProps = TTrafficLight & {
  isActive: boolean;
  onClick?: () => void;
}

const TrafficFrame: React.FC<TrafficFrameProps> = (props) => {
  const { isActive } = props;
  return (
    <div onClick={props.onClick}>
      <Wrapper active={isActive}>
        <TrafficLight showPole={false} {...props}></TrafficLight>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{
  active: boolean
}>`
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: 30px;
  svg {width: 100%;}
  border: 2px solid transparent;
  border-radius: 5px;
  ${p => p.active ? 'background-color: ' + theme.bare_blue + '; box-shadow: 0 0 10px ' + theme.bare_blue + '; border-color: ' + theme.bare_blue + ';' : ''}
`;

export default TrafficFrame;
