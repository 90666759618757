import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Phisherman from '../../characters/Phisherman';

const BlockUserDodgePhisher: React.FC<ISlide> = ({ defaultHeader, gotoSlide, currentIndex }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Phisherman,
            text: t`No way! I thought I had you there. That's so annoying, it took me ages to make that fake NikNak account...`,
            textSpriteName: 'fish-phisherman-1',
            buttonText: t`Next`,
            buttonAction: () => gotoSlide(currentIndex + 4),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default BlockUserDodgePhisher;
