import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { CyberSecurityData } from '../..';
import { t } from 'ttag';
import turtle from '../../assets/characters/2-turtle.svg';
import VoiceoverContext from '../../../../misc/voiceover-context';

const YourDetails: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-personal-2')
    }, []);

    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col className={'text-center'}>
                    <h2 className={'mt-3'}>{t`Your Personal Information`}</h2>
                </Col>
            </Row>
            <IDCard>
                <Reflection />

                <Row>
                    <Col xs={'12'} sm={'4'} style={{ maxWidth: '150px' }}>
                        <Circle>
                            <Avatar src={data.avatar ? data.avatar : 'https://placehold.it/300x300'} width={'100'} />
                        </Circle>
                        <h3>{data.name}</h3>
                    </Col>
                    <Col xs={'12'} sm={'8'}>
                        <p>{t`Email`}</p>
                        <h3>{data.name}@fishmail.co.uk</h3>
                        <p>{t`Password`}</p>
                        <h3>fish!1451soup#</h3>
                        <p>{t`Home Address`}</p>
                        <h3>123 Pearcroft Road, Countryside Place, Cornwall</h3>
                        <p>{t`Payment card number`}</p>
                        <h3>3030 9391 9384 2134</h3>
                    </Col>
                </Row>
            </IDCard>
            <Message>
                <Character src={turtle} />
                <p>{t`Don’t worry! This information is only used for today's adventure.`}</p>
            </Message>
        </Slide>
    );
};

const Message = styled.div`
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    p {
        background-color: #ffffff;
        display: inline-block;
        max-width: 80%;
        border-radius: 5px;
        margin-left: 0;
        margin-top: 0;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.3);
        color: #6400aa;
        padding: 10px 20px;
        font-size: 18px;
        position: relative;
        text-align: left;
        &::after {
            content: '';
            position: absolute;
            right: 100%;
            top: 50%;
            width: 0;
            height: 0;
            border-bottom: 5px solid transparent;
            border-right: 10px solid #ffffff;
            border-top: 5px solid transparent;
            clear: both;
        }
    }
`;

const IDCard = styled.div`
    border-radius: 10px;
    background-color: #00A0D6;
    max-width: 500px;
    padding: 1rem 2rem;
    p {margin-bottom: 5px;}
    border: 2px solid #ffffff;
    position: relative;
    overflow: hidden;
    display; block;
    margin 1rem auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Avatar = styled.img`
    width: 100%;
    position: absolute;
    transform: translateY(-50%) scale(1.2);
    top: 50%;
    left: 0;
`;

const Circle = styled.div`
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-top: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
`;

const Reflection = styled.div`
    position: absolute;
    border-style: solid;
    border-width: 200px 200px 0 0;
    border-color: rgba(255, 255, 255, 0.2) transparent transparent transparent;
    top: 0;
    left: 0;
`;

const Character = styled.img`
    width: 12%;
    margin-left: 5%;
    display: inline-block;
`;

export default YourDetails;
