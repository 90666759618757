import { remove } from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Button, Row, Col } from 'react-bootstrap';

import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import VolumeContext from '../../../../../misc/volume-context';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { CyberSecurityData } from '../../..';

import CharacteristicBtn from './CharacteristicBtn';

import c_grammar from '../../../assets/characteristics/c-grammar.svg';
import c_design from '../../../assets/characteristics/c-design.svg';
import c_good from '../../../assets/characteristics/c-good.svg';
import c_pressure from '../../../assets/characteristics/c-pressure.svg';
import c_source from '../../../assets/characteristics/c-source.svg';
import { useGameTimer } from '../../../../../../hooks/game';

import slide_1 from '../../../assets/characteristics/tutorial/slide_1.svg';
import slide_2 from '../../../assets/characteristics/tutorial/slide_2.svg';
import slide_3 from '../../../assets/characteristics/tutorial/slide_3.svg';
import slide_4 from '../../../assets/characteristics/tutorial/slide_4.svg';
import VoiceoverContext from '../../../../../misc/voiceover-context';
import Cookies from 'js-cookie';

export type TCharacteristicsQuestion = GameQuestion & {
    image: string;
    type: TCharacteristic;
    duration?: number;
};

interface ICharacteristics {
    data: CyberSecurityData;
    logIncorrectAnswer: (answer: string) => void;
}

type TCharacteristic = 'grammar' | 'pressure' | 'scam' | 'design' | 'domain';

const Characteristics: React.FC<GameProps<TCharacteristicsQuestion> & ICharacteristics> = ({
    gameData,
    index = 0,
    data,
    onGameComplete,
    logIncorrectAnswer,
}) => {
    const anim_timer = useAnimation();
    const anim_success = useAnimation();
    const anim_failure = useAnimation();
    const anim_check = useAnimation();
    const anim_times = useAnimation();

    const [demo, setDemo] = useState(true);
    const [show, setShow] = useState(false);
    const [tutorialShow, setTutorialShow] = useState(true);
    const { volume } = useContext(VolumeContext);
    const [tutorialIndex, setTutorialIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    const lang = Cookies.get('bfg_lang');

    const [indexesViewed, setIndexesViewed] = useState<Array<Number>>([]);

    const defaultQuestionTime = 30;

    const { playVoiceover, stopVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        if (tutorialShow === true) {
            playVoiceover('whale-howto-'+(tutorialIndex + 1));
        }

    }, [tutorialIndex, tutorialShow]);

    const descriptions = (index: number) => {
        switch (index) {
            case 0: {
                return t`Phishing scams can be littered with poor grammar and spelling errors. This is a warning sign as trustworthy organisations avoid communicating in such an unprofessional way.`;
            }
            case 1: {
                return t`Intimidation by putting pressure on you to make a decision is a popular tactic for phishing scams. Phrases such as 'Urgent action required' are common.`;
            }
            case 2: {
                return t`Extremely good offers and eye-catching or attention-grabbing statements are designed to attract your attention immediately, but don't fall for it!`;
            }
            case 3: {
                return t`Some phishing scams look genuine but there are often small errors in their design that give it away.`;
            }
            case 4: {
                return t`Whether it looks like it's from someone you don't know or someone you do know, if anything seems out of the ordinary or just suspicious in general don't click on it!`;
            }
            default: {
                return t`Phishing scams can be littered with poor grammar and spelling errors. This is a warning sign as trustworthy organisations avoid communicating in such an unprofessional way.`;
            }
        }
    };

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TCharacteristicsQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const onTimerFinish = () => {
        // alert('time is up! You scored'+ score);
        flashFailure();
        // nextQuestion();
    };

    const [timeLeft, isActive, start, reset, setTimeLeft] = useGameTimer(defaultQuestionTime, onTimerFinish);
    const [score, setScore] = useState(0);

    useEffect(() => {
        setTimeLeft(question && question.duration ? question.duration : defaultQuestionTime);
    }, [question]);

    const handleStart = () => {
        stopVoiceover();
        setDemo(false);
        start();
        const timer = setTimeout(() => {
            anim_timer.start('start');
        }, 1);
        return () => clearTimeout(timer);
    };

    // useEffect(() => {
    //     // start()
    //     if(!demo) {
    //       const timer = setTimeout(() => {
    //           anim_timer.start("start");
    //       },1);
    //       return () => clearTimeout(timer);
    //    }
    // }, [])

    async function resetTimer() {
        await anim_timer.start('stop');
        await anim_timer.start('start');
    }
    async function flashSuccess() {
        await anim_success.start('show');
        await anim_check.start('show');
        resetTimer();
        nextQuestion();
        await anim_check.start('visible');
        await anim_success.start('hidden');
    }
    async function flashFailure() {
        await anim_failure.start('show');
        await anim_times.start('show');
        resetTimer();
        nextQuestion();
        await anim_times.start('visible');
        await anim_failure.start('hidden');
    }

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = (correct: boolean, type: string) => {
        stopVoiceover();
        if (correct) {
            flashSuccess();
            data.addPoints(10);
            setScore(score + 10);
            setShow(true);
            if (volume) sfx.success.play();
        } else {
            logIncorrectAnswer(type);
            flashFailure();
            if (volume) sfx.failure.play();
        }
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    const timerVariants = {
        stop: {
            pathLength: 1,
        },
        start: {
            pathLength: 0,
            transition: {
                duration: defaultQuestionTime,
                ease: 'linear',
            },
        },
    };
    const resultVariants = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.01,
                ease: 'easeInOut',
            },
        },
    };

    const iconVariants = {
        hidden: {
            fill: 'rgba(255,255,255,0)',
            pathLength: 0,
        },
        visible: {
            fill: '#ffffff',
            pathLength: 1,
            transition: {
                duration: 0.4,
                ease: 'easeInOut',
            },
        },
    };

    const getSlide = (index: number) => {
        switch (index) {
            case 0: {
                return slide_1;
            }
            case 1: {
                return slide_2;
            }
            case 2: {
                return slide_3;
            }
            case 3: {
                return slide_4;
            }
            default: {
                return slide_1;
            }
        }
    };

    const getDescription = (index: number) => {
        switch (index) {
            case 0: {
                return t`View screenshots of messages that are part of phishing scams.`;
            }
            case 1: {
                return t`Choose the suspicious characteristic visible in the screenshot.`;
            }
            case 2: {
                return t`Be quick and make your choice before the time runs out!`;
            }
            case 3: {
                return t`Get as many as possible right to get a high score!`;
            }
            default: {
                return t`View different examples of phishing scams, one by one.`;
            }
        }
    };

    const renderCharacteristcsButton = () => {

    }

    return (
        <React.Fragment>
            {/* <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal> */}
            <GameWrapper>
                <SectionScore>
                    <ScoreRow>
                        <Col xs="6" md="12" className="text-center">
                            <p className="d-inline-block mr-2">{t`Score`}</p>
                            <h2 className="d-inline-block">{score}</h2>
                        </Col>
                        <Col xs="6" md="12" className="text-center">
                            <p className="d-inline-block mr-2">{t`Time`}</p>
                            <h2 className="d-inline-block">{timeLeft}</h2>
                        </Col>
                    </ScoreRow>
                </SectionScore>
                <SectionBox>
                    <BoxContainer>
                        <SquareWrapper>
                            <SquareInside>
                                <Success animate={anim_success} variants={resultVariants}>
                                    <div className="w-100 text-center">
                                        <h2>{t`Correct!`}</h2>
                                        <svg viewBox="0 0 101 75" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                                            <Path
                                                animate={anim_check}
                                                variants={iconVariants}
                                                d="M34.7891 73.4375C36.7422 75.3906 40.0625 75.3906 42.0156 73.4375L99.4375 16.0156C101.391 14.0625 101.391 10.7422 99.4375 8.78906L92.4062 1.75781C90.4531 -0.195312 87.3281 -0.195312 85.375 1.75781L38.5 48.6328L16.4297 26.7578C14.4766 24.8047 11.3516 24.8047 9.39844 26.7578L2.36719 33.7891C0.414062 35.7422 0.414062 39.0625 2.36719 41.0156L34.7891 73.4375Z"
                                                stroke="white"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </Success>
                                <Failure animate={anim_failure} variants={resultVariants}>
                                    <div className="w-100 text-center">
                                        <h2>{t`Oops!`}</h2>
                                        <svg viewBox="0 0 100 100" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                                            <Path
                                                animate={anim_times}
                                                variants={iconVariants}
                                                d="M87.5601 70.4591L62.8211 45.4727L87.5592 20.7347C91.0263 17.5266 91.0263 12.1791 87.5592 8.97099L82.0518 3.46362C78.8437 -0.00349724 73.4963 -0.00349736 70.2882 3.46362L45.5501 28.2017L20.5637 3.4627C17.3556 -0.0035013 12.0088 -0.00319278 8.80097 3.46363L3.29359 8.97101C-0.17355 12.1791 -0.173545 17.5266 3.2936 20.7347L28.0317 45.4727L3.29264 70.4591C-0.173541 73.6673 -0.173229 79.0141 3.29357 82.2219L8.80095 87.7292C12.0087 91.1961 17.3556 91.1964 20.5638 87.7302L45.5501 62.9912L70.2882 87.7292C73.4963 91.1964 78.8438 91.1964 82.0518 87.7292L87.5592 82.2219C91.026 79.0141 91.0263 73.6673 87.5601 70.4591Z"
                                                fill="white"
                                                stroke="white"
                                            />
                                        </svg>
                                    </div>
                                </Failure>
                                {demo ? (
                                    <Demo>
                                        <div className="w-100 text-center px-2">
                                            <i className="fa fa-skull mb-2"></i>
                                            <h2>{t`Are you ready?`}</h2>
                                            <p>{t`Before you start, select each of the characteristics of phishing scams below to learn more about them.`}</p>
                                            {indexesViewed.length === 5 ? (
                                                <Button onClick={() => handleStart()}>{t`Start Game`}</Button>
                                            ) : (
                                                <StyledLeft>{5 - indexesViewed.length} { lang === 'cy' ? null : t`more left to view` }</StyledLeft>
                                            )}
                                        </div>
                                    </Demo>
                                ) : null}
                                <img src={question.image} />
                                <SVGContainer viewBox="0 0 370 370" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <motion.path
                                        variants={timerVariants}
                                        initial="stop"
                                        animate={anim_timer}
                                        d="M3.74707 3.38672H361.747C364.509 3.38672 366.747 5.62529 366.747 8.38672V361.387C366.747 364.148 364.508 366.387 361.747 366.387H8.74707C5.98564 366.387 3.74707 364.148 3.74707 361.387L3.74707 4.95679"
                                        stroke="white"
                                        stroke-width="6"
                                        stroke-linecap="round"
                                    />
                                </SVGContainer>
                            </SquareInside>
                        </SquareWrapper>
                    </BoxContainer>
                </SectionBox>
                <SectionCharacteristics>
                    <AnswerRow>
                        <CharacteristicBtn
                            hoverIndex={() => {
                                setCharIndex(0);
                                if(demo){
                                    playVoiceover('whale-char-1');
                                }
                                if (!indexesViewed.includes(0)) {
                                    setIndexesViewed([...indexesViewed, 0]);
                                }
                            }}
                            active={charIndex === 0 ? true : false}
                            read={indexesViewed.includes(0) && demo}
                            title_top={lang === 'cy' ? "Gramadeg" :  t`Bad` }
                            title_bottom={lang === 'cy' ? "Gwael" : t`Grammar`}
                            icon={c_grammar}
                            handleClick={
                                !demo ? () => handleQuestionComplete(question.type === 'grammar', 'grammar') : undefined
                            }
                        />
                        <CharacteristicBtn
                            hoverIndex={() => {
                                setCharIndex(1);
                                if(demo){
                                    playVoiceover('whale-char-2');
                                }

                                if (!indexesViewed.includes(1)) {

                                    setIndexesViewed([...indexesViewed, 1]);
                                }
                            }}
                            active={charIndex === 1 ? true : false}
                            read={indexesViewed.includes(1) && demo}
                            title_top={lang === 'cy' ? "Dan" : t`Under`}
                            title_bottom={lang === 'cy' ? "Bwysau" : t`Pressure`}
                            icon={c_pressure}
                            handleClick={
                                !demo
                                    ? () => handleQuestionComplete(question.type === 'pressure', 'pressure')
                                    : undefined
                            }
                        />
                        <CharacteristicBtn
                            hoverIndex={() => {
                                setCharIndex(2);
                                if(demo){
                                    playVoiceover('whale-char-3');
                                }
                                if (!indexesViewed.includes(2)) {
                                    setIndexesViewed([...indexesViewed, 2]);
                                }
                            }}
                            active={charIndex === 2 ? true : false}
                            read={indexesViewed.includes(2) && demo}
                            title_top={lang === 'cy' ? "Rhy Dda i" : t`Too Good`}
                            title_bottom={lang === 'cy' ? "Fod yn Wir" : t`To Be True`}
                            icon={c_good}
                            handleClick={
                                !demo ? () => handleQuestionComplete(question.type === 'scam', 'scam') : undefined
                            }
                        />
                        <CharacteristicBtn
                            hoverIndex={() => {
                                setCharIndex(3);
                                if(demo){
                                    playVoiceover('whale-char-4');
                                }
                                if (!indexesViewed.includes(3)) {
                                    setIndexesViewed([...indexesViewed, 3]);
                                }
                            }}
                            active={charIndex === 3 ? true : false}
                            read={indexesViewed.includes(3) && demo}
                            title_top={lang === 'cy' ? "Gwallau" : t`Design`}
                            title_bottom={lang === 'cy' ? "Dylunio" : t`Error`}
                            icon={c_design}
                            handleClick={
                                !demo ? () => handleQuestionComplete(question.type === 'design', 'design') : undefined
                            }
                        />
                        <CharacteristicBtn
                            hoverIndex={() => {
                                setCharIndex(4);
                                if(demo){
                                    playVoiceover('whale-char-5');
                                }
                                if (!indexesViewed.includes(4)) {
                                    setIndexesViewed([...indexesViewed, 4]);
                                }
                            }}
                            active={charIndex === 4 ? true : false}
                            read={indexesViewed.includes(4) && demo}
                            title_top={lang === 'cy' ? "Ffynhonnell" : t`Untrusted`}
                            title_bottom={lang === 'cy' ? "Annibynadwy" : t`Source`}
                            icon={c_source}
                            handleClick={
                                !demo ? () => handleQuestionComplete(question.type === 'domain', 'domain') : undefined
                            }
                        />
                    </AnswerRow>
                    <Description charIndex={charIndex}>
                        <p>{descriptions(charIndex)}</p>
                    </Description>
                </SectionCharacteristics>
            </GameWrapper>
            <Tutorial tutorialShow={tutorialShow}>
                <TutorialCard
                    animate={{ scale: [0, 1], opacity: [0, 1] }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                >
                    <h5 className="mb-0">{t`TRAINING GAME`}</h5>
                    <h3>{t`HOW TO PLAY`}</h3>
                    <hr />
                    <TutorialSlides>
                        <SlideArrow
                            left={true}
                            end={tutorialIndex <= 0}
                            onClick={tutorialIndex - 1 > -1 ? () => setTutorialIndex(tutorialIndex - 1) : undefined}
                        >
                            <i className="fa fa-arrow-left"></i>
                        </SlideArrow>
                        <SlideImage src={getSlide(tutorialIndex)} />
                        <SlideArrow
                            left={false}
                            end={tutorialIndex >= 3}
                            onClick={tutorialIndex + 1 < 4 ? () => setTutorialIndex(tutorialIndex + 1) : undefined}
                        >
                            <i className="fa fa-arrow-right"></i>
                        </SlideArrow>
                    </TutorialSlides>
                    <p>{getDescription(tutorialIndex)}</p>
                    {tutorialIndex < 3 ? (
                        <TutorialProgress>
                            <Progress progress={(tutorialIndex / 3) * 100} />
                        </TutorialProgress>
                    ) : (
                        <Button className="my-2" onClick={() => {setTutorialShow(false); playVoiceover('whale-ready');}}>
                            { t`Finish` }
                        </Button>
                    )}
                </TutorialCard>
            </Tutorial>
        </React.Fragment>
    );
};

const StyledLeft = styled(motion.p)`
    display: inline-block;
    padding: 4px 8px;
    margin: 0;
    background-color: #ffffff;
    border-radius: 5px;
    color: #e60050;
`;

const Path = styled(motion.path)`
    stroke: #ffffff;
    stroke-width: 1.5;
    stroke-linejoin: round;
    stroke-linecap: round;
`;

const Failure = styled(motion.div)`
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: #e60050;
    svg {
        display: block;
        margin: 0 auto;
        max-width: 175px;
        width: 100%;
    }
    i {
        font-size: 100px;
    }
`;

const Success = styled(motion.div)`
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: #00c3a5;
    svg {
        display: block;
        margin: 0 auto;
        max-width: 175px;
        width: 100%;
    }
    i {
        font-size: 100px;
    }
`;

const Demo = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: #e60050;
    z-index: 5;
    i {
        font-size: 40px;
    }
`;

interface ArrowProps {
    left: boolean;
    end: boolean;
}
const SlideArrow = styled.button<ArrowProps>`
    ${(props) => (props.left ? 'left: 5px;' : 'right: 5px;')}
    position: absolute;
    top: calc(50% - 25px);
    background-color: #ffffff;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 0 !important;
    ${(props) => (props.end ? 'opacity: 0.3;' : null)}
`;

const SlideImage = styled.img`
    height: 120px;
    width: 120px;
    margin: 0 auto;
`;

const TutorialSlides = styled.div`
    width: 100%;
    position: relative;
    height: 120px;
    margin-bottom: 10px;
`;

const TutorialProgress = styled.div`
    position: relative;
    width: 100%;
    height: 10px;
    max-width: 100px;
    border-radius: 5px;
    display: block;
    margin: 32px auto;
    background-color: #b80040;
    overflow: hidden;
`;
interface ProgressProps {
    progress: number;
}

const Progress = styled.div<ProgressProps>`
    background-color: #ffffff;
    transition: width 0.3s ease-in-out;
    width: ${(props) => props.progress}%;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
`;

const TutorialCard = styled(motion.div)`
    background-color: #e60050;
    padding: 1rem;
    text-align: center;
    width: 100%;
    max-width: 350px;
    transition: height 0.5s ease-in-out;
    h5 {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: 0.875rem;
        font-family: 'BT Font Extra Bold';
    }
    p {
        line-height: 20px;
        margin-bottom: 0;
    }
`;

interface TutorialProps {
    tutorialShow: boolean;
}
const Tutorial = styled(motion.div)<TutorialProps>`
    position: fixed;
    display: none;
    ${(props) => (props.tutorialShow ? 'display: flex;' : null)}
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding-top: 75px;
    p {
        font-size: 16px;
        margin-bottom: 5px;
    }
`;

const SVGContainer = styled.svg`
    path {
        stroke-width: 6px;
        stroke-linecap: round;
        stroke: #ffffff;
    }
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const ScoreRow = styled(Row)`
    width: 100%;
    margin: 10px auto;
    max-width: 650px;
    padding: 5px;
    align-items: center;
    p,
    h2 {
        margin-bottom: 5px;
        vertical-align: middle;
        color: #e60050;
    }
    background-color: #ffffff;
    border-radius: 5px;
    @media (min-width: 768px) {
        position: fixed;
        width: 100px;
        left: 0;
        top: 50%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
`;

const AnswerRow = styled(Row)`
    width: 100%;
    margin: 10px auto;
    max-width: 650px;
    padding: 0 5px;
`;

interface DescriptionProps {
    charIndex: number;
}

const Description = styled.div<DescriptionProps>`
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 15px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    margin: 15px auto;
    @media (min-width: 650px) {
        border-radius: 10px;
    }
    max-width: 650px;
    p {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 20px;
    }
    &::after {
        content: '';
        position: absolute;
        left: calc(${(props) => (props.charIndex ? props.charIndex * 20 + 10 : '10')}% - 10px);
        top: -13px;
        width: 0;
        height: 0;
        border-bottom: 10px solid rgba(255, 255, 255, 0.5);
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        clear: both;
    }
`;

const BoxContainer = styled.div`
    max-width: 500px;
    display: block;
    width: 100%;
    margin: 10px auto;
    @media (max-height: 900px) {
        width: 90%;
        max-width: 375px;
    }
`;

const SquareWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-color: rgba(0, 0, 0, 0.1);
`;
const SquareInside = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 7px;
    img {
        width: 100%;
    }
`;

const GameWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(54, 21, 89, 0.75);
    display: flex;
    flex-flow: column;
    margin-top: 75px;
    overflow-y: scroll;
`;

const SectionScore = styled.div`
    flex-shrink: 0;
    width: 100%;
`;

const SectionBox = styled.div`
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
`;

const SectionCharacteristics = styled.div`
    flex: 0 0 auto;
    align-self: flex-end;
    position: relative;
    width: 100%;
    text-align: center;
`;

export default Characteristics;
