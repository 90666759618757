import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/penguin.svg';

const Penguin: TCharacter = {
    characterName: 'Penguin',
    characterImage: image,
    characterWidth: 100,
    characterHeight: 110,
};

export default Penguin;
