import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Phisherman from '../../characters/Phisherman';

const UserPhishedPhisher: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Phisherman,
            text: t`Bingo! I bet you didn't expect me to be on the other end of the phone. You have to be a bit more careful than that!`,
            textSpriteName: 'pufferfish-phisherman-3',
            buttonText: t`Next`,
        },
        {
            character: Phisherman,
            text: t`Hiding my number and mentioning that PS5 worked perfectly! I shall do this more often...`,
            textSpriteName: 'pufferfish-phisherman-4',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default UserPhishedPhisher;
