import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import Content from '../../../../general/Content';
import { t, c } from 'ttag';

const CipherDemo: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showCiphered, setShowCiphered] = useState(true);
    const [showFooter, setShowFooter] = useState(false);
    const next = showFooter ? (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    ) : null;

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 5000);
    }, []);

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={'12'} className={'cc-container'}>
                    <h4 className={'cc-intel__heading'}>
                        {showCiphered ? <span>{t`Ciphered Message`}</span> : <span>{t`Deciphered Message`}</span>}
                    </h4>
                    <Content type="island" styles={'p-3 cc-intel__island'}>
                        {showCiphered ? (
                            <span>{c('Caesar of We are going to attack the ships on Tuesday afternoon!')
                                .t`Xf bsf hpjoh up buubdl uif tijqt po Uvftebz bgufsoppo!`}</span>
                        ) : (
                            <span>{t`We are going to attack the ships on Tuesday afternoon!`}</span>
                        )}
                    </Content>
                    <br></br>
                    <Button onClick={() => setShowCiphered(!showCiphered)}>
                        {showCiphered ? <span>{t`Decipher`}</span> : <span>{t`Cipher`}</span>}
                    </Button>
                </Col>
            </Row>
        </Slide>
    );
};

export default CipherDemo;
