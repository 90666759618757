import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
// import stars_4  from '../../../assets/games/space/stars_4.svg';
import { t } from 'ttag';
import blank from '../../../assets/games/space/blank_boom.svg';
import cockpit from '../../../assets/games/space/cockpit.svg';
import boom from '../../../assets/games/space/explosion.gif';
import stars_1 from '../../../assets/games/space/stars_1.svg';
import stars_2 from '../../../assets/games/space/stars_2.svg';
import stars_3 from '../../../assets/games/space/stars_3.svg';
import { randomIntFromBetween } from '../../../helpers/numbers';
import { useGameTimer } from '../../../hooks/game';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import Asteroid from './Asteroid';

export type TAsteriodQuestion = GameQuestion & {
    duration: number;
    scoreVisible: boolean;
    types: TAsteroid[];
    name?: string;
    nameVisible?: boolean;
    zinonVisible?: boolean;
};

export type TAsteroid = {
    asteroidValue: number;
    asteroidName: string;
    asteroidImage?: string;
    asteroidColor?: string;
    asteroidWidth?: number;
    scoreVisible?: boolean;
    zinionEnabled?: boolean;
};

const AsteroidGame: React.FC<
    GameProps<TAsteriodQuestion> & { reportGameScore?: (score: number, scoreObject: object) => void }
> = ({ gameData, index = 0, onGameComplete, reportGameScore }) => {
    const [score, setScore] = useState(0);
    const [count, setCount] = useState(0);
    const [scoreObj, setScoreObj] = useState({});
    const [countObj, setCountObj] = useState({});
    const [explosions, setExplosions] = useState<{ X: number; Y: number }[]>([]);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        // setShow(true);
        if (typeof reportGameScore === 'function') reportGameScore(count ?? 0, scoreObj ?? {});
        onGameComplete();
    };

    const handleNextQuestion = () => {
        // setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TAsteriodQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const onTimerFinish = () => {
        // alert('time is up! You scored'+ score);
        reset();
        nextQuestion();
    };

    const addScore = (type: string, amount: number) => {
        setScoreObj({
            ...scoreObj,
            [type]: scoreObj.hasOwnProperty(type) ? (scoreObj as any)[type] + amount : amount,
        });
        setScore(score + amount);
        setCount(count + 1);
    };

    const incrementCountType = (type: string) => {
        setCountObj({
            ...countObj,
            [type]: countObj.hasOwnProperty(type) ? (countObj as any)[type] + 1 : 1,
        });
    };

    const [timeLeft, isActive, start, reset, setTimeLeft] = useGameTimer(10, onTimerFinish);

    const [asteroidX, setAsteroidX] = useState(randomIntFromBetween(0, 260));
    const [asteroidY, setAsteroidY] = useState(randomIntFromBetween(0, 360));

    const [asteroidTypeIndex, setAsteroidTypeIndex] = useState(0);

    const onAsteroidClick = () => {
        // console.log('clicked', score);
        // add score for clicked droid
        addScore(question.types[asteroidTypeIndex].asteroidName, question.types[asteroidTypeIndex].asteroidValue);

        incrementCountType(question.types[asteroidTypeIndex].asteroidName);

        if (volume) sfx.explosion.play();

        setExplosions([...explosions, { X: asteroidX, Y: asteroidY }]);

        // set random pos
        setAsteroidX(randomIntFromBetween(0, 260));
        setAsteroidY(randomIntFromBetween(0, 360));

        //randomly select next type
        setAsteroidTypeIndex(randomIntFromBetween(0, question.types.length - 1));
    };

    useEffect(() => {
        if (explosions) {
            let ex = [...explosions];
            ex.shift();
            let tmr = setTimeout(() => setExplosions(ex), 2000);
            return () => clearTimeout(tmr);
        }
    }, [explosions]);

    useEffect(() => {
        setTimeLeft(question ? question.duration : 10);
    }, [question]);

    if (!question) {
        return null;
    }

    return (
        <SpaceWrapper>
            <SpaceBackground className={'va-space__bg'} />
            <Cockpit src={cockpit} />

            {/*<button onClick={reset}>reset</button>*/}
            {question.scoreVisible ? (
                <TotalScore>
                    <span>{t`TOTAL`}</span>
                    {count}
                </TotalScore>
            ) : null}
            {/* {question.scoreVisible ? Object.keys(scoreObj).map((val, k) => <h4 key={k}>{val} {(scoreObj as any)[val]}</h4>) : null} */}
            <motion.img
                src={stars_1}
                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                transition={{ loop: Infinity, duration: 10 }}
                className={'va-stars'}
            />
            <motion.img
                src={stars_2}
                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                transition={{ loop: Infinity, duration: 8, delay: 4 }}
                className={'va-stars'}
            />
            <motion.img
                src={stars_3}
                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                transition={{ loop: Infinity, duration: 12, delay: 8 }}
                className={'va-stars'}
            />
            {question.types.map((type) =>
                type.scoreVisible ? (
                    <Score type={type.asteroidName}>
                        {(countObj as any)[type.asteroidName] ?? 0}
                        <span>{type.asteroidName}</span>{' '}
                    </Score>
                ) : null,
            )}
            {/*<TimerLevel timeLeft={(timeLeft / question.duration) * 100}/>*/}
            <SpaceCanvas>
                {!isActive ? (
                    <StartSlide>
                        <StartContent>
                            <h4>{t`Instructions`}</h4>
                            <p>
                                {t`Click/tap on an asteroid to fire.`}
                                <br />
                                {t`Destroy as many asteroids as possible in the time given.`}
                            </p>
                            <Button
                                onClick={() => {
                                    if (volume) sfx.startgame.play();

                                    start();
                                }}
                            >{t`Start`}</Button>
                        </StartContent>
                    </StartSlide>
                ) : null}
                {question.nameVisible ? (
                    <PlayerName>
                        <span>{t`Name`}</span>
                        {question.name}
                    </PlayerName>
                ) : null}
                {/* {question.zinonVisible ? <Zinon><span>Zinon</span>{ (scoreObj as any)[question.types.find((type) => type.zinionEnabled)?.asteroidName] ?? 0}</Zinon> : null} */}
                {question.zinonVisible
                    ? question.types
                          .filter((type) => type.zinionEnabled)
                          ?.map((type) =>
                              type.zinionEnabled ? (
                                  <Zinon>
                                      <span>Zinon</span> {(scoreObj as any)[type.asteroidName] ?? 0}{' '}
                                  </Zinon>
                              ) : null,
                          )
                    : null}

                {isActive ? <Timer>{timeLeft}</Timer> : null}
                {isActive ? (
                    <Asteroid
                        clickHandler={onAsteroidClick}
                        x={asteroidX}
                        y={asteroidY}
                        type={question.types[asteroidTypeIndex]}
                    />
                ) : null}

                {explosions.map((ex, i) => (
                    <Explosion key={'ex' + i} x={ex.X} y={ex.Y} opacity={0} />
                ))}
            </SpaceCanvas>
        </SpaceWrapper>
    );
};

const Explosion: React.FC<AsteroidProps> = ({ x, y }) => {
    const [display, setDisplay] = useState(true);
    const [opacity, setOpacity] = useState(0);
    const [explosionMap, setExplosionMap] = useState(boom);

    useEffect(() => {
        let a = setTimeout(() => {
            setExplosionMap(blank);
            setOpacity(1);
        }, 100);
        let b = setTimeout(() => setExplosionMap(boom), 200);
        let c = setTimeout(() => setDisplay(false), 1000);
        return () => {
            clearTimeout(a);
            clearTimeout(b);
            clearTimeout(c);
        };
    }, []);

    return <ExplosionWrapper src={explosionMap} x={x} y={y} display={display} opacity={opacity} />;
};

const canvasX = 300;
const canvasY = 400;

interface AsteroidProps {
    x: number;
    y: number;
    display?: boolean;
    opacity: number;
}
interface ScoreProps {
    type?: string;
}
interface TimerLevel {
    timeLeft: number;
}

const Score = styled.p<ScoreProps>`
    position: absolute;
    margin-bottom: 0;
    z-index: 5;
    font-size: 30px;
    font-family: 'BT Font Extra Bold';
    right: ${(props) => (props.type == t`Large` ? 'auto' : '5px')};
    left: ${(props) => (props.type == t`Small` ? 'auto' : '5px')};
    bottom: -98px;
    text-transform: lowercase;
    line-height: 1rem;
    text-align: ${(props) => (props.type === t`Small` ? 'left' : 'right')};
    span {
        font-size: 14px;
        display: block;
    }
`;
const StartSlide = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 80;
`;

const StartContent = styled.div`
    text-align: center;
    background-color: rgba(100, 0, 170, 0.2);
    padding: 15px;
    border-radius: 10px;
    min-width: 200px;
    h4 {
        text-transform: uppercase;
        color: white;
        font-family: 'BT Font Extra Bold';
        font-size: 16px;
        margin-top: 15px;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        background-color: black;
        color: #e60050;
        font-family: 'BT Font Extra Bold';
        text-transform: uppercase;
        padding: 5px;
        border-radius: 10px;
    }
`;

const TotalScore = styled.p<ScoreProps>`
    position: absolute;
    margin-bottom: 0;
    z-index: 5;
    font-size: 57px;
    font-family: 'BT Font Extra Bold';
    left: 0;
    width: 100%;
    text-align: center;
    bottom: -88px;
    span {
        display: block;
        font-size: 20px;
        margin-bottom: 10px;
    }
`;

const PlayerName = styled.p`
    position: absolute;
    font-family: 'BT Font Extra Bold';
    z-index: 3;
    background-color: #eaf577;
    color: #000000;
    font-size: 14px;
    text-transform: uppercase;
    padding: 6px;
    border-radius: 3px;
    top: 2px;
    left: 2px;
    margin: 0;
    line-height: 12px;
    span {
        display: block;
        font-size: 10px;
        margin-bottom: 3px;
    }
    max-width: 240px;
    user-select: none;
    overflow: hidden;
`;

const Zinon = styled.p`
    position: absolute;
    font-family: 'BT Font Extra Bold';
    z-index: 3;
    background-color: #7b17ff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 6px;
    border-radius: 3px;
    top: 2px;
    right: 6px;
    margin: 0;
    line-height: 12px;
    text-align: center;
    user-select: none;
    span {
        display: block;
        font-size: 10px;
        margin-bottom: 3px;
    }
`;

// const goDown = keyframes`
//   from {
//     height: 100%;
//   }
//
//   to {
//     height: 0;
//   }
// `;
// const TimerLevel = styled.div<TimerLevel>`
//     position: absolute;
//     z-index: 3;
//     background-color: #222222;
//     width: 100%;
//     height: ${(props) => props.timeLeft}%;
//     bottom:0;
//     left: 0;
//     transition: height 1s linear;
//     border-radius: 5px;
// `;

// const StartButton = styled.button`
//     position: absolute;
//     height: ${canvasY}px;
//     width: ${canvasX}px;
//     background-color: black;
//     color: red;
//     font-family: 'BT Font Extra Bold';
//     z-index: 50;
//     text-transform: uppercase;
//     border: none;
//     border-radius: 5px;
// `;

const ExplosionWrapper = styled.img<AsteroidProps>`
    position: absolute;
    height: 50px;
    width: 50px;
    z-index: 3;
    top: ${(props) => props.y || '0'}px;
    left: ${(props) => props.x || '0'}px;
    display: ${(props) => (props.display ? '' : 'none')};
    opacity: ${(props) => props.opacity};
    transition: opacity 0.2s linear;
`;

const SpaceWrapper = styled.div`
    height: ${canvasY}px;
    width: ${canvasX}px;
    display: block;
    margin: 10px auto 114px auto;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const SpaceCanvas = styled.div`
    position: relative;
    background-color: transparent;
    height: ${canvasY}px;
    width: ${canvasX}px;
    overflow: hidden;
    border-radius: 5px;
    z-index: 4;
`;

const SpaceBackground = styled.div`
    position: absolute;
    height: 550px;
    width: 460px;
    background-color: black;
    top: calc(50% - 270px);
    left: calc(50% - 230px);
`;

const TimerHeight = 60;
const TimerWidth = 100;
const Timer = styled.h1`
    position: absolute;
    height: ${TimerHeight}px;
    width: ${TimerWidth}px;
    text-align: center;
    top: calc(50% - ${TimerHeight / 2}px);
    left: calc(50% - ${TimerWidth / 2}px);
    opacity: 0.6;
    z-index: 3;
    user-select: none;
`;

const Cockpit = styled.img`
    position: absolute;
    height: 600px;
    width: 950px;
    z-index: 4;
    top: calc(50% - 280px);
    left: calc(50% - 470px);
`;

export default AsteroidGame;
