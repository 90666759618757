import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import largeAsteroid from '../../../../assets/games/space/asteroid--large.svg';
import smallAsteroid from '../../../../assets/games/space/asteroid--small.svg';
import characters from '../../../../assets/misc/va-endslide.svg';
import sfx from '../../../../services/sounds';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { VolumeContext } from '../../../misc/volume-context';
import stars_1 from '../../../../assets/games/space/stars_1.svg';
import stars_2 from '../../../../assets/games/space/stars_2.svg';
import stars_3 from '../../../../assets/games/space/stars_3.svg';

const EndSlide: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;
    const { volume } = useContext(VolumeContext);

    let moduleName = t`Asteroid Blasters`;

    if (volume) sfx.cheer.play();

    let animation = {
        scale: [0, 1, 1, 1, 1, 1, 1, 0],
        rotate: [0, 0, 20, -20, 20, -20, 0, 0],
    };
    let times = [0, 0.1, 0.25, 0.4, 0.55, 0.7, 0.8, 0.9];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}

                    <Content type="island_dark" styles={'my-2 p-2'}>
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 4,
                                delay: 2,
                            }}
                            className={'ck-home__images ck-home__images--1'}
                            width={'60px'}
                            alt=""
                            src={smallAsteroid}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 5,
                                delay: 4,
                            }}
                            className={'ck-home__images ck-home__images--2'}
                            width={'60px'}
                            src={smallAsteroid}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 3,
                            }}
                            className={'ck-home__images ck-home__images--3'}
                            width={'60px'}
                            src={largeAsteroid}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 2,
                                delay: 1,
                            }}
                            className={'ck-home__images ck-home__images--4'}
                            width={'60px'}
                            src={largeAsteroid}
                        />
                        <div className="va-end__stars-wrap">
                            <motion.img
                                src={stars_1}
                                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                                transition={{ loop: Infinity, duration: 10 }}
                                className={'va-stars va-stars--home'}
                            />
                            <motion.img
                                src={stars_2}
                                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                                transition={{ loop: Infinity, duration: 8, delay: 4 }}
                                className={'va-stars va-stars--home'}
                            />
                            <motion.img
                                src={stars_3}
                                animate={{ y: [-100, 0, 100], opacity: [0, 1, 0] }}
                                transition={{ loop: Infinity, duration: 12, delay: 8 }}
                                className={'va-stars va-stars--home'}
                            />
                            <img src={characters} width={'200px'} className={'va-end__characters'} alt="" />
                            <h3 className={'mt-2'}>{t`You have completed ${moduleName}!`}</h3>
                            <p>{t`Congratulations!`}</p>
                            <motion.div
                                animate={{ x: [-600, 0, 600], opacity: [0, 1, 0], y: [-400, 0, 400] }}
                                transition={{
                                    duration: 1,
                                    ease: 'linear',
                                    loop: Infinity,
                                    repeatDelay: 10,
                                    delay: 2,
                                }}
                                className={'va-end__star'}
                            />
                            <motion.div
                                animate={{ x: [600, 0, -600], opacity: [0, 1, 0], y: [-400, 0, 400] }}
                                transition={{
                                    duration: 1,
                                    ease: 'linear',
                                    loop: Infinity,
                                    repeatDelay: 10,
                                    delay: 7,
                                }}
                                className={'va-end__star'}
                            />
                        </div>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about in ${moduleName} and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/asteroid-blaster"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20the%20Asteroid%20Blaster%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/asteroid-blaster"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndSlide;
