import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import GiftSend, { TGiftSendQuestion } from '../../components/interactive/gift-send-2/GiftSend2';
import home1 from '../../assets/send/home.svg';
import home2 from '../../assets/send/home-2.svg';
import home3 from '../../assets/send/home-3.svg';

const SendingGame2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            Next
        </Button>
    );

    const gameData: TGiftSendQuestion[] = [
        {
            toy: 'soldier',
            box: '#3B82F6',
            house: home1,
        },
        {
            toy: 'snowman',
            box: '#10B981',
            house: home2,
        },
        {
            toy: 'robot',
            box: '#F59E0B',
            house: home3,
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <GiftSend gameData={gameData} onGameComplete={nextSlide}></GiftSend>
        </Slide>
    );
};

export default SendingGame2;
