import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import VoiceoverContext from '../../../../../misc/voiceover-context';
import VolumeContext from '../../../../../misc/volume-context';
import crash_img from '../../../assets/car/crash.png';

const CrashModal: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { volume } = useContext(VolumeContext);
  const { playVoiceover } = useContext(VoiceoverContext);
  useEffect(() => {
    if (volume) sfx.crash.play()
    let t = setTimeout(() => playVoiceover('21'), 1000);
    return () => clearTimeout(t);
  }, []);
  return (
    <CrashPopUp onClick={onClick}>
      <motion.div animate={{ y: [100, 0], opacity: [0, 1] }} transition={{ type: "spring" }}>
        <div className="island rounded p-3 mb-2">
          <h4>{t`Crash Reported`}</h4>
          <motion.img src={crash_img} width={'200'} animate={{ x: [0, -10, 10, -10, 0] }} transition={{ loop: Infinity, delay: 1, duration: 0.3, repeatDelay: 5 }} />
        </div>
        <p className="text-center">{t`Press anywhere to continue`}</p>
      </motion.div>
    </CrashPopUp>

  );
};

const CrashPopUp = styled.div`
    display: flex;
    align-items: center;
    padding-top: 75px;
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    .island {
      background-color: #C2006A !important;
      max-width: 300px;

    }
    h4 {
      background-color: #FFFFFF;
      text-align: center;
      padding: 8px 16px;
      color: #E5007D;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: 'BT Font Extra Bold';
      letter-spacing: 0.07em;
    }
    img {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
`;
export default CrashModal;
