import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar } from '../../components/interactive/scene/Road';

const NoLightsCrash: React.FC<ISlide<TrafficData>> = ({ data, nextSlide, defaultHeader }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const { width } = useWindowDimensions();
    const [help] = useState(t`Watch what happens when two cars try to cross the bridge at the same time!`);
    const [carData] = useState<TCar[]>([
        { id: 'go-1', pos: 0, moving: true, direction: 'clockwise' },
        { id: 'gi-1', pos: width, moving: true, direction: 'anticlockwise' },
    ]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        let t = setTimeout(() => data.playVoiceover(`10`), 0);

        return () => {
            clearTimeout(t);
        }
    }, []);

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`Oh dear, yet another accident!
            As you can see, we are going to need to make some changes!`,
            textSpriteName: `11`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Our lead engineer has suggested using two traffic lights to solve this problem.`,
            textSpriteName: `12`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Using two traffic lights sounds a bit tricky! Let’s break this task down into smaller parts and start with just one traffic light for now.`,
            textSpriteName: `13`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`See if you can get both of the cars to cross the bridge without them crashing!`,
            textSpriteName: `14`,
            buttonText: t`Play`,
            buttonAction: nextSlide,
        },
    ];


    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Scene
                nextSlide={nextSlide}
                roadworks={false}
                help={help}
                crash={false}
                lights={false}
                road={{ carData: carData, onCrash: () => setTimeout(() => setShow(true), 2000) }}
            >
                <>
                    {show ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} /> : null}
                </>
            </Scene>
        </Slide>
    );
}

export default NoLightsCrash;
