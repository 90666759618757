import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { AbstractionData } from '../..';
import WordDiscovery from '../../../../general/WordDiscovery';
import { jt, t } from 'ttag';

const EscapedAnimalOutro: React.FC<ISlide<AbstractionData>> = ({ nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue = [
        {
            character: RangerJack,
            text: t`${data.name} you’re a genius! A lion! Silly me, how could I have forgotten.. 
            Did the features I gave you help you identify the animal?`,
            buttonText: t`Yes, they did`,
        },
        {
            character: RangerJack,
            text: t`Maybe we are onto something... 
            My old textbook says that when I identify the key features and don’t worry too much about the detail I am abstracting.`,
            buttonText: t`Abstraction?`,
        },
        {
            character: RangerJack,
            text: t`Abstraction is about simplifying things; identifying what is important without worrying too much about the detail.`,
            buttonText: t`Got it!`,
            buttonAction: () => setShow(true),
        },
    ];

    let title = <span className="font-bt-400">{t`Identify the Animal`}</span>;
    const header = (
        <React.Fragment>
            <h3>{jt`Mission 1: ${title}`}</h3>
            {/* <div className={'section--header__complete'}>COMPLETE</div> */}
        </React.Fragment>
    );

    return (
        <Slide header={header} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Abstraction`}
                wordDefinition={t`Abstraction is about simplifying things; identifying what is important without worrying too much about the detail.`}
            />
        </Slide>
    );
};

export default EscapedAnimalOutro;
