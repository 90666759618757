import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { ZookeeperFiona } from '../../../sorting/characters/Zookeepers';
import WordDiscovery from '../../../../general/WordDiscovery';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: ZookeeperFiona,
            text: t`Let’s try an algorithm. An algorithm is a precise sequence of instructions or rules to get something done.`,
            buttonText: t`Good idea!`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Algorithm`}
                wordDefinition={t`An algorithm is a precise sequence of instructions or a set of rules to get something done.`}
            ></WordDiscovery>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
