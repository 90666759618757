import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import keypad from '../../../../../assets/misc/th-keypad.svg';
import parachute_1 from '../../../../../assets/misc/th-parachute-1.svg';
import parachute_2 from '../../../../../assets/misc/th-parachute-2.svg';
import parachute_3 from '../../../../../assets/misc/th-parachute-3.svg';
import plane from '../../../../../assets/misc/th-plane.svg';
import Slide, { ISlide } from '../../../../core/Slide';

let animation = {
    y: [0, 0, 50, 110, 160, 210, 260, 310, 360, 400, 450],
    scale: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    rotate: [0, 0, 20, -20, 20, -15, 15, -15, 10, -10, 5, 0],
};
let times = [0, 0.05, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 0.85, 0.95, 1];

const PartOne: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showFooter, setShowFooter] = useState(false);
    const next = showFooter ? <Button className="btn-3d" onClick={() => nextSlide()}>{t`Start`}</Button> : null;

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 5000);
    }, []);

    return (
        <Slide header={defaultHeader} footer={next} backgroundColor={'#361559'}>
            <div className={`island island--rounded bg-secondary-700 th-part`}>
                <motion.img
                    src={parachute_1}
                    className={'th-part__parachute th-part__parachute--1'}
                    width={'100px'}
                    height={'100px'}
                    animate={animation}
                    transition={{
                        delay: 2.25,
                        ease: 'easeInOut',
                        times: times,
                        y: { type: 'inertia', duration: 8 },
                        rotate: { type: 'inertia', duration: 8 },
                        default: { duration: 8 },
                    }}
                />
                <motion.img
                    src={parachute_2}
                    className={'th-part__parachute th-part__parachute--2'}
                    width={'100px'}
                    height={'100px'}
                    animate={animation}
                    transition={{
                        y: { type: 'inertia', duration: 8 },
                        rotate: { type: 'inertia', duration: 8 },
                        default: { duration: 8 },
                        delay: 1.5,
                        ease: 'easeInOut',
                        times: times,
                    }}
                />
                <motion.img
                    src={parachute_3}
                    className={'th-part__parachute th-part__parachute--3'}
                    width={'100px'}
                    height={'100px'}
                    animate={animation}
                    transition={{
                        y: { type: 'inertia', duration: 8 },
                        rotate: { type: 'inertia', duration: 8 },
                        default: { duration: 8 },
                        delay: 1,
                        ease: 'easeInOut',
                        times: times,
                    }}
                />
                <motion.img
                    src={plane}
                    className={'th-part__plane'}
                    width={'150px'}
                    height={'100px'}
                    animate={{ x: [-200, 450] }}
                    transition={{
                        duration: 1.5,
                        delay: 0.5,
                    }}
                />
                <motion.img
                    src={keypad}
                    className={'th-part__keypad'}
                    width={'300px'}
                    height={'400px'}
                    animate={{ opacity: [0, 1] }}
                    transition={{
                        duration: 1.5,
                        delay: 8,
                    }}
                />
                <div className="th-part__text">
                    <h4>{t`Part One`}</h4>
                    <p>{t`Entering the lair`}</p>
                </div>
            </div>
        </Slide>
    );
};

export default PartOne;
