import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import WordDiscovery from '../../../../general/WordDiscovery';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import town_scene from '../../assets/scene/town_scene.svg';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Cookies from 'js-cookie';
import town_scene_welsh from '../../assets/scene/town_scene-welsh.svg';

const lang = Cookies.get('bfg_lang');

const Definition: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);
    const [nextDialogue, setNextDialogue] = useState<any>(null);
    const [defTimer, setDefTimer] = useState<number | undefined>();

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`We just created an algorithm, which we used to program the traffic lights with the Sequencer 3000.`,
            textSpriteName: `93`,
            buttonText: t`Algorithm?`,
        },
        {
            character: EngineerShahid,
            text: t`An algorithm is a set of instructions or rules to get something done.`,
            textSpriteName: `94`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`We have created an algorithm that we used to program the computer to make the traffic lights run automatically!`,
            textSpriteName: `95`,
            buttonText: t`Next`,
            buttonAction: () => {
                setShow(true)
                let a = setTimeout(() => data.playVoiceover('45'), 400);
                let b = setTimeout(() => data.playVoiceover('96'), 2000);
                setTimeouts([
                    a, b,
                ]);
            }
        },
    ];

    const handleClose = () => {
        data.stopVoiceover();
        nextSlide()
    }

    const [timeouts, setTimeouts] = useState<number[]>()

    useEffect(() => {
        return () => {
            timeouts?.forEach((t) => clearTimeout(t))
        }
    }, [timeouts]);

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <TownWrapper>
                <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />
                <WordDiscovery show={show} word={t`Algorithm`} wordDefinition={t`An algorithm is a set of instructions or rules to get something done.`} onClose={handleClose} />
            </TownWrapper>

        </Slide>
    );
}

const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
`;

export default Definition;
