import React, { useEffect, useState, useContext } from 'react';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import Dodged from '../../components/Dodged';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';


const UserDodge: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        const dodged = setTimeout(() => playVoiceover('pufferfish-dodged-desc'), 4000);
        return () => { clearTimeout(dodged) }
    }, []);
    return (
        <Slide header={defaultHeader}>
            <Dodged
                points={20}
                gameData={data}
                onClose={nextSlide}
                show={true}
                explanation={t`Hanging up the phone when something sounds too good to be true is a great move!`}
            ></Dodged>
        </Slide>
    );
};

export default UserDodge;
