import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentTokyo from '../../characters/AgentTokyo';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import SkillModal from '../../../../general/SkillModal';
import algo_image from '../../../../../assets/icons/icon_algorithms.svg';

const DiamondOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentTokyo,
            text: t`Your algorithm skills successfully helped us open the vault! You defined the rules for the algorithms in the correct order and solved the sequences. You rock!`,
            buttonText: t`Thanks`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`Algorithms`}
                skillDescription={t`You created the correct algorithm to solve the sequence.`}
                skillImage={algo_image}
            ></SkillModal>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default DiamondOutro;
