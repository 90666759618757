import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/2-turtle.svg';
import wanted from '../assets/characters/2-turtle-wanted.svg';
import { t } from 'ttag';

const Turtle: TCharacter = {
    characterName: t`Turtle`,
    characterImage: image,
    characterWidth: 100,
    characterHeight: 150,
};

export const TurtleWanted: TCharacter = {
    characterName: t`Turtle`,
    characterImage: wanted,
    characterWidth: 120,
    characterHeight: 150,
};

export default Turtle;
