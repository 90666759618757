import React, { useState, useEffect, useContext } from 'react';
import { Feature } from './GroupFlags';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';
import styled from 'styled-components';

const Tile: React.FC<{
    feature: Feature;
    onAnswer: (correct: boolean) => void;
    resetQuestion: boolean;
    isDisabled: boolean;
}> = ({ feature, onAnswer, resetQuestion, isDisabled }) => {
    const [selected, setSelected] = useState(false);
    // const [tileFeature, setTileFeature] = useState(feature);
    const { volume } = useContext(VolumeContext);

    useEffect(() => {
        setSelected(false);
        // setTileFeature(feature);
    }, [feature]);

    useEffect(() => {
        console.log('Question reset');
        setSelected(false);
    }, [resetQuestion]);

    // let textClasses = classNames({
    //     'text-center': true,
    //     'text-success': selected && feature.correct,
    //     'text-danger': selected && !feature.correct,
    // });

    const handleSelected = () => {
        setSelected(true);
        onAnswer(feature.correct);
        if (feature.correct) {
            if (volume) sfx.correct.play();
        } else {
            if (volume) sfx.incorrect.play();
        }
    };

    return (
        <TileStyle
            className={`grid-tile ck-flags__tile ${feature.correct && selected ? 'grid-tile--correct' : ''} ${
                !feature.correct && selected ? 'grid-tile--false' : ''
            } `}
            onClick={selected || isDisabled ? undefined : handleSelected}
        >
            <div className="grid-tile__content">
                <h5 className={'text-center mb-0 ck-flags__text'}>
                    {feature.name}
                    {/*<i className={`w-100 ${selected ? "" : "d-none"} fas fa-${feature.correct ? 'check' : 'times'} `}></i>*/}
                </h5>
            </div>
        </TileStyle>
    );
};
const TileStyle = styled.button`
    width: 100%;
    display: block;
    border: none;
    border-radius: 5px;
    border: solid 3px transparent;
    overflow: hidden;
    color: #ffffff;
    &:focus {
        outline: none;
        border-color: #0ab9f0;
    }
    h5 {
        word-break: break-word;
        font-size: 18px;
    }
    @media screen and (max-width: 425px) {
        h5 {
            font-size: 4vw;
        }
    }
`;
export default Tile;
