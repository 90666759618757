import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const StormIntroGame: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide onStart={nextSlide} header={defaultHeader} number={3}>
            {t`Quickly identify the animals to help Ranger Jack return them to safety in their enclosures. You do not have much time!`}
        </MissionSlide>
    );
};

export default StormIntroGame;
