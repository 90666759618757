import React, { useEffect, useContext } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Phone from '../../components/Phone';
import { t } from 'ttag';
import { actions } from '../../../../../services/analytics';
import * as PhoneCall from '../../components/interactive/phonecall/PhoneCall';
import sfx from '../../../../../services/sounds';
import VolumeContext from '../../../../misc/volume-context';
import VoiceoverContext from '../../../../misc/voiceover-context';

const CallInbound: React.FC<ISlide> = ({ defaultHeader, nextSlide, gotoSlide }) => {
    const { volume } = useContext(VolumeContext);
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        if (volume) sfx.ringtone.play();
        const incomingCall = setTimeout(() => playVoiceover('pufferfish-phone'), 1500);
        return () => { clearTimeout(incomingCall) }
    }, []);


    return (
        <>
            <Slide header={defaultHeader} footerHidden={true}>
                <Phone backgroundColor={'#007BFF'}>
                    <StyledContainer>
                        <PhoneHeader></PhoneHeader>
                        <PhoneContent>
                            <Row className="w-100">
                                <Col>
                                    <p>{t`Incoming Call`}</p>
                                    <h3>{t`Unknown Number`}</h3>
                                </Col>
                            </Row>
                        </PhoneContent>
                        <PhoneButtons>
                            <Row>
                                <Col xs={'6'} className={'text-center'}>
                                    <HangUp className="fa fa-phone" />
                                </Col>
                                <Col xs={'6'} className={'text-center'}>
                                    <PickUp onClick={() => nextSlide()} className="fa fa-phone" />
                                </Col>
                            </Row>
                        </PhoneButtons>
                    </StyledContainer>
                </Phone>
            </Slide>
        </>
    );
};

const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
`;

const PhoneContent = styled.div`
    flex: 1;
    padding: 5% 5% 3% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (min-width: 768px) {
        h3 {
            font-size: 2rem;
        }
    }
`;

const PhoneButtons = styled.div`
    margin-top: auto;
    align-self: flex-end;
    width: 100%;
    padding: 5% 5% 3% 5%;
`;

const PhoneHeader = styled.div`
    width: 100%;
    padding: 10% 5% 3% 5%;
    flex-shrink: 0;
`;

const PickUp = styled.i`
    min-width: 1rem;
    color: #007bff;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 100px;
    font-size: 2rem;
    @media screen and (min-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 3rem;
    }
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const HangUp = styled.i`
    min-width: 1rem;
    color: #007bff;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 100px;
    opacity: 0.4;
    transform: rotate(-135deg);
    font-size: 2rem;
    @media screen and (min-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 3rem;
    }
`;

export default CallInbound;
