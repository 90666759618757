import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import town_scene from '../../assets/scene/town_scene.svg';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Cookies from 'js-cookie';
import town_scene_welsh from '../../assets/scene/town_scene-welsh.svg';

const lang = Cookies.get('bfg_lang');

const DecompositionQuestion: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [questionShow, setQuestionShow] = useState(true);
    const [nextDialogue, setNextDialogue] = useState<any>(null);

    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`What is an Algorithm?`,
            answers: [
                { text: t`A type of traffic light.`, correct: false },
                { text: t`Breaking down a task into smaller, more manageable parts.`, correct: false },
                { text: t`A set of instructions or rules to get something done.`, correct: true },
            ],
        },
    ];

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: '',
            buttonText: t`Next`,
            buttonAction: nextSlide
        },
    ];

    const handleModalClose = () => {
        data.stopVoiceover();
        nextSlide();
    }

    useEffect(() => {
        data.playVoiceover('98');
        let a = setTimeout(() => data.playVoiceover('99'), 2000);
        setTimeouts([a]);
        return () => {
            data.stopVoiceover();
        }
    }, []);


    const [timeouts, setTimeouts] = useState<number[]>([])

    useEffect(() => {
        return () => {
            timeouts?.forEach((t) => clearTimeout(t))
        }
    }, [timeouts]);

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <TownWrapper>
                <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />

                {questionShow ?
                    <Layout>
                        <Content>
                            <Character src={EngineerShahid.characterImage} />
                            <motion.div
                                className="island island--dialogue rounded mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {t`Let’s check if you were paying attention...`}
                                <MultipleChoice
                                    gameData={gameData}
                                    onCorrectAnswer={() => {
                                        data.playVoiceover('52')
                                        let b = setTimeout(() => data.playVoiceover('94'), 2000)
                                        setTimeouts([
                                            ...timeouts,
                                            b
                                        ])
                                    }}
                                    onGameComplete={() => {
                                        data.stopVoiceover()
                                        nextSlide();
                                    }}
                                    onIncorrectAnswer={() => { }}
                                ></MultipleChoice>
                            </motion.div>
                        </Content>
                    </Layout>
                    : null}
            </TownWrapper>
        </Slide>
    );
}


const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
`;

const Character = styled.img`
    position: absolute;
    z-index: -1;
    top: -70px;
    left: 15px;
    width: 50px;
`;

const Content = styled.div`
    position:relative;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 1rem;
    background-color: #ffffff;
    @media (min-width: 576px){
        border-radius: 5px;
    }
    min-height: 136px;
`;


const Layout = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    .island {
        background-color: #EAF4F7;
        padding: 6px 12px !important;
        width: 100%;
        margin: 0 0 10px !important;
        font-size: 18px;
        line-height: 24px;
        min-height: auto;
        box-shadow: none;
        &::after{
            content: '';
            position: absolute;
            left: 8px;
            top: -12px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #EAF4F7;
            clear: both;
        }
    }
    .island__author--dialogue {
        font-size: 12px;
        top: -55px;
        left: 50px;
        padding: 2px 8px;
        letter-spacing: 0.05em;
        border-radius: 5px;
    }
    @media (min-width: 576px){
        bottom: 10px;
        .island {
            border-radius: 5px !important;
            margin-bottom: 10px !important;
        }
        ${Content} {
            max-width: 400px;
        }
    }
    @media (min-width: 1200px){
        .island {
            font-size: 20px;
        }
        ${Content} {
            max-width: 500px;
        }
    }
`;
export default DecompositionQuestion;
