import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { FlubFlub } from '../../characters/Aliens';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: FlubFlub,
            text: t`You blast asteroids so quick, I think we need a new variable to keep track of the ‘Total’ number of asteroids you’ve destroyed!`,
            buttonText: t`Next`,
        },
        {
            character: FlubFlub,
            text: t`I need a rule to calculate the total number of asteroids you’ve blasted using the data in our existing variables. I'll turn this rule into code on the ship’s computer. Do you think you can help?`,
            buttonText: t`Sure!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
