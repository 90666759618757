import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import GiftBuilder, { TGiftBuilderQuestion } from '../../components/interactive/giftbuilder/GiftBuilder';

const GiftboxGame: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            Next
        </Button>
    );

    const gameData: TGiftBuilderQuestion[] = [
        {
            size: 8,
            pattern: 'star',
            color: '#EC4899',
        },

        {
            size: 1,
            pattern: 'snowflake',
            color: '#10B981',
        },

        {
            size: 10,
            pattern: 'tree',
            color: '#8B5CF6',
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            {/* <Giftbox gameData={gameData} onGameComplete={nextSlide}></Giftbox> */}
            <GiftBuilder gameData={gameData} onGameComplete={nextSlide}></GiftBuilder>
        </Slide>
    );
};

export default GiftboxGame;
