import React from 'react';
import styled from 'styled-components';
import { TAsteroid } from './AsteroidGame';

const Asteroid: React.FC<{ clickHandler: () => void; x: number; y: number; type: TAsteroid }> = ({
    clickHandler,
    children,
    x,
    y,
    type,
}) => {
    return !type.asteroidImage ? (
        <AsteroidWrapper onClick={clickHandler} x={x} y={y} type={type}>
            {type.asteroidName} {type.asteroidValue}
        </AsteroidWrapper>
    ) : (
        <AsteroidWrapper2 onClick={clickHandler} x={x} y={y} type={type} src={type.asteroidImage ?? undefined} />
    );
};

export default Asteroid;

interface WrapperProps {
    x: number;
    y: number;
    onClick?: () => void;
    type: TAsteroid;
}

const AsteroidWrapper = styled.div<WrapperProps>`
    position: absolute;
    top: ${(props) => props.y || '0'}px;
    left: ${(props) => props.x || '0'}px;
    background-color: ${(props) => props.type.asteroidColor || 'green'};
    height: 10px;
    width: 10px;
    z-index: 10;
    cursor: pointer;
`;

const AsteroidWrapper2 = styled.img<WrapperProps>`
    position: absolute;
    top: ${(props) => props.y || '0'}px;
    left: ${(props) => props.x || '0'}px;
    background-color: ${(props) => props.type.asteroidColor || 'transparent'};
    height: auto;
    width: ${(props) => props.type.asteroidWidth || '20'}px;
    z-index: 10;
    cursor: pointer;
`;
