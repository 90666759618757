import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import AgentGreen from '../../characters/AgentGreen';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CodeCrackingData } from '../../index';
import { t } from 'ttag';

const GoodbyeMessage: React.FC<ISlide<CodeCrackingData>> = ({ nextSlide, defaultHeader, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: AgentGreen,
            text: t`Well done ${data.name}! Nice work with that Caesar Cipher. The Enigma Machine is a lot more complex, with over 158,962,555,217,826,360,000 combinations... It’s a challenge to solve!`,
            buttonText: t`Good Luck!`,
        },
        {
            character: AgentGreen,
            text: t`Now for a little debrief of all you have learnt today. You really have achieved a lot in so little time!`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default GoodbyeMessage;
