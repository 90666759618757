import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import Computer from '../../components/Computer';
import Laptop from '../../components/Laptop';
import { t } from 'ttag';
import EmailClient, { TEmailClientQuestion } from '../../components/interactive/emailclient/EmailClient';
import Turtle from '../../characters/Turtle';
import styled from 'styled-components';
import { CyberSecurityData } from '../..';

const EmailGame: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const gameData: TEmailClientQuestion[] = [
        {
            subject: t`This year's top movies so far`,
            name: t`Movies 4 Free`,
            email: t`no-reply@freemoviesss.com`,
            content: t`It's your lucky day! Here are some free movies for you to watch that we know you are going to love.

            It's all legal... we promise!`,
            contentSpriteName: 'octo-email-1',
            attachment: t`free_movies.zip`,
            read: false,
            trustworthy: false,
            dodgyAttachment: true,
            dodgyEmail: true,
            onOpen: () => {},
        },
        {
            subject: t`Daily Fish: Among Us breaks gaming records`,
            name: t`Daily Fish Newsletter`,
            email: t`news@dailyfish.com`,
            content: t`The wildly popular game Among Us reportedly had over 3 million users playing at once last weekend. Wow!`,
            contentSpriteName: 'octo-email-2',
            read: false,
            trustworthy: true,
            onOpen: () => {},
        },
        {
            subject: t`500 FortKite coins won!`,
            name: t`Mr Smith`,
            email: t`no-reply@fortneeet.com`,
            content: t`You just won 500 coins, claim them now by logging into your account.

            Log in now.`,
            contentSpriteName: 'octo-email-3',
            read: false,
            trustworthy: false,
            dodgyEmail: true,
            dodgyBody: true,

            onOpen: () => {},
        },

        {
            subject: t`Have you seen our latest games?`,
            name: t`Barefoot Computing`,
            email: t`games@barefootgames.org`,
            content: t`Check out the latest educational adventures from Barefoot, head to barefootgames.org to view all our games.`,
            contentSpriteName: 'octo-email-4',
            read: false,
            trustworthy: true,
            onOpen: () => {},
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Laptop url={'mail.barefootcomputing.org'} backgroundColor={'#E1DEF9'}>
                {/* <Hint>Go through the emails and decide if they are trustworthy! <i className="fa fa-info"></i></Hint> */}
                <EmailClient
                    gameData={gameData}
                    onGameComplete={nextSlide}
                    onIncorrect={() => data.addPoints(-2)}
                ></EmailClient>
            </Laptop>
            {/* <Commentary>
                <Comment>Looks like you have some emails, check them out and decide if they are trustworthy or not!</Comment>
                <Character alt={Turtle.characterName} src={Turtle.characterImage} width={Turtle.characterWidth}/>
            </Commentary> */}
        </Slide>
    );
};
const Hint = styled.div`
    background-color: #00A0D6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    color: #ffffff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: -1%;
    display none;
    z-index:999;
    i {
        position: absolute;
        left: -15px;
        top: 20%;
        height: 28px;
        width: 28px;
        font-size: 1rem;
        text-align: center;
        border-radius: 100px;
        padding: 5px 10px;
        color: #00A0D6;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    }
    @media (min-width: 768px) {
        display: block;
    }

`;

const Commentary = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1rem;
    z-index: 999;
    @media (min-width: 768px) and (max-width: 1200px) and (max-height: 600px) {
        display: none;
    }
`;
const Character = styled.img`
    width: 70px;
    @media (min-width: 768px) {
        width: 110px;
    }
`;
const Comment = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    color: #6400aa;
    padding: 1rem;
    text-align: left;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    max-width: 400px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
    @media (min-width: 768px) {
        font-size: 1rem;
    }
`;

export default EmailGame;
