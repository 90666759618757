import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CodeCrackingData } from '../..';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import ModalOverlay from '../../../../general/ModalOverlay';
import { Button, Col, Row } from 'react-bootstrap';
import logic from '../../../../../assets/icons/icon_logic.svg';
import { t } from 'ttag';

const OffsetOutro: React.FC<ISlide<CodeCrackingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`Nice work on cracking those codes. You have now seen how the cipher works, it takes in one message, offsets the letters by a certain number and then outputs the result.`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];
    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h2 className="text-white text-center cc-heading">{t`LEARNING COVERED`}</h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <ModalOverlay
                show={show}
                header={modalHeader}
                footer={<Button onClick={() => nextSlide()}>{t`Next`}</Button>}
            >
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                            <img
                                src={logic}
                                className="d-block mx-auto"
                                width={'100px'}
                                height={'100px'}
                                alt={t`Logic icon`}
                            />
                            <p className={'cc-text'}>{t`LOGIC`}</p>
                            <p
                                className={'cc-text'}
                            >{t`You worked your way through the different offsets to output the message correctly`}</p>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
        </Slide>
    );
};

export default OffsetOutro;
