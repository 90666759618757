import React from 'react';
import ModalOverlay from '../../general/ModalOverlay';
import { TSortAlgorithmQuestion } from './SortAlgorithmGame';
import { Button, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const SortAlgorithmCorrectModal: React.FC<{ show: boolean; question: TSortAlgorithmQuestion; onClose: () => void }> = ({
    show,
    question,
    onClose,
}) => {
    const header = (
        <React.Fragment>
            <motion.div
                className={'w-100 text-center'}
                animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
                transition={{ duration: 0.2 }}
            >
                <h2 className="text-white text-center">
                    <i className="fas fa-thumbs-up mr-2"></i> {t`CORRECT!`}
                </h2>
                <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
            </motion.div>
        </React.Fragment>
    );

    return (
        <ModalOverlay
            show={show}
            header={header}
            footer={<Button onClick={onClose}>{t`Next`}</Button>}
            backgroundColor="#00C3A5"
        >
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <div className="island island--guide island--rounded p-3 mx-auto">
                        <p
                            className={'mb-2 text-secondary'}
                        >{t`Well done! Your algorithm sorted the animals successfully.`}</p>
                        <p className={'mb-2 text-secondary'}>
                            <strong>{t`Sorted animals:`}</strong>
                        </p>
                        {question.question.objects
                            .filter((object) => {
                                return question.answers.every((answer) => object.tags.includes(answer));
                            })
                            .map((object) => (
                                <img className={'so-algo__img'} width="100px" src={object.image} alt={object.name} />
                            ))}
                    </div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default SortAlgorithmCorrectModal;
