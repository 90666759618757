import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const ConfusedTouristsIntroGame: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide onStart={nextSlide} header={defaultHeader} number={2}>
            {t`Help the confused tourists identify the different animals by just picking their most important features.`}
        </MissionSlide>
    );
};

export default ConfusedTouristsIntroGame;
