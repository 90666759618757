import React, { useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerAisha from '../../characters/EngineerAisha';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';
import TrafficSequence from '../../components/interactive/sequence/TrafficSequence';





const SequenceGame: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
  const [footer, buttonRenderer] = useFooterButtonRenderer();
  const [show, setShow] = useState(false);
  const [dialogueShow, setDialogueShow] = useState(true);
  const [nextDialogue, setNextDialogue] = useState<any>(null);
  const { width } = useWindowDimensions();

  const gameData = [
    {
      sequence: [
        { red: { enabled: true }, duration: 1000 },
        { amber: { enabled: true }, duration: 1000 },
        { green: { enabled: true }, duration: 1000 },
      ],
    },
    {
      sequence: [
        { green: { enabled: true }, duration: 1000 },
        { amber: { enabled: true }, duration: 1000 },
        { red: { enabled: true }, duration: 1000 },
      ],
    }
  ]

  const openSequencer = () => {
    setShow(true);
    setDialogueShow(false);
  }

  const dialogue: DialogueData[] = [
    {
      character: EngineerAisha,
      text: t`The Sequencer 3000 will help us program the traffic lights by breaking down the sequence of instructions we need.`,
      textSpriteName: '60',
      buttonText: t`Next`,
    },
    {
      character: EngineerAisha,
      text: t`Let's have a go! Don't worry if it sounds complicated. I'll explain how it works.`,
      textSpriteName: '61',
      buttonText: t`Use Sequencer 3000`,
      buttonAction: openSequencer
    },
  ];

  const trafficLight1: TRoadLight = {
    sequence: [
      { red: { enabled: true }, duration: 1000 },
    ],
    pos: width - 300, // TODO REMOVE THINGS LIKE THIS
    direction: 'clockwise'
  };
  const trafficLight2: TRoadLight = {
    sequence: [
      { red: { enabled: true }, duration: 1000 },
    ],
    pos: width + 300, // TODO REMOVE THINGS LIKE THIS
    direction: 'anticlockwise'
  };
  const [carData, setCarData] = useState<TCar[]>([
    { id: 'go-1', pos: 0, moving: true, direction: 'clockwise' },
    { id: 'go-2', pos: width, moving: true, direction: 'anticlockwise' },
  ]);


  return (
    <Slide header={defaultHeader} footerHidden={true}>
      <Scene nextSlide={nextSlide} lights={false} road={{ carData: carData, trafficLight1, trafficLight2 }} data={data}>
        {dialogueShow ?
          <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />
          : null}
        {show ?
          <TrafficSequence gameData={gameData} onGameComplete={nextSlide}></TrafficSequence>
          : null}


      </Scene>
    </Slide>
  );
}

export default SequenceGame;
