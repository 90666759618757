import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import alert_program from '../../assets/lights/alert-programming.svg';
import alert_timing from '../../assets/lights/alert-timing.svg';
import alert_warning from '../../assets/lights/alert-warning.svg';
import highlight_circle from '../../assets/lights/traffic-highlight.png';
import { theme } from '../../styles/theme';
import { isChrome } from 'react-device-detect';



type TrafficLightProps = TTrafficLight & {
    showEditor?: boolean;
    showPole?: boolean;
    reportDataChange?: (status: TTrafficLight) => void
    alert?: TAlert;
    highlight?: boolean
}

export type TTrafficLight = {
    red?: TLight;
    amber?: TLight;
    green?: TLight;
}

export type TLight = {
    enabled: boolean;
    onClick?: () => void
}

export type TAlert = 'timing' | 'program' | 'warning' | null;

const getAlert = (alert: TAlert) => {
    switch (alert) {
        case 'timing': {
            return alert_timing;
        }
        case 'program': {
            return alert_program;
        }
        case 'warning': {
            return alert_warning;
        }
        default: {
            return alert_warning;
        }
    }
};

const COLOUR_EMPTY = '#575757';

const TrafficLight: React.FC<TrafficLightProps> = ({
    red = { enabled: false },
    amber = { enabled: false },
    green = { enabled: false },
    showEditor = false,
    showPole = true,
    alert = null,
    reportDataChange,
    highlight = false,
}) => {
    const [light, setLight] = useState({ red, amber, green });

    useEffect(() => {
        // setLight({ red, amber, green });

        if (showEditor) {
            // console.log(red, amber, green);

        }
    }, [red, amber, green, setLight]);

    useEffect(() => {
        if (reportDataChange) {
            reportDataChange(light);
        }
    }, [light]);

    return (
        <Wrapper showEditor={showEditor}>
            {highlight ? <Highlight animate={{ scale: [1, 1.2, 1] }} transition={{ loop: Infinity, duration: 1, repeatDelay: 5, ease: "easeInOut" }} src={highlight_circle} /> : null}
            <SVGWrapper showPole={showPole}>
                <div>
                    <svg viewBox={`0 0 33 ${showPole ? 110 : 70}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="shadowRed" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="red" />
                            </filter>
                            <filter id="shadowAmber" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="yellow" />
                            </filter>
                            <filter id="shadowGreen" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="green" />
                            </filter>
                        </defs>
                        {showPole ? (<>
                            <ellipse cx="16.3964" cy="102.316" rx="16.0819" ry="7.4664" fill="black" fillOpacity="0.05" />
                            <path d="M19.1603 68.3762V102.694C17.3952 103.843 15.1151 103.843 13.3501 102.694V68.3762H19.1603Z" fill="#333333" stroke="black" />

                        </>) : null}
                        <rect x="0.5" y="1.13086" width="31.5041" height="67.7572" rx="4.5" fill="#575757" stroke="black" />
                        <rect x="2.76025" y="3.54248" width="26.9841" height="62.9337" rx="2.5" fill="#333333" stroke="black" />

                        <StyledCircle enabled={true} style={{ filter: (red.enabled && isChrome ? 'url(#shadowRed)' : '') }} cx="16.5484" cy="16.0671" r="6.84919" fill={(showEditor ? light.red.enabled : red.enabled) ? theme.bare_pink : COLOUR_EMPTY} onClick={showEditor ? () => setLight({ ...light, red: { enabled: !light.red.enabled } }) : light.red.onClick} stroke="black" />
                        <StyledCircle enabled={true} style={{ filter: (amber.enabled && isChrome ? 'url(#shadowAmber)' : '') }} cx="16.5484" cy="35.1352" r="6.84919" fill={(showEditor ? light.amber.enabled : amber.enabled) ? theme.bare_yellow : COLOUR_EMPTY} onClick={showEditor ? () => setLight({ ...light, amber: { enabled: !light.amber.enabled } }) : light.amber.onClick} stroke="black" />
                        <StyledCircle enabled={true} style={{ filter: (green.enabled && isChrome ? 'url(#shadowGreen)' : '') }} cx="16.5484" cy="53.7655" r="6.84919" fill={(showEditor ? light.green.enabled : green.enabled) ? theme.bare_green : COLOUR_EMPTY} onClick={showEditor ? () => setLight({ ...light, green: { enabled: !light.green.enabled } }) : light.green.onClick} stroke="black" />
                    </svg>
                </div>
            </SVGWrapper>
            {alert ? <AlertWrapper><img src={getAlert(alert)} /></AlertWrapper> : ''}

        </Wrapper>
    );
};



const SVGWrapper = styled.div<{ showPole: boolean }>`
    width: 100%;
    padding-top: ${(props) => props.showPole ? '333.33%' : '212.12%'};
    div {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        bottom: 0;
    }
    svg {width: 100%;}

`;

interface CircleProps {
    enabled: boolean;
}
const StyledCircle = styled.circle<CircleProps>`
    box-shadow: 0 0 15px #ffffff;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        filter: none !important;
    }
`;

const Wrapper = styled.div<{ showEditor: boolean }>`
    position: relative;
    height: 100%;
    width: 100%;
    svg {width: 100%;}
    circle {stroke-width: 0.5px; stroke: #222222}
    ${props => props.showEditor ? 'circle { cursor: pointer; stroke: ' + theme.bare_blue + '; &:hover {stroke: ' + theme.bare_blue_700 + ';}}' : ''}
`;

const AlertWrapper = styled.div`
  position:absolute;
  left: -50%;
  bottom: 0%;
  width: 150%;
  padding-top: 150%;
  font-size: 16px;
  img { width: 100%; position: absolute; top: 0; right: 0; left: 0; bottom: 0;}
`;

const Highlight = styled(motion.img)`
    position: absolute;
    width: 260%;
    left: -80%;
    bottom: 0;
`;

export default TrafficLight;
