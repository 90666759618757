import PartThree from './PartThree';
import VaultIntro from './VaultIntro';
import Vault from './Vault';
import VaultGame from './VaultGame';
import VaultAnim from './VaultAnim';
import VaultIntro2 from './VaultIntro2';
import VaultGame2 from './VaultGame2';
import VaultIntro3 from './VaultIntro3';
import VaultGame3 from './VaultGame3';
import VaultAnimOpen from './VaultAnimOpen';

export default [
    PartThree,
    VaultIntro,
    Vault,
    VaultGame,
    VaultAnim,
    VaultIntro2,
    VaultGame2,
    VaultAnim,
    VaultIntro3,
    VaultGame3,
    VaultAnimOpen,
];
