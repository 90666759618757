import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import ModalOverlay from '../../../../general/ModalOverlay';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import c_design from '../../assets/characteristics/c-design.svg';
import c_good from '../../assets/characteristics/c-good.svg';
import c_grammar from '../../assets/characteristics/c-grammar.svg';
import c_pressure from '../../assets/characteristics/c-pressure.svg';
import c_source from '../../assets/characteristics/c-source.svg';
import Whale from '../../characters/Whale';


const WhaleOutro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Whale,
            text: t`That was a little tricky wasn't it! Let’s have a look at the characteristics you found most difficult to find.`,
            textSpriteName: 'whale-4-1',
            buttonText: t`View Recap`,
            buttonAction: data.incorrectCharacteristics.length > 1 ? () => setShow(true) : nextSlide,
        },
    ];

    useEffect(() => {
        if (data.incorrectCharacteristics.length === 1 && process.env.NODE_ENV !== 'development') {
            nextSlide();
        }
    }, []);

    return (
        <Slide header={defaultHeader} footer={footer}>
            <ModalOverlay
                show={show}
                backgroundColor={'#E60050'}
                header={
                    <>
                        <h3 className="text-center w-100 mt-2">
                            <i className="fa fa-skull mr-2"></i>
                            {t`Characteristics Recap`}
                        </h3>{' '}
                        <StyledP className="mb-4 w-100 text-center">{t`Read through the characteristics below then continue`}</StyledP>
                    </>
                }
                footer={<Button onClick={nextSlide}>{t`Continue`}</Button>}
            >
                <Styled>
                    {data.incorrectCharacteristics.includes('grammar') ? (
                        <Row className="align-items-center">
                            <Col xs="2" md="3" className="pr-0">
                                <img src={c_grammar} className="w-100" alt={t`Bad Grammar Characteristic`} />
                            </Col>
                            <Col xs="10" md="7">
                                <h5>{t`Bad Grammar`}</h5>
                                <p>{t`Phishing scams can be littered with poor grammar and spelling errors. This is a warning sign as trustworthy organisations avoid communicating in such an unprofessional way.`}</p>
                            </Col>
                        </Row>
                    ) : null}

                    {data.incorrectCharacteristics.includes('pressure') ? (
                        <Row className="align-items-center">
                            <Col xs="2" md="3" className="pr-0">
                                <img src={c_pressure} className="w-100" alt={t`Under Pressure Characteristic`} />
                            </Col>
                            <Col xs="10" md="7">
                                <h5>{t`Under Pressure`}</h5>
                                <p>{t`Intimidation by putting pressure on you to make a decision is a popular tactic for phishing scams. Phrases such as 'Urgent action required' are common.`}</p>
                            </Col>
                        </Row>
                    ) : null}

                    {data.incorrectCharacteristics.includes('scam') ? (
                        <Row className="align-items-center">
                            <Col xs="2" md="3" className="pr-0">
                                <img src={c_good} className="w-100" alt={t`Too Good To Be True Characteristic`} />
                            </Col>
                            <Col xs="10" md="7">
                                <h5>{t`Too Good To Be True`}</h5>
                                <p>{t`Extremely good offers and eye-catching or attention-grabbing statements are designed to attract your attention immediately, but don't fall for it!`}</p>
                            </Col>
                        </Row>
                    ) : null}

                    {data.incorrectCharacteristics.includes('design') ? (
                        <Row className="align-items-center">
                            <Col xs="2" md="3" className="pr-0">
                                <img src={c_design} className="w-100" alt={t`Design Errors Characteristic`} />
                            </Col>
                            <Col xs="10" md="7">
                                <h5>{t`Design Errors`}</h5>
                                <p>{t`Some phishing scams look genuine but often there are strange quirks in there design that give it away. Keep an eye out for something looking a bit off!`}</p>
                            </Col>
                        </Row>
                    ) : null}

                    {data.incorrectCharacteristics.includes('domain') ? (
                        <Row className="align-items-center">
                            <Col xs="2" md="3" className="pr-0">
                                <img src={c_source} className="w-100" alt={t`Untrustworthy Source Characteristic`} />
                            </Col>
                            <Col xs="10" md="7">
                                <h5>{t`Untrustworthy Source`}</h5>
                                <p>{t`Whether it looks like it's from someone you don't know or someone you do know, if anything seems out of the ordinary or just suspicious in general don't click on it!`}</p>
                            </Col>
                        </Row>
                    ) : null}
                </Styled>
            </ModalOverlay>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};
const StyledP = styled.p`
    font-size: 15px;
    line-height: 18px;
    opacity: 0.8;
`;

const Styled = styled.div`
    p {
        font-size: 16px;
        line-height: 18px;
    }
    h5 {
        font-family: 'BT Font Bold';
    }
    img {
        max-width: 125px;
        margin-left: auto;
        display: block;
    }
`;

export default WhaleOutro;

// switch (index) {
//     case 0: {
//         return t`Phishing scams can be littered with poor grammar and spelling errors. This is a warning sign as companies avoid communicating in such an unprofessional way.`;
//     }
//     case 1: {
//         return t`Intimidation by putting pressure on you to make a decision is a popular tactic for phishing scams. Phrases such as 'Urgent action required' are common.`;
//     }
//     case 2: {
//       return t`Extremely good offers and eye-catching or attention-grabbing statements are designed to attract your attention immediately, but don't fall for it!`;
//     }
//     case 3: {
//       return t`Some phishing scams look genuine but often there are strange quirks in there design that give it away. Keep an eye out for something looking a bit off!`;
//     }
//     case 4: {
//       return t`Whether it looks like it's from someone you don't know or someone you do know, if anything seems out of the ordinary or just suspicious in general don't click on it!`;
//     }
