import React from 'react';
import { VariablesData } from '../..';
import smallAsteroid from '../../../../../assets/games/space/asteroid--small.svg';
import largeAsteroid from '../../../../../assets/games/space/asteroid--large.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import AsteroidGame, { TAsteriodQuestion } from '../../../../interactive/asteroid/AsteroidGame';
import { t } from 'ttag';

const MissionGame: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const gameData: TAsteriodQuestion[] = [
        {
            scoreVisible: false,
            duration: 15,
            types: [
                { asteroidValue: 1, asteroidName: t`Small`, asteroidImage: smallAsteroid },
                { asteroidValue: 1, asteroidName: t`Large`, asteroidImage: largeAsteroid, asteroidWidth: 40 },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true} overflow={false}>
            <AsteroidGame
                gameData={gameData}
                onGameComplete={() => {}}
                reportGameScore={(score: number, scoreObject: object) => {
                    data.setGameScore(score ?? 0);
                    data.setGameScoreObject(score ? scoreObject : { [t`Small`]: 0 });
                    nextSlide();
                }}
            ></AsteroidGame>
        </Slide>
    );
};

export default MissionGame;
