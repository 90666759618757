import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Reward from 'react-rewards';
import { t } from 'ttag';
import equip_kayak from '../../../../assets/games/kits/equip_kayak.svg';
import head_helmet from '../../../../assets/games/kits/head_helmet.svg';
import top_gb from '../../../../assets/games/kits/top_gb.svg';
import top_jamaica from '../../../../assets/games/kits/top_jamaica.svg';
import ck_home from '../../../../assets/misc/ck-home.svg';
import sfx from '../../../../services/sounds';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { VolumeContext } from '../../../misc/volume-context';

const EndPage: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const { volume } = useContext(VolumeContext);
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;

    if (volume) sfx.cheer.play();
    let animation = {
        scale: [0, 1, 1, 1, 1, 1, 1, 0],
        rotate: [0, 0, 20, -20, 20, -20, 0, 0],
    };
    let times = [0, 0.1, 0.25, 0.4, 0.55, 0.7, 0.8, 0.9];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}
                    <Content type="island_dark" styles={'my-2 p-2'}>
                        <Reward ref={(ref: any) => (ref ? ref.rewardMe() : null)} type="confetti" />

                        <Reward
                            ref={(ref: any) => {
                                return ref ? setInterval(() => ref.rewardMe(), 5000) : null;
                            }}
                            type="confetti"
                        ></Reward>
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 4,
                                delay: 2,
                            }}
                            className={'ck-home__images ck-home__images--1'}
                            width={'60px'}
                            src={head_helmet}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 5,
                                delay: 4,
                            }}
                            className={'ck-home__images ck-home__images--2'}
                            width={'60px'}
                            src={top_gb}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 3,
                            }}
                            className={'ck-home__images ck-home__images--3'}
                            width={'60px'}
                            src={top_jamaica}
                        />
                        <motion.img
                            animate={animation}
                            transition={{
                                duration: 2,
                                ease: 'easeInOut',
                                times: times,
                                loop: Infinity,
                                repeatDelay: 2,
                                delay: 1,
                            }}
                            className={'ck-home__images ck-home__images--4'}
                            width={'60px'}
                            src={equip_kayak}
                        />
                        <img src={ck_home} width={'200px'} className={'ck-home__characters'} alt="" />
                        <h3>
                            {t`You have completed`}
                            <br />
                            {t`Colourful Kits`}!
                        </h3>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about in Colourful Kits and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/colourful-kits"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20the%20Colourful%20Kits%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/colourful-kits"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndPage;
