import { TCharacter } from '../../../general/Character';
import image from '../../../../assets/characters/thief_boy.svg';
import { t } from 'ttag';

const AgentRio: TCharacter = {
    characterName: t`Agent Harry`,
    characterImage: image,
    characterWidth: 150,
    characterHeight: 180,
};

export default AgentRio;
