import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import WordDiscovery from '../../../../general/WordDiscovery';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import town_scene from '../../assets/scene/town_scene.svg';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Cookies from 'js-cookie';
import town_scene_welsh from '../../assets/scene/town_scene-welsh.svg';

const lang = Cookies.get('bfg_lang');

const Definition: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);
    const [nextDialogue, setNextDialogue] = useState<any>(null);


    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`Breaking larger problems down into smaller parts is called decomposition.`,
            textSpriteName: `41`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`We’ve worked hard to solve the larger problem of too many cars crashing on the bridge. We did this by breaking down the problem into smaller parts and working with one traffic light at a time.`,
            textSpriteName: `42`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Looking at each part helped us understand the problem more clearly and allowed us to think carefully about the solution.`,
            textSpriteName: `43`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Decomposition is really important for solving tricky problems like this!`,
            textSpriteName: `44`,
            buttonText: t`Next`,
            buttonAction: () => {
                let a = setTimeout(() => data.playVoiceover('45'), 400)
                let b = setTimeout(() => data.playVoiceover('46'), 2000)
                setShow(true);

                setTimeouts([
                    a, b,
                ])
            }
        },
    ];

    const handleClose = () => {
        nextSlide()
    }

    const [timeouts, setTimeouts] = useState<number[]>([])

    useEffect(() => {
        return () => {
            timeouts?.forEach((t) => clearTimeout(t))
        }
    }, [timeouts]);

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <TownWrapper>
                <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />
                <WordDiscovery show={show} word={t`Decomposition`} wordDefinition={t`Decomposition is the process of breaking down a task into smaller, more manageable parts.`} onClose={handleClose} />
            </TownWrapper>

        </Slide>
    );
}

const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
`;

export default Definition;
