import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const MissionIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide
            onStart={nextSlide}
            header={defaultHeader}
            number={4}
        >{t`Collect at least 10 lots of Zinon by blasting the large asteroids`}</MissionSlide>
    );
};

export default MissionIntro;
