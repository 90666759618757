import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import blueprint from '../../../../../assets/misc/th-blueprint.svg';
import blueprint_cy from '../../../../../assets/misc/th-blueprint-cy.svg';
import { t } from 'ttag';
import Cookies from 'js-cookie';

const Blueprint: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;
    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={{ offset: 2, span: 8 }} md={{ offset: 4, span: 4 }}>
                    <h3 className={'text-center my-2'}>{t`DR MISCHIEF'S LAIR`}</h3>
                    <img
                        src={lang === 'cy' ? blueprint_cy : blueprint}
                        className={'w-100'}
                        alt={t`DR MISCHIEF'S LAIR`}
                        width={'400px'}
                    />
                </Col>
            </Row>
        </Slide>
    );
};

export default Blueprint;
