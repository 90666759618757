import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { ZookeeperFiona } from '../../../sorting/characters/Zookeepers';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: ZookeeperFiona,
            text: t`An algorithm will help the other farmers sort the animals, can you help me create one?`,
            buttonText: t`Of course!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
