import React from 'react';
import { Button, Col } from 'react-bootstrap';
import paint_stroke from '../../assets/misc/paint-stroke.svg';
import Slide from '../core/Slide';
import Content from './Content';
import { t } from 'ttag';

interface IMissionSlide {
    header: JSX.Element;
    number: number;
    backgroundColor?: string;
    onStart: () => void;
}

export const MissionSlide: React.FC<IMissionSlide> = ({
    onStart,
    header,
    number,
    children,
    backgroundColor = '#361559',
}) => {
    return (
        <Slide header={header} backgroundColor={backgroundColor}>
            <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className="text-center mission">
                <h2>
                    {t`Mission`} {number}
                </h2>
                <img className="w-50 mx-auto d-block my-2" src={paint_stroke} alt="" />
                <Content type="island_dark" styles="my-5 p-4 mission__content">
                    {children}
                </Content>
                <Button className="btn-3d" onClick={onStart}>
                    {t`Start mission`}
                </Button>
            </Col>
        </Slide>
    );
};

export default MissionSlide;
