import React from 'react';
import { TMultiChoiceQuestion } from './MultiChoice';
import ModalOverlay from '../../../general/ModalOverlay';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const IncorrectModal: React.FC<{ question: TMultiChoiceQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-times mr-2"></i> {t`INCORRECT!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    const footer = <Button onClick={() => onClose()}>{t`Continue`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#E73981">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <h3 className="mb-3">{question.question}</h3>
                    <p>
                        {t`The answer you provided was incorrect. Look carefully at the previous section and have another go!`}
                    </p>
                    {question.hint ? <p>{question.hint}</p> : null}
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default IncorrectModal;
