import React from 'react';
import frame from '../assets/devices/phone-frame.svg';
import styled from 'styled-components';

interface IPhone {
    backgroundColor?: string;
}

const Phone: React.FC<IPhone> = ({ children, backgroundColor = '#6400aa' }) => {
    return (
        <PhoneContainer>
            <PhoneBlock>
                <PhoneContent backgroundColor={backgroundColor}>
                    <PhoneContentScroll>{children}</PhoneContentScroll>
                    <PhoneFrame src={frame} alt="iPhone" />
                </PhoneContent>
            </PhoneBlock>
        </PhoneContainer>
    );
};

interface PhoneContentProps {
    backgroundColor?: string;
}

const PhoneContainer = styled.div`
    height: calc(100vh - 75px);
    position: relative;
    z-index: 2;
    overflow: hidden;
`;
const PhoneBlock = styled.div`
    margin-bottom: -10%;
    position: absolute;
    max-width: 500px;
    width: 100%;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    @media screen and (min-width: 500px) {
        margin-bottom: -50px;
        top: 10px;
    }
`;

const PhoneContent = styled.div<PhoneContentProps>`
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    padding-top: 202.5%;
    border-radius: 14vw;
    background: ${(props: PhoneContentProps) => props.backgroundColor};
    // padding: 5% 4% 9% 4%;
    @media screen and (min-width: 500px) {
        border-radius: 80px;
    }
    @media screen and (min-width: 767px) {
        // padding: 5% 4% 9% 4%;
    }
    overflow: hidden;
`;

const PhoneContentScroll = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: absolute;
    top: 0;
    display: flex;
    padding: 5%;
    max-height: calc(100vh - 75px);
`;

const PhoneFrame = styled.img`
    width: 100%;
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 3;
    top: 0;
`;

export default Phone;
