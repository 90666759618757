import React, { useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import * as Phishing from '../../components/interactive/phishing/Phishing';
import { CyberSecurityData } from '../..';
import sfx from '../../../../../services/sounds';

const PhishingGame: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    data,
    previousSlide,
    currentIndex,
    gotoSlide,
}) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    // useEffect(() => {
    //     data.playMusic(sfx.themeCybersecurityBattle);
    // }, []);

    const gameData: Phishing.TPhishingQuestion[] = [
        {
            text: t`An unknown number calls you. 'Hi ${data.name}, this is NikNak Support. Your account has been hacked, please confirm your account email and password.'`,
            textSpriteName: 'end-question-1',
            points: 3,
            answers: [
                {
                    text: t`Ask them to prove that they are from NikNak support.`,
                    points: 2,
                    textSpriteName: "end-a-1-a"
                },
                {
                    text: t`Hang up and block their number permanently.`,
                    textSpriteName: "end-a-1-b",
                    points: 5,
                },
                {
                    text: t`Give your email address and password.`,
                    points: 0,
                    textSpriteName: "end-a-1-c"
                },
            ],
        },
        {
            text: t`You receive a text from an unknown number saying your Outstagram account has been hacked and you need to click on the link to update your account details.`,
            textSpriteName: 'end-question-2',
            points: 3,
            answers: [
                {
                    text: t`I go on Outstagram’s official support website online to check if this is genuine.`,
                    points: 4,
                    textSpriteName: "end-a-2-a"
                },
                {
                    text: t`I block the number immediately.`,
                    points: 4,
                    textSpriteName: "end-a-2-b"
                },
                {
                    text: t`I click on the link to see if it is genuine.`,
                    points: 0,
                    textSpriteName: "end-a-2-c"
                },
            ],
        },

        {
            text: t`You are playing a game and someone in the chat asks for your home address.`,
            textSpriteName: 'end-question-3',
            points: 2,
            answers: [
                {
                    text: t`I ignore the message.`,
                    points: 3,
                    textSpriteName: "end-a-3-a"
                },
                {
                    text: t`I provide the person with the information so they can help me.`,
                    points: 0,
                    textSpriteName: "end-a-3-b"
                },
                {
                    text: t`I block the user.`,
                    points: 4,
                    textSpriteName: "end-a-3-c"
                },
            ],
        },

        {
            text: t`A friend urgently asks you to forward on a security code you just received over text from Outstagram.`,
            textSpriteName: 'end-question-4',
            points: 3,
            answers: [
                {
                    text: t`I ask them why they need it.`,
                    points: 2,
                    textSpriteName: "end-a-4-a"
                },
                {
                    text: t`I ignore their messages.`,
                    points: 5,
                    textSpriteName: "end-a-4-b"
                },
                {
                    text: t`I forward on the security code.`,
                    points: 1,
                    textSpriteName: "end-a-4-c"
                },
            ],
        },
        {
            text: t`You get an email from FishFood support saying someone is trying to login from another country.`,
            textSpriteName: 'end-question-5',
            points: 3,
            answers: [
                {
                    text: t`I ignore the email.`,
                    points: 1,
                    textSpriteName: "end-a-5-a"
                },
                {
                    text: t`I contact FishFood support and tell them it wasn't me logging in.`,
                    points: 4,
                    textSpriteName: "end-a-5-b"
                },
                {
                    text: t`I change the password to my account as it has been compromised.`,
                    points: 5,
                    textSpriteName: "end-a-5-c"
                },
            ],
        },
        {
            text: t`A friend sends an email with a link to download a new game for free that you have been wanting to play for ages.`,
            textSpriteName: 'end-question-6',
            points: 3,
            answers: [
                {
                    text: t`You hover on the link to check the destination URL is trustworthy.`,
                    points: 4,
                    textSpriteName: "end-a-6-a"
                },
                {
                    text: t`You ask if they got the game through an official game store.`,
                    points: 5,
                    textSpriteName: "end-a-6-b"
                },
                {
                    text: t`You click on the link to see what they are on about.`,
                    points: 1,
                    textSpriteName: "end-a-6-c"
                },
            ],
        },
        {
            text: t`You receive an email from jblogs3@gmail.com with a link to claim 20% off on your favourite clothing brand. `,
            textSpriteName: 'end-question-7',
            points: 3,
            answers: [
                {
                    text: t`You reply asking if this is a genuine offer.`,
                    points: 2,
                    textSpriteName: "end-a-7-a"
                },
                {
                    text: t`You report the email as spam.`,
                    points: 4,
                    textSpriteName: "end-a-7-b"
                },
                {
                    text: t`Click on the link to claim the 20% off offer.`,
                    points: 1,
                    textSpriteName: "end-a-7-c"
                },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Phishing.default
                data={data}
                gameData={gameData}
                onGameComplete={nextSlide}
                onGameFailed={() => {
                    data.removeLife();
                    gotoSlide(currentIndex - 1);
                    gotoSlide(currentIndex);
                }}
            ></Phishing.default>
        </Slide>
    );
};

export default PhishingGame;
