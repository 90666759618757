import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import ContextualMuteButton from '../../misc/ContextualMuteButton';
import VoiceoverContext from '../../misc/voiceover-context';
import VoiceoverMuteButton from '../../misc/VoiceoverMuteButton';
import { VolumeContext } from '../../misc/volume-context';
import diver from './assets/characters/avatars/diver.svg';
import fish from './assets/characters/avatars/fish.svg';
import jelly from './assets/characters/avatars/jelly-old.svg';
import llama from './assets/characters/avatars/llama.svg';
import ray from './assets/characters/avatars/ray.svg';
import star from './assets/characters/avatars/star.svg';
import heart from './assets/gamification/heart.svg';
import pointsStar from './assets/gamification/star.svg';
import voiceoverSpriteSheetDefault from './assets/voiceovers/vo-cybersecurity-spritesheet';
import voiceoverSpriteSheetChrome from './assets/voiceovers/vo-cybersecurity-spritesheet-chrome';
import { TIslandArea } from './components/Island';
import Underwater from './components/Wrapper';
import Intro from './units/1-intro';
import Welcome from './units/2-welcome';
import FishFood from './units/3-fishfood';
import PhoneCall from './units/4-phonecall';
import Email from './units/5-email';
import Tiktok from './units/6-tiktok';
import Advert from './units/7-advert';
import Finale from './units/8-finale';
import Outro from './units/9-outro';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';












export type CyberSecurityData = {
    name: string;
    setName: (name: string) => void;
    avatars: string[];
    avatar: string;
    setAvatar: (a: string) => void;
    points: number;
    lives: number;
    addPoints: (amount: number) => void;
    setLives: (amount: number) => void;
    removeLife: () => void;
    availableIslandAreas: TIslandArea[];
    setAvailableIslandAreas: (islands: TIslandArea[]) => void;
    removeIslandLocation: (location: TIslandArea) => void;
    incorrectCharacteristics: string[];
    setIncorrectCharacteristics: (characteristic: string[]) => void;
    voiceover: Howl,
    music: Howl,
    playMusic: (m: Howl) => void,
    stopMusic: () => void,
};

const CyberSecurity: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const [avatars] = useState([llama, ray, fish, jelly, star, diver]);
    const [avatar, setAvatar] = useState(avatars[0]);

    const [points, setPoints] = useState(0);
    const [lives, setLives] = useState(0);

    const [incorrectCharacteristics, setIncorrectCharacteristics] = useState(['']);

    const [availableIslandAreas, setAvailableIslandAreas] = useState<TIslandArea[]>([]);

    const removeIslandLocation = (location: TIslandArea) => {
        if (!availableIslandAreas) return;
        let index = availableIslandAreas.indexOf(location);

        if (index !== -1) {
            availableIslandAreas.splice(index, 1);
            console.log(availableIslandAreas);
            setAvailableIslandAreas([...availableIslandAreas]);
        }
    };

    const addPoints = (amount: number) => {
        if (points + amount < 0) {
            setPoints(0);
            console.log('No points!');
        } else {
            if (amount > 0) {
                sfx.coinsCollect.play();
            }
            setPoints(points + amount);
        }
    };

    const removeLife = () => {
        if (lives > 0) {
            setLives(lives - 1);
        } else {
            console.log('No lives left!');
        }
    };


    const slides = [
        StartSlide,
        ...Intro,
        ...Welcome,
        ...FishFood,
        ...PhoneCall,
        ...Email,
        ...Tiktok,
        ...Advert,
        ...Finale,
        ...Outro,
        EndSlide,
    ];

    const { voiceover, setVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        if (!setVoiceover || ((Cookies.get('bfg_lang') ?? 'en') !== 'en')) {
            return;
        }

        setVoiceover(
            new Howl({
                src: require('./assets/voiceovers/vo-cybersecurity-2.mp3'),
                sprite: isSafari || isFirefox ? voiceoverSpriteSheetDefault : voiceoverSpriteSheetChrome,
                html5: true,
                volume: 0.3,
                preload: true,
            })
        );
    }, [])


    const { musicMuted } = useContext(VolumeContext);
    const [music, setMusic] = useState<Howl | undefined>(sfx.themeCybersecurity);

    const playMusic = (m: Howl) => {
        if (m) {
            let vo: number;
            stopMusic()
            let mInstance = m.load();
            setMusic(mInstance);

            if (musicMuted === false) {
                vo = mInstance.play();
            }
        }
    }

    const stopMusic = () => {
        if (music) {
            music.stop();
        }
    }

    useEffect(() => {
        if (musicMuted) {
            stopMusic()
        } else if (musicMuted === false && music) {
            playMusic(music);
        }

        return () => {
            stopMusic();
        }
    }, [music, musicMuted])

    const barefootLogo = lives ? (
        <Header>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <Score>
                <img src={heart} width="35" alt="Lives Icon" />
                <span>{lives}</span>
                <img src={pointsStar} width="35" alt="Points Icon" />
                <span>{points}</span>
            </Score>
            <VoiceoverMuteButton></VoiceoverMuteButton>
            {/* NA THIS DOESNT GET THE MUTE STATUS JUST  */}
            <ContextualMuteButton context={music} zIndex={5}></ContextualMuteButton>

        </Header>
    ) : (
            <div>
                <img src={logo} height="50px" alt="BT-Barefoot" />
                <VoiceoverMuteButton></VoiceoverMuteButton>
                <ContextualMuteButton context={music} zIndex={5}></ContextualMuteButton>
                {/* <span>Points: {points}</span>
                <span>Lives: {lives}</span> */}
            </div>
        );


    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);


    // Characteristics 17
    // Email 31
    // Pop up 63
    // End Game 58

    return (
        <React.Fragment>
            <Helmet>
                <title>The Phisherman | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="3765e2f2-7f8f-4066-8d63-3a79f7cfa165"
                name="cybersecurity"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={(barefootLogo)}
                data={{
                    name,
                    setName,
                    avatars,
                    avatar,
                    setAvatar,
                    addPoints,
                    points,
                    lives,
                    removeLife,
                    setLives,
                    setAvailableIslandAreas,
                    availableIslandAreas,
                    removeIslandLocation,
                    incorrectCharacteristics,
                    setIncorrectCharacteristics,
                    voiceover,
                    music,
                    playMusic,
                    stopMusic,
                }}
                Wrapper={Underwater}
            />
        </React.Fragment>
    );
};

const Header = styled.div`
    width: 100%;
    position: relative;
`;

const Score = styled.div`
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 7px 20px;
    padding: 7px 10px;
    height: 50px;
    position: absolute;
    right: 0;
    right: -5px;
    top: 0;
    img {
        height: 18px;
        width: 18px;
        margin: 0 3px;
    }
    span {
        padding: 0 3px;
        font-family: 'BT Font Extra Bold';
        font-size: 15px;
    }
    display: flex;
    align-items: center;
    @media (min-width: 400px) {
        img {
            height: 25px;
            width: 25px;
            margin: 0 5px;
        }
        span {
            padding: 0 10px;
            font-size: 1.25rem;
        }
        right: 0;
    }
    @media (min-width: 768px) {
        img {
            height: 36px;
            width: 36px;
            margin: 0 10px;
        }
        transform: translateX(50%);
        right: 50%;
    }
`;

export default CyberSecurity;
