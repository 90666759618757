import React, { useState, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import vault_f from '../../../../../assets/misc/th-vault--front.svg';
import vault_b from '../../../../../assets/misc/th-vault--back.svg';
import { motion } from 'framer-motion';

const VaultAnim: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [showFooter, setShowFooter] = useState(false);
    const next = showFooter ? <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button> : null;

    useEffect(() => {
        setTimeout(() => setShowFooter(true), 2000);
    }, []);

    return (
        <Slide header={defaultHeader} footer={next}>
            <Row>
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }}>
                    <div className="th-vanim">
                        <motion.img
                            src={vault_f}
                            alt="Front of Vault"
                            className="th-vanim__v th-vanim__v--front"
                            animate={{
                                rotate: [0, 30],
                            }}
                            transition={{
                                delay: 0.5,
                                duration: 2,
                            }}
                        />
                        <img src={vault_b} alt="Back of Vault" className="th-vanim__v th-vanim__v--back" />
                    </div>
                </Col>
            </Row>
        </Slide>
    );
};

export default VaultAnim;
