import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { FlubFlub } from '../../characters/Aliens';
import { VariablesData } from '../../index';

const VariablesQuizOutro2: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: FlubFlub,
            text: t`Well done ${data.name}. Let's send you out again with the new 'Zinon' variable and see how much you can collect!`,
            buttonText: t`Sure!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default VariablesQuizOutro2;
