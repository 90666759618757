import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import pattern_chicken from '../../../../../assets/games/features/pattern--chicken.svg';
import pattern_cow from '../../../../../assets/games/features/pattern--cow.svg';
import pattern_sheep from '../../../../../assets/games/features/pattern--sheep.svg';
import beak from '../../../../../assets/games/features/so-icon--beak.svg';
import feather from '../../../../../assets/games/features/so-icon--feather.svg';
import fur from '../../../../../assets/games/features/so-icon--mane.svg';
import paw from '../../../../../assets/games/features/so-icon--paw.svg';
import wing from '../../../../../assets/games/features/so-icon--wing.svg';
import four from '../../../../../assets/games/features/so-icon__four.svg';
import hooves from '../../../../../assets/games/features/so-icon__hooves.svg';
import horns from '../../../../../assets/games/features/so-icon__horns.svg';
import wool from '../../../../../assets/games/features/so-icon__wool.svg';
import chicken1 from '../../../../../assets/games/stock/so-stock--chicken1.jpg';
import chicken2 from '../../../../../assets/games/stock/so-stock--chicken2.jpg';
import chicken3 from '../../../../../assets/games/stock/so-stock--chicken3.jpg';
import chicken4 from '../../../../../assets/games/stock/so-stock--chicken4.jpg';
import cow1 from '../../../../../assets/games/stock/so-stock--cow1.jpg';
import cow2 from '../../../../../assets/games/stock/so-stock--cow2.jpg';
import cow3 from '../../../../../assets/games/stock/so-stock--cow3.jpg';
import cow4 from '../../../../../assets/games/stock/so-stock--cow4.jpg';
import sheep1 from '../../../../../assets/games/stock/so-stock--sheep1.jpg';
import sheep2 from '../../../../../assets/games/stock/so-stock--sheep2.jpg';
import sheep3 from '../../../../../assets/games/stock/so-stock--sheep3.jpg';
import sheep4 from '../../../../../assets/games/stock/so-stock--sheep4.jpg';
import Slide, { ISlide } from '../../../../core/Slide';
import SimilarFeatures, { SimilarFeaturesQuestion } from '../../../../interactive/similarfeatures/SimilarFeatures';

const MissionPattern: React.FC<ISlide> = ({ nextSlide }) => {
    const header = (
        <h3>
            {t`Mission 1:`} <span className="font-bt-400">{t`Spot what’s the same for the animals`}</span>
        </h3>
    );

    const gameData: SimilarFeaturesQuestion[] = [
        {
            question: {
                text: t`Look carefully, what’s the same about these sheep? They all have:`,
                images: [sheep1, sheep2, sheep3, sheep4],
                colour: '#FFE202',
            },
            answerText: t`Well done! You have spotted a pattern that all the sheep have wool, 4 legs and hooves.`,
            answerImage: pattern_sheep,
            answers: [
                {
                    image: horns,
                    correct: false,
                    text: t`HORNS`,
                },
                {
                    image: wing,
                    correct: false,
                    text: t`WINGS`,
                },
                {
                    image: four,
                    correct: true,
                    text: t`4 LEGS`,
                },
                {
                    image: paw,
                    correct: false,
                    text: t`PAWS`,
                },
                {
                    image: hooves,
                    correct: true,
                    text: t`HOOVES`,
                },
                {
                    image: feather,
                    correct: false,
                    text: t`FEATHERS`,
                },
                {
                    image: beak,
                    correct: false,
                    text: t`BEAK`,
                },
                {
                    image: wool,
                    correct: true,
                    text: t`WOOL`,
                },
                {
                    image: fur,
                    correct: false,
                    text: t`FUR`,
                },
            ],
        },
        {
            question: {
                text: t`Look carefully, what’s the same about these chickens? They all have:`,
                images: [chicken1, chicken2, chicken3, chicken4],
                colour: '#02C2FF',
            },
            answerText: t`Well done! You have spotted a pattern that all the chickens have wings, feathers and a beak.`,
            answerImage: pattern_chicken,
            answers: [
                {
                    image: horns,
                    correct: false,
                    text: t`HORNS`,
                },
                {
                    image: wing,
                    correct: true,
                    text: t`WINGS`,
                },
                {
                    image: four,
                    correct: false,
                    text: t`4 LEGS`,
                },
                {
                    image: paw,
                    correct: false,
                    text: t`PAWS`,
                },
                {
                    image: hooves,
                    correct: false,
                    text: t`HOOVES`,
                },
                {
                    image: feather,
                    correct: true,
                    text: t`FEATHERS`,
                },
                {
                    image: beak,
                    correct: true,
                    text: t`BEAK`,
                },
                {
                    image: wool,
                    correct: false,
                    text: t`WOOL`,
                },
                {
                    image: fur,
                    correct: false,
                    text: t`FUR`,
                },
            ],
        },
        {
            question: {
                text: t`Look carefully, what’s the same about these cows? They all have:`,
                images: [cow1, cow2, cow3, cow4],
                colour: '#F4B4FF',
            },
            answerText: t`Well done! You have spotted a pattern that all the cows have hooves, 4 legs and fur.`,
            answerImage: pattern_cow,
            answers: [
                {
                    image: horns,
                    correct: false,
                    text: t`HORNS`,
                },
                {
                    image: wing,
                    correct: false,
                    text: t`WINGS`,
                },
                {
                    image: four,
                    correct: true,
                    text: t`4 LEGS`,
                },
                {
                    image: paw,
                    correct: false,
                    text: t`PAWS`,
                },
                {
                    image: hooves,
                    correct: true,
                    text: t`HOOVES`,
                },
                {
                    image: feather,
                    correct: false,
                    text: t`FEATHERS`,
                },
                {
                    image: beak,
                    correct: false,
                    text: t`BEAK`,
                },
                {
                    image: wool,
                    correct: false,
                    text: t`WOOL`,
                },
                {
                    image: fur,
                    correct: true,
                    text: t`FUR`,
                },
            ],
        },
    ];

    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <Row>
                <Col xs={'12'} className={'text-center'}>
                    <SimilarFeatures gameData={gameData} onGameComplete={nextSlide} />
                </Col>
            </Row>
        </Slide>
    );
};

export default MissionPattern;
