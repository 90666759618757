import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import CaesarCipher, { TCaesarCipherQuestion } from '../../../../interactive/caesarcipher/CaesarCipher';

const CaesarCipherGameOffset: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TCaesarCipherQuestion[] = [
        {
            answer: t`ATTACK TOMORROW`,
            answerOffset: 4,
            answerInputDisabled: true,
            offsetInputDisabled: false,
            offset: 0,
        },
        {
            answer: t`INVADE LONDON`,
            answerOffset: 8,
            answerInputDisabled: true,
            offsetInputDisabled: false,
            offset: 0,
        },
        {
            answer: t`READY THE SHIPS`,
            answerOffset: 14,
            answerInputDisabled: true,
            offsetInputDisabled: false,
            offset: 0,
        },
    ];

    const header = (
        <h3 className={'cc-header--small'}>
            {t`Code Cracking:`} <span className="font-bt-400">{t`Fix the offset to crack the code!`}</span>
        </h3>
    );

    return (
        <Slide header={header}>
            <CaesarCipher gameData={gameData} onGameComplete={nextSlide}></CaesarCipher>
        </Slide>
    );
};

export default CaesarCipherGameOffset;
