import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import CorrectModal from './CorrectModal';
import LetterInput from './LetterInput';
import PictureTileGroup from './PictureTileGroup';

export type Question = GameQuestion & {
    answer: string;
    answerImage: string;
    pictures: [GamePicture, GamePicture, GamePicture, GamePicture];
    additionalLetters: Array<string>;
    hintLevel?: 0 | 1 | 2 | 3 | 4;
};

export type GamePicture = {
    text: string;
    image: string;
};

const FourPicturesGame: React.FC<GameProps<Question>> = ({ gameData, onGameComplete, index = 0 }) => {
    const [show, setShow] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<Question>(gameData, handleGameComplete, handleNextQuestion, index);

    // Move to game
    const [hintLevel, setHintLevel] = useState(0);

    useEffect(() => {
        if (question) {
            setHintLevel(question.hintLevel ?? 4);
        }
    }, [question]);

    const handleHintRequest = () => {
        if (hintLevel < 4) {
            setHintLevel(hintLevel + 1);
        }
    };
    // ---

    const { volume } = useContext(VolumeContext);

    const handleModalClose = () => {
        setTimeout(nextQuestion, 0);
        setShow(false);
    };

    const handleQuestionComplete = () => {
        if (volume) sfx.success.play();
        setShow(true);
    };

    if (question === null) {
        return null;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <p className={'ab-game__howto'}>{t`Guess the animal by selecting and deselecting the letters below`}</p>
            <PictureTileGroup pictures={question.pictures} hintLevel={hintLevel} />
            <Row>
                <Col>
                    <LetterInput
                        target={question.answer}
                        onQuestionComplete={handleQuestionComplete}
                        additionalLetters={question.additionalLetters}
                        onHintRequest={handleHintRequest}
                        hintLevel={hintLevel}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FourPicturesGame;
