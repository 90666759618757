import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { ColourfulKitsData } from '../..';
import WordDiscovery from '../../../../general/WordDiscovery';
import { t } from 'ttag';

const ShapeGroupingOutro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`Excellent ${data.name}. You just grouped the shapes by their attributes.`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Attribute`}
                wordDefinition={t`An attribute is a feature of something e.g. colour is an attribute of a shape.`}
            ></WordDiscovery>
        </Slide>
    );
};

export default ShapeGroupingOutro;
