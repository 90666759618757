import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import Intel from '../../components/Intel';
import enigma from '../../../../../assets/misc/illustration_enigma.svg';
import { t } from 'ttag';

const CipherEnigmaIntel: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    );

    return (
        <Slide header={defaultHeader} footer={next}>
            <Intel>
                <img
                    src={enigma}
                    height="350px"
                    width="350px"
                    className={'w-75 mx-auto d-block mb-2'}
                    alt={t`Enigma machine`}
                />
                <p className={'text-center'}>{t`TOP SECRET`}</p>
                <p className={'text-center'}>{t`THE ENIGMA MACHINE`}</p>
            </Intel>
        </Slide>
    );
};

export default CipherEnigmaIntel;
