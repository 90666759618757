import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Reward from 'react-rewards';
import { t } from 'ttag';
import sfx from '../../../../services/sounds';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { VolumeContext } from '../../../misc/volume-context';
import Penguin from '../characters/Penguin';

const EndPage: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const footer = <Button onClick={onComplete}>{t`Finish`}</Button>;
    const { volume } = useContext(VolumeContext);

    const [confetti, setConfetti] = useState<any>(null);

    useEffect(() => {
        confetti?.rewardMe();
        let interval = setInterval(() => confetti?.rewardMe(), 5000);
        return () => clearInterval(interval);
    }, [confetti]);

    if (volume) sfx.cheer.play();
    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}

                    <Content type="island_dark" styles={'my-2 p-2'}>
                        <Reward ref={(ref: any) => setConfetti(ref)} type="confetti">
                            <img src={Penguin.characterImage} className={`w-25 mb-3`} alt="" />
                            <div className={'character__shadow'}></div>
                        </Reward>

                        <h3>
                            {t`You have completed`}
                            <br />
                            {t`Gift Wrapped`}!
                        </h3>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about in Gift Wrapped and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/gift-wrapped"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20the%20Gift%20Wrapped%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/gift-wrapped"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndPage;
