import React from 'react';
import styled from 'styled-components';
import DialogueGroup, { DialogGroupProps } from './DialogueGroup';

export type TCharacter = {
    characterName: string;
    characterImage: string;
    characterWidth?: number;
    characterHeight?: number;
};

export interface ICharacter {
    character: TCharacter;
}

interface CharacterProps {
    characterWidth?: number;
    characterHeight?: number;
}

const Character: React.FC<ICharacter> = ({ children, character }) => {
    return (
        <div>
            <div className="character__wrapper">
                <StyledCharacter
                    characterWidth={character.characterWidth}
                    characterHeight={character.characterHeight}
                    src={character.characterImage}
                    alt={character.characterName}
                    className="char char--ranger"
                />

                <div className="character__shadow"></div>
            </div>
            {children}
        </div>
    );
};

const StyledCharacter = styled.img<CharacterProps>`
    width: ${(props) => props.characterWidth || '70'}px;
    height: ${(props) => props.characterHeight || '180'}px;
`;

export const CharacterDialogue: React.FC<ICharacter> & React.FC<DialogGroupProps> = (props: any) => {
    return <DialogueGroup Wrapper={Character} {...props} />;
};

export default Character;
