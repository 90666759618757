import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import { Content } from '../../../../general/Content';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import logos from '../../../../../assets/misc/logo_ideas.svg';
import logos_cy from '../../../../../assets/misc/logo_ideas-welsh.svg';
import { ColourfulKitsData } from '../..';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import ModalOverlay from '../../../../general/ModalOverlay';
import { Button, Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { t } from 'ttag';
import Cookies from 'js-cookie';

const AttributesGame2Outro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`That was speedy ${data.name}! Let's have a look at the logo ideas you have come up with.`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];
    const modalHeader = (
        <React.Fragment>
            <h3 className="w-100 text-white text-center">
                <i className={'fas fa-pencil-alt mr-2'}></i>
                {t`LOGOS FINISHED`}
            </h3>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </React.Fragment>
    );

    let lang = Cookies.get('bfg_lang');

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <ModalOverlay
                show={show}
                header={modalHeader}
                footer={<Button onClick={() => nextSlide()}>{t`Next`}</Button>}
                backgroundColor={'#6126A0'}
            >
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                            <Content type={'island'} styles={'p-x'}>
                                <img
                                    src={lang === 'cy' ? logos_cy : logos}
                                    width={'300px'}
                                    height={'400px'}
                                    className={'img--modal'}
                                    alt={t`Generated logos`}
                                />
                            </Content>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
        </Slide>
    );
};

export default AttributesGame2Outro;
