import VariablesQuiz from './VariablesQuiz';
import VariablesQuizOutro from './VariablesQuizOutro';
import VariablesQuizOutro2 from './VariablesQuizOutro2';
import MissionIntro from './MissionIntro';
import MissionGame from './MissionGame';
import MissionOutro from './MissionOutro';
import VariablesIntro from './VariablesIntro';
import VariablesIntro2 from './VariablesIntro2';
export default [
    MissionIntro,
    MissionGame,
    MissionOutro,
    VariablesQuiz,
    VariablesQuizOutro,
    VariablesQuizOutro2,
    VariablesIntro,
    VariablesIntro2,
];
