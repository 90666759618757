import React from 'react';
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import LevelSelector from '../../components/interactive/levels/LevelSelector';

const LevelThree: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {

    const recap = t`You were able to guide the cars safely across the bridge. You did this by decomposing the problem and looking at each traffic light individually. This has made the road more safe, which was part of the larger problem the Mayor wanted us to solve.`

    return (
        <Slide header={defaultHeader} footerHidden={true} >
            <LevelSelector level={3} nextSlide={nextSlide} recap={recap} textSpriteName='117' />
        </Slide>
    );
};




export default LevelThree;
