import { motion, useAnimation, useMotionValue } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { isChrome } from 'react-device-detect';
import styled from 'styled-components';
import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion } from '../../../../../core/Game';
import { VolumeContext } from '../../../../../misc/volume-context';
import howto2 from '../../../assets/timings/howto2.png';
import howto3 from '../../../assets/timings/howto3.png';
import { breakpoint } from '../../../styles/junction';
import { theme } from '../../../styles/theme';
import Phone from '../../Phone';
import { TTrafficLight } from '../../traffic-lights/TrafficLight';
import TrafficLightSequence from '../../traffic-lights/TrafficLightSequence';
import Tutorial, { TTutorial } from '../Tutorial';


export type TTrafficTimingsQuestion = GameQuestion & {
    data: boolean
    // answer: TTrafficLight[]
};

const TrafficTimings: React.FC<GameProps<TTrafficTimingsQuestion>> = ({
    gameData,
    index = 0,
    onGameComplete,
}) => {
    const [redOn, setRedOn] = useState(false);
    const [amberOn, setAmberOn] = useState(false);
    const [greenOn, setGreenOn] = useState(false);
    const [redClicked, setRedClicked] = useState(false);
    const [amberClicked, setAmberClicked] = useState(false);
    const [greenClicked, setGreenClicked] = useState(false);
    const [complete, setComplete] = useState(false);
    const [played, setPlayed] = useState(false);
    const [tutorialShow, setTutorialShow] = useState(true);

    const [start, setStart] = useState(false);
    const [result, setResult] = useState('');

    const anim_response = useAnimation();

    const { volume } = useContext(VolumeContext);

    // As each light turns on, click the light on

    // The traffic light will light up as a correct timing is fed in.
    // Quickly try and click on the light that has switch on to program the time.
    // Get all the timings to fully program the timing of the light.

    const slides: TTutorial[] = [
        // { text: t`The traffic light will light up as a correct timing is fed in.`, image: howto1, textSpriteName: '76' },
        { text: t`Select the light that has switched on to program the time.`, image: howto2, textSpriteName: '77' },
        { text: t`Get all the timings correct to fully program the traffic light. Good Luck!`, image: howto3, textSpriteName: '78' },
    ]

    useEffect(() => {
        let x: number, y: number, z: number;
        if (start) {
            let a = setTimeout(() => {
                setRedOn(true)
                x = setTimeout(() => {
                    //alert failed
                    setRedOn(false)
                }, 1500)
            }, 2500)

            let b = setTimeout(() => {
                setAmberOn(true)
                y = setTimeout(() => {
                    //alert failed
                    setAmberOn(false)
                }, 1500)
            }, 4500)

            let c = setTimeout(() => {
                setGreenOn(true)
                z = setTimeout(() => {
                    //alert failed
                    setGreenOn(false)
                    handleLightsFinish();
                    setCorrectAnswers([]);
                }, 1500)
            }, 6500)

            return () => {
                clearTimeout(a)
                clearTimeout(b)
                clearTimeout(c)
                clearTimeout(x)
                clearTimeout(y)
                clearTimeout(z)
            };

        }
    }, [start]);

    const handleLightsFinish = () => {
        if (!complete) {
            setPlayed(true);
            setStart(false);
            setGreenClicked(false);
            setAmberClicked(false);
            setRedClicked(false);
            if (volume) sfx.incorrect.play();
        }

    }

    const handleClick = (name: string) => {
        flashResponse();
    }

    const handleLightDataChange = (data: TTrafficLight) => {
        console.log('changed: ' + data)
    }

    const results = [t`Perfect!`, t`Awesome!`, t`Super!`, t`Yes!`, t`Excellent!`];

    async function flashResponse() {
        setResult(results[Math.floor(Math.random() * results.length)])
        if (volume) sfx.success.play();
        await anim_response.start('show');
        await anim_response.start('hidden');
    }
    async function flashWrong() {
        setResult(t`Not quite!`)
        if (volume) sfx.failure.play();
        await anim_response.start('show');
        await anim_response.start('hidden');
    }

    const responseVariants = {
        hidden: {
            backgroundColor: theme.bare_blue,
            transition: {
                ease: 'easeInOut',
                duration: 0.1,
                delay: 0.3
            },
        },
        show: {
            backgroundColor: (result == 'Not Quite!' ? '#cccccc' : theme.bt_purple),
            transition: {
                ease: 'easeInOut',
                duration: 0.1
            },
        },
    };

    const textVariants = {
        hidden: {
            y: 100,
            opacity: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.3,
                delay: 0.2
            },
        },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                ease: "easeInOut",
                duration: 0.3,
            },
        },
    };

    const [correctAnswer, setCorrectAnswers] = useState<string[]>([])

    useEffect(() => {
        if (correctAnswer.length === 3) {
            setComplete(true);
            if (volume) sfx.synthSuccess.play();
        }

    }, [correctAnswer])


    const COLOUR_EMPTY = '#575757';

    const handleLightClick = (color: string, on: boolean) => {
        let answered = [...correctAnswer];
        if (start) {
            if (color == 'red') {
                setRedClicked(true);
                if (on && !redClicked) {
                    flashResponse()
                    answered.push('red')
                }
                else {
                    flashWrong()
                }
            }
            if (color == 'amber') {
                setAmberClicked(true);
                if (on && !amberClicked) {
                    flashResponse()
                    answered.push('amber')
                }
                else {
                    flashWrong()
                }
            }
            if (color == 'green') {
                setGreenClicked(true);
                if (on && !greenClicked) {
                    flashResponse()
                    answered.push('green')
                }
                else {
                    flashWrong()
                }
            }
        }
        setCorrectAnswers(answered);

    }

    const sequence = [
        { red: { enabled: true }, duration: 4000 },
        { amber: { enabled: true }, duration: 1000 },
        { green: { enabled: true }, duration: 3000 },
    ]
    const handleStart = () => {
        setStart(true);

    }


    const tutorialClose = () => {
        setTutorialShow(false)
    }

    return (
        <OverlayContainer>


            <Phone backgroundColor={theme.bare_blue}>
                <ResponseModal variants={responseVariants} animate={anim_response}>
                    <motion.div variants={textVariants}>
                        <h1>{result}</h1>
                    </motion.div>
                </ResponseModal>


                {!start ?
                    <CorrectModal animate={{ backgroundColor: [theme.bare_blue, 'rgba(0,0,0,0.5)'] }} transition={{ duration: 0.5 }}>
                        {played ?
                            <div>
                                <h3>{t`Oh no!`}</h3>
                                <hr />
                                <p>{t`Your timings were not correct, try again to select the lights at the right time!`}</p>
                                <Button onClick={handleStart}>{t`Next`}</Button>
                            </div>
                            :
                            <div>
                                <h3>{t`Timings Game`}</h3>
                                <hr />
                                <p>{t`Press Start to begin programming the light's timings. Good luck!`}</p>
                                <Button onClick={handleStart}>{t`Start`}</Button>
                            </div>
                        }
                    </CorrectModal>
                    : null}
                {complete ?
                    <CorrectModal animate={{ backgroundColor: [theme.bare_blue, theme.bare_green] }} transition={{ duration: 0.5 }}>
                        <div>
                            <h3>{t`Timings Programmed!`}</h3>
                            <hr />
                            <div className="w-25 d-block mx-auto mb-3">
                                <TrafficLightSequence sequence={sequence} loop={true} setLightIndex={(index) => { }}></TrafficLightSequence>
                            </div>
                            <p>{t`Red`}: <b>4s</b> {t`Amber`}: <b>1s</b> {t`Green`}: <b>3s</b></p>
                            <Button onClick={onGameComplete}>{t`Next`}</Button>
                        </div>
                    </CorrectModal>
                    : null}

                <Tutorial title={t`HOW TO USE`} subtitle={`-`} tutorialShow={tutorialShow} slides={slides} backgroundColor={'#0AB9F0'} handleClose={() => tutorialClose()}></Tutorial>
                <p className="text-center position-relative mt-5 px-3">{t`Select the lights as they light up.`}</p>

                <GameWrapper>
                    <TrackWrapper>
                        <Light clicked={redClicked && correctAnswer.indexOf('red') == -1} duration={3} colour='#E5007D' start={start}></Light>
                        <Light clicked={amberClicked && correctAnswer.indexOf('amber') == -1} duration={5} colour='#FFE202' start={start}></Light>
                        <Light clicked={greenClicked && correctAnswer.indexOf('green') == -1} duration={7} colour='#00C3A5' start={start}></Light>
                    </TrackWrapper>

                    <TrafficWrapper>
                        <Wrapper>
                            <SVGWrapper>
                                <div>
                                    <svg viewBox={`0 0 33 110`} fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <filter id="shadowRed" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="red" />
                                            </filter>
                                            <filter id="shadowAmber" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="yellow" />
                                            </filter>
                                            <filter id="shadowGreen" x="-75%" y="-75%" width="250%" height="250%" filterUnits="userSpaceOnUse">
                                                <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="green" />
                                            </filter>
                                        </defs>
                                        <ellipse cx="16.3964" cy="102.316" rx="16.0819" ry="7.4664" fill="black" fillOpacity="0.05" />
                                        <path d="M19.1603 68.3762V102.694C17.3952 103.843 15.1151 103.843 13.3501 102.694V68.3762H19.1603Z" fill="#333333" stroke="black" />
                                        <rect x="0.5" y="1.13086" width="31.5041" height="67.7572" rx="4.5" fill="#575757" stroke="black" />
                                        <rect x="2.76025" y="3.54248" width="26.9841" height="62.9337" rx="2.5" fill="#333333" stroke="black" />

                                        <StyledCircle enabled={true} style={{ filter: (redOn && isChrome ? 'url(#shadowRed)' : '') }} cx="16.5484" cy="16.0671" r="6.84919" fill={redOn ? theme.bare_pink : COLOUR_EMPTY} onClick={() => handleLightClick('red', redOn)} stroke="black" />
                                        <StyledCircle enabled={true} style={{ filter: (amberOn && isChrome ? 'url(#shadowAmber)' : '') }} cx="16.5484" cy="35.1352" r="6.84919" fill={amberOn ? theme.bare_yellow : COLOUR_EMPTY} onClick={() => handleLightClick('amber', amberOn)} stroke="black" />
                                        <StyledCircle enabled={true} style={{ filter: (greenOn && isChrome ? 'url(#shadowGreen)' : '') }} cx="16.5484" cy="53.7655" r="6.84919" fill={greenOn ? theme.bare_green : COLOUR_EMPTY} onClick={() => handleLightClick('green', greenOn)} stroke="black" />
                                    </svg>
                                </div>
                            </SVGWrapper>
                        </Wrapper>
                    </TrafficWrapper>
                </GameWrapper>
                <div className="w-100 text-center position-relative">
                    <hr />
                </div>
            </Phone>
        </OverlayContainer >
    );
};

const Light = ({ duration, colour, start, clicked }: { duration: number, colour: string, start: boolean, clicked: boolean }) => {

    // Create the MotionValue
    const x = useMotionValue(700);
    const [xState, setXState] = useState(x.get());

    useEffect(() => {
        const unsubscribe = x.onChange(setXState);
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (start) {
            x.set(0)

        } else {
            x.set(700)
        }
    }, [start]);

    return (
        <LightTrack clicked={clicked}>
            <LightCircle
                color={colour}
                initial={{
                    x: x.get() // 100
                }}
                animate={{
                    x: x.get(),
                }}
                transition={{
                    duration: start ? duration : 0,
                    ease: "linear",
                    // loop: Infinity,
                    // repeatDelay: 0.5
                }}
            />
        </LightTrack>
    );
}
const ResponseModal = styled(motion.div)`
    position: absolute;
    top: 1%;
    right: 1%;
    bottom: 1%;
    background-color: ${theme.bare_blue};
    left: 1%;
    padding: 50% 10% 10% 10%;
    transform-origin: bottom;
    display: flex;
    justify-content: center;
    align-items:center;
    div {
        text-align:center;
        h1 {
            font-size: 1.25rem;
            @media ${breakpoint.mobileL}{
                font-size: 1.5rem;
            }
            @media ${breakpoint.laptop}{
                font-size: 2rem;
            }
            @media ${breakpoint.laptopL}{
                font-size: 2.5rem;
            }
        }
    }
`;

const CorrectModal = styled(motion.div)`
    position: absolute;
    top: 1%;
    right: 1%;
    bottom: 1%;
    left: 1%;
    padding: 15% 10%;
    background-color: ${theme.bare_green};
    z-index: 3;
    transform-origin: bottom;
    display: flex;
    justify-content: center;
    align-items:center;
    div {
        text-align:center;
    }
    b {
        background-color: rgba(0,0,0,0.1);
        border-radius: 3px;
        padding: 0 2px;
    }
`;


const OverlayContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    padding-top: 75px;
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

`;

interface LightProps {
    color: string
}

const LightCircle = styled(motion.div) <LightProps>`
  border-radius: 100%;
  width: 10%;
  height: 80%;
  border: solid #ffffff 3px;
  box-shadow: 0 3px 10px rgba(0,0,0,0.25);
  background-color: ${(props) => props.color};
`;


const TrackWrapper = styled.div`
    height: 60%;
    position: absolute;
    right: 0;
    top 17%;
    left: 7%;
`;

const LightTrack = styled.div<{ clicked: boolean }>`
  height: 18%;
  padding-top: 1%;
  margin-bottom: 3.5%;
  width: 100%;
  background-color: ${(props) => props.clicked ? theme.bare_pink : 'rgba(255,255,255,0.1)'};
`;

const GameWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 12% 5%;
  @media ${breakpoint.mobileL}{
    margin-top: 10%;
  }
  @media ${breakpoint.laptop}{
    margin-top: 25%;
  }
  position: relative;
`;



// Traffic Light Placements

const TrafficWrapper = styled.div`
    display: inline-block;
    width:30%;
`;


const SVGWrapper = styled.div`
    width: 100%;
    padding-top: 333.33%;
    div {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        bottom: 0;
    }
    svg {width: 100%;}

`;


interface CircleProps {
    enabled: boolean;
}
const StyledCircle = styled.circle<CircleProps>`
    box-shadow: 0 0 15px #ffffff;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        filter: none !important;
    }
    cursor: pointer;
    &:hover{
        stroke: #ffffff;
    }
`;

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    svg {width: 100%;}
    circle {stroke-width: 0.5px; stroke: #222222}

`;



export default TrafficTimings;
