import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import AgentSparrow from '../../characters/AgentSparrow';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';

const TuringIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentSparrow,
            text: t`Welcome to Code Cracking HQ, your base for the next couple of days. 
            Before we get started, please could I take your Agent name?`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TuringIntro;
