import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Kitty from '../../characters/Kitty';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const Goodbye: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Kitty,
            text: t`You really have learnt a lot in such a short amount of time! You are always welcome here and I hope to see you at the competition!`,
            buttonText: t`Bye Amber`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Goodbye;
