import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';

import Puzzle, { TPuzzleQuestion } from '../../components/interactive/puzzle/Puzzle';

import soldierHead from '../../assets/puzzles/soldier/head.png';
import soldierHeadShadow from '../../assets/puzzles/soldier/head_shadow.svg';
import soldierBody from '../../assets/puzzles/soldier/torso.png';
import soldierBodyShadow from '../../assets/puzzles/soldier/torso_shadow.svg';
import soldierBottom from '../../assets/puzzles/soldier/legs.png';
import soldierBottomShadow from '../../assets/puzzles/soldier/legs_shadow.svg';
import soldier from '../../assets/puzzles/soldier/soldier.svg';

import robotHead from '../../assets/puzzles/robot/robot-top.png';
import robotBottom from '../../assets/puzzles/robot/robot-legs.png';
import soldierHeadDummy from '../../assets/puzzles/soldier/head-alt.png';
import snowmanBody from '../../assets/puzzles/snowman/middle.svg';
import teddyHead from '../../assets/puzzles/bear/top.png';
import robotDummyBottom from '../../assets/puzzles/robot/robot-legs-alt.png';

const Puzzle1: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TPuzzleQuestion[] = [
        {
            name: 'Toy Soldier',
            image: soldier,
            pieces: [
                {
                    image: soldierHead,
                    shadow: soldierHeadShadow,
                    position: 'top',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 3,
                        position: 'relative',
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-23px',
                        width: '145px',
                        height: '120px',
                    },
                    completed: false,
                },
                {
                    image: soldierBody,
                    shadow: soldierBodyShadow,
                    position: 'middle',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 2,
                        position: 'relative',
                        filter: 'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-32px',
                        width: '140px',
                        height: '165px',
                    },
                    completed: false,
                },
                {
                    image: soldierBottom,
                    shadow: soldierBottomShadow,
                    position: 'bottom',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 1,
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        width: '66px',
                        height: '110px',
                    },
                    completed: false,
                },
                { image: robotHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotBottom, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: soldierHeadDummy, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: snowmanBody, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotDummyBottom, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: teddyHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Puzzle gameData={gameData} onGameComplete={nextSlide}></Puzzle>
        </Slide>
    );
};

export default Puzzle1;
