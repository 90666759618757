import React from 'react';
import frame from '../assets/devices/phone-frame.svg';
import styled from 'styled-components';
import { game, size, breakpoint } from '../styles/junction';

interface IPhone {
    backgroundColor?: string;
}

const Phone: React.FC<IPhone> = ({ children, backgroundColor = '#6400aa' }) => {
    return (
            <PhoneBlock>
                <PhoneContent backgroundColor={backgroundColor}>
                    <PhoneContentScroll>{children}</PhoneContentScroll>
                    <PhoneFrame src={frame} alt="iPhone" />
                </PhoneContent>
            </PhoneBlock>
    );
};

interface PhoneContentProps {
    backgroundColor?: string;
}


const PhoneContent = styled.div<PhoneContentProps>`
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    padding-top: 179.5%;
    background: ${(props: PhoneContentProps) => props.backgroundColor};
    // padding: 5% 4% 9% 4%;
    border-radius:12vw;
    @media screen and (min-width: 500px) {
        border-radius: 54px;
    }
    overflow: hidden;
`;


const PhoneBlock = styled.div`
    max-width: 300px;
    width: 90%;
    display: block;
    margin: 0 auto;

    @media ${breakpoint.mobileL}{
        max-width: 300px;
        ${PhoneContent}{
            border-radius: 54px;
        }
    }
    @media ${breakpoint.laptop}{
        max-width: 400px;
        ${PhoneContent}{
            border-radius: 64px;
        }
    }
    @media ${breakpoint.laptopL}{
        max-width: 440px;
        ${PhoneContent}{
            border-radius: 70px
        }
    }
`;


const PhoneContentScroll = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: absolute;
    top: 0;
    padding: 5%;
`;

const PhoneFrame = styled.img`
    width: 100%;
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 3;
    top: 0;
`;

export default Phone;
