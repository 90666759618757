import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { ColourfulKitsData } from '../..';
import { t } from 'ttag';

const Intro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`Thank you ${data.name}! It is important for us to have a logo that is easy to identify. `,
            buttonText: t`Next`,
        },
        {
            character: CoachAlbert,
            text: t`Can you help us group together similar shapes to make our logo easy to spot?`,
            buttonText: t`Yes!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
