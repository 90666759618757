import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CodeCrackingData } from '../..';
import { t } from 'ttag';

const LabOutro: React.FC<ISlide<CodeCrackingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`Well done Agent ${data.name}! You have cracked all of the lines of the code and now we know the full meaning of the enemy message! We need to send it to the Prime Minister now!`,
            buttonText: t`Good idea!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default LabOutro;
