import React, { useState, useContext } from 'react';
import TileGroup from './TileGroup';
import CorrectModal from './CorrectModal';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';
import { useGameLogic, GameProps } from '../../core/Game';

export type Feature = {
    image: string;
    correct: boolean;
};

export type ShapeGroupQuestion = {
    question: string;
    answer: string;
    answers: [Feature, Feature, Feature, Feature, Feature, Feature];
};

const ShapeGroup: React.FC<GameProps<ShapeGroupQuestion>> = ({ gameData, onGameComplete }) => {
    const [show, setShow] = useState(false);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<ShapeGroupQuestion>(gameData, handleGameComplete, handleNextQuestion);

    const { volume } = useContext(VolumeContext);

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    if (question === null) {
        return null;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>

            <div className="text-center">
                <h4 className="my-3">{question.question}</h4>
            </div>
            <TileGroup tiles={question.answers} onComplete={handleQuestionComplete}></TileGroup>
        </React.Fragment>
    );
};

export default ShapeGroup;
