import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Phisherman from '../../characters/Phisherman';

const BlockUserDodgePhisher: React.FC<ISlide> = ({ defaultHeader, gotoSlide, currentIndex }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Phisherman,
            text: t`Darn. I really thought getting your details over a phone call would work, you must be smarter than I thought!`,
            textSpriteName: 'pufferfish-phisherman-1',
            buttonText: t`Next`,
        },
        {
            character: Phisherman,
            text: t`I wonder if it was the phone number or the free PS5 that gave me away...`,
            textSpriteName: 'pufferfish-phisherman-2',
            buttonText: t`Next`,
            buttonAction: () => gotoSlide(currentIndex + 3),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default BlockUserDodgePhisher;
