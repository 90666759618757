import React from 'react';
import { t } from 'ttag';
import { VariablesData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { FlubFlub, GlibGlob } from '../../characters/Aliens';

const MissionOutro: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: GlibGlob,
            text: t`Wow ${data.name}! You breezed through that mission like the target of 10 asteroids was nothing!`,
            buttonText: t`Thanks!`,
        },
        {
            character: FlubFlub,
            text: t`I’ve just had a message from Asteroid Blaster Command (ABC). The large asteroids contain a rare metal called Zinon which we can collect when we blast them.`,
            buttonText: t`Next`,
        },
        {
            character: FlubFlub,
            text: t`There are 2 lots of Zinon in each large asteroid. I need a rule to help me code the ‘Zinon’ variable on the ship’s computer so it tracks how much you’ve collected. Can you help?`,
            buttonText: t`Sure!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default MissionOutro;
