import React, { useContext, useState } from 'react';
import sfx from '../../../services/sounds';
import { VolumeContext } from '../../misc/volume-context';

interface KeypadProps {
    prefix: string;
    onSubmit: (input: string) => void;
}

const Keypad: React.FC<KeypadProps> = ({ onSubmit, prefix }) => {
    const [input, setInput] = useState('');
    const { volume } = useContext(VolumeContext);

    const inputChar = (character: string) => {
        if (input.length < 4) {
            if (volume) sfx.beep.play();
            setInput(input + character);
        }
    };

    const clearInput = () => {
        setInput('');
    };

    return (
        <React.Fragment>
            <div className="th-keypad">
                <div className="th-keypad__input">
                    <p>
                        {prefix} <span className={'th-keypad__answer'}>{input}</span>
                    </p>
                </div>
                <button className="btn th-keypad__button" onClick={() => inputChar('1')}>
                    1
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('2')}>
                    2
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('3')}>
                    3
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('4')}>
                    4
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('5')}>
                    5
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('6')}>
                    6
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('7')}>
                    7
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('8')}>
                    8
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('9')}>
                    9
                </button>
                <button className="btn th-keypad__button th-keypad__button--clear" onClick={() => clearInput()}>
                    <i className={'fas fa-times'}></i>
                </button>
                <button className="btn th-keypad__button" onClick={() => inputChar('0')}>
                    0
                </button>
                <button
                    className="btn th-keypad__button th-keypad__button--submit"
                    onClick={() => {
                        onSubmit(input);
                        clearInput();
                    }}
                >
                    <i className={'fas fa-check'}></i>
                </button>
            </div>
        </React.Fragment>
    );
};

export default Keypad;
