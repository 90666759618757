import React from 'react';
import ModalOverlay from '../../general/ModalOverlay';
import { TSortAlgorithmQuestion } from './SortAlgorithmGame';
import { Button, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const SortAlgorithmWrongModal: React.FC<{
    show: boolean;
    question: TSortAlgorithmQuestion;
    onClose: () => void;
    answersInput: { [key: string]: string };
}> = ({ show, question, onClose, answersInput }) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-times mr-2"></i> {t`INCORRECT!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    let filteredAnswers = question.question.objects
        .filter((object) => {
            return Object.values(answersInput).every((answer: string) => object.tags.includes(answer));
        })
        .map((object) => (
            <div className={`btn--feature__wrapper`}>
                <img
                    className={'ck-flags mb-3'}
                    style={{ maxWidth: '100px' }}
                    width="100px"
                    src={object.image}
                    alt={object.name}
                />
            </div>
        ));

    return (
        <ModalOverlay
            show={show}
            header={header}
            footer={<Button onClick={onClose}>{t`Continue`}</Button>}
            backgroundColor="#E73981"
        >
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <div className="island island--guide island--rounded p-4 px-md-5 mx-auto">
                        <p className={'mb-1 text-secondary'}>{question.question.text}</p>
                        <b className={'mb-3 text-secondary'}>{t`Your algorithm resulted in:`}</b>
                        {filteredAnswers.length ? filteredAnswers : <p>{t`No results`}</p>}
                    </div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default SortAlgorithmWrongModal;
