import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import escapedAnimals from './units/2-escaped-animal';
import confusedTourists from './units/3-confused-tourists';
import storm from './units/4-storm';
import goodbye from './units/6-goodbye';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type AbstractionData = {
    name: string;
    setName: (name: string) => void;
};

const Abstraction: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [StartSlide, ...intro, ...escapedAnimals, ...confusedTourists, ...storm, ...goodbye, EndSlide];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>
        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeAbstraction.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeAbstraction.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Barefoot Zoo | Barefoot Games</title>
            </Helmet>

            <Module
                devIndex={0}
                id="65ced9b5-4b72-4101-8531-a85ed7ee5e01"
                name="abstraction"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
            />
        </React.Fragment>
    );
};

export default Abstraction;
