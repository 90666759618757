import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import ribbon_right from '../../../assets/boxing/ribbon-right.svg';
import ribbon_left from '../../../assets/boxing/ribbon-left.svg';

import pattern_snowflake from '../../../assets/boxing/pattern-snow.svg';
import pattern_star from '../../../assets/boxing/pattern-star.svg';
import { TGiftColor, TGiftPattern, TGiftSize } from '../giftbuilder/GiftBuilder';

interface GiftProps {
    color: TGiftColor;
    size: TGiftSize;
    pattern?: TGiftPattern;
}

interface SquareProps {
    color: string;
    size: number;
}

const getPattern = (pattern: TGiftPattern) => {
    switch (pattern) {
        case 'snowflake': {
            return pattern_snowflake;
        }
        case 'star': {
            return pattern_star;
        }
        default: {
            return pattern_snowflake;
        }
    }
};

const Gift: React.FC<GiftProps> = ({ color, size, pattern }) => {
    return (
        <Square color={color} size={size}>
            <Stripe type={'horizontal'} />
            <Stripe type={'vertical'} />
            <RibbonRight src={ribbon_right} />
            <RibbonLeft src={ribbon_left} />
        </Square>
    );
};

const Square = styled.div<SquareProps>`
    background-color: ${(props) => (props.color ? props.color : 'red')};
    margin: 0 auto;
    display: block;
    position: relative;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    width: ${(props) => (props.size ? props.size * 10 : 50) + 20}px;
    height: ${(props) => (props.size ? props.size * 10 : 50) + 20}px;
    @media (min-width: 1000px) {
        width: ${(props) => (props.size ? props.size * 16 : 50) + 20}px;
        height: ${(props) => (props.size ? props.size * 16 : 50) + 20}px;
        border-radius: 10px;
    }
    @media (min-width: 1000px) and (min-height: 1000px) {
        width: ${(props) => (props.size ? props.size * 25 : 50) + 20}px;
        height: ${(props) => (props.size ? props.size * 25 : 50) + 20}px;
    }
`;

interface StripeProps {
    type: string;
}
const Pattern = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
    }
`;
const Stripe = styled.div<StripeProps>`
    background-color: #ffffff;
    position: absolute;
    ${(props) =>
        props.type === 'horizontal'
            ? 'width: 16%; top: 0; bottom: 0; left: 42%;'
            : 'height: 16%; left: 0; right: 0; top: 42%;'}
`;

const RibbonRight = styled.img`
    position: absolute;
    top: -20%;
    left: 58%;
    height: 20%;
`;
const RibbonLeft = styled.img`
    position: absolute;
    top: -20%;
    right: 58%;
    height: 20%;
`;

const Column = styled.div`
    width: 30%;
    display: inline-block;
`;

const Plinth = styled.div`
    background-color: #ffb950;
    bottom: -10px;
    height: 10px;
    width: calc(100% + 20px);
    left: -10px;
    position: absolute;
`;

export default Gift;
