import React from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Turtle from '../../characters/Turtle';

const TurtleIntro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`Hi, I’m Turtle the new head of the Cyber Security team in our underwater village, Kelpy Deeps. Today I am here to help you learn about phishing.`,
            textSpriteName: 'turtle-1-1',
            buttonText: t`Sure!`,
        },
        {
            character: Turtle,
            text: t`Don’t know what phishing is? Don’t worry, all will become clear soon. First, I'm going to need your nickname...`,
            textSpriteName: 'turtle-1-2',
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TurtleIntro;
