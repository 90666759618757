import React, { useContext, useState } from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import WordDiscovery from '../../../../general/WordDiscovery';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import VoiceoverContext from '../../../../misc/voiceover-context';
import Turtle from '../../characters/Turtle';

const TurtleIntro3: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [show, setShow] = useState(false);
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const { playVoiceover } = useContext(VoiceoverContext);
    const handleWordDiscovered = () => {
        setShow(true);
        playVoiceover('turtle-word-discovered');
    }
    const dialogue: DialogueData[] = [
        {
            character: Turtle,
            text: t`Excellent work! It is super important to keep your personal information safe, as criminals can use it to scam you. Cyber criminals will often try phishing... `,
            textSpriteName: 'turtle-5-1',
            buttonText: t`Phishing?`,
        },
        {
            character: Turtle,
            text: t`Yes, phishing is when cyber criminals try and trick people into giving away personal information online, such as their passwords and bank account details.`,
            textSpriteName: 'turtle-5-2',
            buttonText: t`Next`,
        },
        {
            character: Turtle,
            text: t`It is so important that we keep ourselves safe from these scams. Falling for a scam could allow a criminal to gain access to your online accounts and steal your money!`,
            textSpriteName: 'turtle-5-3',
            buttonText: t`Next`,
            buttonAction: () => handleWordDiscovered(),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Phishing`}
                wordDefinition={t`A method which cyber criminals use to try and trick people into giving away personal information online, such as their passwords and bank account details.`}
            ></WordDiscovery>

            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default TurtleIntro3;
