import React from 'react';
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import LevelSelector from '../../components/interactive/levels/LevelSelector';

const LevelTwo: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {

    const recap = t`You guided both cars safely across the bridge using a traffic light. This is one step towards solving the larger problem of making the road safer.`

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <LevelSelector level={2} nextSlide={nextSlide} recap={recap} textSpriteName={'25'} />
        </Slide>
    );
};




export default LevelTwo;
