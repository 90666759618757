import React, {useContext, useEffect} from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import CyberCard from '../../components/CyberCard';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import VoiceoverContext from '../../../../misc/voiceover-context';

const CloseAd: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        playVoiceover('endturtle-cyber-close');
    }, [])
    return (
        <Slide header={defaultHeader} footer={next}>
            <CyberCard
                title={t`Close`}
                description={t`Great idea dismissing that advert, but watch out! A pop-up was launched as you closed the advert...`}
                points={15}
                gameData={data}
                characterText={t`Be careful!`}
            />
        </Slide>
    );
};

export default CloseAd;
