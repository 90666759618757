import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Reward from 'react-rewards';
import { t } from 'ttag';
import { CyberSecurityData } from '..';
import sfx from '../../../../services/sounds';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { VolumeContext } from '../../../misc/volume-context';
import turtle from '../assets/characters/2-turtle.svg';
import pufferfish from '../assets/characters/2-pufferfish-2.svg';
import squid from '../assets/characters/2-octopus.svg';
import seal from '../assets/characters/2-fish.svg';
import whale from '../assets/characters/2-whale-bowie.svg';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import VoiceoverContext from '../../../misc/voiceover-context';

const EndSlide: React.FC<ISlide<CyberSecurityData>> = ({ onComplete, defaultHeader, data }) => {
    const footer = <Button onClick={() => {
        data.stopMusic();
        onComplete();
    }}>{t`Finish`}</Button>;
    const { volume } = useContext(VolumeContext);
    const [confetti, setConfetti] = useState<any>(null);
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        const congrats1 = setTimeout(() => playVoiceover('end-finish-1'), 1500);
        const congrats2 = setTimeout(() => playVoiceover('end-finish-2'), 3500);
        const congrats3 = setTimeout(() => playVoiceover('end-finish-3'), 11500);
        if (volume) sfx.cheer.play();
        return () => { clearTimeout(congrats1); clearTimeout(congrats2); clearTimeout(congrats3) }
    }, []);

    useEffect(() => {
        confetti?.rewardMe();
        let interval = setInterval(() => confetti?.rewardMe(), 10000);
        return () => clearInterval(interval);
    }, [confetti]);

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}

                    <Content type="island_dark" styles={'position-relative overflow-hidden my-2 p-2'}>
                        {/* <Reward ref={(ref: any) => ref ? ref.rewardMe() : null } type='confetti'/> */}
                        <Reward ref={(ref: any) => setConfetti(ref)} type="confetti">
                            <img src={data.avatar} className={`w-50 mb-3`} alt="" />

                            <div className={'character__shadow'}></div>
                        </Reward>

                        <h3>
                            {t`You have completed`}
                            <br />
                            {t`The Phisherman`}!
                        </h3>
                        <Turtle src={turtle} animate={{ x: [-20, 0], rotate: [25, 25] }} transition={{ delay: 1 }} />
                        <Squid src={squid} animate={{ x: [50, 0], opacity: [0, 1] }} transition={{ delay: 2 }} />
                        <Pufferfish
                            src={pufferfish}
                            animate={{ x: [-50, 0], y: [-50, 0], opacity: [0, 1] }}
                            transition={{ delay: 4 }}
                        />
                        <Whale
                            src={whale}
                            animate={{ x: [-50, 0], y: [-50, 0], opacity: [0, 1], rotate: [60, 60] }}
                            transition={{ delay: 5 }}
                        />
                        <Seal
                            src={seal}
                            animate={{ x: [50, 0], opacity: [0, 1], rotate: [-25, -25] }}
                            transition={{ delay: 3 }}
                        />
                    </Content>
                    <p className={'p--endshare'}>{t`Let an adult know what you’ve learnt about in The Phisherman and if they know anyone who might also enjoy it they can share it with them too! Don’t forget you should always tell an adult if you feel unsafe or sad when you are online.`}</p>
                </Col>
            </Row>
        </Slide>
    );
};

const Whale = styled(motion.img)`
    position: absolute;
    right: 25px;
    width: 140px;
    height: 120px;
    top: -25px;
    @media (max-width: 767px) {
        width: 70px;
        height: 60px;
    }
`;
const Seal = styled(motion.img)`
    position: absolute;
    right: -70px;
    width: 140px;
    height: 120px;
    bottom: 30%;
    @media (max-width: 767px) {
        width: 70px;
        height: 60px;
        right: -30px;
    }
`;

const Turtle = styled(motion.img)`
    position: absolute;
    left: -30px;
    width: 80px;
    height: 120px;
    bottom: 0;
    @media (max-width: 767px) {
        width: 60px;
        height: 80px;
    }
`;

const Pufferfish = styled(motion.img)`
    position: absolute;
    top: 10px;
    width: 80px;
    height: 80px;
    left: 5px;
    @media (max-width: 767px) {
        width: 50px;
        height: 50px;
    }
`;

const Squid = styled(motion.img)`
    position: absolute;
    right: 10px;
    width: 140px;
    height: 90px;
    bottom: -3px;
    @media (max-width: 767px) {
        width: 80px;
        height: 50px;
    }
`;

export default EndSlide;
