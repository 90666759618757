import SendingGame from './SendingGame';
import SendingGame2 from './SendingGame2';
import SendingIntro from './SendingIntro';
import SendingMission from './SendingMission';
import SendingOutro from './SendingOutro';

export default [
    SendingIntro,
    SendingMission,
    SendingGame2,
    // SendingGame,
    SendingOutro,
];
