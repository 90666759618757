import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Puzzle, { TPuzzleQuestion } from '../../components/interactive/puzzle/Puzzle';

import robotHead from '../../assets/puzzles/robot/robot-top.png';
import robotHeadShadow from '../../assets/puzzles/robot/robot-top-shadow.svg';
import robotBody from '../../assets/puzzles/robot/robot-middle.png';
import robotBodyShadow from '../../assets/puzzles/robot/robot-middle-shadow.svg';
import robotBottom from '../../assets/puzzles/robot/robot-legs.png';
import robotBottomShadow from '../../assets/puzzles/robot/robot-legs-shadow.svg';
import robot from '../../assets/puzzles/robot/robot.svg';

import soldierHead from '../../assets/puzzles/soldier/head.png';
import soldierBody from '../../assets/puzzles/soldier/torso.png';
import robotDummyLegs from '../../assets/puzzles/robot/robot-legs-alt.png';
import snowmanHead from '../../assets/puzzles/snowman/top.png';
import robotDummyHead from '../../assets/puzzles/robot/robot-top-alt.png';
import soldierBottom from '../../assets/puzzles/soldier/legs.png';

const Puzzle2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TPuzzleQuestion[] = [
        {
            name: 'Robot',
            image: robot,
            pieces: [
                {
                    image: robotHead,
                    shadow: robotHeadShadow,
                    position: 'top',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 3,
                        position: 'relative',
                        filter: 'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-2px',
                        width: '80px',
                        height: '90px',
                    },
                    completed: false,
                },
                {
                    image: robotBody,
                    shadow: robotBodyShadow,
                    position: 'middle',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 2,
                        position: 'relative',
                        filter: 'drop-shadow(0px 15px 4px rgba(0, 0, 0, 0.15)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        marginBottom: '-2px',
                        width: '150px',
                        height: '105px',
                    },
                    completed: false,
                },
                {
                    image: robotBottom,
                    shadow: robotBottomShadow,
                    position: 'bottom',
                    correct: true,
                    styles: {
                        userSelect: 'none',
                        zIndex: 1,
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        width: '77px',
                        height: '26px',
                    },
                    completed: false,
                },
                { image: soldierHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: soldierBody, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotDummyLegs, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: snowmanHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: robotDummyHead, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
                { image: soldierBottom, shadow: '', position: 'none', styles: {}, completed: false, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Puzzle gameData={gameData} onGameComplete={nextSlide}></Puzzle>
        </Slide>
    );
};

export default Puzzle2;
