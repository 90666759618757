import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { t } from 'ttag';

import room from '../../../../../assets/misc/th-room.svg';
import room2 from '../../../../../assets/misc/th-room--back.svg';
import girl_hiding from '../../../../../assets/misc/thief_girl-hiding.svg';
import boy_hiding from '../../../../../assets/misc/thief_boy-hiding.svg';
import box from '../../../../../assets/misc/th-box.svg';
import laser from '../../../../../assets/misc/th-laser.svg';

const PartTwo: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={next} backgroundColor={'#361559'}>
            <div className={`island island--rounded bg-secondary-700 th-part`}>
                <img src={room} className={'th-part__room'} width={'300px'} height={'350px'} alt="" />
                <img src={room2} className={'th-part__room2'} width={'300px'} height={'350px'} alt="" />
                <motion.img
                    src={girl_hiding}
                    className={'th-part__gh'}
                    width={'50px'}
                    height={'60px'}
                    animate={{ y: [0, -40, -40, 0] }}
                    transition={{
                        loop: Infinity,
                        repeatDelay: 8,
                        duration: 4,
                    }}
                    alt=""
                />
                <motion.img
                    src={boy_hiding}
                    className={'th-part__bh'}
                    width={'50px'}
                    height={'60px'}
                    animate={{ y: [0, -40, -40, 0] }}
                    alt=""
                    transition={{
                        loop: Infinity,
                        repeatDelay: 8,
                        duration: 4,
                        delay: 7,
                    }}
                />
                <img src={box} className={'th-part__box--bh'} width={'50px'} height={'50px'} alt="" />
                <img src={box} className={'th-part__box--gh'} width={'50px'} height={'50px'} alt="" />
                <motion.img
                    src={laser}
                    className={'th-part__laser th-part__laser--1'}
                    width={'200px'}
                    height={'30px'}
                    animate={{ rotate: [0, -40, 0] }}
                    transition={{
                        loop: Infinity,
                        duration: 7,
                    }}
                    alt=""
                />
                <motion.img
                    src={laser}
                    className={'th-part__laser th-part__laser--2'}
                    width={'200px'}
                    height={'30px'}
                    animate={{ rotate: [0, 40, 0] }}
                    transition={{
                        loop: Infinity,
                        duration: 7,
                    }}
                    alt=""
                />
                <div className="th-part__text">
                    <h4>{t`Part Two`}</h4>
                    <p>{t`Switching off the lasers`}</p>
                </div>
            </div>
        </Slide>
    );
};

export default PartTwo;
