import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import VoiceoverContext from '../../../../../misc/voiceover-context';
import { VolumeContext } from '../../../../../misc/volume-context';
import howto1 from '../../../assets/decomposer/howto1.jpg';
import howto2 from '../../../assets/decomposer/howto2.jpg';
import howto3 from '../../../assets/decomposer/howto3.jpg';
import howto4 from '../../../assets/decomposer/howto4-extra.png';
import howto5 from '../../../assets/decomposer/howto4.jpg';
import { breakpoint } from '../../../styles/junction';
import { theme } from '../../../styles/theme';
import Phone from '../../Phone';
import TrafficFrame from '../../traffic-lights/TrafficFrame';
import TrafficLightEditor from '../../traffic-lights/TrafficLightEditor';
import TrafficLightSequence, { TTrafficLightSequence } from '../../traffic-lights/TrafficLightSequence';
import Tutorial, { TTutorial } from '../Tutorial';

export type TTrafficSequenceQuestion = GameQuestion & {
    sequence: TTrafficLightSequence[];
    // answer: TTrafficLight[]
};

const TrafficSequence: React.FC<GameProps<TTrafficSequenceQuestion>> = ({
    gameData,
    index = 0,
    onGameComplete,
}) => {
    const [lightIndex, setLightIndex] = useState<number | null>(null);
    const [correct, setCorrect] = useState(0);
    const [showEditor, setShowEditor] = useState(false);
    const [tutorialShow, setTutorialShow] = useState(true);
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(1);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        onGameComplete();
    };

    const handleNextQuestion = () => {
        // Hide Modal and next Question
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TTrafficSequenceQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const tutorialClose = () => {
        setTutorialShow(false)
    }
    const slides: TTutorial[] = [
        { text: t`Look at the sequence playing on the traffic light in 'View' mode.`, image: howto1, textSpriteName: '62' },
        { text: t`Use the bottom button to switch between 'View' and 'Program' mode.`, image: howto2, textSpriteName: '63' },
        { text: t`Select a light to turn it on or off and edit each step in the sequence. `, image: howto3, textSpriteName: '64' },
        { text: t`Break down the problem by looking at each light individually.`, image: howto4, textSpriteName: '65' },
        { text: t`Make sure all the steps in the sequencer are correct before testing your program.`, image: howto5, textSpriteName: '66' },
    ]
    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    }

    const handleQuestionComplete = () => {
        // Show Modal and reset
        if (volume) sfx.correct.play();
        setCorrect(0);
        setShowEditor(false);
        setShow(true)
        setCount(count + 1);
    };

    const handleIncorrectAnswer = () => {
        // if (volume) sfx.incorrect.play();
        // onIncorrectAnswer()
    };


    const { playVoiceover, stopVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        if (show) {
            playVoiceover('71');
            return () => {
                stopVoiceover();
            }
        }

    }, [show]);

    if (!question) {
        return null;
    }

    const updateCorrect = (correct: number) => {
        setCorrect(correct);
    }


    return (
        <OverlayContainer initial={{ opacity: 0 }} animate={{ opacity: [0, 1], y: [100, 0] }}>

            <Phone backgroundColor={theme.bare_blue}>
                {show ?
                    <CorrectModal animate={{ backgroundColor: [theme.bare_blue, theme.bare_green] }} transition={{ duration: 0.5 }}>
                        <div>
                            <h3>{t`Sequence Correct!`}</h3>
                            <ToggleWrapper style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
                                {question.sequence.map((s, index) => (
                                    <Slot isActive={index === lightIndex}>
                                        <TrafficFrame green={s.green} amber={s.amber} red={s.red} isActive={index === lightIndex} />
                                    </Slot>
                                ))}
                            </ToggleWrapper>
                            <Button onClick={handleModalClose}>{t`Next`}</Button>
                        </div>
                    </CorrectModal>
                    : null}
                <Tutorial title={t`HOW TO USE`} subtitle={`-`} tutorialShow={tutorialShow} slides={slides} backgroundColor={'#0AB9F0'} handleClose={() => tutorialClose()}></Tutorial>


                <GameWrapper>
                    <Help>

                        {!showEditor ? (
                            <span>{t`Look at the sequence then program the traffic light in the 'Program' tab.`}</span>
                        ) : (
                                <><span>{correct}/{question.sequence.length}</span>{t`Steps Correct`}</>
                            )}
                    </Help>
                    <TrafficWrapper>
                        <p>
                            {t`Light`} #{count}
                        </p>
                        {showEditor ? (
                            <TrafficLightEditor updateCorrect={updateCorrect} index={lightIndex} onCorrectAnswer={handleQuestionComplete} answers={question.sequence}></TrafficLightEditor>
                        ) : (
                                <TrafficLightSequence sequence={question.sequence} loop={true} setLightIndex={setLightIndex}></TrafficLightSequence>
                            )}
                        {showEditor ? null :
                            <ToggleWrapper>
                                {question.sequence.map((s, index) => (
                                    <Slot isActive={index === lightIndex}>
                                        {/* <TrafficFrame isActive={index === lightIndex} /> */}
                                    </Slot>
                                ))}
                            </ToggleWrapper>
                        }

                    </TrafficWrapper>


                    {/* <Intro>{t`Find the correct combination of lights to match the sequence`}</Intro> */}
                    <SequenceContainer edit={showEditor}>
                        <Toggle onClick={() => setShowEditor(false)} enabled={!showEditor}><span>{t`View`}</span></Toggle>
                        <Toggler recreate={showEditor} onClick={() => setShowEditor(!showEditor)}><div></div></Toggler>
                        <Toggle onClick={() => setShowEditor(true)} enabled={showEditor}><span>{t`Program`}</span></Toggle>
                    </SequenceContainer>
                </GameWrapper>
            </Phone>
        </OverlayContainer>
    );
}
const OverlayContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    padding-top: 75px;
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

`;

const CorrectModal = styled(motion.div)`
    position: absolute;
    top: 1%;
    right: 1%;
    bottom: 1%;
    left: 1%;
    padding: 15% 10%;
    background-color: ${theme.bare_green};
    z-index: 3;
    transform-origin: bottom;
    display: flex;
    justify-content: center;
    align-items:center;
    div {
        text-align:center;
    }
`;



interface TogglerProps {
    recreate: boolean
}


const Toggler = styled.div<TogglerProps>`
    display: inline-block;
    padding-top: 10px;
    margin: 0 10px;
    cursor: pointer;
    div {
        background-color: rgba(0,0,0,0.1);
        width: 40px;
        position:relative;
        height: 10px;
        border-radius: 15px;
        &::after{
            content: '';
            position: absolute;
            ${p => p.recreate ? 'right: -7px;' : 'left:-7px;'}
            top: -7px;
            width: 24px;
            height: 24px;
            border-radius: 40px;
            background-color: #ffffff;
            clear: both;
        }
    }
`;

const Toggle = styled.p <{
    enabled: boolean
}>`
    display: inline-block;
    width: calc(50% - 40px);
    margin: 0 4px;
    color: #ffffff;
    font-size: 4.5vw;
    cursor: pointer;
    ${p => p.enabled ? 'color: #ffffff !important;' : 'color: rgba(0,0,0,0.4) !important;'}
    span {
        display: inline-block;
        ${p => p.enabled ? 'border-bottom: solid 4px rgba(255,255,255,0.6);' : ''}
    }
    @media screen and (min-width: 400px) {
        font-size: 14px;
    }
    @media ${breakpoint.mobileL}{
        font-size: 16px;
    }
    @media ${breakpoint.laptop}{
        font-size: 22px;
    }
`;

const Help = styled.p`
    margin: 10px 0;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background-color: rgba(0,0,0,0.1);
    line-height: 1.2;
    padding: 8px 12px;
    position: relative;
    @media screen and (min-width: 400px) {
        font-size: 14px;
    }

    @media ${breakpoint.mobileL}{
        font-size: 14px;
    }
    @media ${breakpoint.laptop}{
        font-size: 18px;
    }
    @media ${breakpoint.laptopL}{
        font-size: 20px;
    }
    span {
        margin: 0 8px;
    }

`;

const GameWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 12% 5%;
    overflow:hidden;
`;

interface SlotProps {
    isActive: boolean;
}

const Slot = styled.div<SlotProps>`
    width: 39px;
    height: 65px;
    padding: 3px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px;
    border: solid 2px transparent;
    transition: border-color ease-in-out 0.3s;
    ${(props) => props.isActive ? 'border-color: #ffffff;' : ''}
`;

interface SequenceProps {
    edit: boolean
}

const SequenceContainer = styled.div<SequenceProps>`
    background-color: #03A6DA;
    max-width: 400px;
    padding: 4% 1rem;
    color: #FFFFFF;
    position: absolute;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'BT Font Bold';
    border-radius: 50px;
    line-height: 20px;
    text-align: center;
    bottom: 6%;
    left: 12%;
    right: 12%;
    ${Slot} { background-color: ${p => p.edit ? theme.bare_blue_700 : 'rgba(0,0,0,0.1)'}};


`;


export const ToggleWrapper = styled.div`
    background-color: #03A6DA;
    padding: 0.5rem 1rem;
    width: 300px;
    display: block;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    bottom: 10%;
    left: calc(50% - 150px);

`;

// Traffic Light Placements

const TrafficWrapper = styled.div`
    position: relative;
    width: 44%;
    display: block;
    margin: 0 auto;
    p {
        text-align: center;
        height: 30px;
        line-height: 25px;
        z-index: 2;
        width: 150%;
        max-width: 120px;
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: -10px;
        font-size: 4vw;
        color: #ffffff;
        background-color: #09A7D8;
        opacity:0.9;
        font-family: 'BT Font Extra Bold';
        left: 50%;
        transform: translateX(-50%);
        padding: 2px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @media(min-width: 420px){
            font-size: 14px;
        }
        @media ${breakpoint.mobileL}{
            font-size: 14px;
        }
        @media ${breakpoint.laptop}{
            font-size: 16px;
        }
        @media ${breakpoint.laptopL}{
            max-width: 150px;
            font-size: 20px;
        }
    }
`;

const Intro = styled.p`
    background-color: rgba(77, 120, 134, 0.7);
    width: 100%;
    max-width: 400px;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    position: absolute;
    top: 15px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    left: 0;
    right: 0;
`;

export default TrafficSequence;
