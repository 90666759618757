import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
// Import css files
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
import { t } from 'ttag';
import VoiceoverContext from '../../../../../misc/voiceover-context';
import level_1 from '../../../assets/levels/level_1.png';
import level_2 from '../../../assets/levels/level_2.png';
import level_3 from '../../../assets/levels/level_3.png';
import level_4 from '../../../assets/levels/level_4.png';
import town_scene from '../../../assets/scene/town_scene.svg';
import town_scene_welsh from '../../../assets/scene/town_scene-welsh.svg';
import { breakpoint } from '../../../styles/junction';
import Cookies from 'js-cookie';

interface SelectorProps {
  nextSlide: () => void;
  level: number;
  recap?: JSX.Element | string;
  textSpriteName?: string;
}

const lang = Cookies.get('bfg_lang');


const LevelSelector: React.FC<SelectorProps> = ({ nextSlide, level, recap = '', textSpriteName }) => {
  const [selected, setSelected] = useState(level);
  const [selector, setSelector] = useState(false);
  const [showRecap, setShowRecap] = useState(false);
  const { playVoiceover, stopVoiceover } = useContext(VoiceoverContext);


  const next = (
    <Button className="btn-3d" onClick={() => nextSlide()}>
      {t`Next`}
    </Button>
  );

  useEffect(() => {
    if (level <= 1 || recap === '') {
      setSelector(true);
    }
    else {
      setShowRecap(true);
      playVoiceover('24');
      if (typeof textSpriteName !== 'undefined') {
        let t = setTimeout(() => {
          playVoiceover(textSpriteName as string)
        }, 3000);
        return () => {
          clearTimeout(t)
        };
      }
    }
  }, []);
  const slider = useRef<Slider>(null)

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "20px",
    ref: slider,
    variableWidth: true,
    initialSlide: (level - 1 > 0 ? level - 1 : 0),
    beforeChange: (current: number, next: number) => (setSelected(next + 1)),
  };
  const handleGoTo = () => {
    if (slider && slider.current && level > 1) {
      slider.current.slickNext()
    }
  }

  // useEffect(() => {
  //   const delay = setTimeout(() => handleGoTo(), 8000);
  //   return () => clearTimeout(delay);
  // }, []);


  const handleStart = () => {
    if (selected == level) {
      nextSlide();
    }
    else {
      return null;
    }
  }

  type TRecap = {
    text: string;
  };
  const recaps: TRecap[] = [
    { text: t`Placeholder text for slide recap.` },
    { text: t`Placeholder text for slide recap` },
    { text: t`Placeholder text for slide recap` },
    { text: t`Placeholder text for slide recap` },
  ]

  const dismissRecap = () => {
    setShowRecap(false);
    setSelector(true);
    stopVoiceover();
  }
  return (
    <TownWrapper>
      <div className="level-overlay"></div>
      {level > 1 ?
        <Banner>
          <Top animate={{ scaleY: [1, 0] }} transition={{ duration: 0.8, delay: 3 }} />
          <Bottom animate={{ scaleY: [1, 0] }} transition={{ duration: 0.8, delay: 3 }} />
          <motion.h1 animate={{ scale: [0, 1, 1, 1, 1], rotate: [-720, 0, 0, 0, 0], opacity: [1, 1, 1, 1, 0] }} transition={{ duration: 3 }}>
            {t`LEVEL COMPLETE!`}
          </motion.h1>
        </Banner>
        : null}
      { showRecap ?
        <RecapPopup>
          <h3 className="text-center">{t`Level Recap`}</h3>
          <hr />


          <p>{recap}</p>
          <Button className="btn-3d  btn-3d-dark" onClick={() => dismissRecap()}>{t`Ok`}</Button>
        </RecapPopup>
        : null}

      { selector ?
        <Levels animate={{ opacity: [0, 1] }} transition={{ delay: 0.3 }}>
          <h2 className="text-center">{t`LEVEL SELECTOR`}</h2>
          <hr />

          <StyledSlider {...settings}>
            <Level unlocked={level >= 1} complete={level > 1} selected={level == 1}>
              <LevelHeader>{t`WELCOME TO RED TOWN`}</LevelHeader>
              <LevelImgWrap>
                <LevelImg src={level_1} />
                <LevelComplete>
                  <FontAwesomeIcon icon={faCheck} />
                </LevelComplete>
              </LevelImgWrap>
            </Level>
            <Level unlocked={level >= 2} complete={level > 2} selected={level == 2}>
              <LevelHeader>{t`MORNING RUSH`}</LevelHeader>
              <LevelImgWrap>
                <LevelImg src={level_2} />
                <LevelComplete>
                  <FontAwesomeIcon icon={faCheck} />
                </LevelComplete>
              </LevelImgWrap>
            </Level>
            <Level unlocked={level >= 3} complete={level > 3} selected={level == 3}>
              <LevelHeader>{t`GOING AUTOMATIC`}</LevelHeader>
              <LevelImgWrap>
                <LevelImg src={level_3} />
                <LevelComplete>
                  <FontAwesomeIcon icon={faCheck} />
                </LevelComplete>
              </LevelImgWrap>
            </Level>
            <Level unlocked={level >= 4} complete={level > 4} selected={level == 4}>
              <LevelHeader>{t`THE END`}</LevelHeader>
              <LevelImgWrap>
                <LevelImg src={level_4} />
                <LevelComplete>
                  <FontAwesomeIcon icon={faCheck} />
                </LevelComplete>
              </LevelImgWrap>
            </Level>
          </StyledSlider>
          <div className="w-100 mt-4 mt-md-5 text-center position-relative">
            <Button disabled={selected !== level} onClick={() => handleStart()}>{t`Start`}</Button>
          </div>
        </Levels>
        : null}
    </TownWrapper>);
};
const Levels = styled(motion.div)`
  position: relative;
  width: 100%;
  padding-top: 20%;
  hr {
    margin-bottom: 0 !important;
  }
  h2 {
    font-size: 24px;
    @media ${breakpoint.mobileL}{
      font-size: 2rem;
    }
  }
  @media ${breakpoint.laptop}{
    padding-top: 0;
    hr {
      margin-bottom: 15px;
    }
  }
`;

const RecapPopup = styled.div`
  width: 100%;
  display: block;
  position: relative;
  h3, p { color: #6400AA }
  hr { border-top: solid 2px; border-color: #6400aa !important}
  padding: 2rem 1rem;
  background-color: #ffffff;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  text-align: center;
  p { text-align: justify; font-size: 18px; line-height: 1.2}
`;


const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
    .level-overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0,0,0,0.5)
    }
    hr {
      display: block;
      margin: 15px auto;
      width: 100%;
      max-width 300px;
      border-color: #ffffff;
      opacity: 0.7;
    }
`;
const StyledSlider = styled(Slider)`
  width: calc(100% + 30px);
  display: block;
  margin: 10px -15px;
  .slick-slide {
    cursor: pointer;
    &:hover {
      opacity:0.8;
    }
  }
  .slick-active {
    opacity: 1;
    &:hover {
      opacity:0.95;
    }
  }
  .slick-next, .slick-prev {
    height: 45px;
    width: 45px;
  }
  .slick-prev {
    left: 0;
    z-index: 998
  }
  .slick-next {
    right: 0;
  }
  .slick-next:before, .slick-prev:before {
    font-size: 44px;
    height: 4
  }
`;

const Banner = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  user-select: none;
  pointer-events: none;
  z-index: 999;
  overflow: hidden;
  h1 {
    transform-origin: center;
    width: 100%;
    text-align: center;
    font-size: 60px;
    font-family: 'BT Font Extra Bold';
    z-index: 999;
  }
  display: flex;
  align-items: center;

`;

const Top = styled(motion.div)`
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: top;
  background-color: #6400aa;
`;
const Bottom = styled(motion.div)`
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #6400aa;
  transform-origin: bottom;
`;


const LevelHeader = styled.h5`
  text-transform: uppercase;
  font-family: 'BT Font Extra Bold';
  letter-spacing: 0.05em;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.75rem;
  @media ${breakpoint.laptop}{
    margin-bottom: 1.5rem;
  }
`;
const LevelImg = styled.img`
  width: 100%;
  max-width: 120px;
  display: block;
  margin: 0 auto;
  @media ${breakpoint.mobileL}{
    max-width: none;
  }

`;
const LevelComplete = styled.div`
  position: absolute;
  bottom: 0;
  width: 70px;
  height: 70px;
  left: calc(50% - 35px);
  border-radius: 35px;
  border: solid #ffffff 4px;
  background-color: #FFE202;
  box-shadow: 0 4px 8px rgba(0,0,0,0.25);
  font-size: 32px !important;
  text-align: center;
  padding: 10px 10px;
`;
interface LevelProps {
  unlocked: boolean;
  complete: boolean;
  selected: boolean;
}
const Level = styled.div<LevelProps>`
  display: block;
  margin: 15px auto;
  width: 180px;
  position:relative;
  ${LevelImg} {
    width: 180px;
    ${(props) => props.unlocked ? '' : 'filter: grayscale(100%); opacity: 0.75 !important;'}
  }
  ${LevelComplete} {
    ${(props) => props.complete ? 'display: block;' : 'display: none;'}
  }
`;

const LevelImgWrap = styled.div`

`;


export default LevelSelector;
