import React, { Dispatch, SetStateAction, createContext } from 'react';

type VolContextProps = {
    volume: number;
    setVolume: Dispatch<SetStateAction<number>>;
    voiceoverMuted: boolean;
    setVoiceoverMuted: Dispatch<SetStateAction<boolean>>;
    musicMuted: boolean;
    setMusicMuted: Dispatch<SetStateAction<boolean>>;
};

export const VolumeContext = createContext<Partial<VolContextProps>>({
    volume: 0,
    voiceoverMuted: false,
    musicMuted: false,
});

// set the defaults
// export const VolumeContext = React.createContext({
//     volume: 0.5,
//     setVolume: () => {}
// });

export default VolumeContext;
