import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import FeatureMatch, { FeatureMatchQuestion } from '../../../../interactive/featurematch/FeatureMatch';
import elephant from '../../../../../assets/animals/elephant.svg';
import monkey from '../../../../../assets/animals/monkey.svg';
import penguin from '../../../../../assets/animals/penguin.svg';
import frog from '../../../../../assets/animals/frog.svg';
import { jt, t } from 'ttag';

const ConfusedTourists: React.FC<ISlide> = ({ nextSlide }) => {
    const gameData: FeatureMatchQuestion[] = [
        {
            subject: {
                name: t`Penguin`,
                image: penguin,
            },
            features: [
                {
                    name: t`legs`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`orange beak`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`alive`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`black & white`,
                    correct: true,
                    reason: ``,
                },
                { name: t`bird`, correct: false, reason: `` },
                {
                    name: t`webbed feet`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`flippers`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`nose`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`eyes`,
                    correct: false,
                    reason: ``,
                },
            ],
        },
        {
            subject: {
                name: t`Elephant`,
                image: elephant,
            },
            features: [
                {
                    name: t`trunk`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`tusks`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`big ears`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`ears`,
                    correct: false,
                    reason: ``,
                },
                { name: t`legs`, correct: false, reason: `` },
                {
                    name: t`mouth`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`eyes`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`very large`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`feet`,
                    correct: false,
                    reason: ``,
                },
            ],
        },
        {
            subject: {
                name: t`Monkey`,
                image: monkey,
            },
            features: [
                {
                    name: t`legs`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`curly tail`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`mouth`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`ears`,
                    correct: false,
                    reason: ``,
                },
                { name: t`two hands`, correct: true, reason: `` },
                {
                    name: t`nose`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`eyes`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`climbs trees`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`thumbs`,
                    correct: true,
                    reason: ``,
                },
            ],
        },
        {
            subject: {
                name: t`Frog`,
                image: frog,
            },
            features: [
                {
                    name: t`slimy`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`mouth`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`legs`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`heart`,
                    correct: false,
                    reason: ``,
                },
                { name: t`swims`, correct: true, reason: `` },
                {
                    name: t`webbed feet`,
                    correct: true,
                    reason: ``,
                },
                {
                    name: t`nose`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`eyes`,
                    correct: false,
                    reason: ``,
                },
                {
                    name: t`hops`,
                    correct: true,
                    reason: ``,
                },
            ],
        },
    ];

    let title = <span className="font-bt-400">{t`Help the Tourists`}</span>;
    const header = <h3>{jt`Mission 2: ${title}`}</h3>;
    // make the game handle the footer?
    // const [footer, setFooter] = useState<JSX.Element | null>(null)
    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <FeatureMatch gameData={gameData} onGameComplete={nextSlide}></FeatureMatch>
        </Slide>
    );
};

export default ConfusedTourists;
