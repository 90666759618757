import React from 'react';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import Slide, { ISlide } from '../../../../core/Slide';
import { AbstractionData } from '../../';
import { t } from 'ttag';

const ZooKeeperOutro: React.FC<ISlide<AbstractionData>> = ({ nextSlide, data, defaultHeader }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: RangerJack,
            text: t`Thanks so much ${data.name}. 
            So the issue with the zoo is that my animals are so naughty and keep escaping!`,
            buttonText: t`Oh no!`,
        },
        {
            character: RangerJack,
            text: t`To get you started, your first mission is to try and find out which of my animals has gone missing! 
            I think it had a tail... a mane... paws... and big teeth?`,
            buttonText: t`I can help!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue buttonHandler={buttonRenderer} dialogue={dialogue} />
        </Slide>
    );
};

export default ZooKeeperOutro;
