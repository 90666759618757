import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/engineer-2.png';
import { t } from 'ttag';

const EngineerAisha: TCharacter = {
    characterName: t`Engineer Aisha`,
    characterImage: image,
    characterWidth: 90,
    characterHeight: 180,
};

export default EngineerAisha;
