import React, { useState, useEffect, useContext } from 'react';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import styled from 'styled-components';
import card_answer from '../../../assets/end/card_answer.jpg';
import card_phish from '../../../assets/end/card_phish.svg';
import phisherman_head from '../../../assets/characters/phisherman-head.svg';
import phisherman from '../../../assets/characters/phisherman.svg';
import turtle from '../../../assets/characters/2-turtle.svg';
import { motion, useAnimation } from 'framer-motion';
import { CyberSecurityData } from '../../..';
import ModalOverlay from '../../../../../general/ModalOverlay';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import toaster from 'toasted-notes';

import slide_1 from '../../../assets/end/slide_1.svg';
import slide_2 from '../../../assets/end/slide_2.svg';
import slide_3 from '../../../assets/end/slide_3.svg';
import slide_4 from '../../../assets/end/slide_4.svg';
import sfx from '../../../../../../services/sounds';

import VolumeContext from '../../../../../misc/volume-context';
import VoiceoverContext from '../../../../../misc/voiceover-context';


export type TPhishingQuestion = GameQuestion & {
    text: string;
    textSpriteName: string;
    points: number;
    answers: TPhishingAnswer[];
    answered?: boolean;
};

type TPhishingAnswer = {
    text: string;
    textSpriteName: string;
    points: number;
};

interface IPhishing {
    data: CyberSecurityData;
    onGameFailed: () => void;
}

const ResultStates = ['selected', 'won', 'lost'];

const PhishStates = ['won', 'lost'];

interface IToast {
    correct: boolean;
}

const Phishing: React.FC<GameProps<TPhishingQuestion> & IPhishing> = ({
    gameData,
    data,
    index = 0,
    onGameComplete,
    onGameFailed,
}) => {

    const { volume } = useContext(VolumeContext);

    const [tutorialShow, setTutorialShow] = useState(true);
    const [tutorialIndex, setTutorialIndex] = useState(0);
    const [count, setCount] = useState(1);

    const { playVoiceover, stopVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        if (tutorialShow === true) {
            playVoiceover('end-howto-'+(tutorialIndex + 1));
        }

    }, [tutorialIndex, tutorialShow]);

    const [correct, setCorrect] = useState(false);

    const [selected, setSelected] = useState<TPhishingAnswer | null>(null);
    const [correctAnswer, setCorrectAnswer] = useState<TPhishingAnswer | null>(null);

    const anim_r_player = useAnimation();
    const anim_r_phisher = useAnimation();
    const anim_popup = useAnimation();
    const [phisherResult, setPhisherResult] = useState(0);
    const [playerResult, setPlayerResult] = useState(0);

    const [phisherHealth, setPhisherHealth] = useState(6);
    const [playerHealth, setPlayerHealth] = useState(8);

    const [failed, setFailed] = useState(false);

    const [result, setResult] = useState(0);
    const [phish, setPhish] = useState(-1);

    const handleNextQuestion = () => {
        stopVoiceover();
        setSelected(null);
    };

    const handleGameComplete = () => {
        if (volume) sfx.synthSuccess.play();
        setTimeout(onGameComplete, 1000);
    };

    const handleGameFailed = () => {
        if (volume) sfx.synthFail.play();
        setTimeout(() => setFailed(true), 500);
    };

    const [question, nextQuestion] = useGameLogic<TPhishingQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    useEffect(() => {
        if (!tutorialShow && question) {
            playVoiceover(question.textSpriteName);
        }
    }, [question?.textSpriteName, tutorialShow]);

    useEffect(() => {
        if (!question) {
            return;
        }

        let highest = question.answers.reduce((answer, highestAnswer) => {
            if (!highestAnswer) {
                return answer;
            }

            if (answer.points > highestAnswer.points) {
                return answer;
            }

            return highestAnswer;
        });

        setCorrectAnswer(highest);
    }, [question]);

    const handleSelect = (answer: TPhishingAnswer) => {
        stopVoiceover();
        // console.log('on select', answer, answer.points, question.points)

        // select card
        question.answered = true;
        setSelected(answer);

        // check for higher answers (in a useeffect?)
        if (question.points < answer.points) {
            handleCorrect(answer);
        } else {
            handleIncorrect(answer);
        }

        // next question
    };

    const handleCorrect = (answer: TPhishingAnswer) => {
        setPhish(1);
        // toaster.notify(t`Nice work! That's a great response.`, {
        //     duration: 2000,
        //     position: 'center',
        // });
        animateCorrect(answer);
        // find difference in points

        setTimeout(() => {
            setPhish(0);
            setSelected(null);

            nextQuestion();
        }, 1500);
    };

    const handleIncorrect = (answer: TPhishingAnswer) => {
        animateIncorrect(answer);
        setTimeout(() => {
            setSelected(null);

            nextQuestion();
        }, 1500);
    };

    async function animateCorrect(answer: TPhishingAnswer) {
        setPhisherResult(question.points - answer.points);
        if (volume) sfx.correct.play();
        setTimeout(() => playVoiceover('sound-7'), 300);
        setTimeout(() => setCount(Math.random()), 3000);
        setCorrect(true);
        await anim_popup.start('show');
        await anim_r_phisher.start('show');
        if (phisherHealth - (answer.points - question.points) <= 0) {
            setPhisherHealth(0);
            handleGameComplete();
        } else {
            setPhisherHealth(phisherHealth - (answer.points - question.points));
        }
        await anim_popup.start('hidden');
        await anim_r_phisher.start('hidden');
    }

    async function animateIncorrect(answer: TPhishingAnswer) {
        setPlayerResult(answer.points - question.points);
        if (volume) sfx.incorrect.play();
        setTimeout(() => playVoiceover('sound-1'), 300);
        setTimeout(() => setCount(Math.random()), 3000);
        setCorrect(false);
        await anim_popup.start('show');
        await anim_r_player.start('show');
        if (playerHealth - (question.points - answer.points) <= 0) {
            setPlayerHealth(0);
            // Loss
            handleGameFailed();
        } else {
            setPlayerHealth(playerHealth - (question.points - answer.points));
        }
        await anim_popup.start('hidden');
        await anim_r_player.start('hidden');
    }

    if (!question) {
        return null;
    }
    const popUpVariants = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2,
                delay: 2,
            },
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.2,
            },
        },
    };
    const resultVariants = {
        hidden: {
            opacity: 0,
            y: -50,
            scaleX: 0,
            transition: {
                delay: 1.5,
            },
        },
        show: {
            scaleX: 1,
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.5,
            },
        },
    };

    // Tutorial slides

    const getSlide = (index: number) => {
        switch (index) {
            case 0: {
                return slide_1;
            }
            case 1: {
                return slide_2;
            }
            case 2: {
                return slide_3;
            }
            case 3: {
                return slide_4;
            }
            default: {
                return slide_1;
            }
        }
    };

    const getDescription = (index: number) => {
        switch (index) {
            case 0: {
                return t`The Phisherman will try to phish you through different scenarios.`;
            }
            case 1: {
                return t`Pick a suitable response card to avoid getting phished.`;
            }
            case 2: {
                return t`Be careful, a poor response will damage your health!`;
            }
            case 3: {
                return t`Choose the right answers to defeat the Phisherman once and for all!`;
            }
            default: {
                return t`The Phisherman will try to phish you through different scenarios.`;
            }
        }
    };
    return (
        <>
            <GameWrapper>
                <ModalOverlay
                    show={failed}
                    header={
                        <h2 className="text-center w-100">
                            <i className="fa fa-skull mr-2"></i>{ t`Not Quite!` }
                        </h2>
                    }
                    footer={
                        <Button
                            onClick={() => {
                                setTimeout(onGameFailed, 0);
                                setFailed(false);
                            }}
                        >{t`Try again`}</Button>
                    }
                >
                    <>
                        <img src={phisherman_head} className="mx-auto d-block" width="100" />
                        <h3 className="text-center">"{t`Aha! I got you!`}"</h3>
                        <p
                            className="w-100 mx-auto text-center"
                            style={{ maxWidth: '400px' }}
                        >{t`You didn't manage to defeat the Phisherman! Try battling him again, make sure you read all of the options!`}</p>
                    </>
                </ModalOverlay>
                <PopUp correct={correct} animate={anim_popup} variants={popUpVariants}>
                    {correct ? (
                        <>
                            <h3>{ t`Nice work!` }</h3>
                            <p>{ t`You picked a great response and dealt some damage to the Phisherman.` }</p>
                            <Character src={turtle} />
                        </>
                    ) : (
                        <>
                            <h3>{ t`Oh no!` }</h3>
                            <p>{ t`You picked a poor response to the scam and lost some health!` }</p>
                            <Character src={turtle} />
                        </>
                    )}
                </PopUp>
                <Score>
                    <Player>
                        <Avatar>
                            <img src={data.avatar} />
                        </Avatar>
                        <Result animate={anim_r_player} variants={resultVariants} char="player">
                            <i className="fa fa-heart mr-2"></i> {playerResult}
                        </Result>
                        <Progress>
                            <ProgressLevel progress={(playerHealth / 8) * 100} />
                        </Progress>
                    </Player>
                    <Phisherman>
                        <Progress>
                            <ProgressLevel progress={(phisherHealth / 6) * 100} />
                        </Progress>
                        <Result animate={anim_r_phisher} variants={resultVariants} char="phisherman">
                            <i className="fa fa-heart mr-2"></i> {phisherResult}
                        </Result>
                        <Avatar>
                            <img src={phisherman_head} />
                        </Avatar>
                    </Phisherman>
                </Score>
                <Top>
                    <Question
                        className={phish === 1 ? 'card_lost' : ''}
                    >
                        <img src={card_phish} width="180" />
                        <h4 style={{opacity: count > 0.5  ? 1 : 0.99 }}>{question.text}</h4>
                        <Value>{question.answered ? question.points : ''}</Value>
                    </Question>
                </Top>
                <ScrollHelp>
                    <i className="fa fa-arrow-left mr-2"></i> {t`Scroll and select an action`}{' '}
                    <i className="ml-2 fa fa-arrow-right"></i>
                </ScrollHelp>
                <Bottom>
                    {question.answers.map((answer, index) => (
                        <AnswerWrapper  custom={index}
                        variants={AnswerVariants}
                        initial={'init'}
                        animate={selected?.text === answer.text ? ResultStates[result] : 'visible'}
                        >
                            <Answer
                                className={
                                    selected?.text === answer.text && answer.points <= question.points
                                        ? 'card_lost'
                                        : 'card_won'
                                }
                                key={index + 'answer'}
                                onClick={() => (!question.answered ? handleSelect(answer) : undefined)}
                            >
                                <img src={card_answer} width="160" />
                                <span>{answer.text}</span>
                                <Value>{selected?.text === answer.text ? answer.points : ''}</Value>
                            </Answer>
                            <AudioWrapper>
                                <AnswerAudio onClick={() => playVoiceover(answer.textSpriteName)}><i className="fa fa-volume-up"></i></AnswerAudio>
                            </AudioWrapper>
                        </AnswerWrapper>
                    ))}
                </Bottom>
                <Tutorial tutorialShow={tutorialShow}>
                    <TutorialCard
                        animate={{ scale: [0, 1], opacity: [0, 1] }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                    >
                        <h5 className="mb-0">{t`FINAL BATTLE`}</h5>
                        <h3>{t`HOW TO PLAY`}</h3>
                        <hr />
                        <TutorialSlides>
                            <SlideArrow
                                left={true}
                                end={tutorialIndex <= 0}
                                onClick={tutorialIndex - 1 > -1 ? () => setTutorialIndex(tutorialIndex - 1) : undefined}
                            >
                                <i className="fa fa-arrow-left"></i>
                            </SlideArrow>
                            <SlideImage src={getSlide(tutorialIndex)} />
                            <SlideArrow
                                left={false}
                                end={tutorialIndex >= 3}
                                onClick={tutorialIndex + 1 < 4 ? () => setTutorialIndex(tutorialIndex + 1) : undefined}
                            >
                                <i className="fa fa-arrow-right"></i>
                            </SlideArrow>
                        </TutorialSlides>
                        <p>{getDescription(tutorialIndex)}</p>
                        {tutorialIndex < 3 ? (
                            <TutorialProgress>
                                <ProgressLevel progress={(tutorialIndex / 3) * 100} />
                            </TutorialProgress>
                        ) : (
                            <Button className="my-2" onClick={() => setTutorialShow(false)}>
                                { t`Finish` }
                            </Button>
                        )}
                    </TutorialCard>
                </Tutorial>
                <StyledHint
                    className={'game__howto'}
                >{t`Play the best response card in each scenario to beat the Phisherman!`}</StyledHint>
            </GameWrapper>
        </>
    );
};

const AnswerWrapper = styled(motion.div)`
    display: inline-block;
`;
const AudioWrapper = styled.div`
    width: 100%;
    text-align: center;
    line-height: 0;
    padding-bottom: 3px;
`;

const AnswerAudio = styled(Button)`
    border-top-left-radius: 0;
    border-top-right-radius:0;
    padding: 2px 15px;
    display: inline-block;
    line-height:14px;
    background-color: #6400aa;
    i { font-size: 14px; color: #ffffff;}
    &:hover {
        i { color: #6400aa;}
    }
`;

interface PopUpProps {
    correct: boolean;
}

const PopUp = styled(motion.div)<PopUpProps>`
    pointer-events: none;
    user-select: none;
    position: fixed;
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    left: 50%;
    z-index: 999;
    padding: 20px;
    border-radius: 5px;
    top: 50%;
    box-shadow: 0 0 600px 50px rgba(0, 0, 0, 0.85);
    transform: translateY(-50%) translateX(-50%);
    background-color: ${(props) => (props.correct ? '#00C3A5' : '#E60050')};
`;

const StyledHint = styled.p`
    @media (max-height: 815px) {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
        width: 100%;
        max-width: 475px;
        font-size: 15px;
        text-align: center;
        padding-bottom: 5px;
        pointer-events: none;
        user-select: none;
        background-color: rgba(0, 0, 0, 0.7);
    }
`;

const Character = styled.img`
    position: absolute;
    z-index: -1;
    width: 50px;
    right: 5px;
    top: -50px;
`;

interface ProgressProps {
    progress: number;
}
interface ArrowProps {
    left: boolean;
    end: boolean;
}
const SlideArrow = styled.button<ArrowProps>`
    ${(props) => (props.left ? 'left: 5px;' : 'right: 5px;')}
    position: absolute;
    top: calc(50% - 25px);
    background-color: #ffffff;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 0 !important;
    ${(props) => (props.end ? 'opacity: 0.3;' : null)}
`;

const SlideImage = styled.img`
    height: 120px;
    width: 120px;
    margin: 0 auto;
`;

const TutorialSlides = styled.div`
    width: 100%;
    position: relative;
    height: 120px;
    margin-bottom: 10px;
`;

const TutorialProgress = styled.div`
    position: relative;
    width: 100%;
    height: 10px;
    max-width: 100px;
    border-radius: 5px;
    display: block;
    margin: 32px auto;
    background-color: #b80040;
    overflow: hidden;
`;

const TutorialCard = styled(motion.div)`
    background-color: #e60050;
    padding: 1rem;
    text-align: center;
    width: 100%;
    max-width: 350px;
    transition: height 0.5s ease-in-out;
    h5 {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: 0.875rem;
        font-family: 'BT Font Extra Bold';
    }
    p {
        line-height: 20px;
        margin-bottom: 0;
    }
`;

interface TutorialProps {
    tutorialShow: boolean;
}
const Tutorial = styled(motion.div)<TutorialProps>`
    position: fixed;
    display: none;
    ${(props) => (props.tutorialShow ? 'display: flex;' : null)}
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding-top: 75px;
    p {
        font-size: 16px;
        margin-bottom: 5px;
    }
`;

interface ResultProps {
    char: string;
}

const Result = styled(motion.div)<ResultProps>`
    position: absolute;
    height: 50px;
    bottom: -60px;
    color: #ffffff;
    padding: 10px;
    z-index: -1;
    border-radius: 5px;
    background-color: ${(props) => (props.char === 'player' ? '#61BAAD' : '#DC2D00')};
    ${(props) => (props.char === 'player' ? 'left:' : 'right:')} 5px;
`;

const QuestionVariants = {
    init: {
        rotate: -10,
        y: -45,
        opacity: 0,
    },
    visible: {
        y: 0,
        rotate: -15,
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.6,
        },
    },
    won: {
        scale: 1.025,
        opacity: 0.99,
    },
    lost: {
        y: -5,
        rotate: 5,
        scale: 0.95,
        opacity: 0.99,
    },
};

const AnswerVariants = {
    init: {
        y: 10,
        opacity: 0,
    },
    visible: (i: number) => ({
        y: 0,
        opacity: 1,
        transition: {
            delay: 1.25 + i * 0.4,
            duration: 0.4,
        },
    }),
    hover: {
        y: -5,
        opacity: 0.9,
    },
    selected: {
        y: -13,
        opacity: 1,
    },
    won: {
        y: -16,
        opacity: 1,
    },
    lost: {
        y: 0,
        rotate: 5,
        opacity: 1,
        scale: 0.95,
    },
};

const ScrollHelp = styled.div`
    width: 100%;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
    display: block;
    margin-bottom: 10px;
    @media (min-width: 576px) {
        display: none;
    }
`;

const Avatar = styled.div`
    border-radius: 100px;
    overflow: hidden;
    width: 40px;
    flex-shrink: 0;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    img {
        width: 200%;
        margin-top: -50%;
        margin-left: -50%;
    }
`;

const Progress = styled.div`
    height: 10px;
    width: 100%;
    margin: 0 7px;
    border-radius: 5px;
    background-color: #6400aa;
    border-radius: 5px;
    overflow: hidden;
`;
const ProgressLevel = styled.div<ProgressProps>`
    height: 10px;
    width: ${(props: ProgressProps) => (props.progress >= 0 ? props.progress : 0)}%;
    background-color: #ffffff;
    transition: width 1s ease-in-out;
`;
const Player = styled.div`
    display: flex;
    align-items: center;
    ${ProgressLevel} {
        background-color: #61baad;
    }
`;
const Phisherman = styled.div`
    display: flex;
    align-items: center;
    ${ProgressLevel} {
        background-color: #dc2d00;
    }
`;

const Score = styled.div`
    height: 60px;
    padding: 10px;
    max-width: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    z-index: 77;
    margin: 0 auto;
    background-color: #2a0b4a;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    ${Player}, ${Phisherman} {
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 50%;
        margin: 0 12px;
    }
`;

const GameWrapper = styled.div`
    text-align: center;
    position: fixed;
    top: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    .card_lost img {
        filter: grayscale(100%);
    }
`;

const Top = styled.div``;
const Bottom = styled.div`
    text-align: center;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    padding-top: 10px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media (min-width: 768px) {
        -ms-overflow-style: none;
    }
    @media (max-height: 815px) {
        padding-bottom: 50px;
    }
`;
const Question = styled(motion.div)`
    position: relative;
    display: inline-block;
    border: 0;
    padding: 0;
    background-color: transparent;
    transform: rotate(-14deg);
    margin-bottom: 2rem;
    h4 {
        position: absolute;
        color: white;
        top: 30%;
        left: 6%;
        right: 17%;
        min-width: 150px;
        bottom: 4%;
        margin-bottom: 0;
        text-align: left;
        line-height: 1.2;
        font-size: 0.875rem;
        font-family: 'BT Font Bold';
    }
    img {
        width: 100%;
        max-width: 200px;
    }
`;

const Value = styled(motion.div)`
    position: absolute;
    left: 6%;
    bottom: 1.5%;
    color: white;
    font-family: 'BT Font Extra Bold';
    font-size: 1.5rem;
`;

const Answer = styled(motion.button)`
    white-space: normal;
    position: relative;
    border: 0;
    padding: 0;
    background-color: transparent;
    transition: transform 0.5s easeInOut;
    margin: 0.5rem 0.5rem 0 0.5rem;
    &:focus,
    &:hover {
        outline-width: 1px;
        outline-color: #fff;
        outline-style: ridge;
    }
    span {
        color: white;
        position: absolute;
        top: 4%;
        left: 7%;
        right: 4%;
        bottom: 12%;
        line-height: 1.2;
        margin-bottom: 0;
        text-align: left;
        font-size: 1rem;
        font-family: 'BT Font Bold';
    }
`;
export default Phishing;
