import React from 'react';
import styled from 'styled-components';
import DialogueGroup, { DialogGroupProps } from '../../../general/DialogueGroup';
import useFooterButtonRenderer from '../../../misc/FooterButtonRenderer';
import image from '../assets/characters/engineer-2.png';
import Character2 from '../components/Character2';
import { ICharacter } from '../../../../components/general/Character';

export const DialogueOverlay: React.FC<ICharacter> & React.FC<DialogGroupProps> = (props: any) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    return (
        <Layout>
            <Content>
                <DialogueGroup {...props} type={true} Wrapper={Character2} buttonHandler={buttonRenderer} />

                <ButtonWrapper>{footer}</ButtonWrapper>
            </Content>
        </Layout>
    );
}



const ButtonWrapper = styled.div`
    min-height: 60px;
    .btn-3d {
        background-color: #6400aa;
        color: #ffffff !important;
        &:hover, &:active, &:focus {
            background-color: #540e85 !important;
            color: #DDDDDD !important;
        }
    }
`;

const Content = styled.div`
    position:relative;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 1rem;
    background-color: #ffffff;
    @media (min-width: 576px){
        border-radius: 5px;
    }
    min-height: 136px;
`;


const Layout = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    .island {
        background-color: #EAF4F7;
        padding: 6px 12px !important;
        width: 100%;
        margin: 0 0 10px !important;
        font-size: 18px;
        line-height: 24px;
        min-height: auto;
        box-shadow: none;
        &::after{
            content: '';
            position: absolute;
            left: 8px;
            top: -12px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #EAF4F7;
            clear: both;
        }
    }
    .island__author--dialogue {
        font-size: 12px;
        top: -55px;
        left: 50px;
        padding: 2px 8px;
        letter-spacing: 0.05em;
        border-radius: 5px;
    }
    @media (min-width: 576px){
        bottom: 10px;
        .island {
            border-radius: 5px !important;
            margin-bottom: 10px !important;
        }
        ${Content} {
            max-width: 400px;
        }
    }
    @media (min-width: 1200px){
        .island {
            font-size: 20px;
        }
        ${Content} {
            max-width: 500px;
        }
    }
`;


export default DialogueOverlay;
