import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';

const BletchleyIntelQuiz: React.FC<ISlide> = ({ defaultHeader, nextSlide, gotoSlide, currentIndex }) => {
    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`When did World War II start?`,
            answers: [
                { text: `1941`, correct: false },
                { text: `1932`, correct: false },
                { text: `1939`, correct: true },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Row>
                <Col xs={'12'} className={'cc-container'}>
                    <h4 className={'cc-quiz__heading'}>{t`REPORT BACK`}</h4>
                    <Content type={'island'} styles={'p-3 cc-quiz__island'}>
                        <MultipleChoice
                            gameData={gameData}
                            onGameComplete={nextSlide}
                            onIncorrectAnswer={() => gotoSlide(currentIndex - 1)}
                        ></MultipleChoice>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default BletchleyIntelQuiz;
