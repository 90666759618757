import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import sfx from '../../services/sounds';
import { VolumeContext } from '../misc/volume-context';
import Dialogue, { DialogueData } from './Dialogue';

export interface DialogGroupProps {
    buttonHandler: (button: JSX.Element | null) => void;
    Wrapper?: React.FC;
    index?: number;
    dialogue: Array<DialogueData>;
    type?: boolean;
    nextDialogueHandler?: (next: () => void) => void
}

const DialogueGroup: React.FC<DialogGroupProps> = ({
    dialogue,
    index = 0,
    buttonHandler,
    Wrapper = React.Fragment,
    type = false,
    nextDialogueHandler = null,
}) => {
    const [dialogueIndex, setDialogueIndex] = useState(index);
    const [dialogueData, setDialogueData] = useState(dialogue);
    const [activeDialogue, setActiveDialogue] = useState<JSX.Element | null>(null);
    const { volume } = useContext(VolumeContext);

    useEffect(() => {
        setDialogueIndex(index);
    }, [index]);

    useEffect(() => {
        setDialogueData(dialogue);
    }, [dialogue]);

    useEffect(() => {
        setActiveDialogue(renderDialogue(dialogueIndex));
    }, [dialogueIndex, dialogueData]);

    const nextDialogue = () => {
        setActiveDialogue(null);
        setDialogueIndex(dialogueIndex + 1);
    };

    useEffect(() => {
        if (typeof nextDialogueHandler === 'function') {
            nextDialogueHandler(() => nextDialogue);
        }
    }, [dialogueIndex]);

    //not going to work because we need the data for the character wrapper
    const renderDialogue = (index: number) => {
        const data = dialogueData[index];

        if (!data) {
            return <p>Error rendering dialogue (NOdata)</p>;
        }

        return (
            <Dialogue
                Wrapper={Wrapper}
                onTypingDone={() => {
                    const button = generateButton(data.buttonText, data.buttonAction);
                    buttonHandler(button);
                }}
                data={data}
                type={type}
            />
        );
    };

    const generateButton = (text: string, action = nextDialogue) => (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Button
                className="btn-3d"
                size="sm"
                onClick={() => {
                    buttonHandler(null);
                    if (volume) sfx.click.play();
                    action();
                }}
            >
                {text}
            </Button>
        </motion.div>
    );

    if (!activeDialogue) {
        return null;
    }

    return activeDialogue;
};

export default DialogueGroup;
