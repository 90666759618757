import React, {useContext, useEffect} from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Col, Row, Container } from 'react-bootstrap';
import Phone from '../../components/Phone';
import styled from 'styled-components';
import seal_head from '../../assets/characters/fish-head.svg';
import unknown_tiktok from '../../assets/characters/unknown-tiktok.jpg';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const TikTokMessages: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('fish-messages');
    }, [])

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Phone backgroundColor={'#E1DEF9'}>
                <StyledContainer>
                    <PhoneHeader>
                        <PhoneHeaderTitle>{t`NikNak Messages`}</PhoneHeaderTitle>
                    </PhoneHeader>
                    <PhoneContent>
                        <Message read={false} className={'align-items-center'} onClick={() => nextSlide()}>
                            <Col xs={'3'} className={'pr-0'}>
                                <SealCircle src={unknown_tiktok} alt="Seal's Profile Picture" width={'100'} />
                            </Col>
                            <Col xs={'9'}>
                                <h4 className={'mb-0'}>{t`Hi there! Download the new version of NikNak!`}</h4>
                            </Col>
                        </Message>
                        <Message read={true} className={'align-items-center'}>
                            <Col xs={'3'} className={'pr-0'}>
                                <SealCircle src={seal_head} alt="Seal's Profile Picture" width={'100'} />
                            </Col>
                            <Col xs={'9'}>
                                <h4 className={'mb-0'}>{t`You have a new friend! You can now message them.`}</h4>
                            </Col>
                        </Message>
                    </PhoneContent>
                </StyledContainer>
            </Phone>
        </Slide>
    );
};

interface MessageProps {
    read: boolean;
}

const Message = styled(Row)<MessageProps>`
    padding: 2%;
    color: ${(props: MessageProps) => (props.read ? '#b1afe1' : '#6400aa')};
    background-color: ${(props: MessageProps) => (props.read ? 'transparent' : '#d6d1ff')};
    ${(props: MessageProps) => (props.read ? null : 'cursor: pointer; &:hover{opacity: 0.7}')}
    h4 {
        @media screen and (max-width: 767px) {
            font-size: 4vw;
        }
        font-family: ${(props: MessageProps) => (props.read ? 'BT Font Regular' : 'BT Font Bold')};
    }
`;

const StyledContainer = styled(Container)`
    min-height: 100%;
    display: flex;
    flex-flow: column;
    padding: 0;
`;

const SealCircle = styled.img`
    width: 100%;
    border-radius: 1000px;
    overflow: hidden;
`;

const PhoneHeaderTitle = styled.p`
    align-self: flex-end;
    margin: 0;
`;
const App = styled.img`
    width: 100%;
    padding-left: 10%;
    display: block;
`;

const PhoneContent = styled.div`
    flex: 1;
    padding: 5% 5% 3% 5%;
    display: flex;
    flex-flow: column;
`;

const TikTok = styled.div`
    display: inline-block;
    margin: 2%;
    width: 28%;
    img {
        width: 100%;
    }
`;

const PhoneHeader = styled.div`
    width: 100%;
    background-color: #6400aa;
    padding: 10% 5% 3% 5%;
    flex-shrink: 0;
`;

const PhoneFooter = styled.div`
    height: 13%;
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    @media screen and (min-width: 1000px) {
        display: block;
    }
    overflow: hidden;
`;

export default TikTokMessages;
