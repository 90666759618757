import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const PersonalDetails: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-personal-1')
    }, []);

    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <div className="text-center">
                <h2>{t`Personal Information`}</h2>
                <p>{t`You need to keep your online details safe!`}</p>
                <Button onClick={nextSlide}>{t`Generate Details`}</Button>
            </div>
        </Slide>
    );
};

export default PersonalDetails;
