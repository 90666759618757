import UserPhished from './UserPhished';
import PufferfishIntro from './PufferfishIntro';
import PufferfishIntro2 from './PufferfishIntro2';
import PufferfishOutro from './PufferfishOutro';
import TurtleQuestion from './TurtleQuestion';
import CallInbound from './CallInbound';
import UnknownCall from './UnknownCall';
import UserDodge from './UserDodge';
import UserDodgePhisher from './UserDodgePhisher';
import UserPhishedPhisher from './UserPhishedPhisher';
import WhaleIsland from '../3-fishfood/WhaleIsland';

export default [
    WhaleIsland,
    PufferfishIntro,
    PufferfishIntro2,
    CallInbound,
    UnknownCall,
    UserDodge,
    UserDodgePhisher,
    UserPhished,
    UserPhishedPhisher,
    TurtleQuestion,
    PufferfishOutro,
];
