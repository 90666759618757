import { motion } from 'framer-motion';
import React from 'react';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import equip_bike from '../../../../assets/games/kits/equip_bike.svg';
import equip_kayak from '../../../../assets/games/kits/equip_kayak.svg';
import head_helmet from '../../../../assets/games/kits/head_helmet.svg';
import top_gb from '../../../../assets/games/kits/top_gb.svg';
import top_jamaica from '../../../../assets/games/kits/top_jamaica.svg';
import ck_home from '../../../../assets/misc/ck-home.svg';
import Slide, { ISlide } from '../../../core/Slide';

const StartSlide: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const begin = (
        <Button className="btn-3d" onClick={nextSlide}>
            {t`Begin`}
        </Button>
    );
    let animation = {
        scale: [0, 1, 1, 1, 1, 1, 1, 0],
        rotate: [0, 0, 20, -20, 20, -20, 0, 0],
    };
    let times = [0, 0.1, 0.25, 0.4, 0.55, 0.7, 0.8, 0.9];
    return (
        <Slide header={defaultHeader} footer={begin}>
            <div className="ck-home">
                <motion.img
                    animate={animation}
                    transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: times,
                        loop: Infinity,
                        repeatDelay: 4,
                        delay: 2,
                    }}
                    className={'ck-home__images ck-home__images--1'}
                    width={'60px'}
                    src={head_helmet}
                />
                <motion.img
                    animate={animation}
                    transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: times,
                        loop: Infinity,
                        repeatDelay: 5,
                        delay: 4,
                    }}
                    className={'ck-home__images ck-home__images--2'}
                    width={'60px'}
                    src={top_gb}
                />
                <motion.img
                    animate={animation}
                    transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: times,
                        loop: Infinity,
                        repeatDelay: 3,
                    }}
                    className={'ck-home__images ck-home__images--3'}
                    width={'60px'}
                    src={top_jamaica}
                />
                <motion.img
                    animate={animation}
                    transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: times,
                        loop: Infinity,
                        repeatDelay: 2,
                        delay: 1,
                    }}
                    className={'ck-home__images ck-home__images--4'}
                    width={'60px'}
                    src={equip_kayak}
                />
                <motion.img
                    animate={animation}
                    transition={{
                        duration: 2,
                        ease: 'easeInOut',
                        times: times,
                        loop: Infinity,
                        repeatDelay: 5,
                        delay: 3,
                    }}
                    className={'ck-home__images ck-home__images--5'}
                    width={'60px'}
                    src={equip_bike}
                />
                <motion.div
                    className={'ck-home__content'}
                    animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                >
                    <img src={ck_home} width={'200px'} className={'ck-home__characters'} alt="" />
                    <h2 className="ck-home__title">{t`Colourful Kits`}</h2>
                </motion.div>
            </div>
        </Slide>
    );
};

export default StartSlide;
