import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentRio from '../../characters/AgentRio';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const LandedIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentRio,
            text: t`Nice work. We have landed outside. We need to be very quiet so Dr Mischief doesn’t know we’re here.`,
            buttonText: t`Next`,
        },
        {
            character: AgentRio,
            text: t`We’ve found the back entrance to the lair. The door is locked with a high-tech electronic system... Maybe we should look at the door’s keypad.`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default LandedIntro;
