import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentSparrow from '../../characters/AgentSparrow';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import AgentGreen from '../../characters/AgentGreen';
import { CodeCrackingData } from '../..';
import { t } from 'ttag';

const AgentIntro: React.FC<ISlide<CodeCrackingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentSparrow,
            text: t`Great start. You’re learning fast! So welcome to the home of the country’s best code-breakers. Here we crack the enemy's codes to find out what they are planning!`,
            buttonText: t`Next`,
        },
        {
            character: AgentSparrow,
            text: t`Let me introduce you to ${AgentGreen.characterName}, she’s the best code-cracker we have and is keen to tell you about ciphers!`,
            buttonText: t`Next`,
        },
        {
            character: AgentGreen,
            text: t`Hi Agent ${data.name}. I am ${AgentGreen.characterName}, head of the code-breaking team here at HQ. Our job is to study the cipher that the enemy use to jumble all of their messages! `,
            buttonText: t`Cipher?`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default AgentIntro;
