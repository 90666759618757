import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';
import { TGiftSendQuestion } from './GiftSend2';
import ModalOverlay from '../../../../../general/ModalOverlay';
import Gift from './Gift';

const CorrectModal: React.FC<{ question: TGiftSendQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-thumbs-up mr-2"></i> {t`Perfect!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    const footer = <Button onClick={() => onClose()}>{t`Continue`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#00C3A5">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <p>{t`You successfully made the perfect present!`}</p>
                    <br></br>
                    {/* <Gift size={question.size} color={question.color} pattern={question.pattern}></Gift> */}
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default CorrectModal;
