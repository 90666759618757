import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Pufferfish from '../../characters/Pufferfish';

const PufferfishOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Pufferfish,
            text: t`Phew... I’ve calmed down a bit. That was so out of the blue! Phishing can be done over a phone call, I did not know that! Thank you for your help!`,
            buttonText: t`Next`,
            textSpriteName: 'pufferfish-2-1',
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default PufferfishOutro;
