import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const AttributesQuizIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`An attribute is a feature of something. In the first task I gave you, you were actually grouping the shapes by their attributes such as colour and number of sides.`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default AttributesQuizIntro;
