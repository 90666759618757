import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Kitty from '../../characters/Kitty';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Kitty,
            text: t`Fantastic work! You helped all of us here prepare for the competition and I’m sure it’s going to be a great success thanks to you!`,
            buttonText: t`Yippee!`,
        },
        {
            character: Kitty,
            text: t`Let’s have a look at what you have learnt along the way.`,
            buttonText: t`Show Me`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
