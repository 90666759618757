import { remove } from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Button, Row, Col } from 'react-bootstrap';

import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import VolumeContext from '../../../../../misc/volume-context';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import table from '../../../assets/gifts/table.svg';
import box from '../../../assets/gifts/box.svg';
import workshop from '../../../assets/boxing/workshop2.svg';
import GiftBuilderSelector from './GiftBuilderSelector';
import Gift from './Gift';
import CorrectModal from './CorrectModal';

import balloon from '../../../assets/characters/balloon_penguin.svg';
import gift from '../../../assets/send/gift.svg';
import home from '../../../assets/send/home.svg';
import power_ups from '../../../assets/send/power-ups.svg';
import heart_circle from '../../../assets/send/heart-circle.svg';
import toaster from 'toasted-notes';

export type TGiftSendQuestion = GameQuestion & {
    box: TBox;
    toy: TToy;
    house: string;
};

export type TBox = '#6B7280' | '#EF4444' | '#F59E0B' | '#10B981' | '#3B82F6' | '#8B5CF6' | '#EC4899';
export type TToy = 'snowman' | 'soldier' | 'robot';

interface GiftboxProps {}

const GiftBuilder: React.FC<GameProps<TGiftSendQuestion> & GiftboxProps> = ({
    gameData,
    index = 0,
    onGameComplete,
}) => {
    const [show, setShow] = useState(false);
    const [happiness, setHappiness] = useState(0);
    const [totalSent, setTotalSend] = useState(0);
    const [hint, setHint] = useState(true);

    const { volume } = useContext(VolumeContext);

    const [box, setBox] = useState<TBox>('#6B7280');
    const [toy, setToy] = useState<TToy>('snowman');

    const anim_gift = useAnimation();

    async function sequence() {
        await anim_gift.start({ y: 150, opacity: 1, transition: { duration: 1, ease: 'linear' } });
        await anim_gift.start({ y: 200, opacity: 0, transition: { duration: 0.25, ease: 'linear' } });
        await anim_gift.start({ y: 0, opacity: 0 });
    }

    const handleGameComplete = () => {
        // setShow(true);
        if (volume) sfx.success.play();

        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TGiftSendQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(onGameComplete, 0);
    };

    const handleQuestionComplete = () => {
        setTotalSend(totalSent + 1);
        nextQuestion();
    };

    useEffect(() => {
        let happiness = 0;

        if (!question) {
            return;
        }

        if (question.box === box) {
            happiness += 50;
        }

        if (question.toy === toy) {
            happiness += 50;
        }

        setHappiness(happiness);
        // Change to on button press?
        // if (question && (question.box === box && question.toy === toy)) {
        //     if (volume) sfx.correct.play();
        //     setTimeout(handleQuestionComplete, 1000);
        // }
    }, [box, toy, question]);

    const handlePackAndSend = () => {
        if (question && question.box === box && question.toy === toy) {
            sequence();
            if (volume) sfx.correct.play();
            toaster.notify(t`Perfect! Gift delivered!`, {
                duration: 2000,
                position: 'bottom',
            });
            setTimeout(handleQuestionComplete, 1000);
        } else {
            if (volume) sfx.incorrect.play();
            toaster.notify(t`Not quite, try another combination!`, {
                duration: 2000,
                position: 'bottom',
            });
        }
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <GameWrapper>
                <Balloon animate={{ y: [-25, 0, -25] }} transition={{ ease: 'easeInOut', duration: 5, loop: Infinity }}>
                    <BalloonImg src={balloon} />
                    <GiftDrop initial={{ y: 0, opacity: 1 }} animate={anim_gift}>
                        <Gift size={1} color={box}></Gift>
                    </GiftDrop>
                    <Score>
                        <h4>
                            <span>{gameData.length - totalSent}</span> BOX
                            {gameData.length - totalSent > 1 ? 'ES' : null} LEFT
                        </h4>
                        {/* <p>Increment: {increment}</p> */}
                        {/* <p className="mb-0">Total Sent: {totalSent}</p> */}
                    </Score>
                </Balloon>
                {hint ? (
                    <Hint>
                        <i className="fa fa-info"></i>
                        <i className="fa fa-times" onClick={() => setHint(false)}></i>
                        Use your new tinkering skills to send the perfect present for each house.
                    </Hint>
                ) : null}

                <SectionEdit>
                    <div className="w-100">
                        <EditCard>
                            <DesktopBg />
                            <Row className="align-items-center mb-2">
                                <Col>
                                    <Label>BOX COLOUR</Label>
                                    <GiftBuilderSelector type={'box'} onSelect={setBox}></GiftBuilderSelector>
                                </Col>
                                <Col>
                                    <Label>TOY</Label>
                                    <GiftBuilderSelector type={'toy'} onSelect={setToy}></GiftBuilderSelector>
                                </Col>
                            </Row>
                            <HeartCircle desktop={true} src={heart_circle} />
                            <DesktopHappiness>
                                <BarWrapper>
                                    <DesktopBar happiness={happiness} />
                                </BarWrapper>
                            </DesktopHappiness>
                            <SendButton
                                ready={question.box === box && question.toy === toy}
                                onClick={handlePackAndSend}
                            >
                                Pack & Send
                            </SendButton>
                        </EditCard>
                    </div>
                </SectionEdit>

                {/* <SectionTable>
                    <TableTop>
                        <GiftWrapper>
                            <GiftPlaceholder>
                                <Gift size={size} color={color} pattern={pattern}></Gift>
                            </GiftPlaceholder>
                            <GiftPlaceholder>
                                <Gift size={question.size} color={question.color} pattern={question.pattern}></Gift>
                            </GiftPlaceholder>
                        </GiftWrapper>
                    </TableTop>
                    <TableBottom></TableBottom>
                </SectionTable> */}
                <SubmitContainer>
                    <Happiness>
                        <BarWrapper>
                            <HappinessBar happiness={happiness} />
                        </BarWrapper>
                    </Happiness>
                    <HeartCircle src={heart_circle} />
                    <Home src={question.house} />

                    {/* <Button>Box it up!</Button> */}
                </SubmitContainer>
            </GameWrapper>
        </React.Fragment>
    );
};
const DesktopBg = styled.div`
    display: none;
    @media (min-width: 620px) {
        display: block;
        position: absolute;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        right: 0;
        width: 164px;
    }
`;
const DesktopBar = styled.div<HappyProps>`
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #e5007d;
    width: ${(props) => props.happiness}%;
    transition: width 0.5s ease-in-out;
`;

interface HeartProps {
    desktop?: boolean;
}

const DesktopHappiness = styled.div`
    display: none;
    @media (min-width: 620px) {
        position: absolute;
        display: block;
        top: 24px;
        height: 8px;
        right: 10px;
        width: 140px;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
    }
`;

interface SendProps {
    ready?: boolean;
}

const SendButton = styled(Button)<SendProps>`
    width: 100%;
    @media (min-width: 620px) {
        position: absolute;
        top: 45px;
        right: 10px;
        width: auto;
    }
    ${(props) =>
        props.ready ? 'background-color: #E60050 !important; color: #ffffff !important; &:hover {opacity: 0.9}' : ''}
`;

const HeartCircle = styled.img<HeartProps>`
    position: absolute;
    height: 30px;
    width: 30px;
    ${(props) =>
        !props.desktop
            ? 'bottom: 2px;left: calc(50% - 110px); z-index: 999;'
            : 'z-index: 50; top: 16px; height: 25px; width: 25px; right: 130px; @media (max-width: 619px) {display: none}'}
`;

const BarWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

interface HappyProps {
    happiness: number;
}

const Happiness = styled.div`
    position: absolute;
    bottom: 5px;
    width: 10px;
    overflow: hidden;
    border-radius: 15px;
    height: 125px;
    left: calc(50% - 100px);
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    z-index: 999;
`;
const HappinessBar = styled.div<HappyProps>`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #e5007d;
    height: ${(props) => props.happiness}%;
    transition: height 0.5s ease-in-out;
`;

const Label = styled.p`
    font-size: 14px;
`;

const Hint = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    width: 200px;
    font-size: 16px;
    position: fixed;
    bottom: 175px;
    left: calc(50% - 100px);
    z-index: 5;
    i {
        position: absolute;
        left: 5px;
        top: -15px;
        height: 28px;
        width: 28px;
        font-size: 1rem;
        text-align: center;
        border-radius: 100px;
        padding: 5px 10px;
        color: #00a0d6;
        background-color: #ffffff;
    }
    .fa-times {
        background-color: transparent;
        left: auto;
        color: rgba(255, 255, 255, 0.5);
        right: 3px;
        top: 3px;
        font-size: 0.75rem;
        cursor: pointer;
    }
    @media (min-width: 900px) {
        bottom: auto;
        display: block;
        background-color: rgba(0, 0, 0, 0.1);
        left: 5px;
        width: 100%;
        max-width: 200px;
        top: 96px;
    }
    @media (min-width: 1200px) {
        max-width: 300px;
        left: calc(50% - 550px);
    }
`;

const EditCard = styled.div`
    background: #00a0d6;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    position: relative;
    z-index: 4;
    overflow: hidden;
    @media (min-width: 620px) {
        max-width: 450px;
        padding: 14px 170px 10px 10px;
    }
    p {
        font-family: 'BT Font Extra Bold';
        margin-bottom: 0;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }
`;

const SectionEdit = styled.div`
    position: fixed;
    top: 60px;
    width: 100%;
`;

const SubmitContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    z-index: 999;
`;

const Balloon = styled(motion.div)`
    max-width: 300px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -200px;
    @media (min-width: 767px) and (min-height: 650px) {
        max-width: 350px;
    }
    @media (min-width: 767px) and (min-height: 800px) {
        max-width: 400px;
    }
    @media (min-width: 767px) and (min-height: 1000px) {
        top: -100px;
    }
`;

const BalloonImg = styled(motion.img)`
    width: 100%;
    z-index: 2;
    position: relative;
`;

const GameWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    margin-top: 65px;
`;

const Score = styled.div`
    background-color: #361559;
    left: calc(50% - 69px);
    bottom: 0;
    z-index: 10;
    width: 130px;
    position: absolute;
    padding: 10px 5px;
    text-transform: uppercase;
    line-height: 40px;
    h4 {
        font-size: 16px;
        font-family: 'BT Font Bold';
        letter-spacing: 0.05em;
        margin-bottom: 0;
    }
    span {
        background-color: rgba(0, 0, 0, 0.1);
        padding: 5px;
    }
`;

const GiftDrop = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
    width: 50px;
    z-index: 1;
`;

const Home = styled(motion.img)`
    position: absolute;
    bottom: 0;
    width: 200px;
    left: calc(50% - 100px);
`;

export default GiftBuilder;
