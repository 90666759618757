import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { AbstractionData } from '../..';
import { t } from 'ttag';

const StormIntro1: React.FC<ISlide<AbstractionData>> = ({ nextSlide, defaultHeader, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: RangerJack,
            text: t`Hang on ${data.name}, it’s getting pretty windy here...`,
            buttonText: t`Are you ok?`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default StormIntro1;
