import React, { useEffect } from 'react';
import { t } from 'ttag';
import { TCharacter } from '../../../general/Character';
import Turtle from '../characters/Turtle';
import styled from 'styled-components';
import star from '../assets/gamification/star.svg';
import reflection from '../assets/gamification/reflection.svg';
import { CyberSecurityData } from '..';
import { motion } from 'framer-motion';

type CyberCardProps = {
    title: string;
    description: string;
    points: number;
    gameData: CyberSecurityData;
    character?: TCharacter;
    characterText?: string;
    backgroundColor?: string;
    showPoints?: boolean;
};

const CyberCard: React.FC<CyberCardProps> = ({
    title,
    description,
    points,
    backgroundColor = '#00C3A5',
    character = Turtle,
    characterText = t`Nice work!`,
    showPoints = true,
    gameData,
}) => {
    useEffect(() => {
        if (points && showPoints) {
            gameData.addPoints(points);
        }
    }, []);
    return (
        <Wrapper>
            {showPoints ? (
                <Points
                    animate={{ opacity: [0, 1, 1], scale: [0.3, 1.4, 1] }}
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 2.3 }}
                >
                    <h6>
                        <Star src={star} width={'24px'} alt={'Star'} /> {points > 0 ? '+' : ''} {points}{' '}
                        {t`Cyber Points`}
                    </h6>
                </Points>
            ) : null}

            <Card
                backgroundColor={backgroundColor}
                animate={{ y: [-30, 0], opacity: [0, 1] }}
                transition={{ duration: 0.4, delay: 0.3 }}
            >
                <Reflection src={reflection} />
                <h3>{title}</h3>
                <hr />
                <p className={'font-weight-bolder'}>{description}</p>
            </Card>

            <Message animate={{ x: [-15, 0], opacity: [0, 1] }} transition={{ duration: 0.3, delay: 1.3 }}>
                <Character src={character.characterImage} />
                <p>{characterText}</p>
            </Message>
        </Wrapper>
    );
};

interface CardProps {
    backgroundColor: string;
}

const Card = styled(motion.div)<CardProps>`
    width: 100%;
    padding: 30px 25px 40px 25px;
    background-color: ${(props: CardProps) => props.backgroundColor};
    border: 3px solid rgba(255, 255, 255, 0.5);
    position: relative;
    hr {
        border-color: #ffffff;
    }
`;
const Character = styled.img`
    width: 15%;
    margin-left: 10%;
    display: inline-block;
`;

const Reflection = styled.img`
    position: absolute;
    width: 40%;
    top: 0;
    left: 0;
`;
const Message = styled(motion.div)`
    position: relative;
    margin-top: -40px;
    p {
        background-color: #ffffff;
        display: inline-block;
        max-width: 65%;
        border-radius: 5px;
        margin-left:3%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        color: #6400aa;
        padding: 10px 20px;
        font-size: 18px;
        position: relative;
        height: 50px;
        &::after{
            content: '';
            position: absolute;
            right: 100%;
            top: 10px;
            width: 0;
            height: 0;
            border-bottom: 5px solid transparent;
            border-right: 10px solid #ffffff;
            border-top: 5px solid transparent;
            clear: both;
        }}
    }
`;

const Star = styled.img`
    width: 24px;
    margin-right: 5px;
    margin-top: -3px;
`;

const Points = styled(motion.div)`
    display: block;
    text-align: center;
    margin: 15px 0;
    h6 {
        margin-bottom: 0;
        background-color: rgba(0,0,0,0.1);
        border-radius 100px;
        padding: 10px 20px;
        display: inline-block;
        font-family: 'BT Font Extra Bold', sans-serif;
    }
`;
const Wrapper = styled.div`
    max-width: 300px;
    display: block;
    margin: 0 auto;
`;

export default CyberCard;
