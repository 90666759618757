import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Patrick from '../../characters/Patrick';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import logic from '../../../../../assets/icons/icon_logic.svg';
import { ColourfulKitsData } from '../..';
import SkillModal from '../../../../general/SkillModal';
import { t } from 'ttag';

const LogicGameOutro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Patrick,
            text: t`${data.name}, you got them all right! You used logical reasoning to figure how I’d grouped my flags. Wow you’re clever!`,
            buttonText: t`Thanks!`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`LOGIC`}
                skillImage={logic}
                skillDescription={t`You have used logic to predict which features have been used to group objects.`}
            />
        </Slide>
    );
};

export default LogicGameOutro;
