import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import { TCaesarCipherQuestion } from './CaesarCipher';
import ModalOverlay from '../../general/ModalOverlay';
import { t } from 'ttag';

const CorrectModal: React.FC<{ question: TCaesarCipherQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <motion.div className={'w-100 text-center'} animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2 }}>
            <h2 className="text-white text-center mt-2">
                <i className="fas fa-thumbs-up mr-2"></i> {t`CORRECT!`}
            </h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    const footer = <Button onClick={() => onClose()}>{t`Continue`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#00C3A5">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <p>{t`Code cracked!`}</p>
                    <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.7 }}>
                        <div className="island island--rounded m-3 p-3">
                            <p className={'cc-text mb-0'}>{question.answer}</p>
                        </div>
                    </motion.div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default CorrectModal;
