import React from 'react';
import TaskSlide from '../../../../general/TaskSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const LogicGameIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <TaskSlide onStart={nextSlide} header={defaultHeader} number={3}>
            {t`Select the attributes that Patrick uses to group his flags.`}
        </TaskSlide>
    );
};

export default LogicGameIntro;
