import React from 'react';
import TaskSlide from '../../../../general/TaskSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const AtrributesGame2Intro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <TaskSlide onStart={nextSlide} header={defaultHeader} number={2}>
            {t`Group more shapes for the logo by their attributes. Look for patterns to help group them quickly!`}
        </TaskSlide>
    );
};

export default AtrributesGame2Intro;
