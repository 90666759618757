import React from 'react';
import MissionSlide from '../../../../general/MissionSlide';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const MissionIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <MissionSlide
            onStart={nextSlide}
            header={defaultHeader}
            number={3}
        >{t`Destroy at least 10 asteroids in the given time.`}</MissionSlide>
    );
};

export default MissionIntro;
