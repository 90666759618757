import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { DialogueData } from '../../../../general/Dialogue';
import { t } from 'ttag';
import { FlubFlub } from '../../characters/Aliens';
import WordDiscovery from '../../../../general/WordDiscovery';
import { VariablesData } from '../..';

const VariablesQuizOutro2: React.FC<ISlide<VariablesData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: FlubFlub,
            text: t`Hey ${data.name}! I can help! I’ll create two variables in the ship’s onboard computer. I can write some code so these keep track of how many large and small asteroids you blast.`,
            buttonText: t`Sure!`,
            buttonAction: () => setShow(true),
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <WordDiscovery
                show={show}
                onClose={nextSlide}
                word={t`Variable`}
                wordDefinition={t`A simple way of storing one piece of data somewhere in the computer’s memory whilst a program is running, and of getting that information back later`}
            ></WordDiscovery>
        </Slide>
    );
};

export default VariablesQuizOutro2;
