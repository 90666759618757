const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const fullAlphabet = alphabet + alphabet + alphabet;

export function caesar(text: string, offset: number): string {
    offset = offset % alphabet.length;
    let cipherFinish = '';

    for (let i = 0; i < text.length; i++) {
        let letter = text[i];
        const upper = letter === letter.toUpperCase();
        letter = letter.toLowerCase();

        let index = alphabet.indexOf(letter);

        if (index === -1) {
            cipherFinish += letter;
        } else {
            index = index + offset + alphabet.length;
            let nextLetter = fullAlphabet[index];
            if (upper) {
                nextLetter = nextLetter.toUpperCase();
            }
            cipherFinish += nextLetter;
        }
    }

    if (!cipherFinish) {
        return '';
    }

    return cipherFinish;
}
