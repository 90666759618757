import React from 'react';
import Car from './Car';
import { TCar } from './Road';

type CarProps = {
    cars: TCar[],
    scale: number
    debug: boolean
}

const Cars: React.FC<CarProps> = ({ cars, scale, debug }) => {
    return (
        <>
            { cars.map((car) => {
                // return (<h1>Car {car.pos}</h1>)
                return (<Car debug={debug} {...car} scale={scale}></Car>);
            })}
        </>
    );
}

export default Cars;
