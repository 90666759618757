import React, { useContext, useState } from 'react';
import { c, t } from 'ttag';
import sfx from '../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../core/Game';
import { VolumeContext } from '../../misc/volume-context';
import CorrectModal from '../kitmistake/CorrectModal';
import KitBoxGroup from './KitBoxGroup';

export type TKitMistakeQuestion = GameQuestion & {
    athlete: string;
    sport: string;
    country: string;
    flag: string;
    answers: [TKitMistakeAnswer, TKitMistakeAnswer, TKitMistakeAnswer, TKitMistakeAnswer];
};

export type TKitMistakeAnswer = {
    type: string;
    image: string;
    correct: boolean;
};

const KitMistake: React.FC<GameProps<TKitMistakeQuestion>> = ({ gameData, index = 0, onGameComplete }) => {
    const [show, setShow] = useState(false);
    const { volume } = useContext(VolumeContext);

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TKitMistakeQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        if (volume) sfx.success.play();
        setShow(true);
    };

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <div>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <p className={'ck-kit__howto'}>
                {t`Spot the mistake in the kit`}
                <br />
                {c('Spot the mistake in the kit...').t`and select it below`}
            </p>
            <div className="ck-kit__card">
                <div className="ck-kit__flag-col">
                    <img className={'ck-kit__flag'} src={question.flag} alt={question.country + t` flag`}></img>
                </div>
                <div className="ck-kit__details-col">
                    <div className={'ck-kit__detail-col'}>
                        <p className={'ck-kit__label'}>{t`Country`}</p>
                        <p className={'ck-kit__detail'}>{question.country}</p>
                    </div>
                    <div className={'ck-kit__detail-col'}>
                        <p className={'ck-kit__label'}>{t`Sport`}</p>
                        <p className={'ck-kit__detail'}>{question.sport}</p>
                    </div>
                </div>
            </div>
            <div className="ck-kit ck-kit--mistake">
                <img className={'ck-kit__athlete'} src={question.athlete} alt={t`athlete`} />
                <KitBoxGroup kits={question.answers} onComplete={handleQuestionComplete} />
            </div>
        </div>
    );
};

export default KitMistake;
