import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentRio from '../../characters/AgentRio';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import { DiamondData } from '../..';

const SeqQuizOutro: React.FC<ISlide<DiamondData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: AgentRio,
            text: t`Nice work ${data.name}. Dr Mischief has been using algorithms to generate these sequences. This algorithm was to add 8 to the previous number.`,
            buttonText: t`Next`,
        },
        {
            character: AgentRio,
            text: t`Now let’s complete the number sequence to open this door. We need to use the correct algorithm to complete the sequence.`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default SeqQuizOutro;
