import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import { FestiveData } from '../..';

const PenguinIntro2: React.FC<ISlide<FestiveData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`${data.name}, what a lovely name! The elves have gone on holiday so we need to help out.`,
            buttonText: t`Next`,
        },
        {
            character: Penguin,
            text: t`We need to build, wrap and pack all the toys before tomorrow. You look like the perfect person to help!`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default PenguinIntro2;
