import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import FourPicturesGame, { Question } from '../../../../interactive/fourpic/FourPicturesGame';
import { t, jt } from 'ttag';

import lion from '../../../../../assets/animals/lion.svg';
import lion_1 from '../../../../../assets/animals/lion/lion-1.jpg';
import lion_2 from '../../../../../assets/animals/lion/lion-2.jpg';
import lion_3 from '../../../../../assets/animals/lion/lion-3.jpg';
import lion_4 from '../../../../../assets/animals/lion/lion-4.jpg';

const EscapedAnimal: React.FC<ISlide> = ({ nextSlide }) => {
    const gameData: Question[] = [
        {
            answer: t`lion`,
            pictures: [
                { text: t`TAIL`, image: lion_1 },
                { text: t`MANE`, image: lion_2 },
                { text: t`BIG TEETH`, image: lion_3 },
                { text: t`PAWS`, image: lion_4 },
            ],
            answerImage: lion,
            additionalLetters: ['K', 'J', 'P'],
            hintLevel: 0,
        },
    ];

    let title = <span className="font-bt-400">{t`Identify the Animal`}</span>;
    const header = <h3>{jt`Mission 1: ${title}`}</h3>;

    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <FourPicturesGame gameData={gameData} onGameComplete={() => nextSlide()} />
        </Slide>
    );
};

export default EscapedAnimal;
