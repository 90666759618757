import React, { useEffect, useState } from 'react';
import toaster from 'toasted-notes';
import { t } from 'ttag';
import { TMultiChoiceAnswer, TMultiChoiceQuestion } from './MultiChoice';
import MultiChoiceAnswer from './MultiChoiceAnswer';

const MultiChoiceQuestion: React.FC<{
    question: TMultiChoiceQuestion;
    onQuestionComplete: () => void;
    onIncorrectAnswer: () => void;
    onCorrectAnswer?: () => void;
}> = ({ question, onQuestionComplete, onIncorrectAnswer, onCorrectAnswer = () => {} }) => {
    const [correctAnswers, setCorrectAnswers] = useState<TMultiChoiceAnswer[]>([]);

    useEffect(() => {
        if (
            question &&
            correctAnswers.length > 0 &&
            correctAnswers.length === question.answers.filter((a) => a.correct === true).length
        ) {
            setCorrectAnswers([]);
            onCorrectAnswer();
            onQuestionComplete();
        }
    }, [correctAnswers, onQuestionComplete, question]);

    const handleAnswer = (answer: TMultiChoiceAnswer) => {
        if (answer.completed) {
            return;
        }

        answer.completed = true;

        if (answer.correct) {
            setCorrectAnswers([...correctAnswers, answer]);

        } else {
            onIncorrectAnswer();
            // toaster.notify(<p className={'toast__text toast__text--false'}>{ t`Oh no! That was incorrect.` }</p>, {
            //     duration: 2000,
            //     position: 'bottom',
            // });
        }
    };

    return (
        <React.Fragment>
            <p>{question.question}</p>
            {question.questionContext ? <h5>{question.questionContext}</h5> : null}
            <ul>
                {question.answers.map((answer, index) => {
                    return (
                        <li className="mb-1">
                            <MultiChoiceAnswer
                                key={'q' + index}
                                answer={answer}
                                onAnswer={() => handleAnswer(answer)}
                            ></MultiChoiceAnswer>
                        </li>
                    );
                })}
            </ul>
        </React.Fragment>
    );
};

export default MultiChoiceQuestion;
