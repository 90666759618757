import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import Intel from '../../components/Intel';
import { t } from 'ttag';

const BletchleyIntel: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const next = (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    );

    return (
        <Slide header={defaultHeader} footer={next}>
            <Intel>
                <p>
                    <small className="text-danger">{t`Today’s date -`} </small>
                    {t`1 September 1940`}
                </p>
                <ul>
                    <li>{t`The war is known as World War II.`}</li>
                    <li>{t`We have been at war with the enemy for one year today.`}</li>
                    <li>{t`The enemy is communicating using a cipher, making it hard to gather intelligence.`}</li>
                    <li>
                        {t`We have identified the name of the machine being used by the enemy to cipher their communications.`}
                    </li>
                </ul>
            </Intel>
        </Slide>
    );
};

export default BletchleyIntel;
