import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar } from '../../components/interactive/scene/Road';

const IntroScene: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [nameShow, setNameShow] = useState(false);

    const [carData] = useState<TCar[]>([
        { id: 'go-1', pos: 0, moving: true, direction: 'clockwise', loop: true },
    ]);

    const [nextDialogue, setNextDialogue] = useState<any>(null);


    const next = data.name ? (
        <Button className="btn-3d" onClick={() => {
            if (typeof nextDialogue === 'function') {
                nextDialogue();
                nextDialogue();
                setNameShow(false);
            }
        }}>
            {t`Next`}
        </Button>
    ) : null;

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`${data.name}, the narrow bridge you see in-front of you has caused quite a few crashes recently. `,
            textSpriteName: `6`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`The Mayor of Red Town has asked us to make it safe for cars to cross in both directions.`,
            textSpriteName: `7`,
            buttonText: t`Next`,
        },

        {
            character: EngineerShahid,
            text: t`As the bridge is so narrow, cars can only cross one at a time. If two cars drive onto the bridge at the same time, there may be a crash!`,
            textSpriteName: `8`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Hold on! Two cars are driving towards each other right now!`,
            textSpriteName: `9`,
            buttonText: t`Watch`,
            buttonAction: () => {
                nextSlide();
            },
        },
    ];

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Scene
                nextSlide={nextSlide}
                data={data}
                alert={'warning'}
                roadworks={false}
                crash={false}
                lights={false}
                road={{ carData: carData }}//onCrash: setTimeout(nextSlide, 5000)
            >
                <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />

            </Scene>
        </Slide>
    );
};

const ButtonWrapper = styled.div`
    min-height: 60px;
    .btn-3d {
        background-color: #6400aa;
        color: #ffffff !important;
        &:hover, &:active, &:focus {
            background-color: #540e85 !important;
            color: #DDDDDD !important;
        }
    }
`;
const Character = styled.img`
    position: absolute;
    z-index: -1;
    top: -70px;
    left: 15px;
    width: 50px;
`;

const Content = styled.div`
    position:relative;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 1rem;
    background-color: #ffffff;
    @media (min-width: 576px){
        border-radius: 5px;
    }
    min-height: 136px;
`;


const Layout = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    .island {
        background-color: #EAF4F7;
        padding: 6px 12px !important;
        width: 100%;
        margin: 0 0 10px !important;
        font-size: 18px;
        line-height: 24px;
        min-height: auto;
        box-shadow: none;
        &::after{
            content: '';
            position: absolute;
            left: 8px;
            top: -12px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #EAF4F7;
            clear: both;
        }
    }
    .island__author--dialogue {
        font-size: 12px;
        top: -55px;
        left: 50px;
        padding: 2px 8px;
        letter-spacing: 0.05em;
        border-radius: 5px;
    }
    @media (min-width: 576px){
        bottom: 10px;
        .island {
            border-radius: 5px !important;
            margin-bottom: 10px !important;
        }
        ${Content} {
            max-width: 400px;
        }
    }
    @media (min-width: 1200px){
        .island {
            font-size: 20px;
        }
        ${Content} {
            max-width: 500px;
        }
    }
`;

export default IntroScene;
