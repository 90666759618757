import React from 'react';
import FestiveTask from '../../components/FestiveTask';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const SendingMission: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <FestiveTask
            onStart={nextSlide}
            header={defaultHeader}
            number={3}
        >{t`Help Penguin pack and deliver the perfect presents to each house.`}</FestiveTask>
    );
};

export default SendingMission;
