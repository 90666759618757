import React from 'react';
import { FeatureMatchQuestion } from './FeatureMatch';
import ModalOverlay from '../../general/ModalOverlay';
import { Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import paint_stroke from '../../../assets/misc/paint-stroke.svg';
import { t } from 'ttag';

const CorrectModal: React.FC<{ question: FeatureMatchQuestion; onClose: () => void; show: boolean }> = ({
    question,
    onClose,
    show,
}) => {
    const header = (
        <React.Fragment>
            <motion.div
                className={'w-100 text-center'}
                animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
                transition={{ duration: 0.2 }}
            >
                <h2 className="text-white text-center">
                    <i className="fas fa-thumbs-up mr-2"></i> {t`CORRECT!`}
                </h2>
                <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
            </motion.div>
        </React.Fragment>
    );

    const footer = <Button onClick={() => onClose()}>{t`Add animal`}</Button>;

    return (
        <ModalOverlay header={header} footer={footer} show={show} onHide={() => onClose()} backgroundColor="#00C3A5">
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="text-center">
                    <div className="island island--guide island--rounded p-3 p-sm-4 px-md-5 mx-auto">
                        <div className="character__wrapper">
                            <div className="character__label--animal">
                                <i className={'fas fa-binoculars mr-2'}></i> {t`WHAT TO LOOK FOR`}
                            </div>
                            <motion.div
                                className={`d-block`}
                                animate={{ y: [0, -10, 0, -10, 0] }}
                                transition={{ loop: Infinity, duration: 0.5, repeatDelay: 5 }}
                            >
                                <img
                                    className={'char'}
                                    width="150px"
                                    src={question.subject.image}
                                    alt={question.subject.name}
                                />
                            </motion.div>
                            <div className="character__shadow character__shadow--animal"></div>
                        </div>
                        <h3 className={'mb-3'}>{question.subject.name.toLowerCase()}</h3>
                        {question.features
                            .filter((feature) => feature.correct)
                            .map((feature) => (
                                <div className={`btn--feature__wrapper`}>
                                    <div className={'btn btn-3d-dark btn--feature'}>{feature.name}</div>
                                </div>
                            ))}
                    </div>
                </Col>
            </Row>
        </ModalOverlay>
    );
};

export default CorrectModal;
