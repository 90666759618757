import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AlgorithmGame, { TAlgorithmQuestion } from '../../../../interactive/algorithm/AlgorithmGame';

const VaultGame2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const gameData: TAlgorithmQuestion[] = [
        {
            question: '1255... 1265... 1275... 1285...',
            answers: ['Add10'],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <AlgorithmGame gameData={gameData} onGameComplete={nextSlide} />
        </Slide>
    );
};

export default VaultGame2;
