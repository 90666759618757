import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import FourPicturesGame, { Question } from '../../../../interactive/fourpic/FourPicturesGame';

import crocodile from '../../../../../assets/animals/crocodile.svg';
import crocodile_1 from '../../../../../assets/animals/crocodile/crocodile-1.jpg';
import crocodile_2 from '../../../../../assets/animals/crocodile/crocodile-2.jpg';
import crocodile_3 from '../../../../../assets/animals/crocodile/crocodile-3.jpg';
import crocodile_4 from '../../../../../assets/animals/crocodile/crocodile-4.jpg';

import panda from '../../../../../assets/animals/panda.svg';
import panda_1 from '../../../../../assets/animals/panda/panda-1.jpg';
import panda_2 from '../../../../../assets/animals/panda/panda-2.jpg';
import panda_3 from '../../../../../assets/animals/panda/panda-3.jpg';
import panda_4 from '../../../../../assets/animals/panda/panda-4.jpg';

import hippo from '../../../../../assets/animals/hippo.svg';
import hippo_1 from '../../../../../assets/animals/hippo/hippo-1.jpg';
import hippo_2 from '../../../../../assets/animals/hippo/hippo-2.jpg';
import hippo_3 from '../../../../../assets/animals/hippo/hippo-3.jpg';
import hippo_4 from '../../../../../assets/animals/hippo/hippo-4.jpg';

import snake from '../../../../../assets/animals/snake.svg';
import snake_1 from '../../../../../assets/animals/snake/snake-1.jpg';
import snake_2 from '../../../../../assets/animals/snake/snake-2.jpg';
import snake_3 from '../../../../../assets/animals/snake/snake-3.jpg';
import snake_4 from '../../../../../assets/animals/snake/snake-4.jpg';
import { t, jt } from 'ttag';

const Storm: React.FC<ISlide> = ({ nextSlide }) => {
    const gameData: Question[] = [
        {
            answer: t`snake`,
            pictures: [
                { text: t`SCALY SKIN`, image: snake_1 },
                { text: t`LARGE EYES`, image: snake_2 },
                { text: t`PATTERNS`, image: snake_3 },
                { text: t`FORKED TONGUE`, image: snake_4 },
            ],
            answerImage: snake,
            additionalLetters: [`Y`, `O`, `A`],
            hintLevel: 0,
        },
        {
            answer: t`panda`,
            pictures: [
                { text: t`ROUND EARS`, image: panda_1 },
                { text: t`FLUFFY`, image: panda_2 },
                { text: t`BLACK & WHITE`, image: panda_3 },
                { text: t`EATS BAMBOO`, image: panda_4 },
            ],
            answerImage: panda,
            additionalLetters: [`Q`, `J`, `L`],
            hintLevel: 0,
        },
        {
            answer: t`hippo`,
            pictures: [
                { text: t`HAIRY EARS`, image: hippo_1 },
                { text: t`BIG TEETH`, image: hippo_2 },
                { text: t`THICK SKIN`, image: hippo_3 },
                { text: t`SHORT LEGS`, image: hippo_4 },
            ],
            answerImage: hippo,
            additionalLetters: [`K`, `P`, `C`],
            hintLevel: 0,
        },
        {
            answer: t`crocodile`,
            pictures: [
                { text: t`SHARP CLAWS`, image: crocodile_1 },
                { text: t`LARGE EYES`, image: crocodile_2 },
                { text: t`LOTS OF TEETH`, image: crocodile_3 },
                { text: t`SCALY SKIN`, image: crocodile_4 },
            ],
            answerImage: crocodile,
            additionalLetters: [`Z`],
            hintLevel: 0,
        },
    ];

    let title = <span className="font-bt-400">{t`Save the animals`}</span>;
    const header = <h3>{jt`Mission 3: ${title}`}</h3>;

    return (
        <Slide header={header} backgroundColor="#361559" animDisabled={true}>
            <FourPicturesGame gameData={gameData} onGameComplete={() => nextSlide()} />
        </Slide>
    );
};

export default Storm;
