import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import debugging from '../../../../../assets/icons/icon_debugging.svg';
import logic from '../../../../../assets/icons/icon_logic.svg';
import pattern from '../../../../../assets/icons/icon_pattern.svg';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';

const Recap: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const footer = <Button onClick={nextSlide}>{t`Next`}</Button>;

    return (
        <Slide header={defaultHeader} footer={footer}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    <h4 className={'my-4'}>{t`You have achieved the following:`}</h4>
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={pattern} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'8'} className={'text-md-left'}>
                                <h3>{t`Pattern`}</h3>
                                <p className={'mb-0'}>{t`You grouped objects by their attributes.`}</p>
                            </Col>
                        </Row>
                    </Content>
                    <Content type="island_dark" styles={'my-2 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={logic} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'8'} className={'text-md-left'}>
                                <h3>{t`Logic`}</h3>
                                <p className={'mb-0'}>{t`You worked out which features were used to group objects.`}</p>
                            </Col>
                        </Row>
                    </Content>
                    <Content type="island_dark" styles={'mt-2 mb-4 py-3'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 0 }} className={'pl-md-4 pr-md-0'}>
                                <img src={debugging} className="w-50 mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col xs={'12'} md={'8'} className={'text-md-left'}>
                                <h3>{t`Debugging`}</h3>
                                <p className={'mb-0'}>{t`You spotted mistakes and fixed them!`}</p>
                            </Col>
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default Recap;
