import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import SkillModal from '../../../../general/SkillModal';
import logic_icon from '../../../../../assets/icons/icon_logic.svg';

const SeqQuizOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer] = useFooterButtonRenderer();

    return (
        <Slide header={defaultHeader} footer={footer}>
            <SkillModal
                show={true}
                onClose={nextSlide}
                skillName={t`Logic`}
                skillDescription={t`You used logical reasoning to predict the next number in the sequence.`}
                skillImage={logic_icon}
            ></SkillModal>
        </Slide>
    );
};

export default SeqQuizOutro;
