import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import CoachAlbert from '../../characters/CoachAlbert';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const Intro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: CoachAlbert,
            text: t`Welcome to the Barefoot Sporting Academy! We are hosting a big sports competition and need to get ready.`,
            buttonText: t`Let's go!`,
        },
        {
            character: CoachAlbert,
            text: t`I am ${CoachAlbert.characterName}, I am in charge of the competition’s brand and logo. Before we get started, I will need your nickname...`,
            buttonText: t`My nickname is...`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default Intro;
