import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Reward from 'react-rewards';
import { t } from 'ttag';
import image from '../../../../assets/misc/cc_medal.svg';
import star from '../../../../assets/misc/cc_star.svg';
import sfx from '../../../../services/sounds';
import Slide, { ISlide } from '../../../core/Slide';
import Content from '../../../general/Content';
import { VolumeContext } from '../../../misc/volume-context';

const EndSlide: React.FC<ISlide> = ({ onComplete, defaultHeader }) => {
    const { volume } = useContext(VolumeContext);
    const finish = (
        <Button className="btn-3d" onClick={onComplete}>
            {t`Finish`}
        </Button>
    );

    let animation = {
        scale: [0, 1, 1, 1, 1],
        rotate: [360, 0, -20, 20, 0],
    };

    let times = [0, 0.25, 0.35, 0.5, 0.7];

    if (volume) sfx.cheer.play();

    return (
        <Slide header={defaultHeader} footer={finish}>
            <Row>
                <Col xs="12" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} className={'text-center'}>
                    {/*<GoldStar/>*/}
                    <Content type="island_dark" styles={'my-2 p-2 text-center'}>
                        <Reward ref={(ref: any) => (ref ? ref.rewardMe() : null)} type="confetti" />

                        <Reward
                            ref={(ref: any) => {
                                return ref ? setTimeout(() => ref.rewardMe(), 1000) : null;
                            }}
                            type="confetti"
                        ></Reward>
                        <motion.div
                            animate={{ opacity: [0, 1], y: [-20, 0] }}
                            transition={{ duration: 0.2, delay: 0.5 }}
                            className="character__wrapper"
                        >
                            <img
                                src={image}
                                className={'char'}
                                width={'135px'}
                                alt={t`Success Medal`}
                                style={{ marginTop: '-8px', position: 'relative' }}
                            />
                            <motion.img
                                animate={animation}
                                transition={{
                                    duration: 2,
                                    ease: 'easeInOut',
                                    times: times,
                                    delay: 1,
                                }}
                                className={'cc-end__star'}
                                width={'60px'}
                                alt={'Success Star'}
                                src={star}
                            />
                            <div className="character__shadow" style={{ width: '140px' }}></div>
                        </motion.div>
                        <h3>
                            {t`You have completed`}
                            <br />
                            {t`Code Cracking`}!
                        </h3>
                    </Content>
                    <p
                        className={'p--endshare'}
                    >{t`Let your parent know what you’ve learnt about Code Cracking and if they know any family or friends who might also enjoy it they can share it via Facebook or Twitter`}</p>
                    <Row>
                        <Col xs={'6'}>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=https://barefootgames.org/code-cracking"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-facebook-f mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                        <Col xs={'6'}>
                            <a
                                href="https://twitter.com/intent/tweet?text=Try%20the%20Code%20Cracking%20game%20for%20a%20fun%20interactive%20learning%20experience!%20%20https://barefootgames.org/code-cracking"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="btn-3d btn-block mb-2">
                                    <i className={`fab fa-twitter mr-2`}></i>
                                    {t`Share`}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Slide>
    );
};

export default EndSlide;
