import React from 'react';
import FestiveTask from '../../components/FestiveTask';
import { ISlide } from '../../../../core/Slide';
import { t } from 'ttag';

const MissionIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    return (
        <FestiveTask
            onStart={nextSlide}
            header={defaultHeader}
            number={2}
        >{t`Help Penguin make the perfect gift boxes by tinkering with the gift box machine's settings.`}</FestiveTask>
    );
};

export default MissionIntro;
