import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { CodeCrackingData } from '../..';
import { Button, Col, Row } from 'react-bootstrap';
import ModalOverlay from '../../../../general/ModalOverlay';
import { motion } from 'framer-motion';
import paint_stroke from '../../../../../assets/misc/paint-stroke.svg';
import algorithms from '../../../../../assets/icons/icon_algorithms.svg';
import { t } from 'ttag';

const LabOutro2: React.FC<ISlide<CodeCrackingData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`That intelligence may have saved countless lives Agent ${data.name}. Congratulations on cracking that code so fast, it may have been just in time!`,
            buttonText: t`Woohoo!`,
            buttonAction: () => setShow(true),
        },
    ];

    const modalHeader = (
        <motion.div
            className={'w-100 text-center'}
            animate={{ type: 'spring', scale: [0.5, 1.1, 1] }}
            transition={{ duration: 0.2 }}
        >
            <h2 className="text-white text-center cc-heading">{t`LEARNING COVERED`}</h2>
            <img src={paint_stroke} className={`modal__stroke d-block my-2 mx-auto`} alt="" />
        </motion.div>
    );

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />

            <ModalOverlay
                show={show}
                header={modalHeader}
                footer={<Button onClick={() => nextSlide()}>{t`Next`}</Button>}
            >
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <motion.div animate={{ scale: [0, 1.1, 1] }} transition={{ duration: 0.2, delay: 0.5 }}>
                            <img
                                src={algorithms}
                                className="d-block mx-auto"
                                width={'100px'}
                                height={'100px'}
                                alt={t`Algorithms icon`}
                            />
                            <p className={'cc-text'}>{t`ALGORITHMS`}</p>
                            <p
                                className={'cc-text'}
                            >{t`You have followed the step by step process to decode the message.`}</p>
                        </motion.div>
                    </Col>
                </Row>
            </ModalOverlay>
        </Slide>
    );
};

export default LabOutro2;
