import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import CrashModal from '../../components/interactive/scene/CrashModal';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';
import { TTrafficLight } from '../../components/traffic-lights/TrafficLight';
import TrafficLightEditorStatic from '../../components/traffic-lights/TrafficLightEditorStatic';

const TouristGame: React.FC<ISlide<TrafficData>> = ({ data, nextSlide, defaultHeader }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const { width } = useWindowDimensions();
    const [help] = useState(t`Get the red car safely to the other side of the bridge.`);
    const [carData] = useState<TCar[]>([
        { id: 'go-1', pos: 0, moving: true, direction: 'clockwise', onComplete: () => setTimeout(() => setShow(true), 1000) },
        { id: 'gi-1', pos: width, moving: true, direction: 'anticlockwise', loop: true },
        { id: 'gi-2', pos: width + 100, moving: true, direction: 'anticlockwise', loop: true },
        { id: 'gi-3', pos: width + 200, moving: true, direction: 'anticlockwise', loop: true },
        { id: 'gi-4', pos: width + 300, moving: true, direction: 'anticlockwise', loop: true },
        { id: 'gi-5', pos: width + 400, moving: true, direction: 'anticlockwise', loop: true },

    ]);
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`Excellent work. You managed to guide the car safely across the bridge and out of Red Town!`,
            textSpriteName: `35`,
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`Well done for breaking down the problem into smaller parts by looking at each traffic light individually.`,
            textSpriteName: `36`,
            buttonText: t`Next`,
            buttonAction: nextSlide,
        },
    ];

    useEffect(() => {
        let t = setTimeout(() => data.playVoiceover(`34`), 0);

        return () => {
            clearTimeout(t);
        }
    }, []);

    const [showCrash, setShowCrash] = useState(false);
    const [paused, setPaused] = useState(false);
    const [resetGame, setResetGame] = useState(false);


    const [t1, setT1] = useState<TTrafficLight>({
        red: { enabled: true }
    });
    const [showEditor1, setShowEditor1] = useState(false);
    const [trafficLight1, setTrafficLight1] = useState<TRoadLight>({
        sequence: [
        ],
        highlight: true,
        pos: 1280 - 300,
        direction: 'clockwise',
        onClick: () => setShowEditor1(true),

    });

    useEffect(() => {
        console.log('t1 change', t1)
        setTrafficLight1({
            ...trafficLight1,
            sequence: [
                { ...t1, duration: 1000 }
            ]
        })
    }, [t1])

    const [t2, setT2] = useState<TTrafficLight>({});
    const [showEditor2, setShowEditor2] = useState(false);

    const [trafficLight2, setTrafficLight2] = useState<TRoadLight>({
        sequence: [
        ],
        highlight: true,
        pos: 1280 - 300,
        direction: 'anticlockwise',
        onClick: () => setShowEditor2(true),

    });

    useEffect(() => {
        console.log('t2 change', t2)
        setTrafficLight2({
            ...trafficLight2,
            sequence: [
                { ...t2, duration: 1000 }
            ]
        })
    }, [t2])

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <Scene
                nextSlide={nextSlide}
                roadworks={false}
                help={help}
                crash={false}
                lights={false}
                road={{
                    carData: carData,
                    trafficLight1: trafficLight1,
                    trafficLight2: trafficLight2,
                    onCrash: () => {
                        setPaused(true);
                        setShowCrash(true)
                    },
                    paused: paused,
                    setPause: setPaused,
                    gameReset: resetGame

                }}// , setResetAction: setResetAction
            >
                <>
                    {show ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} /> : null}
                    {showCrash ? <CrashModal onClick={() => {
                        setResetGame(!resetGame)
                        setShowCrash(false)
                        setPaused(true);
                        // setPaused(false); NEED TO RESET AS WELL
                    }}></CrashModal> : null}
                    {showEditor1 ?

                        <TrafficLightEditorStatic onClose={() => setShowEditor1(false)} onAnswerSubmit={(light) => {
                            setT1(light);
                            setShowEditor1(false);
                        }}></TrafficLightEditorStatic>
                        : null}
                    {showEditor2 ?

                        <TrafficLightEditorStatic onClose={() => setShowEditor2(false)} onAnswerSubmit={(light) => {
                            setT2(light);
                            setShowEditor2(false);
                        }}></TrafficLightEditorStatic>
                        : null}
                    {/* GAME TO GET THE ONE GREEN CAR THROUGH THE LIGHTS WITH HEAVY ONCOMING TRAFFIC (TASK2) */}
                </>
            </Scene>
        </Slide>
    );
}

export default TouristGame;
