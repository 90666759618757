import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/brand/logo-barefoot.svg';
import { filter } from '../../../helpers/filter';
import sfx from '../../../services/sounds';
import Module, { IModule } from '../../core/Module';
import MuteButton from '../../misc/MuteButton';
import { VolumeContext } from '../../misc/volume-context';
import intro from './units/1-intro';
import patterns from './units/2-patterns';
import algorithms from './units/3-algorithms';
import algorithms2 from './units/4-algorithms2';
import goodbye from './units/5-goodbye';
import EndSlide from './units/EndSlide';
import StartSlide from './units/StartSlide';




export type SortingData = {
    name: string;
    setName: (name: string) => void;
};

const Sorting: React.FC<IModule> = ({ onComplete }) => {
    const [name, setName] = useState(null);
    const { volume } = useContext(VolumeContext);

    const slides = [StartSlide, ...intro, ...patterns, ...algorithms, ...algorithms2, ...goodbye, EndSlide];

    const barefootLogo = (
        <div>
            <img src={logo} height="50px" alt="BT-Barefoot" />
            <MuteButton></MuteButton>
        </div>
    );

    useEffect(() => {
        setName(filter.clean(name ?? ''));
    }, [name, setName]);

    useEffect(() => {
        let theme = sfx.themeFarm.load().play();
        return () => {
            if (theme !== undefined) {
                sfx.themeFarm.stop(theme);
            }
        };
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Sorting it Out! | Barefoot Games</title>
            </Helmet>
            <Module
                devIndex={0}
                id="f31e7c20-d997-4a63-b6eb-2675ba58fa01"
                name="sorting"
                slides={slides}
                onComplete={onComplete}
                defaultHeader={barefootLogo}
                data={{ name, setName }}
            />
        </React.Fragment>
    );
};

export default Sorting;
