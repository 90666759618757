import React from 'react';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { Puffed } from '../../characters/Pufferfish';

const PufferfishIntro2: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue: DialogueData[] = [
        {
            character: Puffed,
            text: t`Hold on, what’s that noise? Oh it's the phone ringing again! You better answer it!`,
            textSpriteName: 'pufferfish-1-3',
            buttonText: t`Check phone`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default PufferfishIntro2;
