import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Kitty from '../../characters/Kitty';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import debugging from '../../../../../assets/icons/icon_debugging.svg';
import { ColourfulKitsData } from '../..';
import SkillModal from '../../../../general/SkillModal';
import { t } from 'ttag';

const DebugGame2Outro: React.FC<ISlide<ColourfulKitsData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Kitty,
            text: t`Wonderful work ${data.name}! You helped me find the mistakes in the kit and then fixed them, this is an example of debugging!`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`DEBUGGING`}
                skillImage={debugging}
                skillDescription={t`You can spot the mistakes and are able to fix them.`}
            />
        </Slide>
    );
};

export default DebugGame2Outro;
