import React, { useEffect, useState, useContext } from 'react';
import VoiceoverContext from '../../../../misc/voiceover-context';
import { CyberSecurityData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import Phished from '../../components/Phished';
import { t } from 'ttag';

const UserPhished: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        const phished = setTimeout(() => playVoiceover('pufferfish-phished-desc'), 4000);
        return () => { clearTimeout(phished) }
    }, []);
    return (
        <Slide header={defaultHeader} backgroundColor={'#872666'}>
            <Phished
                pointsLost={20}
                gameData={data}
                onClose={nextSlide}
                show={true}
                explanation={t`You gave your details to a stranger and that deal seemed too good to be true!`}
            ></Phished>
        </Slide>
    );
};

export default UserPhished;
