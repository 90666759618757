import React, { useState, useEffect } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { TAlgorithmQuestion } from './AlgorithmGame';
import { t } from 'ttag';

type TOperators = 'Add' | 'Subtract' | 'Multiply' | 'Divide';

const AlgorithmSelector: React.FC<{
    onSelectionChange: (sid: string, selection: string) => void;
    sid: string;
    question: TAlgorithmQuestion;
}> = ({ onSelectionChange, sid, question }) => {
    const [valOne, setValOne] = useState<TOperators | string>('Add');
    const [valTwo, setValTwo] = useState<number>(1);

    useEffect(() => {
        // send to parent
        onSelectionChange(sid, valOne + valTwo);
    }, [valOne, valTwo]);

    useEffect(() => {
        setValOne('Add');
        setValTwo(1);
    }, [question]);

    return (
        <Form>
            <Form.Group controlId="formAlgoSelect">
                <Form.Control
                    as="select"
                    value={valOne}
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setValOne(e.currentTarget.value)}
                >
                    <option value="Add">{t`Add`}</option>
                    <option value="Subtract">{t`Subtract`}</option>
                    <option value="Multiply">{t`Multiply`}</option>
                    <option value="Divide">{t`Divide`}</option>
                </Form.Control>

                {/* <Form.Label>by</Form.Label> */}
                <Form.Control
                    as="select"
                    value={valTwo}
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) =>
                        setValTwo(parseInt(e.currentTarget.value))
                    }
                >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                </Form.Control>
            </Form.Group>
        </Form>
    );
};

export default AlgorithmSelector;
