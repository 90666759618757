import React, { useState } from 'react';
import { t } from 'ttag';
import { TrafficData } from '../..';
import useWindowDimensions from '../../../../../hooks/window';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import EngineerAisha from '../../characters/EngineerAisha';
import DialogueOverlay from '../../components/DialogueOverlay';
import Scene from '../../components/interactive/Scene';
import { TCar, TRoadLight } from '../../components/interactive/scene/Road';
import TrafficTimings from '../../components/interactive/timings/TrafficTimings';






const SequenceSimulation: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
  const [footer, buttonRenderer] = useFooterButtonRenderer();
  const [show, setShow] = useState(false);
  const [dialogueShow, setDialogueShow] = useState(true);
  const [nextDialogue, setNextDialogue] = useState<any>(null);
  const { width } = useWindowDimensions();



  const openSequencer = () => {
    setShow(true);
    setDialogueShow(false);
  }

  const gameData = [
    {
      data: true
    }

  ]

  const dialogue: DialogueData[] = [
    {
      character: EngineerAisha,
      text: t`That looks pretty good. Let's see how this goes...`,
      textSpriteName: '73',
      buttonText: t`Next`,
    },
    {
      character: EngineerAisha,
      text: t`Hmmm... Something’s not right. The light is playing the correct sequence, but way too fast. Looks like we need the correct timings too!`,
      textSpriteName: '74',
      buttonText: t`Next`,

    },
    {
      character: EngineerAisha,
      text: t`Let’s use decomposition again and break down the traffic light sequence to look at the timing of each light.`,
      textSpriteName: '75',
      buttonText: t`Use Sequencer 3000`,
      buttonAction: openSequencer
    },
  ];

  const trafficLight1: TRoadLight = {
    sequence: [
      { red: { enabled: true }, duration: 1200 },
      { amber: { enabled: true }, duration: 100 },
      { green: { enabled: true }, duration: 100 },
    ],
    pos: width - 300, // TODO REMOVE THINGS LIKE THIS
    direction: 'clockwise'
  };
  const trafficLight2: TRoadLight = {
    sequence: [
      { red: { enabled: true }, duration: 1200 },
      { amber: { enabled: true }, duration: 100 },
      { green: { enabled: true }, duration: 100 },
    ],
    pos: width + 300, // TODO REMOVE THINGS LIKE THIS
    direction: 'anticlockwise'
  };
  const [carData, setCarData] = useState<TCar[]>([
    { id: 'go-1', pos: 0, moving: true, direction: 'clockwise', loop: true },
    { id: 'go-2', pos: width, moving: true, direction: 'anticlockwise', loop: true },
  ]);


  return (
    <Slide header={defaultHeader} footerHidden={true}>
      <Scene nextSlide={nextSlide} lights={false} road={{ carData: carData, trafficLight1, trafficLight2 }} data={data}>
        {dialogueShow ?
          <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} />
          : null}
        {show ?
          <TrafficTimings gameData={gameData} onGameComplete={nextSlide}></TrafficTimings>
          : null}


      </Scene>
    </Slide>
  );
}

export default SequenceSimulation;
