import { remove } from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Button, Row, Col } from 'react-bootstrap';

import { t } from 'ttag';
import sfx from '../../../../../../services/sounds';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import VolumeContext from '../../../../../misc/volume-context';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import table from '../../../assets/gifts/table.svg';
import box from '../../../assets/gifts/box.svg';
import workshop from '../../../assets/boxing/workshop2.svg';
import GiftBuilderSelector from './GiftBuilderSelector';
import Gift from './Gift';
import CorrectModal from './CorrectModal';

export type TGiftBuilderQuestion = GameQuestion & {
    pattern: TGiftPattern;
    size: TGiftSize;
    color: TGiftColor;
};

export type TGiftSize = number;
export type TGiftPattern = 'snowflake' | 'star' | 'tree';
export type TGiftColor = '#6B7280' | '#EF4444' | '#F59E0B' | '#10B981' | '#3B82F6' | '#8B5CF6' | '#EC4899';

interface GiftboxProps {}

const GiftBuilder: React.FC<GameProps<TGiftBuilderQuestion> & GiftboxProps> = ({
    gameData,
    index = 0,
    onGameComplete,
}) => {
    const [show, setShow] = useState(false);
    const { volume } = useContext(VolumeContext);

    const [color, setColor] = useState<TGiftColor>('#6B7280');
    const [size, setSize] = useState<TGiftSize>(1);
    const [pattern, setPattern] = useState<TGiftPattern>('snowflake');

    const handleGameComplete = () => {
        setShow(true);
        onGameComplete();
    };

    const handleNextQuestion = () => {
        setShow(false);
    };

    const [question, nextQuestion] = useGameLogic<TGiftBuilderQuestion>(
        gameData,
        handleGameComplete,
        handleNextQuestion,
        index,
    );

    const handleModalClose = () => {
        setShow(false);
        setTimeout(nextQuestion, 0);
    };

    const handleQuestionComplete = () => {
        setShow(true);
        if (volume) sfx.success.play();
    };

    useEffect(() => {
        // Change to on button press?
        if (question && question.color === color && question.pattern === pattern && question.size === size) {
            if (volume) sfx.correct.play();
            setTimeout(handleQuestionComplete, 1000);
        }
    }, [color, pattern, size, question]);

    if (!question) {
        return <p>{t`Question not found`}</p>;
    }

    return (
        <React.Fragment>
            <CorrectModal show={show} question={question} onClose={handleModalClose}></CorrectModal>
            <GameWrapper>
                <SectionEdit>
                    <div className="w-100">
                        <Hint>
                            <i className="fa fa-info"></i>
                            Tinker with the pattern, size and colour below to make the gifts look the same.
                        </Hint>
                        <EditCard>
                            <Row className="align-items-center mb-2">
                                <Col>
                                    <p>PATTERN</p>
                                </Col>
                                <Col xs="7" className="text-right">
                                    <GiftBuilderSelector type={'pattern'} onSelect={setPattern}></GiftBuilderSelector>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col>
                                    <p>Size</p>
                                </Col>
                                <Col xs="7" className="text-right">
                                    <GiftBuilderSelector type={'size'} onSelect={setSize}></GiftBuilderSelector>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-2">
                                <Col>
                                    <p>Colour</p>
                                </Col>
                                <Col xs="7" className="text-right">
                                    <GiftBuilderSelector type={'colour'} onSelect={setColor}></GiftBuilderSelector>
                                </Col>
                            </Row>
                        </EditCard>
                    </div>
                </SectionEdit>
                <SectionTable>
                    <TableTop>
                        <GiftWrapper>
                            <GiftPlaceholder>
                                <Gift size={size} color={color} pattern={pattern}></Gift>
                            </GiftPlaceholder>
                            <GiftPlaceholder>
                                <Gift size={question.size} color={question.color} pattern={question.pattern}></Gift>
                            </GiftPlaceholder>
                        </GiftWrapper>
                    </TableTop>
                    <TableBottom></TableBottom>
                </SectionTable>
                <SubmitContainer>
                    <DialProgress></DialProgress>
                    {/* <Button>Box it up!</Button> */}
                </SubmitContainer>
            </GameWrapper>
        </React.Fragment>
    );
};

const Hint = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    position: relative;
    i {
        position: absolute;
        left: 5px;
        top: -15px;
        height: 28px;
        width: 28px;
        font-size: 1rem;
        text-align: center;
        border-radius: 100px;
        padding: 5px 10px;
        color: #00a0d6;
        background-color: #ffffff;
    }
`;

const DialProgress = styled.div``;

const GiftPlaceholder = styled.div`
    width: 50%;
    max-width: 150px;
    display: inline-block;
    @media (min-width: 1000px) {
        max-width: 250px;
    }
    @media (min-width: 1000px) and (min-height: 1000px) {
        max-width: 350px;
    }
`;

const GiftWrapper = styled.div`
    position: absolute;
    bottom: 25px;
    width: 100%;
    text-align: center;
`;

const TableTop = styled.div`
    height: 50px;
    background-color: #ffdb9e;
    position: relative;
    z-index: 3;
`;

const TableBottom = styled.div`
    height: 100px;
    border-top: solid 10px #ffc57a;
    background-color: #572109;
`;

interface ColorProps {
    color: string;
}
const EditCard = styled.div`
    background: #00a0d6;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    display: block;
    margin: 0.5rem auto 0 auto;
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    position: relative;
    z-index: 4;
    p {
        font-family: 'BT Font Extra Bold';
        margin-bottom: 0;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }
`;

const SectionEdit = styled.div`
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-bottom: 3rem;
`;

const SectionTable = styled.div`
    flex: 0 0 auto;
    align-self: flex-end;
    position: relative;
    width: 100%;
    text-align: center;
`;

const SubmitContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    z-index: 999;
`;
const GameWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f5e5d8;
    display: flex;
    flex-flow: column;
    margin-top: 65px;
    background: url(${workshop}) no-repeat center center fixed;
    background-size: cover;
`;

export default GiftBuilder;
