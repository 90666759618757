import fiona from '../../../../assets/characters/farmer-leanne.svg';
import leanne from '../../../../assets/characters/farmer-fiona.svg';
import jacob from '../../../../assets/characters/farmer-jacob.svg';
import allZookeepers from '../../../../assets/characters/farmers-trio.svg';
import { TCharacter } from '../../../general/Character';
import { t } from 'ttag';

export const ZookeeperLeanne: TCharacter = {
    characterName: t`Farmer Leanne`,
    characterImage: leanne,
    characterWidth: 100,
    characterHeight: 180,
};

export const ZookeeperFiona: TCharacter = {
    characterName: t`Farmer Fiona`,
    characterImage: fiona,
    characterWidth: 100,
    characterHeight: 180,
};

export const ZookeeperJacob: TCharacter = {
    characterName: t`Farmer Jacob`,
    characterImage: jacob,
    characterWidth: 90,
    characterHeight: 160,
};

export const AllZookeepers: TCharacter = {
    characterName: t`Leanne, Fiona & Jacob`,
    characterImage: allZookeepers,
    characterWidth: 220,
    characterHeight: 180,
};
