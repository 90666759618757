import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';
import SkillModal from '../../../../general/SkillModal';
import evaluation from '../../../../../assets/icons/icon_evaluation.svg';

const VariablesQuiz: React.FC<ISlide> = ({ defaultHeader, nextSlide, gotoSlide, currentIndex }) => {
    const [show, setShow] = useState(false);

    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`Which of these rules will help keep track of how much Zinon is collected?`,
            answers: [
                { text: t`If a small asteroid is blasted then increase ‘Zinon’ by 2`, correct: false },
                { text: t`If a large asteroid is blasted then reduce ‘Zinon’ by 2`, correct: false },
                { text: t`If a large asteroid is blasted then increase ‘Zinon’ by 2`, correct: true },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Row>
                <Col xs={12} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }} xl={{ offset: 3, span: 6 }}>
                    <Content type={'island'} styles={'p-3 mt-2'}>
                        <MultipleChoice
                            gameData={gameData}
                            onGameComplete={() => setShow(true)}
                            onIncorrectAnswer={() => gotoSlide(currentIndex - 1)}
                        />
                        <SkillModal
                            show={show}
                            onClose={nextSlide}
                            skillName={t`Evaluation`}
                            skillDescription={t`Evaluation is about making judgements about what you have done and thinking about how you might do something differently next time.`}
                            skillImage={evaluation}
                        ></SkillModal>
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default VariablesQuiz;
