import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import AgentGreen from '../../characters/AgentGreen';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import ModalOverlay from '../../../../general/ModalOverlay';
import { Button } from 'react-bootstrap';
import caesar_top from '../../../../../assets/misc/caesar_top.svg';
import caesar_bottom from '../../../../../assets/misc/caesar_bottom.svg';
import { motion } from 'framer-motion';
import { t, jt } from 'ttag';

const LabIntro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: AgentGreen,
            text: t`Awesome! You have seen what a cipher does and even found out the name of the one the enemy is using.`,
            buttonText: t`Next`,
        },
        {
            character: AgentGreen,
            text: t`I am working on a big machine to try and decipher the enemy messages myself. Without access to one of their Enigma machines it’s proving to be pretty difficult!`,
            buttonText: t`Next`,
        },
        {
            character: AgentGreen,
            text: t`How about you try a simpler cipher to practise cracking codes in the meantime?`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];

    let decipher = <strong>{t`decipher`}</strong>;
    let inner = <strong>{t`inner`}</strong>;
    let outer = <strong>{t`outer`}</strong>;

    return (
        <Slide header={defaultHeader} footer={footer}>
            <ModalOverlay header={<p></p>} footer={<Button onClick={nextSlide}>{t`Continue`}</Button>} show={show}>
                <div className="w-75 d-block mx-auto">
                    <div className="cc-cipher__wrapper">
                        <div className={'cc-cipher'}>
                            <motion.img
                                animate={{
                                    scale: [0.95, 1, 1, 0.95, 0.95, 1, 1, 0.95],
                                    rotate: [0, 0, 221.54, 221.54, 221.54, 221.54, 0, 0],
                                }}
                                transition={{
                                    duration: 6,
                                    ease: 'easeInOut',
                                    times: [0, 0.1, 0.3, 0.4, 0.6, 0.7, 0.9, 1],
                                    loop: Infinity,
                                    repeatDelay: 4,
                                }}
                                className={'cc-cipher__disc cc-cipher__disc--top'}
                                src={caesar_top}
                                width={'500px'}
                                height={'500px'}
                            />
                            <img
                                className={'cc-cipher__disc cc-cipher__disc--bottom'}
                                src={caesar_bottom}
                                width={'500px'}
                                height={'500px'}
                                alt={t`Caesar Cipher wheel`}
                            />
                        </div>
                    </div>
                    <p className={'text-center'}>
                        {jt`Using a given offset, you can ${decipher} a code by matching its letters in the ${inner} wheel to those in the ${outer} wheel.`}
                    </p>
                </div>
            </ModalOverlay>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default LabIntro;
