import React, { useEffect, useState, useContext } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { CyberSecurityData } from '../..';
import Phished from '../../components/Phished';
import Dodged from '../../components/Dodged';
import { motion } from 'framer-motion';
import { t } from 'ttag';
import VoiceoverContext from '../../../../misc/voiceover-context';

const PhishingSite: React.FC<ISlide<CyberSecurityData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    const [show, setShow] = useState(false);
    const [dodgedShow, setDodgedShow] = useState(false);
    const [usernameActive, setUsernameActive] = useState(false);
    const [passwordActive, setPasswordActive] = useState(false);

    const { playVoiceover } = useContext(VoiceoverContext);

    const handlePhished = () => {
        setShow(true);
        const phished = setTimeout(() => playVoiceover('endturtle-phished-desc'), 4000);
        return () => { clearTimeout(phished) }
    }
    const handleDodged = () => {
        setDodgedShow(true);
        const dodged = setTimeout(() => playVoiceover('endturtle-dodged-desc'), 4000);
        return () => { clearTimeout(dodged) }
    }

    return (
        <Slide header={defaultHeader}>
            {show ? (
                <Phished
                    pointsLost={20}
                    gameData={data}
                    onClose={nextSlide}
                    show={show}
                    explanation={t`You gave away your personal details! You should close unexpected pop-ups, especially when they offer something that looks too good to be true!`}
                ></Phished>
            ) : null}
            {dodgedShow ? (
                <Dodged
                    points={30}
                    gameData={data}
                    onClose={() => gotoSlide(currentIndex + 2)}
                    show={dodgedShow}
                    explanation={t`You closed that unexpected pop-up! Dismissing pop-ups asking you to do something urgently is usually the best course of action.`}
                ></Dodged>
            ) : null}

            <PopUp animate={{ scale: [0.3, 1], opacity: [0, 1] }} transition={{ duration: 0.5, ease: 'easeInOut' }}>
                <h3>{t`Complete`}</h3>
                <p>{t`Quick! Complete the game now.`}</p>
                <span>{t`Username`}:</span>
                <InputBtn active={usernameActive} onClick={() => setUsernameActive(true)}>
                    {usernameActive ? (
                        <>
                            <i className={'fa fa-check'}></i>
                            {data.name}@fishmail.co.uk
                        </>
                    ) : (
                        <>
                            <i className={'fa fa-plus'}></i>{ t`Enter email` }
                        </>
                    )}
                </InputBtn>
                <span>{t`Password`}:</span>
                <InputBtn active={passwordActive} onClick={() => setPasswordActive(true)}>
                    {passwordActive ? (
                        <>
                            <i className={'fa fa-check'}></i>fish!1451soup#
                        </>
                    ) : (
                        <>
                            <i className={'fa fa-plus'}></i>
                            {t`Enter password`}
                        </>
                    )}
                </InputBtn>
                <StyledButton
                    onClick={passwordActive && usernameActive ? () => handlePhished(): undefined}
                    className={passwordActive && usernameActive ? '' : 'disabled'}
                >{t`Complete Game`}</StyledButton>
                <Close role="button" className={'fa fa-times'} onClick={() => handleDodged()}></Close>
            </PopUp>
        </Slide>
    );
};

interface InputBtnProps {
    active: boolean;
}
const StyledButton = styled.button`
    margin-top: 1.5rem;
    width: 100%;
    border-radius: 10px;
    font-family: 'Arial';
    text-align: center;
    background-color: #6400aa;
    border: 0 !important;
    color: #ffffff;
    padding: 1rem;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &.disabled {
        background-color: #999999;
        box-shadow: none;
        cursor: not-allowed;
    }
`;
const InputBtn = styled(Button)<InputBtnProps>`
    text-align: left;
    border: 1px solid #999999;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.75rem 1.5rem;
    font-family: 'BT Font Extra Bold';
    letter-spacing: 0;
    i {
        margin-right: 10px;
    }

    ${(p) =>
        p.active
            ? 'color: #ffffff !important; &:active{color: #ffffff;} background-color: #9969FF !important;'
            : 'color: #B7B7B7 !important;  &:active{color: #ffffff;} '}
`;

const PopUp = styled(motion.div)`
    padding: 3%;
    border-radius: 10px;
    background-color: #ffffff;
    display: block;
    max-width: 450px;
    margin: 1rem auto;
    p,span, h3 {color: #6400aa}
    position: relative;
    button{
        width 100%;
    }
    span {
        display: block;
        margin-top: 1rem;
        display: flex;
    }
    input {
        margin: 0 10px;
        flex: 1;
        border: 1px solid #999999;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
`;

const Close = styled.i`
    position: absolute;
    top: 10px;
    right: 45px;
    @media (min-width: 768px){
        top: 15px;
    }
    cursor: pointer;
    color: #6400aa;
    &:hover {
        opacity: 0.7;
    }
    @media (min-width: 768px) {
        right: 15px;
    }
    width: 37px;
    height: 37px;
    padding: 10px;
    border-radius: 30px;
    background-color: rgba(0,0,0,0.1);
    text-align:center;
`;

export default PhishingSite;
