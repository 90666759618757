import { motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { TrafficData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import { DialogueData } from '../../../../general/Dialogue';
import ModalOverlay from '../../../../general/ModalOverlay';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import medal from '../../assets/misc/medal.png';
import town_scene from '../../assets/scene/town_scene.svg';
import town_scene_welsh from '../../assets/scene/town_scene-welsh.svg';
import EngineerShahid from '../../characters/EngineerShahid';
import DialogueOverlay from '../../components/DialogueOverlay';
import Cookies from 'js-cookie';

const lang = Cookies.get('bfg_lang');

const Intro: React.FC<ISlide<TrafficData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);
    const [dialogueShow, setDialogueShow] = useState(true);
    const [nextDialogue, setNextDialogue] = useState<any>(null);

    const handleMedalShow = () => {
        setShow(true);
        data.playVoiceover('103')
        // setDialogueShow(false);
    }

    const dialogue: DialogueData[] = [
        {
            character: EngineerShahid,
            text: t`You have done brilliantly learning about decomposition and algorithms, you certainly deserve a medal...`,
            textSpriteName: '102',
            buttonText: t`Next`,
            buttonAction: handleMedalShow
        },
        {
            character: EngineerShahid,
            text: t`You successfully used decomposition today to break the problems down into smaller parts. You also made an algorithm to program the traffic lights automatically and help get the cars across the bridge safely!`,
            textSpriteName: '105',
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`We can't thank you enough, ${data.name}. The residents of Red Town and Blue Town are over the moon.`,
            textSpriteName: '106',
            buttonText: t`Next`,
        },
        {
            character: EngineerShahid,
            text: t`We hope to see you again soon, you’re always welcome here. Who knows, one day we might need a roundabout!`,
            textSpriteName: '107',
            buttonText: t`Next`,
            buttonAction: nextSlide
        },
    ];

    const handleModalClose = () => {
        data.stopVoiceover();
        nextDialogue();
        setShow(false);
    }

    return (
        <Slide header={defaultHeader} footerHidden={true}>
            <TownWrapper>
                {dialogueShow ? <DialogueOverlay buttonHandler={buttonRenderer} dialogue={dialogue} nextDialogueHandler={setNextDialogue} /> : null}
            </TownWrapper>
            <ModalOverlay show={show} backgroundColor={'rgba(0,0,0,0.5)'} header={null} headerHidden={true} footer={null}>
                <div onClick={handleModalClose} className="text-center">
                    <motion.img animate={{ scale: [0, 1] }} transition={{ duration: 0.5 }} src={medal} width={'150'} height={'150'} />
                    <h3 className={'my-3'}>{t`AWARD RECEIVED`}</h3>
                    <h5>{t`Decomposition Champion`}</h5>
                    <p>{t`Click to continue`}</p>
                </div>

            </ModalOverlay>
        </Slide>
    );
};


const TownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${lang === 'cy' ? town_scene_welsh : town_scene}');
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content:center;
`;

export default Intro;
