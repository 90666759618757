import React, { useContext, useEffect } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button, Row, Col } from 'react-bootstrap';
import Character from '../../../../general/Character';
import { CyberSecurityData } from '../../';
import { t } from 'ttag';
import Turtle from '../../characters/Turtle';
import VoiceoverContext from '../../../../misc/voiceover-context';

const NameInput: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('turtle-nickname');
    }, [])

    const next = data.name ? (
        <Button className="btn-3d" onClick={() => nextSlide()}>
            {t`Next`}
        </Button>
    ) : null;

    return (
        <Slide header={defaultHeader} footer={next}>
            <Character character={Turtle}>
                <Row>
                    <Col xs="12" md={{ span: 6, offset: 3 }} className="text-center">
                        <h2>{t`What is your nickname?`}</h2>
                        <input
                            className="form-control"
                            type="text"
                            placeholder={t`Nickname`}
                            onChange={(e) => data.setName(e.target.value)}
                        />
                    </Col>
                </Row>
            </Character>
        </Slide>
    );
};

export default NameInput;
