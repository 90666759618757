import React, { useEffect } from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import sfx from '../../../../../services/sounds';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import Seal from '../../characters/Seal';

const SealIntro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    useEffect(() => {
        data.playMusic(sfx.themeCybersecurityFish);
    }, []);

    const dialogue: DialogueData[] = [
        {
            character: Seal,
            text: t`Hi, I'm ${Seal.characterName} - I’m just watching this hilarious new NikNak video... Have you got it? You should follow me! I do some cool tricks on there.`,
            textSpriteName: 'fish-1-1',
            buttonText: t`Sure, I’ll follow you!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default SealIntro;
