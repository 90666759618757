import React, { useState, useEffect, useContext } from 'react';
import { GameProps, GameQuestion, useGameLogic } from '../../../../../core/Game';
import { t } from 'ttag';
import { Button, Row, Col, Table } from 'react-bootstrap';
import Email from './Email';
import styled from 'styled-components';
import Turtle from '../../../characters/Turtle';
import { motion } from 'framer-motion';
import VoiceoverContext from '../../../../../misc/voiceover-context';
import Cookies from 'js-cookie';


export type TEmailClientQuestion = GameQuestion & {
    subject: string;
    name: string;
    email: string;
    content: string;
    contentSpriteName: string;
    attachment?: string;
    read: boolean;
    trustworthy: boolean;
    dodgyEmail?: boolean;
    dodgyAttachment?: boolean;
    dodgyBody?: boolean;
    onOpen: () => void;
    completed?: boolean;
};

type TEmailClientAction = {
    text: string;
    action?: () => void;
    icon?: string;
};

const EmailClient: React.FC<GameProps<TEmailClientQuestion> & { onIncorrect: () => void }> = ({
    gameData,
    index = 0,
    onGameComplete,
    onIncorrect,
}) => {
    const [openEmail, setOpenEmail] = useState<TEmailClientQuestion | null>(null);
    const [unread, setUnread] = useState(gameData.length);

    const [completed, setCompleted] = useState(0);

    const [localGameData, setLocalGameData] = useState(gameData);

    const { playVoiceover } = useContext(VoiceoverContext);

    useEffect(() => {
        playVoiceover('octo-help-1');
    }, [])


    useEffect(() => {
        if (localGameData && openEmail) {
            localGameData.map((mail) => {
                if (mail.subject === openEmail.subject && !openEmail.read) {
                    setUnread(unread - 1);
                    openEmail.read = true;
                }

                // shouldnt this be mail???
                return openEmail;
            });
        }
    }, [openEmail, localGameData]);

    useEffect(() => {
        if (localGameData) {
            if (localGameData.length === completed) {
                onGameComplete();
            }
        }
    }, [completed, localGameData]);

    if (!localGameData) {
        return null;
    }

    if (openEmail) {
        return (
            <Email
                email={openEmail}
                close={() => setOpenEmail(null)}
                onIncorrect={onIncorrect}
                onComplete={() => {
                    setCompleted(completed + 1);

                    localGameData.map((mail) => {
                        if (mail.subject === openEmail.subject) {
                            openEmail.completed = true;
                        }

                        return openEmail;
                    });

                    setOpenEmail(null);
                }}
            ></Email>
        );
    }

    return (
        <>
            <LaptopContent>
                <Row>
                    <Col xs={'12'} sm={{ span: '10', offset: '1' }}>
                        <h3>
                            {t`BarefootMail`} {unread && (unread > 0) ? `(${unread})` : null}{' '}
                        </h3>
                        {localGameData.map((email, index) => (
                            <EmailButton
                                key={index + 'email'}
                                onClick={() => (!email.completed ? setOpenEmail(email) : undefined)}
                                read={email.read}
                                completed={email.completed}
                            >
                                {email.completed ? (
                                    <i className="fas fa-check mr-2"></i>
                                ) : (
                                    <i className="far fa-question-circle mr-2"></i>
                                )}{' '}
                                {email.read ? email.subject : <b>{email.subject}</b>}
                            </EmailButton>
                        ))}
                    </Col>
                </Row>
                {!openEmail ? (
                    <Trustworthy
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ y: [25, 0], scale: [0.7, 1], opacity: [0, 1] }}
                        transition={{ duration: 0.6, ease: 'easeInOut' }}
                    >
                        <Message welsh={((Cookies.get('bfg_lang') ?? 'en') !== 'en')}>
                            <Character
                                animate={{ y: [0, -10, 0, -10, 0] }}
                                transition={{ loop: Infinity, repeatDelay: 10, duration: 1, ease: 'easeInOut' }}
                                alt={Turtle.characterName}
                                src={Turtle.characterImage}
                                width={Turtle.characterWidth}
                            />
                            <p>{t`You've got mail! Open each email and see if you can spot anything suspicious.`}</p>
                        </Message>
                    </Trustworthy>
                ) : null}
            </LaptopContent>
        </>
    );
};

interface EmailProps {
    read: boolean;
    completed?: boolean;
}

const EmailButton = styled.button<EmailProps>`
    display: block;
    padding: 1% 2%;
    width: 100%;
    text-align: left;
    color: #6400aa;
    background-color: ${(props: EmailProps) => (props.read ? (props.completed ? '#d0eacf' : '#F1EFFF') : '#ffffff')};
    border: 0;
    border-bottom: solid #e8e8e8 1px;
    font-family: BT Font Bold;
    ${(props: EmailProps) => (props.read ? 'font-family: BT Font Regular;' : null)}
`;
const LaptopContent = styled.div`
    padding: 5% 1%;

    @media screen and (min-width: 768px) {
        h3 {
            font-size: 1.5rem;
        }
    }
`;

const Trustworthy = styled(motion.div)`
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 320px;
    z-index: 999;
    left: calc(50% - 160px);
    p {
        margin-bottom: 5px !important;
        font-size: 18px;
        line-height: 24px;
    }
    h4 {
        font-size: 20px;
        line-height: 25px;
    }
`;

interface MessageProps {
    welsh: boolean
}

const Message = styled.div<MessageProps>`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 350px;
    height: 100%;
    position: relative;
    ${(props) => props.welsh ? 'p { font-size: 15px }' : ''}
`;

const Character = styled(motion.img)`
    position: absolute;
    z-index: -1;
    width: 50px;
    right: 5px;
    top: -50px;
`;

export default EmailClient;
