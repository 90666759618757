import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import RangerJack from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';

const ZooKeeperIntro: React.FC<ISlide> = ({ nextSlide, defaultHeader }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: RangerJack,
            text: t`Hi there! I’m Ranger Jack. 
            I have been having some trouble with the animals at the zoo.
            Can you help?`,
            buttonText: t`Yes, I would love to!`,
        },
        {
            character: RangerJack,
            text: t`Awesome! One thing before we get started... All rangers need to be called something! 
            What is your nickname?`,
            buttonText: t`My nickname is...`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default ZooKeeperIntro;
