import React, { useState } from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import Penguin from '../../characters/Penguin';
import { DialogueData } from '../../../../general/Dialogue';
import { CharacterDialogue } from '../../../../general/Character';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { t } from 'ttag';
import pattern from '../../assets/Trophy.png';
import SkillModal from '../../../../general/SkillModal';

const SendingOutro: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();
    const [show, setShow] = useState(false);

    const dialogue: DialogueData[] = [
        {
            character: Penguin,
            text: t`Amazing! You tinkered with the different combinations until we had the perfect present for each house.`,
            buttonText: t`Next`,
        },
        {
            character: Penguin,
            text: t`They are going to be so happy when they wake up, you are really becoming an expert at this!`,
            buttonText: t`Next`,
            buttonAction: () => setShow(true),
        },
    ];
    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
            <SkillModal
                show={show}
                onClose={nextSlide}
                skillName={t`TINKERING PRO`}
                skillImage={pattern}
                skillDescription={t`You have used tinkering to figure out how to complete multiple challenges!`}
            />
        </Slide>
    );
};

export default SendingOutro;
