import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { VolumeContext } from '../misc/volume-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import VoiceoverContext from './voiceover-context';

const VoiceoverMuteButton: React.FC = () => {
    const { voiceoverMuted, setVoiceoverMuted } = useContext(VolumeContext);
    const { voiceover } = useContext(VoiceoverContext);
    const icon = (!!voiceoverMuted) ?  faMicrophoneSlash : faMicrophone;//|| !volume
    
    useEffect(() => {
        if (voiceover) {
            voiceover.mute(!!voiceoverMuted );// || !volume ? true : false
        }

    }, [voiceoverMuted, voiceover]);

    if (voiceover === undefined || setVoiceoverMuted === undefined ) {
        return null;
    }

    return (
        <React.Fragment>
            <Button
                className={'btn--mute-voice'}
                onClick={() => {
                    return voiceoverMuted ? setVoiceoverMuted(false) : setVoiceoverMuted(true);
                }}
                //disabled={!volume}
            >
                <FontAwesomeIcon icon={icon} />
            </Button>
        </React.Fragment>
    );
};
export default VoiceoverMuteButton;
