import { useEffect, useState } from 'react';

export const useGameTimer: (
    seconds: number,
    onTimerFinish: () => void,
) => [number, boolean, () => void, () => void, (time: number) => void] = (seconds, onTimerFinish) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [isActive, setIsActive] = useState(false);

    const start = () => {
        setIsActive(true);
    };

    const reset = () => {
        setTimeLeft(seconds);
        setIsActive(false);
    };

    useEffect(() => {
        let interval: any = null;

        if (!timeLeft) {
            return onTimerFinish();
        }

        if (isActive) {
            interval = setInterval(() => {
                setTimeLeft((seconds) => seconds - 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timeLeft, isActive]);

    return [timeLeft, isActive, start, reset, setTimeLeft]; //, startTimer, resetTimer, onTimerFinish
};
