import { addLocale, useLocale } from 'ttag';
import Cookies from 'js-cookie';

const LOCALE_COOKIE = 'bfg_lang';

function getLocale() {
    return Cookies.get(LOCALE_COOKIE) ?? 'en';
}

export function saveLocale(locale: string) {
    Cookies.set(LOCALE_COOKIE, locale);
}

// setup
const locale = getLocale();

// load json file with translations - welsh
if (locale === 'cy') {
    const cyTranslationsObj = require(`../../i18n/${locale}.po.json`);
    addLocale('cy', cyTranslationsObj);
    useLocale(locale);
}
