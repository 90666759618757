import React, { useContext, useEffect } from 'react';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import sfx from '../../../../../services/sounds';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { DialogueData } from '../../../../general/Dialogue';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import VolumeContext from '../../../../misc/volume-context';
import Pufferfish from '../../characters/Pufferfish';


const PufferfishIntro: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    useEffect(() => {
        data.playMusic(sfx.themeCybersecurityPufferfish);
    }, []);
    const { volume } = useContext(VolumeContext);

    const dialogue: DialogueData[] = [
        {
            character: Pufferfish,
            text: t`Hey, I'm Pufferfish! My friend Turtle told me you know a thing or two about phishing.`,
            textSpriteName: 'pufferfish-1-1',
            buttonText: t`Nice to meet you!`,
        },
        {
            character: Pufferfish,
            text: t`I have been getting funny calls from people offering me things.`,
            textSpriteName: 'pufferfish-1-2',
            buttonText: t`Next`,
            buttonAction: () => {
                if (volume) sfx.ringtone.play();
                nextSlide();
            },
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default PufferfishIntro;
