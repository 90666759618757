import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { CharacterDialogue } from '../../../../general/Character';
import { RangerJackSearch } from '../../characters/RangerJack';
import useFooterButtonRenderer from '../../../../misc/FooterButtonRenderer';
import { AbstractionData } from '../..';
import { t } from 'ttag';

const StormIntro2: React.FC<ISlide<AbstractionData>> = ({ nextSlide, defaultHeader, data }) => {
    const [footer, buttonRenderer] = useFooterButtonRenderer();

    const dialogue = [
        {
            character: RangerJackSearch,
            text: t`It’s a storm ${data.name}! Let me check on the animals to see if they are alright... `,
            buttonText: t`Oh no!`,
            buttonAction: nextSlide,
        },
    ];

    return (
        <Slide header={defaultHeader} footer={footer}>
            <CharacterDialogue dialogue={dialogue} buttonHandler={buttonRenderer} />
        </Slide>
    );
};

export default StormIntro2;
