import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'ttag';
import { VariablesData } from '../..';
import Slide, { ISlide } from '../../../../core/Slide';
import Content from '../../../../general/Content';
import MultipleChoice, { TMultiChoiceQuestion } from '../../../../interactive/quiz/multi-choice/MultiChoice';

const VariablesQuiz: React.FC<ISlide<VariablesData>> = ({
    defaultHeader,
    nextSlide,
    gotoSlide,
    currentIndex,
    data,
}) => {
    if (data.gameScore === 0) {
        // TODO: Redo if no score
    }

    const gameData: TMultiChoiceQuestion[] = [
        {
            question: t`I think I blew up...`,
            answers: [
                { text: `${(data.gameScoreObject as any)[t`Small`] + 1} ${t`Small Asteroids`}`, correct: false },
                { text: `${(data.gameScoreObject as any)[t`Small`]} ${t`Small Asteroids`}`, correct: true },
                { text: `${(data.gameScoreObject as any)[t`Small`] + 2} ${t`Small Asteroids`}`, correct: false },
            ],
        },
    ];

    return (
        <Slide header={defaultHeader}>
            <Row>
                <Col xs={12} md={{ offset: 3, span: 6 }} lg={{ offset: 4, span: 4 }} xl={{ offset: 3, span: 6 }}>
                    <Content type={'island'} styles={'p-3 mt-2'}>
                        <MultipleChoice
                            gameData={gameData}
                            onGameComplete={nextSlide}
                            onIncorrectAnswer={() => undefined}
                            incorrectModalDisabled={true}
                        />
                    </Content>
                </Col>
            </Row>
        </Slide>
    );
};

export default VariablesQuiz;
