import SealIntro from './SealIntro';
import TikTokFollow from './TikTokFollow';
import SealIntro2 from './SealIntro2';
import TikTokMessages from './TikTokMessages';
import TikTokChat from './TikTokChat';
import BlockUser from './BlockUser';
import UserDodge from './UserDodge';
import UserDodgePhisher from './UserDodgePhisher';
import LinkClicked from './LinkClicked';
import UserPhished from './UserPhished';
import UserPhishedPhisher from './UserPhishedPhisher';
import TurtleQuestion from './TurtleQuestion';
import WhaleIsland from '../3-fishfood/WhaleIsland';

export default [
    WhaleIsland,
    SealIntro,
    TikTokFollow,
    SealIntro2,
    TikTokMessages,
    TikTokChat,
    BlockUser,
    UserDodge,
    UserDodgePhisher,
    LinkClicked,
    UserPhished,
    UserPhishedPhisher,
    TurtleQuestion,
];
