import { TCharacter } from '../../../general/Character';
import image from '../assets/characters/2-fish.svg';
import { t } from 'ttag';

const Seal: TCharacter = {
    characterName: t`Fish`,
    characterImage: image,
    characterWidth: 100,
    characterHeight: 70,
};

export default Seal;
