import React, {useContext, useEffect} from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import CyberCard from '../../components/CyberCard';
import { t } from 'ttag';
import { CyberSecurityData } from '../..';
import VoiceoverContext from '../../../../misc/voiceover-context';

const LinkClicked: React.FC<ISlide<CyberSecurityData>> = ({ defaultHeader, nextSlide, data }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Next`}</Button>;
    const { playVoiceover } = useContext(VoiceoverContext);
    useEffect(() => {
        playVoiceover('fish-cyber-click');
    }, [])

    return (
        <Slide header={defaultHeader} footer={next}>
            <CyberCard
                title={t`Link Clicked!`}
                description={t`This was a suspicious message, you shouldn’t have clicked on the link. It could have taken you anywhere!`}
                points={-30}
                backgroundColor={'#B91010'}
                gameData={data}
                characterText={t`Oh no!`}
                showPoints={false}
            />
        </Slide>
    );
};

export default LinkClicked;
