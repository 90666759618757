import React from 'react';
import Slide, { ISlide } from '../../../../core/Slide';
import { Button } from 'react-bootstrap';
import { t } from 'ttag';
import room from '../../../../../assets/misc/th-room.svg';
import room2 from '../../../../../assets/misc/th-room--back.svg';
import vault from '../../../../../assets/misc/th-vault.svg';

const PartThree: React.FC<ISlide> = ({ defaultHeader, nextSlide }) => {
    let next = <Button className="btn-3d" onClick={() => nextSlide()}>{t`Start`}</Button>;

    return (
        <Slide header={defaultHeader} footer={next} backgroundColor={'#361559'}>
            <div className={`island island--rounded bg-secondary-700 th-part`}>
                <img src={room} className={'th-part__room'} width={'300px'} height={'350px'} alt="" />
                <img src={room2} className={'th-part__room2'} width={'300px'} height={'350px'} alt="" />
                <img src={vault} className={'th-part__vault'} width={'80px'} height={'80px'} alt="" />
            </div>
            <div className="th-part__text">
                <h4>{t`Part Three`}</h4>
                <p>{t`Opening the Vault`}</p>
            </div>
        </Slide>
    );
};

export default PartThree;
